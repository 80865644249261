import { useQuery } from "@apollo/client"
import { useEffect, useMemo, useRef, useState } from "react"
import Tilt from "react-parallax-tilt"
import { useHistory } from "react-router-dom"
import tw, { styled } from "twin.macro"

import AddCustomCard from "./components/AddCustomCard"
import { ReactComponent as ArrowRight } from "../assets/icons/arrow-right.svg"
import { ReactComponent as CloseX } from "../assets/icons/x.svg"
import { useGlobalState } from "../common/GlobalState"
import GradientButton from "../common/GradientButton"
import { useCurrentGift } from "../common/hooks/currentGift"
import { useFeatureAccess } from "../common/hooks/featureAccess"
import { useDeveloperModeContext } from "../common/hooks/useDeveloperModeContext"
import { OCCASIONS_QUERY } from "../common/occasions"
import { UpgradeToProMessageTag } from "../common/ProTag"
import {
  generateRealmPath,
  getRealmFromPath,
  getSegmentFromRealm,
} from "../common/realm"
import { CUSTOM_CARDS_FETCH } from "../customCards/graphql"

import {
  App_OccasionsQuery,
  CustomCardsFetchQuery,
} from "@/types/graphql-types"

interface Props {
  onSelectCard: (id: string, image: string) => void
  closeModal: () => void
  hideCustomCardRedirect?: boolean
}

const CardModal = ({
  onSelectCard,
  closeModal,
  hideCustomCardRedirect,
}: Props) => {
  const [currentGift] = useCurrentGift()
  const [selectedOccasion, setSelectedOccasion] = useState<string | null>(null)
  const selectedCategoryName = useGlobalState("selectedCategoryName")[0]
  const [currentRealm] = useGlobalState("currentRealm")
  const history = useHistory()
  const { enabled: devEnabled } = useDeveloperModeContext()

  const { hasFeature } = useFeatureAccess()

  const { data } = useQuery<App_OccasionsQuery>(OCCASIONS_QUERY, {
    variables: {
      segment: getSegmentFromRealm(getRealmFromPath(history.location.pathname)),
    },
  })
  const customCardsQuery = useQuery<CustomCardsFetchQuery>(CUSTOM_CARDS_FETCH)
  const scrollRef = useRef<HTMLDivElement | null>(null)

  const customCards = hasFeature("pro_plan")
    ? customCardsQuery.data?.me?.customCards
    : []
  const selectedCustomCards = selectedOccasion === "customCards"

  const cards = useMemo(() => {
    if (selectedCustomCards) {
      return customCards
    }

    if (selectedOccasion && data?.occasions) {
      for (const occ of data?.occasions) {
        if (occ.id === selectedOccasion) {
          return occ.cards
        }
      }

      return []
    } else {
      return []
    }
  }, [customCards, data?.occasions, selectedCustomCards, selectedOccasion])

  useEffect(() => {
    if (data?.occasions && data?.occasions.length > 0 && !selectedOccasion) {
      setSelectedOccasion(data.occasions[0].id)
    }
  }, [selectedOccasion, setSelectedOccasion, data])

  useEffect(() => {
    if (data?.occasions && data?.occasions.length > 0 && selectedCategoryName) {
      // This will match the selected store category with an occasion of the same name.
      const matchedOccasion = data.occasions.find(
        (occasion) => occasion.name === selectedCategoryName,
      )
      if (matchedOccasion) {
        setSelectedOccasion(matchedOccasion.id)
      }
    }
  }, [selectedCategoryName, setSelectedOccasion, data])

  const addCustomCard = () => {
    closeModal()
    history.push(generateRealmPath("plus", "/account/custom-cards"))
  }

  return (
    <div className="modal-content modal-content-extrawide">
      <div
        css={{
          height: "80vh",
        }}
      >
        <div
          css={{ background: "#f7f7f7" }}
          tw="flex flex-col md:flex-row h-full rounded-xl"
        >
          <Occasions>
            <div tw="pb-4 lg:pb-8 lg:pr-4 flex flex-row items-center justify-between">
              <OccasionsTitle>Cards</OccasionsTitle>
              <button
                onClick={closeModal}
                tw="block p-4 rounded-lg focus:outline-none hover:bg-gray-200 transition-colors duration-300"
              >
                <CloseX />
              </button>
            </div>
            <div tw="flex md:block md:flex-1 overflow-x-auto overflow-y-hidden md:overflow-x-hidden md:overflow-y-auto">
              {currentRealm !== "consumer" && (
                <OccasionsItem
                  selected={selectedCustomCards}
                  onClick={() => {
                    setSelectedOccasion("customCards")
                    scrollRef?.current?.scrollTo(0, 0)
                  }}
                >
                  Custom Cards
                </OccasionsItem>
              )}
              <OccasionsList>
                {data?.occasions &&
                  data.occasions?.map(({ id, name }) => (
                    <OccasionsItem
                      key={id}
                      onClick={() => {
                        setSelectedOccasion(id)
                        scrollRef?.current?.scrollTo(0, 0)
                      }}
                      selected={id === selectedOccasion}
                    >
                      {name}
                    </OccasionsItem>
                  ))}
              </OccasionsList>
            </div>
          </Occasions>
          <CardsContainer ref={scrollRef}>
            {cards && cards.length > 0 ? (
              <>
                <Cards>
                  {cards.map(({ id, imageLarge }) => (
                    <div key={id}>
                      <ImageTiltContainer
                        key={id}
                        onClick={() => {
                          onSelectCard(id, imageLarge.url)
                        }}
                      >
                        <Tilt
                          css={[
                            {
                              left: 0,
                              height: "100%",
                              position: "absolute",
                              width: "100%",
                              top: 0,
                              zIndex: 40,
                            },
                          ]}
                          scale={1.1}
                          transitionSpeed={5000}
                          tiltMaxAngleX={7.5}
                          tiltMaxAngleY={15}
                          tw="hidden md:block"
                        >
                          <FadeInImage src={imageLarge.url} />
                        </Tilt>
                        <FadeInImage src={imageLarge.url} tw="lg:hidden" />
                        {currentGift?.card?.id === id && (
                          <SelectedBadge>Selected</SelectedBadge>
                        )}
                      </ImageTiltContainer>
                      {devEnabled && (
                        <div tw="pt-4">
                          <div tw="text-gray-500 text-center font-medium text-sm">
                            Card ID
                          </div>
                          <input
                            tw="pt-1 pb-4 focus:outline-none w-full text-center text-gray-600 block"
                            value={id}
                            readOnly
                            onClick={(e) => {
                              e.stopPropagation()
                              e.preventDefault()
                              e.currentTarget.select()
                            }}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </Cards>
              </>
            ) : (
              <>{selectedCustomCards && <AddCustomCard />}</>
            )}
            {selectedCustomCards &&
              !hideCustomCardRedirect &&
              (hasFeature("pro_plan") ? (
                <Button onClick={addCustomCard}>
                  Add custom card
                  <ArrowRight tw="ml-4 md:ml-16" />
                </Button>
              ) : (
                <UpgradeToProMessageTag
                  tw="mt-14"
                  message="to upload custom cards"
                  feature="custom_cards"
                />
              ))}
          </CardsContainer>
        </div>
      </div>
    </div>
  )
}

// Fades in an image on load.
const FadeInImage = ({ src }: { src: string }) => {
  const [loaded, setLoaded] = useState(false)
  return <Image src={src} loaded={loaded} onLoad={() => setLoaded(true)} />
}

const Button = tw(GradientButton)`flex flex-shrink-0 justify-between mt-12`
const Cards = tw.div`gap-12 grid grid-cols-1 md:grid-cols-2 w-full`
const CardsContainer = tw.div`bg-white flex flex-col flex-1 items-center overflow-x-hidden overflow-y-auto p-8 md:p-12 rounded-xl shadow-xl w-full md:w-3/4`

const ImageTiltContainer = styled.button`
  ${tw`flex flex-col focus:outline-none focus-visible:outline-none relative w-full bg-gray-100 rounded-2xl`};
  padding-bottom: calc(100% * 1.414);
`

// <img> styled component that requires a loaded prop, which starts out
// at zero opacity, and fades in when loaded is true.
const Image = styled.img<{ loaded: boolean }>`
  ${tw`absolute h-full left-0 rounded-2xl shadow-lg hover:shadow-xl top-0 transition-all w-full opacity-0 transition-opacity duration-300`}
  ${(props) => props.loaded && tw`opacity-100`}
`

const Occasions = tw.div`p-4 pb-6 md:pl-5 md:pb-12 md:pt-8 md:pr-0 w-full md:w-1/4 flex flex-col md:h-full`
const OccasionsItem = styled.div<{ selected?: boolean }>(({ selected }) => [
  tw`cursor-pointer flex-shrink-0 hover:lg:bg-primary-050 hover:text-primary-600 px-4 py-3 rounded-full md:rounded-l-lg md:rounded-r-none md:text-lg leading-5 transition-colors`,
  selected &&
    tw`bg-primary-100 hover:bg-primary-100 font-medium text-primary-600`,
])
const OccasionsList = tw.ul`flex flex-row md:flex-col flex-shrink-0 overflow-x-hidden overflow-y-auto`
const OccasionsTitle = tw.h2`font-medium pl-2 md:pl-4 text-3xl`

const SelectedBadge = tw.div`absolute bg-primary-600 bottom-4 font-medium inline-block -mb-8 px-4 py-2 rounded-full self-center text-lg leading-5 text-white z-50`

export default CardModal
