import { gql, useMutation, useQuery } from "@apollo/client"
import React, { useCallback, useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import { Helmet } from "react-helmet-async"
import { toast } from "react-hot-toast"
import { Link, useHistory } from "react-router-dom"
import tw, { styled } from "twin.macro"

import AccountSection from "./components/AccountSection"
import AccountSupport from "./components/AccountSupport"
import DataPrivacyRequestButtons from "./dataPrivacyComponents/DataPrivacyRequestButtons"
import EmailVerification from "./sections/EmailVerification"
import { ReactComponent as FailedIcon } from "../assets/icons/failed.svg"
import { ReactComponent as ClearIcon } from "../assets/icons/icon-clear.svg"
import { ReactComponent as GoogleIcon } from "../assets/icons/logo-google.svg"
import { ReactComponent as MicrosoftIcon } from "../assets/icons/logo-microsoft.svg"
import SolidButton from "../common/Button"
import CommonButton from "../common/Button"
import { formatPrice } from "../common/format"
import { useGlobalState } from "../common/GlobalState"
import { useAuth, useAuthErrors } from "../common/hooks"
import { useFeatureAccess } from "../common/hooks/featureAccess"
import { ReactComponent as UploadIcon } from "../common/images/upload.svg"
import OmniAuthButton from "../common/OmniAuthButton"
import PasswordInputField from "../common/PasswordInputField"
import { UpgradeToProMessageTag } from "../common/ProTag"
import { generateRealmPath } from "../common/realm"
import { Loader } from "../common/UI"

import SamlBlock from "@/account/SamlBlock"
import { useFeatureFlags } from "@/common/hooks/featureFlags"
import { PlusEnrollmentStatus } from "@/types/graphql-types"
import {
  Account_MyAccountQuery,
  IdentityFragment,
  Identity_CreateMutation,
  Identity_CreateMutationVariables,
  Identity_UpdateMutation,
  Identity_UpdateMutationVariables,
} from "@/types/graphql-types"

const initialState = {
  firstName: "",
  lastName: "",
  companyTitle: "",
  companyName: "",
  email: "",
  oldPassword: "",
  oldPasswordError: "",
  newPassword: "",
  newPasswordError: "",
}

interface InitialState {
  firstName: string | null
  lastName: string | null
  companyTitle: string | null
  companyName: string | null
  email: string | null
  oldPassword: string | null
  oldPasswordError: string | null
  newPassword: string | null
  newPasswordError: string | null
}

const AccountGeneral = () => {
  const [signedIn] = useGlobalState("signedIn")
  const [enrollmentStatus] = useGlobalState("enrollmentStatus")
  const [form, setFormState] = useState<InitialState>(initialState)

  const history = useHistory()

  useAuthErrors()

  const {
    data: newData,
    refetch,
    previousData,
  } = useQuery<Account_MyAccountQuery>(ACCOUNT_QUERY)

  const { hasFeature } = useFeatureAccess()
  const { hasFeatureFlag } = useFeatureFlags()

  // For issue where newData is not set and previousData is
  // Very intermittent, not sure why it's happening
  const data = newData || previousData

  const [editUser, { loading }] = useMutation(USER_EDIT)

  const editPlusUser = async () => {
    const user = await editUser({
      variables: {
        firstName: form.firstName,
        lastName: form.lastName,
        companyName: form.companyName,
        companyTitle: form.companyTitle,
      },
    })
    if (user?.data?.userEdit?.ok) {
      // Root should refresh
      toast.success("Profile updated!", {
        iconTheme: {
          primary: "#27ae60",
          secondary: "#fff",
        },
      })
    } else {
      if (user?.data?.userEdit?.error) {
        alert(user.data.userEdit.error)
      }
    }
  }

  const me = data?.me

  useEffect(() => {
    setFormState({
      ...form,
      ...me,
    })
    refetch()
  }, [data])

  const updateField =
    (name: string) =>
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      setFormState({
        ...form,
        [name]: value,
      })
    }

  useEffect(() => {
    if (enrollmentStatus === PlusEnrollmentStatus.NONE) {
      history.replace(generateRealmPath("business", "/signup"))
    }
  }, [enrollmentStatus, history])

  // Refetch whenever signedIn, enrolled, or visible changes.
  // User state might have changed. Also, we refetch on visible so we can get
  // the most updated data, especially for credits.
  useEffect(() => {
    refetch()
  }, [signedIn, enrollmentStatus])

  enum PasswordState {
    New,
    Create,
    Edit,
    Update,
  }
  const [passwordFormState, setPasswordFormState] = useState(PasswordState.New)

  const identities = me?.identities || []
  const [passwordIdentity, setPasswordIdentity] = useState<IdentityFragment>()
  const [linkedGoogleAccount, setLinkedGoogleAccount] = useState<
    { email: string } | undefined
  >()
  const [linkedMicrosoftAccount, setLinkedMicrosoftAccount] = useState<
    { email: string } | undefined
  >()
  const [canUnlinkAccount, setCanUnlinkAccount] = useState<boolean>(false)
  const { handleUnlinkOpenid } = useAuth()

  useEffect(() => {
    // Resets linked account status
    setLinkedGoogleAccount(undefined)
    setLinkedMicrosoftAccount(undefined)

    for (const identity of identities) {
      if (identity.hasPassword) {
        setFormState((state) => ({
          ...state,
          email: identity.email ?? null,
          oldPassword: "",
          oldPasswordError: "",
          newPassword: "",
          newPasswordError: "",
        }))

        setPasswordIdentity(identity)
        setPasswordFormState(PasswordState.Edit)
        continue
      }

      if (identity.provider === "google") {
        setLinkedGoogleAccount({ email: `${identity.email}` })
        continue
      }

      if (identity.provider === "microsoft") {
        setLinkedMicrosoftAccount({ email: `${identity.email}` })
        continue
      }
    }

    if (identities.length > 1) {
      setCanUnlinkAccount(true)
    }
  }, [identities])

  const [createIdentity, { loading: createIdentityLoading }] = useMutation<
    Identity_CreateMutation,
    Identity_CreateMutationVariables
  >(IDENTITY_CREATE)

  const [updateIdentity, { loading: updateIdentityLoading }] = useMutation<
    Identity_UpdateMutation,
    Identity_UpdateMutationVariables
  >(IDENTITY_UPDATE)

  const newPassword = async () => {
    setPasswordFormState(PasswordState.Create)
  }

  const createPassword = async () => {
    setFormState((state) => ({
      ...state,
      newPasswordError: "",
    }))

    if (form.newPassword === "" || !form.newPassword) {
      setFormState((state) => ({ ...state, newPasswordError: "Required." }))
      return
    }

    const { data } = await createIdentity({
      variables: {
        password: form.newPassword,
      },
    })

    if (data?.identityCreate?.ok) {
      setPasswordFormState(PasswordState.Edit)
      if (data?.identityCreate?.identity) {
        setPasswordIdentity(data?.identityCreate?.identity)
      }
      setFormState((state) => ({ ...state, newPassword: "" }))

      toast.success("Password created!", {
        iconTheme: {
          primary: "#27ae60",
          secondary: "#fff",
        },
      })
      refetch()
    } else {
      if (data?.identityCreate?.error) {
        const result = data.identityCreate

        if (result.errorIsPassword) {
          setFormState((state) => ({
            ...state,
            newPasswordError: result.error ?? null,
          }))
        } else {
          alert(result.error)
        }
      }
    }
  }

  const editPassword = async () => {
    setPasswordFormState(PasswordState.Update)
  }

  const updatePassword = async () => {
    setFormState((state) => ({
      ...state,
      oldPasswordError: "",
      newPasswordError: "",
    }))

    if (form.oldPassword == "") {
      setFormState((state) => ({ ...state, oldPasswordError: "Required." }))
      return
    }

    if (form.newPassword == "") {
      setFormState((state) => ({ ...state, newPasswordError: "Required." }))
      return
    }

    if (!passwordIdentity?.id || !form.oldPassword) {
      return
    }

    const identity = await updateIdentity({
      variables: {
        id: passwordIdentity?.id,
        password: form.oldPassword,
        newPassword: form.newPassword,
      },
    })

    if (identity?.data?.identityUpdate?.ok) {
      setPasswordFormState(PasswordState.Edit)
      setFormState((state) => ({
        ...state,
        oldPassword: "",
        newPassword: "",
      }))

      toast.success("Password updated!", {
        iconTheme: {
          primary: "#27ae60",
          secondary: "#fff",
        },
      })
    } else {
      if (identity?.data?.identityUpdate?.error) {
        const error = identity.data.identityUpdate.error

        if (identity.data.identityUpdate.errorIsPassword) {
          setFormState((state) => ({ ...state, oldPasswordError: error }))
        } else if (identity.data.identityUpdate.errorIsNewPassword) {
          setFormState((state) => ({ ...state, newPasswordError: error }))
        } else {
          alert(error)
        }
      }
    }
  }

  const hasStoredValue = !!data?.workspace?.organization?.storedValue
  const hasStoredValueTransactions =
    !!data?.workspace?.organization?.storedValueTransactions?.totalCount

  // Show the Corporate Account section if the user has the stored value
  // feature, AND either or both of the following:
  // - Org has stored value.
  // - Org has stored value transactions (must be admin to see).
  const showCorporateAccount =
    hasFeature("payment_stored_value") &&
    (hasStoredValue || hasStoredValueTransactions)

  if (enrollmentStatus === PlusEnrollmentStatus.NONE) {
    return null
  }

  if (!me) {
    return null
  }

  const tryUnlinkAccount = (providerName: string) => {
    const isSure = window.confirm(
      `Are you sure you want to unlink your ${providerName} Account? You will no longer be able to sign in using your ${providerName} Account.`,
    )

    if (isSure) {
      handleUnlinkOpenid({
        provider: providerName.toLowerCase(),
        onSuccess: () => {
          refetch()
        },
      })
    }
  }
  const tryUnlinkGoogle = () => tryUnlinkAccount("Google")
  const tryUnlinkMicrosoft = () => tryUnlinkAccount("Microsoft")

  const passwordUserInputs = [
    form.firstName,
    form.lastName,
    form.companyName,
  ].filter((i) => i != null) as string[]

  return (
    <>
      <Helmet>
        <title>Account – Goody for Business</title>
      </Helmet>
      <div tw="container mx-auto bg-white">
        <div tw="p-6 pt-12">
          {hasFeatureFlag("saml") && me.samlBlocked && (
            <SamlBlock email={me.email} />
          )}
          <div tw="flex flex-row items-end pb-12">
            {me.credit > 0 ? (
              <div tw="flex flex-row items-center">
                {/* If we're in the middle of sending gifts, don't display a
                changing credit value. */}
                {me.hasSendingInProgressGiftBatch ? (
                  <div tw="text-lg text-gray-500 pl-2">
                    Currently sending gifts and recalculating credit. Check back
                    shortly.
                  </div>
                ) : (
                  <>
                    <div tw="bg-green-100 text-green-600 font-medium px-4 py-1 rounded-full text-lg">
                      {formatPrice(me.credit, true)}
                    </div>
                    <div tw="text-lg text-gray-500 pl-2">credit available</div>
                  </>
                )}
              </div>
            ) : null}
          </div>
          <AccountSection headline="Logo">
            {!hasFeature("pro_plan") ? (
              <UpgradeToProMessageTag
                feature="custom_logo"
                message="to add a logo to your gift sends."
              />
            ) : (
              <>
                <div tw="flex flex-col md:flex-row">
                  <LogoPreview src={me.logoUrl} />
                  <LogoUpload
                    onChange={refetch}
                    logoExists={me.logoUrl != null}
                  />
                </div>
              </>
            )}
          </AccountSection>
          {showCorporateAccount && (
            <AccountSection headline="Corporate Account">
              <div tw="p-4 border border-gray-200 rounded-lg flex flex-row items-center">
                {data?.workspace?.organization?.storedValue !== undefined && (
                  <div tw="flex flex-row items-center">
                    <div tw="bg-green-100 text-green-600 font-medium px-4 py-1 rounded-full text-lg">
                      {formatPrice(
                        data.workspace.organization.storedValue,
                        true,
                      )}
                    </div>
                    <div tw="text-lg text-gray-500 pl-2">
                      stored value available
                    </div>
                  </div>
                )}
                {data?.workspace?.organization?.storedValueTransactions
                  ?.totalCount &&
                data?.workspace?.organization?.storedValueTransactions
                  ?.totalCount > 0 ? (
                  <div tw="pl-10">
                    <CommonButton
                      onClick={() => {
                        history.push(
                          generateRealmPath(
                            "plus",
                            "/organization/stored-value",
                          ),
                        )
                      }}
                    >
                      See stored value transactions
                    </CommonButton>
                  </div>
                ) : null}
              </div>
            </AccountSection>
          )}
          <AccountSection headline="Receipts">
            <div>
              Receipts have moved to the&nbsp;<strong>Receipts</strong>
              &nbsp;tab.
              <Link
                to={generateRealmPath("plus", "/account/receipts")}
                tw="inline-flex items-center bg-primary-500 hover:bg-primary-600 transition-colors text-white px-3 h-9 ml-2 rounded-lg"
              >
                Click to open
              </Link>
            </div>
          </AccountSection>
          {me?.plusEnrollmentStatus === PlusEnrollmentStatus.LIMITED && (
            <EmailVerification />
          )}
          <AccountSection headline="Profile">
            <div tw="grid grid-cols-2 grid-rows-2 gap-6 w-full">
              <div>
                <Label>Email</Label>
                <TextInputField type="text" value={me.email} disabled />
              </div>
              <div />
              <div>
                <Label>First name</Label>
                <TextInputField
                  type="text"
                  placeholder="First name"
                  value={form.firstName || ""}
                  onChange={updateField("firstName")}
                />
              </div>
              <div>
                <Label>Last name</Label>
                <TextInputField
                  type="text"
                  placeholder="Last name"
                  value={form.lastName || ""}
                  onChange={updateField("lastName")}
                />
              </div>
              <div>
                <Label>Company</Label>
                <TextInputField
                  type="text"
                  placeholder="Company"
                  value={form.companyName || ""}
                  onChange={updateField("companyName")}
                />
              </div>
              <div>
                <Label>Title</Label>
                <TextInputField
                  type="text"
                  placeholder="Title"
                  value={form.companyTitle || ""}
                  onChange={updateField("companyTitle")}
                />
              </div>
              <div tw="flex justify-start">
                <SolidButton onClick={editPlusUser} disabled={loading}>
                  Update
                </SolidButton>
              </div>
            </div>
          </AccountSection>
          <AccountSection headline="Linked accounts">
            <ul tw="w-full max-w-full md:max-w-lg space-y-6">
              <li>
                {linkedGoogleAccount ? (
                  <div tw="w-full flex border border-primary-100 rounded-lg p-2 pr-4">
                    <div tw="flex-none self-center">
                      <GoogleIcon
                        style={{
                          width: "48px",
                          height: "48px",
                          display: "inline",
                        }}
                      />
                    </div>
                    <div tw="flex-auto self-center">
                      Linked with {linkedGoogleAccount.email}
                    </div>
                    {canUnlinkAccount && (
                      <div tw="flex-none self-center">
                        <SolidButton
                          variant="primary"
                          onClick={tryUnlinkGoogle}
                        >
                          Unlink
                        </SolidButton>
                      </div>
                    )}
                  </div>
                ) : (
                  <OmniAuthButton
                    provider="google"
                    labelText="Link account with"
                    origin="/account"
                  />
                )}
              </li>
              <li>
                {linkedMicrosoftAccount ? (
                  <div tw="w-full flex border border-primary-100 rounded-lg p-2 pr-4">
                    <div tw="flex-none self-center">
                      <MicrosoftIcon
                        style={{
                          width: "24px",
                          height: "24px",
                          margin: "12px",
                          display: "inline",
                        }}
                      />
                    </div>
                    <div tw="flex-auto self-center">
                      Linked with {linkedMicrosoftAccount.email}
                    </div>
                    {canUnlinkAccount && (
                      <div tw="flex-none self-center">
                        <SolidButton
                          variant="primary"
                          onClick={tryUnlinkMicrosoft}
                        >
                          Unlink
                        </SolidButton>
                      </div>
                    )}
                  </div>
                ) : (
                  <OmniAuthButton
                    provider="microsoft"
                    labelText="Link account with"
                    origin="/account"
                  />
                )}
              </li>
            </ul>
          </AccountSection>
          <AccountSection headline="Password">
            {passwordFormState === PasswordState.New && (
              <div>
                <p>
                  You haven’t set a password yet since you’re using phone number
                  or identity provider login.
                </p>
                <p tw="mb-2">
                  If you set a password, you can log in with your email and
                  password as well.
                </p>
                <SolidButton onClick={newPassword}>Set password</SolidButton>
              </div>
            )}
            {passwordFormState === PasswordState.Create && (
              <div tw="flex flex-col gap-6 w-full">
                <div>
                  <Label>New password</Label>
                  <PasswordInputField
                    error={form.newPasswordError || ""}
                    placeholder="Enter a new password"
                    onChange={updateField("newPassword")}
                    value={form.newPassword || ""}
                    userInputs={passwordUserInputs}
                    className="data-hj-suppress ph-no-capture fs-exclude"
                  />
                </div>
                <div tw="flex justify-start">
                  <SolidButton
                    variant="primary"
                    onClick={createPassword}
                    disabled={createIdentityLoading}
                  >
                    Create password
                  </SolidButton>
                </div>
              </div>
            )}
            {passwordFormState === PasswordState.Edit && (
              <div>
                <SolidButton onClick={editPassword}>
                  Change password
                </SolidButton>
              </div>
            )}
            {passwordFormState === PasswordState.Update && (
              <div tw="grid grid-cols-1 grid-rows-2 gap-6 w-6/12">
                <div>
                  <Label>Current password</Label>
                  <TextInputField
                    type="password"
                    error={form.oldPasswordError || ""}
                    placeholder="Enter your current password"
                    onChange={updateField("oldPassword")}
                    className="data-hj-suppress ph-no-capture fs-exclude"
                  />
                </div>
                <div>
                  <Label>New password</Label>
                  <PasswordInputField
                    error={form.newPasswordError || ""}
                    placeholder="Enter a new password"
                    onChange={updateField("newPassword")}
                    value={form.newPassword || ""}
                    userInputs={passwordUserInputs}
                    className="data-hj-suppress ph-no-capture fs-exclude"
                  />
                </div>
                <div tw="flex justify-start">
                  <SolidButton
                    variant="primary"
                    onClick={updatePassword}
                    disabled={updateIdentityLoading}
                  >
                    Update password
                  </SolidButton>
                </div>
              </div>
            )}
          </AccountSection>
          <DataPrivacyRequestButtons />
          <AccountSupport />
        </div>
      </div>
    </>
  )
}

interface LogoUploadProps {
  logoExists: boolean
  onChange: () => void
}
const LogoUpload: React.FC<LogoUploadProps> = ({ onChange, logoExists }) => {
  const [updateMode, setUpdateMode] = useState<"Uploading" | "Removing">(
    "Uploading",
  )
  const [validationError, setValidationError] = useState<string | null>(null)
  const [upload, { loading: isUploading, error: uploadError }] =
    useMutation(LOGO_UPLOAD)

  useEffect(() => {
    if (isUploading) {
      setValidationError(null)
    }
  }, [isUploading])

  const replaceLogoFile = async (logoFile: File | null) => {
    setUpdateMode(logoFile ? "Uploading" : "Removing")
    const {
      data: {
        userEdit: { error },
      },
    } = await upload({ variables: { logoFile } })

    if (error) {
      setValidationError(error)
    } else {
      onChange()
    }
  }

  const onDrop = useCallback((acceptedFiles: any[]) => {
    const logoFile = acceptedFiles[0]
    if (logoFile) {
      replaceLogoFile(logoFile)
    } else {
      setValidationError("Please provide a PNG or JPEG file.")
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/png, image/jpeg",
  })

  const handleDelete = () => {
    if (window.confirm("Do you really want to remove the logo?")) {
      replaceLogoFile(null)
    }
  }

  const errorMessage = uploadError?.message || validationError

  const dropArea = isUploading ? (
    <>
      <Loader tw="mb-4" />
      {updateMode}…
    </>
  ) : (
    <>
      {errorMessage ? (
        <>
          <FailedIcon tw="stroke-current mb-3 mt-1 opacity-70" />
          <p tw="mb-1">
            <strong>{errorMessage}</strong>
          </p>
        </>
      ) : (
        <>
          <UploadIcon tw="mb-4 text-gray-500 stroke-current stroke-1.5" />
          <p tw="mb-1">
            <strong>Drop image or click to upload</strong>
          </p>
        </>
      )}
      <p tw="text-sm text-gray-500">PNG preferred, 250px width minimum</p>
    </>
  )

  const headerStyle = tw`leading-7 font-medium`

  return (
    <div
      tw="rounded-lg p-2 flex flex-col flex-1 border border-transparent transition-all duration-75 my-4 md:my-0"
      css={{
        alignContent: "stretch",
        ...(isDragActive ? tw`bg-none border-gray-200` : tw`bg-gray-100`),
      }}
    >
      <div tw="opacity-50 mb-2">
        {logoExists ? (
          <div tw="flex justify-between items-stretch">
            <h3 tw="ml-2" css={headerStyle}>
              Replace Logo
            </h3>
            <button
              onClick={handleDelete}
              tw="border border-gray-400 rounded text-sm text-gray-800 flex px-1 items-center hover:border-gray-600 transition-colors"
            >
              <ClearIcon tw="stroke-current mx-1 opacity-70" />
              <span tw="mx-1">Remove logo</span>
            </button>
          </div>
        ) : (
          <h3 tw="text-center" css={headerStyle}>
            Upload logo
          </h3>
        )}
      </div>
      <div
        {...getRootProps()}
        tw="p-4 flex-grow rounded-lg flex flex-col items-center transition-all duration-75 text-center"
        css={{
          justifyContent: "center",
          ...(isDragActive
            ? tw`bg-primary-000 border-2 border-dashed border-primary-300`
            : tw`bg-white`),
          ...(errorMessage ? tw`text-red-600` : {}),
        }}
      >
        <input {...getInputProps()} />
        {dropArea}
      </div>
    </div>
  )
}

interface LogoPreviewProps {
  src?: string | null
}
const LogoPreview = ({ src }: LogoPreviewProps) => {
  const previewContent = src ? (
    <img
      src={src}
      alt="Logo"
      tw="w-full h-full object-contain px-16 py-16 md:py-4"
    />
  ) : (
    <p tw="p-12 text-sm text-center text-gray-500">
      Upload a logo to have it appear on your emails and gift acceptance pages.
    </p>
  )

  return (
    <div
      tw="shadow-lg rounded-lg flex items-center overflow-hidden h-44 flex-1 mr-8 w-full"
      css={{ justifyContent: "center" }}
    >
      {previewContent}
    </div>
  )
}

interface TextInputProps {
  error: boolean
}

const TextInput = styled.input<TextInputProps>`
  ${tw`w-full p-4 border border-solid rounded-lg border-gray-200 focus:outline-none transition-colors`}
  ${({ error }) =>
    error ? tw`border-red-500` : tw`focus:border-primary-600 border-gray-200`}
`

const TextInputMessage = styled.div`
  ${tw`text-sm mt-1.5 opacity-50`}
`

const TextInputError = styled.div.attrs({ className: "text-input-error" })`
  ${tw`text-sm mt-1.5 text-red-500`}
`

type TextInputFieldProps = {
  message?: string
  error?: string
} & React.InputHTMLAttributes<HTMLInputElement>

const TextInputField: React.FC<TextInputFieldProps> = (
  props: TextInputFieldProps,
) => {
  const { error, message, ...restProps } = props
  return (
    <TextInputContainer>
      <TextInput error={!!error} {...restProps} />
      {
        error ? (
          <TextInputError>{error}</TextInputError>
        ) : message ? (
          <TextInputMessage>{message}</TextInputMessage>
        ) : null
        // <TextInputSpace />
      }
    </TextInputContainer>
  )
}

const Label = styled.label`
  ${tw`text-gray-500 mb-2 block`}
`

const TextInputContainer = styled.div`
  ${tw`flex flex-1 first:ml-0 flex-col`}
`

const LOGO_UPLOAD = gql`
  mutation Logo_Upload($logoFile: Upload) {
    userEdit(userFields: { logoFile: $logoFile }) {
      ok
      error
    }
  }
`

const USER_EDIT = gql`
  mutation User_Edit(
    $firstName: String
    $lastName: String
    $companyName: String
    $companyTitle: String
  ) {
    userEdit(
      userFields: {
        firstName: $firstName
        lastName: $lastName
        companyName: $companyName
        companyTitle: $companyTitle
      }
    ) {
      ok
      error
    }
  }
`

const IDENTITY_FRAGMENT = gql`
  fragment Identity on Identity {
    id
    provider
    email
    hasPassword
    updatedAt
  }
`

const IDENTITY_CREATE = gql`
  mutation Identity_Create($password: String!) {
    identityCreate(password: $password) {
      ok
      error
      errorIsEmail
      errorIsPassword
      identity {
        ...Identity
      }
    }
  }
  ${IDENTITY_FRAGMENT}
`

const IDENTITY_UPDATE = gql`
  mutation Identity_Update($id: ID!, $password: String!, $newPassword: String) {
    identityUpdate(id: $id, password: $password, newPassword: $newPassword) {
      ok
      error
      errorIsPassword
      errorIsNewPassword
      identity {
        ...Identity
      }
    }
  }
  ${IDENTITY_FRAGMENT}
`

const ACCOUNT_QUERY = gql`
  query Account_MyAccount {
    me {
      id
      logoUrl
      firstName
      lastName
      email
      companyTitle
      companyName
      credit
      hasSendingInProgressGiftBatch
      plusEnrollmentStatus
      samlBlocked
      batchBillingGroups {
        id
        receiptId
        amountNetCharges
        paymentMethodName
        createdAt
      }
      identities {
        ...Identity
      }
    }
    workspace {
      organization {
        id
        storedValue
        storedValueTransactions {
          totalCount
        }
      }
    }
  }
  ${IDENTITY_FRAGMENT}
`

export default AccountGeneral
