import React from "react"
import tw from "twin.macro"

import { ReactComponent as CheckIcon } from "./images/check.svg"

interface Props {
  completed: boolean
  selected: boolean
}

export default function ChecklistIcon({ completed, selected }: Props) {
  return (
    <div
      tw="relative flex rounded-full h-5 w-5 min-w-[20px] justify-center items-center border-1.5"
      css={[
        selected && tw`bg-white border-primary-new-300`,
        completed && tw`bg-green-400 border-green-400`,
      ]}
    >
      {completed && <CheckIcon />}
    </div>
  )
}
