import { gql } from "@apollo/client"

export const BASE_ME_FRAGMENT = gql`
  fragment BaseMe on Me {
    id
    publicId
    plusEnrollmentStatus
    firstName
    lastName
    logoUrl
    isFullOrganizationMember
    workspaces {
      id
      name
      ttype
      role
      permissions {
        inviteMembersPermission
      }
    }
    lastUsedWorkspace {
      id
      name
      ttype
      role
      permissions {
        inviteMembersPermission
      }
    }
    featureAccess
    email
    companyName
    lastRealm
    isOrganizationAdmin
    hasCreatedAGiftBatch
    balance
    balanceAccount {
      id
      balance
      balanceExpiresAt
    }
    budgetRule {
      id
      amount
      recurrence
    }
    lastSendAudience
    dismissedScreens
    plusLimitedGiftCount
    userSignalsChannel
    staffAccessActive
    hasPaidPlan
    hasBalanceTransaction
    isDelinquent
    plusEnrolledAt
    credit
  }
`
