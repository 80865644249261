import { isNil } from "lodash-es"
import React, { useMemo, useState } from "react"
import tw, { styled } from "twin.macro"

import { ChevronTiny } from "../../assets/icons"
import { formatPrice, formatPriceOrNull } from "../../common/format"
import { getProductName } from "../../common/gifts"
import { useCurrentGift } from "../../common/hooks/currentGift"
import RemoveCreditButton from "../../common/RemoveCreditButton"

import { ReactComponent as InfoIcon } from "@/assets/icons/info.svg"
import Tooltip from "@/common/Tooltip"
import { GIFT_CARDS_BRAND_NAME } from "@/store/utils"
import { BatchSendMethod, GiftSwapTypeEnum } from "@/types/graphql-types"
import { Send_PriceEstimateMutation } from "@/types/graphql-types"

interface Props {
  priceEstimate: Send_PriceEstimateMutation["priceEstimate"]
  isAlcohol?: boolean
  isPlus: boolean
  hideTax: boolean
  showProductsDefault?: boolean
  noValidPromoCode?: boolean | null
  runPriceEstimate?: (calculatedTaxEstimate?: boolean) => void
  priceEstimateLoading?: boolean
  priceEstimateIsCalculated?: boolean
  sendV3?: boolean
}

// The sendV3 version of this price estimate table hides the product rows and
// subtotal expandable (since the products are rendered in the <ProductList>
// component along with their prices), and instead displays the shipping costs
// for each brand in the cart, aggregated by brand.
const PriceEstimateTable: React.FC<Props> = ({
  priceEstimate,
  isAlcohol,
  isPlus,
  hideTax,
  showProductsDefault,
  noValidPromoCode,
  runPriceEstimate,
  priceEstimateLoading,
  priceEstimateIsCalculated,
  sendV3,
}) => {
  const [currentGift] = useCurrentGift()
  const [showProducts, setShowProducts] = useState(
    showProductsDefault !== undefined ? showProductsDefault : true,
  )
  const products = currentGift.cart

  const { cartPriceEstimate, totalPriceEstimate } = priceEstimate

  // TODO: bring back "US Shipping"

  const brandShippings = useMemo(() => {
    const shippingAmounts: { [key: string]: number } = {}
    products.forEach((product, index) => {
      const productPriceEstimate = priceEstimate.productPriceEstimates[index]
      shippingAmounts[product.brandID] =
        (shippingAmounts[product.brandID] ?? 0) +
        productPriceEstimate.priceShipping
    })
    return shippingAmounts
  }, [products])

  const brandIDToNameMapping = useMemo(() => {
    const brandMapping: { [key: string]: string } = {}
    products.forEach((product, index) => {
      brandMapping[product.brandID] = product.brandName
    })
    return brandMapping
  }, [products])

  const showEstimateLoading = !!(
    priceEstimateLoading && priceEstimateIsCalculated
  )

  const isCalculatedEstimate = !isNil(totalPriceEstimate.estTaxCalculated)
  const isFlexGift = products.some((product) => product.isFlexGift)

  // Is gift card if at least one product in the cart is a gift card
  const isGiftCard = products.some(
    (product) => product.brandName === GIFT_CARDS_BRAND_NAME,
  )

  return (
    <EstimateTable tw="w-full">
      <tbody>
        {!sendV3 && (
          <tr>
            <td style={{ width: "65%" }}>
              <button
                tw="flex flex-row items-center"
                onClick={() => setShowProducts((prevState) => !prevState)}
              >
                Subtotal
                <ChevronContainer rotated={showProducts} tw="ml-1.5">
                  <ChevronTiny />
                </ChevronContainer>
              </button>
            </td>
            <td tw="text-right w-[48%]">
              {formatPriceOrNull(
                cartPriceEstimate.priceProduct +
                  cartPriceEstimate.priceShipping,
              )}
            </td>
          </tr>
        )}
        {!sendV3 &&
          products.map((product, index) => {
            const productPriceEstimate =
              priceEstimate.productPriceEstimates[index]
            const brandShipping = brandShippings[product.brandID]

            return (
              <React.Fragment key={product.id}>
                <ProductRow shown={showProducts}>
                  <td tw="pl-4">
                    {product.brandName} – {getProductName(product)}
                    {product.quantity > 1 ? ` (×${product.quantity})` : ""}
                  </td>
                  <td tw="text-right" width="40%">
                    {formatPriceOrNull(
                      productPriceEstimate.priceProduct * product.quantity,
                    )}
                  </td>
                </ProductRow>
                {products[index + 1]?.brandID !== product.brandID && (
                  <ProductRow tw="text-gray-450" shown={showProducts}>
                    <td tw="pl-4">
                      {product.brandName} shipping
                      {brandShipping === 0 ? (
                        <span>
                          {product?.isFlexGift ? " (Included)" : " (Free)"}
                        </span>
                      ) : (
                        ""
                      )}
                    </td>
                    <td tw="text-right">{formatPriceOrNull(brandShipping)}</td>
                  </ProductRow>
                )}
              </React.Fragment>
            )
          })}
        {sendV3 &&
          Object.keys(brandShippings).map((brandID) => {
            const brandShipping = brandShippings[brandID]
            const brandName = brandIDToNameMapping[brandID]

            if (!brandName) {
              return null
            }

            return (
              <ProductRow key={brandID} shown={true}>
                <td>{brandName} shipping</td>
                <td tw="text-right">
                  {brandShipping === 0
                    ? isFlexGift
                      ? "Included"
                      : "Free"
                    : formatPrice(brandShipping)}
                </td>
              </ProductRow>
            )
          })}
        {isPlus && (
          <tr>
            <td>5% Goody for Business fee {isAlcohol && "(exempt)"}</td>
            <td tw="text-right">
              {formatPriceOrNull(cartPriceEstimate.priceProcessingFee)}
            </td>
          </tr>
        )}
        {!hideTax && !isCalculatedEstimate && (
          <tr>
            <td>
              {totalPriceEstimate.taxText || "Estimated tax"}
              {currentGift.sendMethod === BatchSendMethod.direct_send &&
                isPlus && (
                  <CalculateButton
                    onClick={() => runPriceEstimate?.(true)}
                    showLoading={showEstimateLoading}
                  />
                )}
              {isGiftCard &&
                currentGift.swapType !== GiftSwapTypeEnum.swap_disabled && (
                  <Tooltip
                    trigger={
                      <span tw="inline-block align-top ml-1">
                        <InfoIcon tw="text-gray-500 h-5 w-5" />
                      </span>
                    }
                    title="Swap is enabled"
                    placement="auto"
                  >
                    <p>
                      If a recipient swaps for a non-gift card, sales tax will
                      apply on that gift. If you don't want to be charged tax,
                      disable swap on your gift.
                    </p>
                  </Tooltip>
                )}
            </td>
            <td tw="text-right">
              {cartPriceEstimate.priceEstTaxLow ===
              cartPriceEstimate.priceEstTaxHigh ? (
                formatPriceOrNull(cartPriceEstimate.priceEstTaxLow)
              ) : (
                <>
                  {formatPriceOrNull(cartPriceEstimate.priceEstTaxLow)} –{" "}
                  {formatPriceOrNull(cartPriceEstimate.priceEstTaxHigh)}
                </>
              )}
            </td>
          </tr>
        )}
        {isPlus ? (
          <>
            {!isCalculatedEstimate && (
              <tr>
                <td tw="font-semibold">Per recipient</td>
                <td tw="font-semibold text-right">
                  {formatPriceOrNull(cartPriceEstimate.priceEstTotalLow)} –{" "}
                  {formatPriceOrNull(cartPriceEstimate.priceEstTotalHigh)}
                </td>
              </tr>
            )}
            {totalPriceEstimate.recipients === 0 ? (
              <tr>
                <td tw="text-gray-600 text-center pt-3" colSpan={2}>
                  Add recipients to see total.
                </td>
              </tr>
            ) : (
              <>
                <tr>
                  <td>
                    {totalPriceEstimate.recipients} recipient
                    {totalPriceEstimate.recipients === 1 ? "" : "s"}
                  </td>
                  <td tw="text-right">× {totalPriceEstimate.recipients}</td>
                </tr>
                {isCalculatedEstimate && (
                  <>
                    <tr>
                      <td>Estimated subtotal</td>
                      <td tw="text-right">
                        {formatPriceOrNull(
                          totalPriceEstimate.estGroupTotalHigh -
                            totalPriceEstimate.estTaxCalculated!,
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Estimated calculated tax</td>
                      <td tw="text-right">
                        {formatPrice(totalPriceEstimate.estTaxCalculated!)}
                      </td>
                    </tr>
                  </>
                )}
                {totalPriceEstimate.creditApplied !== undefined &&
                  totalPriceEstimate.creditApplied > 0 && (
                    <>
                      {!isCalculatedEstimate && (
                        <tr>
                          <td tw="font-medium">Pre-credit subtotal</td>
                          <td tw="font-medium text-right">
                            {formatPriceOrNull(
                              totalPriceEstimate.estPreCreditSubtotalLow,
                            )}{" "}
                            –{" "}
                            {formatPriceOrNull(
                              priceEstimate.totalPriceEstimate
                                .estPreCreditSubtotalHigh,
                            )}
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td>
                          <div>
                            <span tw="font-semibold text-green-500 whitespace-nowrap">
                              Credit applied
                            </span>{" "}
                            {noValidPromoCode && <RemoveCreditButton />}
                          </div>
                          <div tw="text-sm text-green-500">
                            {formatPriceOrNull(
                              totalPriceEstimate.creditAvailable,
                            )}{" "}
                            credit available
                            {isPlus && <RemoveCreditButton small />}
                          </div>
                        </td>
                        <td tw="font-medium text-right text-green-500">
                          –{" "}
                          {formatPriceOrNull(totalPriceEstimate.creditApplied)}
                        </td>
                      </tr>
                    </>
                  )}
                <tr>
                  <td tw="font-semibold">
                    Estimated total
                    {currentGift.sendMethod === BatchSendMethod.direct_send && (
                      <CalculateButton
                        onClick={() => runPriceEstimate?.(true)}
                        showLoading={showEstimateLoading}
                      />
                    )}
                  </td>
                  <td tw="font-semibold text-right">
                    {totalPriceEstimate.recipients !== 0 &&
                    totalPriceEstimate.estGroupTotalLow !== undefined &&
                    totalPriceEstimate.estGroupTotalHigh !== undefined ? (
                      <>
                        {totalPriceEstimate.estGroupTotalLow === 0 &&
                        totalPriceEstimate.estGroupTotalHigh === 0
                          ? "Free"
                          : totalPriceEstimate.estGroupTotalLow ===
                            totalPriceEstimate.estGroupTotalHigh
                          ? formatPrice(totalPriceEstimate.estGroupTotalLow)
                          : `${formatPrice(
                              totalPriceEstimate.estGroupTotalLow,
                            )} – ${formatPrice(
                              totalPriceEstimate.estGroupTotalHigh,
                            )}`}
                      </>
                    ) : (
                      "—"
                    )}
                  </td>
                </tr>
              </>
            )}
          </>
        ) : (
          <>
            {(totalPriceEstimate.creditApplied ?? 0) > 0 && (
              <tr>
                <td tw="whitespace-nowrap">
                  <span tw="font-medium text-green-500">Credit applied</span>{" "}
                  {noValidPromoCode && <RemoveCreditButton />}
                </td>
                <td tw="font-medium text-right text-green-500">
                  – {formatPriceOrNull(totalPriceEstimate.creditApplied)}
                </td>
              </tr>
            )}
            {isCalculatedEstimate && (
              <>
                <tr>
                  <td>Tax</td>
                  <td tw="text-right">
                    {formatPrice(totalPriceEstimate.estTaxCalculated!)}
                  </td>
                </tr>
              </>
            )}
            <tr>
              <td>Estimated total</td>
              <td tw="text-right">
                {totalPriceEstimate.estGroupTotalLow === 0 &&
                totalPriceEstimate.estGroupTotalHigh === 0
                  ? "Free"
                  : totalPriceEstimate.estGroupTotalLow ===
                    totalPriceEstimate.estGroupTotalHigh
                  ? formatPrice(totalPriceEstimate.estGroupTotalLow)
                  : `${formatPrice(
                      totalPriceEstimate.estGroupTotalLow,
                    )} – ${formatPrice(totalPriceEstimate.estGroupTotalHigh)}`}
              </td>
            </tr>
          </>
        )}
        {isCalculatedEstimate &&
        totalPriceEstimate.creditApplied !== undefined &&
        totalPriceEstimate.creditApplied > 0 ? (
          <tr>
            <td colSpan={2} tw="text-center text-sm text-gray-450">
              Final tax could differ depending on the order that credit is
              applied.
            </td>
          </tr>
        ) : null}
      </tbody>
    </EstimateTable>
  )
}

function CalculateButton({
  onClick,
  showLoading,
}: {
  onClick: () => void
  showLoading: boolean
}) {
  return (
    <>
      <span tw="px-2 text-gray-400 font-normal">&middot;</span>
      <button
        tw="text-gray-500 hover:text-primary-new-600 active:scale-90 transition-all font-normal"
        onClick={onClick}
      >
        {showLoading ? "Calculating…" : "Calculate"}
      </button>
    </>
  )
}

const EstimateTable = styled.table`
  td {
    padding-bottom: 0.75rem;
  }

  tr:last-of-type td {
    padding-bottom: 0;
  }
`

const ChevronContainer = styled.div<{ rotated: boolean }>`
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  transform: rotate(${({ rotated }) => (rotated ? "0deg" : "-180deg")});
`

const ProductRow = styled.tr<{ shown: boolean }>`
  transition-property: opacity;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  ${({ shown }) => (shown ? tw`opacity-100` : tw`opacity-0 absolute`)};
`

export default PriceEstimateTable
