import React from "react"
import { Link } from "react-router-dom"
import tw, { css, styled } from "twin.macro"

type ButtonVariant = "default" | "primary" | "updated" | "primary-new" | "plain"

interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  variant?: ButtonVariant
}
const Button: React.FC<ButtonProps> = ({
  variant,
  children,
  className,
  ref, // Passing the ref in the props doesn't work, therefore we ignore it.
  ...props
}) => {
  const chosenVariant: ButtonVariant = variant || "default"

  return (
    <StyledButton className={[chosenVariant, className].join(" ")} {...props}>
      {children}
    </StyledButton>
  )
}

type LinkButtonProps = {
  variant?: ButtonVariant
} & React.ComponentProps<typeof Link>

export const LinkButton = ({
  variant,
  children,
  className,
  ref, // Passing the ref in the props doesn't work, therefore we ignore it.
  ...props
}: LinkButtonProps) => {
  const chosenVariant: ButtonVariant = variant || "default"

  return (
    <StyledButtonLink
      className={[chosenVariant, className].join(" ")}
      {...props}
    >
      {children}
    </StyledButtonLink>
  )
}

const commonButton = css`
  ${tw`
  flex gap-3 items-center bg-white border border-gray-200 py-2 px-4 rounded-lg text-gray-800 transition-all
  hover:(border-primary-new-150 bg-[#fbfaff])
  focus-visible:border-primary-new-200
  focus:outline-none
  enabled:active:(bg-[#f5f3ff] outline-none transition-border)
  disabled:(opacity-50 cursor-default)
  disabled:hover:border-primary-new-100
  `}
  box-shadow: 0 2px 4px rgba(209, 213, 209, 0.3);

  &:hover {
    box-shadow: 0 2px 4px rgba(228, 216, 244, 0.4);
  }

  svg {
    ${tw`stroke-current`}
  }

  &.primary {
    ${tw`border-primary-500 bg-primary-500 hover:bg-primary-550 hover:border-primary-550 focus-visible:border-primary-550 active:bg-primary-600 text-white disabled:hover:border-primary-500`}
  }

  &.primary-new {
    ${tw`border-primary-new-550 bg-primary-new-550 hover:(bg-primary-new-600 border-primary-new-600) focus-visible:(border-primary-new-700 bg-primary-new-700) active:(border-primary-new-700 bg-primary-new-700) text-white disabled:hover:border-primary-new-700`}
  }

  &.updated {
    ${tw`border-purple-500 bg-purple-500 hover:bg-purple-600 hover:border-purple-600 focus-visible:border-purple-600 active:bg-purple-700 text-white disabled:hover:border-primary-500`}
  }

  &.plain {
    ${tw`border-transparent bg-transparent hover:(border-gray-100 bg-gray-100) focus-visible:(border-gray-200 bg-gray-200) active:(border-gray-200 bg-gray-200) text-gray-500 disabled:hover:border-gray-100 shadow-none hover:shadow-none`}
  }

  &:disabled {
    ${tw`shadow-none`}
  }
`

const StyledButton = styled.button`
  ${commonButton}
`

export const StyledButtonLink = styled(Link)`
  ${commonButton}
`

export default Button
