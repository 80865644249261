import { useState } from "react"
import Modal from "react-modal"

import "react-day-picker/lib/style.css"

import Terms from "./components/Terms"
import useConsumerSignUp from "./hooks/useConsumerSignUp"
import SendPagePayment from "./SendPagePayment"
import { SendPageStart } from "./SendPageStart"
import AlcoholInfo from "../components/AlcoholInfo"
import PriceEstimateTable from "../components/PriceEstimateTable"
import PaymentMethodsModal from "../PaymentMethodsModal"

import { ArrowRightLarge } from "@/assets/icons"
import AgeVerificationModal from "@/common/AgeVerificationModal"
import ApplyCreditButton from "@/common/ApplyCreditButton"
import useAddPaymentMethod, {
  AddPaymentMethodFormFields,
} from "@/common/billing/useAddPaymentMethod"
import ConsumerContainer from "@/common/components/ConsumerContainer"
import Notice from "@/common/components/Notice"
import SendBlock from "@/common/components/SendBlock"
import ToggleSwitch from "@/common/components/ToggleSwitch"
import { formatPriceOrNull, isBlank } from "@/common/format"
import { getProduct } from "@/common/gifts"
import { useGlobalState } from "@/common/GlobalState"
import { useAuth } from "@/common/hooks"
import { useGiftData } from "@/common/hooks/giftData"
import { useCurrentGiftPayment } from "@/common/hooks/payment"
import { useConsumerSend } from "@/common/hooks/send"
import useSendBlock from "@/common/hooks/useSendBlock"
import { modalStyle } from "@/common/modal"
import { imageForCardBrand } from "@/common/payment"
import StartButton from "@/common/StartButton"
import { successToast } from "@/common/toast"
import { SubmitButton } from "@/common/UI"
import VerifyModal from "@/common/VerifyModal"
import { GIFT_CARDS_BRAND_NAME } from "@/store/utils"
import {
  BatchSendMethod,
  PaymentMethodCreditCardInput,
} from "@/types/graphql-types"

type SendPageType = "start" | "payment"

function SendPage() {
  const [user] = useGlobalState("user")
  const [consumerRecipient] = useGlobalState("consumerRecipient")

  const {
    formFields: paymentFormFields,
    setFormField: setPaymentFormField,
    setFormFields: setPaymentFormFields,
    preSubmit,
    setPageReady: setPaymentPageReady,
    reset: resetPaymentPage,
  } = useAddPaymentMethod(true)

  const { handleSignUp, signUpLoading, verifyLoading } = useAuth()
  const [page, setPage] = useState<SendPageType>("start")

  const { sendBlockType } = useSendBlock()

  const { firstName, lastName, email, phone } = useConsumerSignUp()

  const [verifyOpen, setVerifyOpen] = useState(false)
  const [displayAgeVerification, setDisplayAgeVerification] = useState(false)
  const [reviewModalOpen, setReviewModalOpen] = useState(false)
  const [paymentCardsModalOpen, setPaymentCardsModalOpen] = useState(false)

  const { paymentMethods, selectedPaymentMethod, refreshPaymentMethods } =
    useCurrentGiftPayment()

  const newUserMode = paymentMethods.length === 0

  const {
    sendConsumerGift,
    priceEstimate,
    promoCode,
    giftBatchCreateLoading,
    setPromoCode,
    userData,
    noValidPromoCode,
    consumerRecipientErrors,
  } = useConsumerSend()

  const { currentGift, toggleAutopayEnabled, onSetCVC, setPaymentMethod } =
    useGiftData()

  // Pre-send happens when the user clicks the Send button.
  // This is where we check if the user is signed up or not, and display the
  // signup verification modal if not. If they are, we display the review gift
  // modal.
  const handlePreSend = () => {
    if (!user) {
      handleSignUp({
        phone,
        email,
        firstName,
        lastName,
        onSuccess: () => {
          setVerifyOpen(true)
        },
        onFail: (signUp) => {
          if (signUp?.error) {
            alert(signUp?.error)
          }
        },
      })
    } else {
      setReviewModalOpen(true)
    }
  }

  // Processes the sending process when the user is already signed in.
  // If the page was loaded in new user mode (i.e. the user was a new user
  // when they loaded the page, but no longer are a new user) then the payment
  // preSubmit will run, otherwise we go directly to performSend.
  const handleSend = async () => {
    setReviewModalOpen(false)

    if (
      newUserMode &&
      currentGift.consumerNewUserPaymentSelection === "addCreditCardNow"
    ) {
      const interimCardToken = await preSubmit()

      if (!interimCardToken) {
        alert("An error occurred processing your credit card.")
        return
      }

      performSend(getCardInput(interimCardToken, paymentFormFields))
    } else {
      performSend()
    }
  }

  // Sends the gift with an optional interimCardToken.
  const performSend = (cardInput?: PaymentMethodCreditCardInput) => {
    sendConsumerGift(setDisplayAgeVerification, cardInput, () => {
      window.scrollTo({ top: 0, behavior: "smooth" })
      setPage("start")
    })
  }

  const isDirectSend = currentGift.sendMethod === BatchSendMethod.direct_send

  const product = getProduct(currentGift)

  const productSelectionComplete = !!product
  const recipientInfoComplete =
    isDirectSend ||
    !!(
      currentGift.card &&
      currentGift.message &&
      (isDirectSend || consumerRecipient.name)
    )
  const sendTypeComplete =
    isDirectSend ||
    currentGift.recipientType === "link" ||
    !!consumerRecipient.recipientInfo

  const newUserRequirementsComplete =
    user || (email && phone && firstName && lastName)

  const submitEnabled =
    productSelectionComplete &&
    recipientInfoComplete &&
    sendTypeComplete &&
    newUserRequirementsComplete

  const hasCredits = !!userData?.me?.credit && userData?.me?.credit > 0
  const isGiftCard = product?.brandName === GIFT_CARDS_BRAND_NAME

  // When we are in new user mode, hide the full payment section on the Start
  // page
  const hideFullPaymentSection = newUserMode && page === "start"

  function hasDirectSendCardOrMessageError() {
    if (
      currentGift.sendMethod === BatchSendMethod.direct_send &&
      currentGift.landingPageSendNotifs &&
      (!currentGift.card || !currentGift.message)
    ) {
      alert(
        "Please enter a message and a card if you want to send a gift notification to your recipient, or disable the recipient notification.",
      )
      return true
    }

    return false
  }

  return (
    <ConsumerContainer title="Send">
      <div tw="mx-auto px-4 flex flex-col" css="max-width: 780px">
        {page === "start" ? (
          <SendPageStart
            productSelectionComplete={productSelectionComplete}
            recipientInfoComplete={recipientInfoComplete}
            sendTypeComplete={sendTypeComplete}
            consumerRecipientErrors={consumerRecipientErrors ?? []}
          />
        ) : (
          <SendPagePayment
            onBack={() => setPage("start")}
            formFields={paymentFormFields}
            setFormField={setPaymentFormField}
            setFormFields={setPaymentFormFields}
            setPageReady={setPaymentPageReady}
            reset={resetPaymentPage}
          />
        )}

        {product?.isAlcohol && (
          <>
            <div tw="mx-auto mt-4 w-full" css="max-width: 500px;">
              <AlcoholInfo isBusiness={false} />
            </div>

            {(hasCredits || promoCode) && (
              <div tw="mx-auto w-full mb-4" css="max-width: 500px;">
                <Notice
                  colorName="yellow"
                  title="Credit cannot be used for alcohol purchases"
                  message="Alcohol purchases can’t have credit or promotional codes applied."
                />
              </div>
            )}
          </>
        )}

        {isGiftCard && (hasCredits || promoCode) && (
          <div tw="mx-auto w-full mb-4 mt-6" css="max-width: 500px;">
            <Notice
              colorName="yellow"
              title="Credit cannot be used for gift card purchases"
              message="Gift card purchases can’t have credit or promotional codes applied."
            />
          </div>
        )}

        {priceEstimate && product && (
          <div
            tw="mx-auto rounded-xl border border-gray-150 mt-12 w-full"
            css="max-width: 500px;"
          >
            <div tw="p-4 lg:p-8">
              <PriceEstimateTable
                hideTax={isGiftCard}
                priceEstimate={priceEstimate}
                isPlus={false}
                // For new users, show a compact payment section on the
                // Start page only
                showProductsDefault={!hideFullPaymentSection}
                key={hideFullPaymentSection.toString()}
                noValidPromoCode={noValidPromoCode}
              />
              <div tw="flex flex-col items-start md:items-center space-y-3 justify-between mt-6 md:flex-row md:space-y-0 lg:mb-[-12px]">
                {(priceEstimate.totalPriceEstimate.creditAvailableToApply ??
                  0) > 0 && noValidPromoCode ? (
                  <ApplyCreditButton
                    creditOmitted={
                      priceEstimate.totalPriceEstimate.creditApplied == 0
                    }
                    amount={formatPriceOrNull(
                      priceEstimate.totalPriceEstimate.creditAvailableToApply ??
                        null,
                    )}
                  />
                ) : null}
                {product.brandName !== GIFT_CARDS_BRAND_NAME && (
                  <button
                    tw="text-gray-500 even:ml-3"
                    onClick={() => {
                      const promoCode = window.prompt("Enter promo code")
                      if (isBlank(promoCode)) {
                        setPromoCode(null)
                      } else {
                        setPromoCode(promoCode)
                      }
                    }}
                  >
                    {promoCode
                      ? `Promo code: ${promoCode}`
                      : "Add a promo code"}
                  </button>
                )}
              </div>
            </div>
            {selectedPaymentMethod ? (
              <>
                <div tw="h-px self-stretch bg-gray-150" />
                <div tw="mx-8 my-6 flex flex-row justify-between items-center">
                  {!isDirectSend && (
                    <div tw="flex flex-row items-center">
                      <ToggleSwitch
                        tw="mx-6 lg:mx-0"
                        isOn={
                          !!(
                            currentGift.autopayEnabled &&
                            currentGift.autopayPaymentMethodID &&
                            currentGift.autopayPaymentMethodName
                          )
                        }
                        onClick={() => toggleAutopayEnabled()}
                      />
                      <div tw="ml-2">Autopay</div>
                    </div>
                  )}
                  <button
                    tw="inline-flex items-center"
                    onClick={() => setPaymentCardsModalOpen(true)}
                  >
                    {imageForCardBrand(selectedPaymentMethod.cardBrand)}
                    <div
                      tw="pl-2"
                      className="data-hj-suppress ph-no-capture fs-exclude"
                    >
                      {selectedPaymentMethod.last4}
                    </div>
                  </button>
                </div>
              </>
            ) : null}
          </div>
        )}

        {sendBlockType === "NONE" ? (
          <div tw="flex flex-row justify-center mt-6">
            {newUserMode && page === "start" ? (
              <StartButton
                label="Review gift"
                onClick={() => {
                  if (hasDirectSendCardOrMessageError()) {
                    return
                  }

                  window.scrollTo({ top: 0, behavior: "smooth" })
                  setPage("payment")
                }}
                id="consumer-go-to-payment"
                disabled={!submitEnabled}
              />
            ) : (
              <StartButton
                label={giftBatchCreateLoading ? "Sending…" : "Send gift"}
                onClick={() => {
                  if (hasDirectSendCardOrMessageError()) {
                    return
                  }

                  handlePreSend()
                }}
                id="consumer-review-gift"
                disabled={
                  !submitEnabled ||
                  giftBatchCreateLoading ||
                  signUpLoading ||
                  verifyLoading
                }
              />
            )}
          </div>
        ) : (
          <div tw="mx-auto mt-4 w-full" css="max-width: 500px;">
            <SendBlock
              sendBlockType={sendBlockType}
              paymentMethod={currentGift.paymentMethod}
              onSetCVC={() => {
                if (currentGift.paymentMethod) {
                  successToast(
                    "Updated payment card. You can now check out with this card.",
                  )

                  onSetCVC()
                }
              }}
            />
          </div>
        )}

        {!hideFullPaymentSection && <Terms />}
      </div>
      <VerifyModal
        isOpen={verifyOpen}
        setClosed={() => setVerifyOpen(false)}
        onSuccess={() => handleSend()}
        email={email}
        phone={phone}
        firstName={firstName}
        lastName={lastName}
      />
      {displayAgeVerification && <AgeVerificationModal verifyAge={true} />}
      <Modal
        isOpen={reviewModalOpen}
        closeTimeoutMS={500}
        onRequestClose={() => {
          setReviewModalOpen(false)
        }}
        onAfterClose={() => {}}
        shouldCloseOnOverlayClick={true}
        style={modalStyle}
      >
        <div className="modal-content modal-content-wide">
          <div tw="p-6">
            <div tw="text-2xl font-medium text-primary-500 pb-6">
              Review gift
            </div>
            <div tw="pb-4">
              <div tw="font-medium text-primary-500">Sending</div>
              <div tw="text-xl">
                {product?.brandName} – {product?.name}
              </div>
            </div>
            <div tw="pb-4">
              <div tw="font-medium text-primary-500">To</div>
              <div tw="text-xl">{consumerRecipient.name}</div>
            </div>
            {!isBlank(currentGift.fromName) && (
              <div tw="pb-4">
                <div tw="font-medium text-primary-500">From</div>
                <div tw="text-xl">{currentGift.fromName}</div>
              </div>
            )}
            <div tw="h-4" />
            <SubmitButton
              className="primary"
              onClick={() => handleSend()}
              id="consumer-send-gift"
            >
              <div>Send gift</div>
              <ArrowRightLarge className="feather feather-arrow-right" />
            </SubmitButton>
          </div>
        </div>
      </Modal>
      <PaymentMethodsModal
        showAdd={true}
        isOpen={paymentCardsModalOpen}
        autopayPaymentMethod={currentGift.paymentMethod}
        close={() => setPaymentCardsModalOpen(false)}
        setPaymentMethod={setPaymentMethod}
      />

      <div tw="h-24" />
    </ConsumerContainer>
  )
}

function getCardInput(
  interimCardToken: string,
  paymentFormFields: AddPaymentMethodFormFields,
): PaymentMethodCreditCardInput {
  return {
    interimCardToken,
    cardholderName: paymentFormFields.name,
    address1: paymentFormFields.address1,
    address2: paymentFormFields.address2,
    addressCity: paymentFormFields.city,
    addressState: paymentFormFields.state,
    addressPostalCode: paymentFormFields.postalCode,
  }
}

export default SendPage
