import React, { useState } from "react"
import Modal from "react-modal"
import { Link } from "react-router-dom"
import tw, { css, styled } from "twin.macro"

import CartContents from "./components/CartContents"
import { SendStepContainer } from "./components/common"
import DirectSendRecipientErrors from "./DirectSendRecipientErrors"
import useConsumerSignUp from "./hooks/useConsumerSignUp"
import MailingAddressInput from "./MailingAddressInput"
import ScheduleGift from "./ScheduleGift"
import {
  ArrowUpRightSmall,
  BrowseLarge,
  CheckCircleGreenLarge,
  SendAirplane,
  SendLink,
  WandLarge,
} from "../../assets/icons"
import { ReactComponent as BusinessCallout } from "../../assets/images/consumer-send/business-callout.svg"
import giftSample from "../../assets/images/consumer-send/gift-sample.png"
import shareDiagram from "../../assets/images/consumer-send/share-diagram.png"
import { formatPhone } from "../../common/format"
import { getProduct } from "../../common/gifts"
import { useGlobalState } from "../../common/GlobalState"
import { useGiftData } from "../../common/hooks/giftData"
import { modalStyle } from "../../common/modal"
import { generateRealmPath } from "../../common/realm"
import StartButton from "../../common/StartButton"
import CardModal from "../CardModal"
import CardSection from "../CardSection"
import DirectSendNotification from "../components/DirectSendNotification"
import DirectSendValidation from "../components/DirectSendValidation"
import SendMethodMenu from "../components/SendMethodMenu"

import { BatchSendMethod } from "@/types/graphql-types"
import { Send_GiftBatchCreateMutation } from "@/types/graphql-types"

type SendStep = "select" | "message" | "sendType"

interface Props {
  productSelectionComplete: boolean
  recipientInfoComplete: boolean
  sendTypeComplete: boolean
  consumerRecipientErrors: NonNullable<
    Send_GiftBatchCreateMutation["giftBatchCreate"]["recipientErrors"]
  >
}

export function SendPageStart({
  productSelectionComplete,
  recipientInfoComplete,
  sendTypeComplete,
  consumerRecipientErrors,
}: Props) {
  const {
    firstName,
    lastName,
    email,
    phone,
    setFirstName,
    setLastName,
    setEmail,
    setPhone,
  } = useConsumerSignUp()

  const [consumerRecipient, setConsumerRecipient] =
    useGlobalState("consumerRecipient")
  const [user] = useGlobalState("user")

  const [cardsModalOpen, setCardsModalOpen] = useState(false)

  const [sendStep, setSendStep] = useState<SendStep>("select")

  const { setSendType, setMessage, currentGift, setCard } = useGiftData()

  const product = getProduct(currentGift)

  // Disabled so people don't miss the "Ship directly to address" toggle
  // useEffect(() => {
  //   // If we've already selected a product, scroll to step 2
  //   if (product && sendStep === "select") {
  //     setAndScrollToStep("message");
  //   }
  // }, [product]);

  const scrollToStep = () => {
    const currentStep = document.querySelector(".step-active")
    if (currentStep) {
      const location = window.matchMedia("(min-width: 640px)").matches
        ? "center"
        : "start"
      const elem = window.matchMedia("(min-width: 640px)").matches
        ? currentStep
        : currentStep.querySelector(".scroll-anchor")

      elem?.scrollIntoView({
        behavior: "smooth",
        block: location,
        inline: location,
      })
    }
  }

  const setAndScrollToStep = (newSendStep: SendStep) => {
    setSendStep(newSendStep)
    setTimeout(() => scrollToStep(), 0)
  }

  const isDirectSend = currentGift.sendMethod == BatchSendMethod.direct_send

  return (
    <>
      <div tw="font-semibold text-5xl text-center" css="margin-top: 3.75rem">
        Send a gift
      </div>
      <div tw="text-xl text-gray-500 mt-4 text-center mx-5">
        It’s super easy. No address required.
      </div>
      <div tw="mt-12" />
      <SendMethodMenu sendV3={true} />
      <DirectSendValidation sendV3={true} />
      <SendStepComponent
        tw="mt-4 sm:mt-8"
        title="Select gifts"
        number="1"
        step="select"
        currentStep={sendStep}
        complete={productSelectionComplete}
        setSendStep={setSendStep}
      >
        {currentGift.cart.length > 0 ? (
          <div tw="m-6 lg:m-9">
            <CartContents
              cart={currentGift.cart}
              showChangeGiftsButton={true}
            />
          </div>
        ) : (
          <SelectGiftButtonContainer>
            <LargeButton
              primaryText="Browse gifts"
              secondaryText="Find something great"
              icon={<BrowseLarge />}
              to={generateRealmPath(null, "/browse")}
            />
            <LargeButton
              primaryText="Send a Gift Collection"
              secondaryText="Let them pick anything within a range"
              icon={<WandLarge />}
              to={generateRealmPath(
                "consumer",
                "/browse/gift-option/gift-collections",
              )}
            />
          </SelectGiftButtonContainer>
        )}
      </SendStepComponent>
      <SendStepComponent
        tw="mt-8 sm:mt-9"
        title={isDirectSend ? "Shipping address" : "Enter a message"}
        number="2"
        step="message"
        currentStep={sendStep}
        setSendStep={setSendStep}
        complete={!isDirectSend && recipientInfoComplete}
      >
        <div tw="flex flex-col">
          {isDirectSend && (
            <>
              {consumerRecipientErrors.length > 0 ? (
                <DirectSendRecipientErrors errors={consumerRecipientErrors} />
              ) : null}
              <MailingAddressInput />
              <div tw="border-t border-gray-150" />
              <DirectSendNotification isConsumer={true} />
            </>
          )}
          {!isDirectSend ||
          (isDirectSend && currentGift.landingPageSendNotifs) ? (
            <div tw="flex flex-col-reverse sm:flex-row mx-6 sm:mx-9 mt-9">
              <div tw="mt-6 sm:mt-0 ">
                <CardSection
                  card={currentGift.card}
                  setCardsModalOpen={setCardsModalOpen}
                />
              </div>
              <div tw="sm:ml-8 flex flex-col flex-1">
                <div tw="border flex flex-col self-stretch rounded-xl flex-1">
                  {!isDirectSend && (
                    <>
                      <input
                        type="text"
                        tw="text-gray-600 placeholder-gray-400 bg-transparent px-4 py-6 sm:p-6 focus:outline-none"
                        className="data-hj-suppress ph-no-capture fs-mask"
                        placeholder={"Recipient's name"}
                        value={consumerRecipient.name}
                        onChange={(e) => {
                          setConsumerRecipient((recipient) => ({
                            ...recipient,
                            name: e.target.value,
                          }))
                        }}
                      />
                      <div tw="h-px self-stretch bg-gray-150" />
                    </>
                  )}
                  <textarea
                    placeholder="Add a message"
                    className="data-hj-suppress ph-no-capture fs-mask"
                    tw="text-gray-600 px-4 py-6 sm:p-6 h-36 sm:h-full bg-transparent focus:outline-none"
                    value={currentGift?.message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
                <div
                  tw="self-end mr-5 rounded-full bg-white hidden sm:block"
                  css="margin-top: -24px;"
                >
                  <StartButton
                    tw="font-normal h-12"
                    label="Next"
                    arrowCss="margin-left: 1rem;"
                    disabled={
                      !currentGift.card ||
                      !currentGift.message ||
                      (!isDirectSend && !consumerRecipient.name)
                    }
                    onClick={(e) => {
                      e.stopPropagation()
                      e.currentTarget?.blur()
                      setAndScrollToStep("sendType")
                    }}
                  />
                </div>
              </div>
            </div>
          ) : null}
          {isDirectSend ? (
            <div tw="pb-6" />
          ) : (
            <BusinessCalloutContainer>
              <div tw="flex flex-row-reverse sm:flex-row items-start sm:items-center gap-5">
                <BusinessCallout />
                <div tw="flex flex-col flex-1 text-gray-600">
                  <div tw="font-semibold">Goody for Business</div>
                  <div tw="mt-1">
                    Send to multiple recipients, add a logo, and auto-schedule
                    gifts for events.
                  </div>
                </div>
              </div>
              <BusinessLink
                to={generateRealmPath("plus", "/send")}
                tw="font-semibold whitespace-nowrap"
              >
                <span tw="hidden sm:inline">Learn about&nbsp;</span>Goody for
                Business
                <ArrowUpRightSmall tw="ml-2" />
              </BusinessLink>
            </BusinessCalloutContainer>
          )}
        </div>
      </SendStepComponent>
      {!isDirectSend || (isDirectSend && currentGift.landingPageSendNotifs) ? (
        <SendStepComponent
          tw="mt-8 sm:mt-9"
          title={isDirectSend ? "Gift notification" : "Send gift"}
          number="3"
          step="sendType"
          currentStep={sendStep}
          setSendStep={setSendStep}
          complete={sendTypeComplete}
        >
          <div tw="px-6 sm:px-9 flex flex-row mt-9 flex-wrap gap-x-6 gap-y-2">
            <SendTypeButton
              className={
                currentGift.recipientType === "manual"
                  ? "selected-send-button"
                  : ""
              }
              onClick={() => setSendType("manual")}
            >
              <SendAirplane tw="mr-3" />
              <StackedItemContainer>
                <div>Send the gift for me</div>
                <div tw="opacity-0 font-semibold">Send the gift for me</div>
              </StackedItemContainer>
            </SendTypeButton>
            <SendTypeButton
              onClick={() => setSendType("link")}
              className={
                currentGift.recipientType === "link"
                  ? "selected-send-button"
                  : ""
              }
            >
              <SendLink tw="mr-2" />
              <StackedItemContainer>
                <div>Get a link to share</div>
                <div tw="opacity-0 font-semibold">Get a link to share</div>
              </StackedItemContainer>
            </SendTypeButton>
          </div>
          <RecipientSection tw="mt-6">
            <StackedItemContainer>
              <DisplayContainer
                tw="flex flex-row"
                className={
                  currentGift.recipientType === "manual"
                    ? ""
                    : "container-hidden"
                }
              >
                <div tw="flex-1 mx-6 sm:ml-9 sm:mr-5 my-6 flex flex-col justify-center">
                  <div tw="flex flex-row items-center">
                    <div>
                      <div tw="text-xl font-semibold">
                        We’ll email or text your recipient with your gift and
                        card.
                      </div>
                      {!isDirectSend && (
                        <div tw="mt-2 text-gray-500 text-xl">
                          You’ll only pay when your recipient accepts.
                        </div>
                      )}
                    </div>
                    <img
                      src={giftSample}
                      tw="sm:mx-5 sm:hidden"
                      css="width: 82px; height: 124px;"
                    />
                  </div>
                  <input
                    type="text"
                    tw="self-stretch bg-white mt-9 rounded-xl placeholder-gray-500 px-6 focus:outline-none border border-transparent focus:border-primary-300 transition-colors"
                    placeholder="Recipient’s phone or email"
                    className="data-hj-suppress ph-no-capture fs-mask"
                    css="height: 62px; max-width: 360px;"
                    value={consumerRecipient.recipientInfo}
                    onChange={(e) => {
                      const value = e.target.value
                      const isPhoneNumber =
                        /^\+?(\d+ ?)?\(?\d{2,}((?![a-z@]).)*$/i.test(value)

                      const newValue = isPhoneNumber
                        ? formatPhone(value)
                        : value
                      setConsumerRecipient((recipient) => ({
                        ...recipient,
                        recipientInfo: newValue,
                      }))
                    }}
                  />
                  <ScheduleGift />
                </div>
                <img
                  src={giftSample}
                  tw="m-4 sm:block hidden"
                  css="width: 142px; height: 214.5px;"
                />
              </DisplayContainer>
              <DisplayContainer
                tw="flex flex-row sm:h-full items-center"
                className={
                  currentGift.recipientType === "link" ? "" : "container-hidden"
                }
              >
                <div tw="flex-1 flex flex-col mx-6 sm:mx-9 text-xl my-6">
                  <div tw="font-semibold">
                    We’ll give you a link you can share with your recipient.
                  </div>
                  <div tw="mt-2 text-gray-500">
                    Send it over text, email, Messenger, wherever.
                  </div>
                  {!isDirectSend && (
                    <div tw="mt-5 text-gray-500">
                      No upfront payment.
                      <br />
                      You’ll only pay when your recipient accepts.
                    </div>
                  )}
                </div>
                <ShareImage src={shareDiagram} />
              </DisplayContainer>
            </StackedItemContainer>
          </RecipientSection>
        </SendStepComponent>
      ) : null}
      {!user ? (
        <div tw="w-full flex flex-row justify-center">
          <button
            tw="mt-16 flex flex-col mx-auto text-left cursor-default flex-1"
            onClick={() => setSendStep("sendType")}
            css="max-width: 695px;"
          >
            <FromContainer>From:</FromContainer>
            <div
              tw="self-stretch rounded-3xl bg-gray-050 items-center flex flex-col p-4 sm:p-9"
              css="padding-top: 52px;"
            >
              <div tw="text-center text-gray-500">
                You’ll only need to fill this out once to create your account.
              </div>
              <FromInnerContainer tw="flex flex-col self-stretch rounded-xl bg-white mt-7">
                <div tw="flex flex-col sm:flex-row">
                  <CustomPlaceholderInput
                    tw="sm:flex-1"
                    setValue={setFirstName}
                    value={firstName}
                    isSensitiveInput
                  >
                    <div>Your first name</div>
                  </CustomPlaceholderInput>
                  <ChangingDivider />
                  <CustomPlaceholderInput
                    value={lastName}
                    setValue={setLastName}
                    tw="sm:flex-1"
                    isSensitiveInput
                  >
                    <div>Your last name</div>
                  </CustomPlaceholderInput>
                </div>
                <HorizontalDivider />
                <CustomPlaceholderInput
                  className="data-hj-suppress ph-no-capture fs-exclude"
                  value={phone}
                  setValue={(newValue) => setPhone(formatPhone(newValue))}
                  isSensitiveInput
                >
                  <div>
                    Your mobile phone number
                    <span tw="text-gray-350 sm:inline hidden">
                      {" "}
                      — you’ll use this to log in
                    </span>
                  </div>
                </CustomPlaceholderInput>
                <HorizontalDivider />
                <CustomPlaceholderInput
                  className="data-hj-suppress ph-no-capture fs-exclude"
                  value={email}
                  setValue={setEmail}
                  isSensitiveInput
                >
                  <div>
                    Your email
                    <span tw="text-gray-350 sm:inline hidden">
                      {" "}
                      — for receipts
                    </span>
                  </div>
                </CustomPlaceholderInput>
              </FromInnerContainer>
            </div>
          </button>
        </div>
      ) : (
        <div tw="h-6" />
      )}
      <Modal
        isOpen={cardsModalOpen}
        closeTimeoutMS={500}
        onRequestClose={() => {
          setCardsModalOpen(false)
        }}
        onAfterClose={() => {}}
        shouldCloseOnOverlayClick={true}
        style={modalStyle}
      >
        <CardModal
          onSelectCard={(id, image) => {
            setCard({ id, image })
            setCardsModalOpen(false)
          }}
          closeModal={() => {
            setCardsModalOpen(false)
          }}
        />
      </Modal>
    </>
  )
}

type LargeButtonProps = {
  primaryText: string
  secondaryText: string
  icon: any
} & React.ComponentProps<Link>

const LargeButton: React.FC<LargeButtonProps> = ({
  primaryText,
  secondaryText,
  icon,
  ...restProps
}) => {
  return (
    <Link
      {...restProps}
      tw="flex-1 self-stretch rounded-xl border border-gray-150 flex flex-col items-center justify-center hover:border-gray-300 active:bg-gray-050 px-4 py-6 sm:py-0"
    >
      {icon}
      <div tw="mt-5 text-xl text-gray-500 text-center">{primaryText}</div>
      <div tw="mt-2 text-gray-400 text-center">{secondaryText}</div>
    </Link>
  )
}

type StepHeaderProps = {
  text: string
  number: string
  active: boolean
} & React.ComponentPropsWithoutRef<"div">

const StepHeader: React.FC<StepHeaderProps> = ({
  text,
  number,
  active,
  className,
  ...restProps
}) => {
  return (
    <StepHeaderContainer
      className={(active ? "step-active" : "") + " " + className}
      {...restProps}
    >
      <div className="step-number-bg">
        <span>{number}</span>
      </div>
      <div className="step-header-text">{text}</div>
    </StepHeaderContainer>
  )
}

const SelectGiftButtonContainer = styled.div`
  ${tw`flex flex-col sm:flex-row m-6 mt-8 sm:m-9 sm:gap-9 gap-4`};

  @media (min-width: 640px) {
    height: 205px;
  }
`

const SendTypeButton = styled.button`
  ${tw`h-12 flex justify-center items-center rounded-full border text-primary-400 border-primary-050 transition-colors transition-border hover:bg-primary-050 hover:border-transparent px-5 active:bg-primary-100`}
  & > svg {
    ${tw`stroke-current text-primary-400 transition-colors`}
  }

  &.selected-send-button {
    ${tw`text-white border-transparent font-medium transition-all focus:outline-none bg-gradient-to-r from-gradient-alternate-pink-medium to-gradient-alternate-purple-medium`}
    & > svg {
      ${tw`text-white`}
    }
  }
`

const ShareImage = styled.img`
  ${tw`mr-6 sm:mr-8`};
  width: 85px;
  @media (min-width: 640px) {
    width: 129px;
  }
`

const FromInnerContainer = styled.div`
  ${tw`flex flex-col self-stretch rounded-xl bg-white mt-7 border`}
  border-color: #ECEEF1;
  box-shadow:
    0px 1px 4px rgba(0, 0, 0, 0.04),
    0px 6px 20px rgba(0, 0, 0, 0.04);
`

const FromContainer = styled.div`
  ${tw`self-center text-gray-500 rounded-full border px-7 z-10 bg-white`}
  font-size: 28px;
  line-height: 60px;
  height: 60px;
  border-color: #eceef1;
  box-shadow:
    0px 1px 4px rgba(0, 0, 0, 0.05),
    0px 4px 32px rgba(0, 0, 0, 0.04);
  margin-bottom: -30px;
`

const HorizontalDivider = styled.div`
  ${tw`h-px self-stretch`}
  background-color: #ECEEF1;
`

const ChangingDivider = styled.div`
  ${tw`h-px w-full sm:w-px sm:h-full self-stretch`}
  background-color: #ECEEF1;
`

const stackedCss = css`
  ${tw`grid`}
  & > * {
    grid-column: 1 / 1;
    grid-row: 1 / 1;
  }
`

type CustomPlaceholderInputProps = {
  value: string
  setValue: (newValue: string) => void
  isSensitiveInput?: boolean
} & React.ComponentPropsWithoutRef<"label">

const CustomPlaceholderInput: React.FC<CustomPlaceholderInputProps> = ({
  value,
  setValue,
  children,
  isSensitiveInput,
  ...restProps
}) => (
  <StackedInputContainer {...restProps}>
    <input
      type="text"
      value={value}
      placeholder="needed for placeholder selector"
      className={
        isSensitiveInput ? "data-hj-suppress ph-no-capture fs-exclude" : ""
      }
      onChange={(e) => setValue(e.target.value)}
    />
    <div>{children}</div>
  </StackedInputContainer>
)

const StackedItemContainer = styled.div`
  ${stackedCss}
`

const StackedInputContainer = styled.label`
  ${stackedCss}
  & > * {
    height: 70px;
    ${tw`px-5 sm:px-6 text-gray-500 text-lg flex flex-row items-center cursor-text`}
  }

  & > input {
    ${tw`focus:outline-none placeholder-transparent bg-transparent`}
    &:not(:placeholder-shown) + * {
      ${tw`hidden`}
    }
  }
`

const DisplayContainer = styled.div`
  ${tw`transition-opacity`}
  &.container-hidden {
    ${tw`opacity-0 pointer-events-none`}
  }
`

const BusinessCalloutContainer = styled.div`
  ${tw`border bg-white rounded-lg m-4 mt-6 sm:m-6 p-6 sm:p-4 flex flex-col items-start gap-4 md:flex-row md:items-center`}

  border-color: rgba(128, 128, 128, 0.2);
  box-shadow:
    0px 1px 4px rgba(0, 0, 0, 0.04),
    0px 6px 20px rgba(0, 0, 0, 0.04);
`

const BusinessLink = styled(Link)`
  ${tw`rounded-full flex flex-row items-center pl-4 pr-3 `}
  color: #c188ce;
  height: 38px;

  background: linear-gradient(
    51.41deg,
    rgba(228, 161, 184, 0.15) -26.91%,
    rgba(179, 125, 213, 0.15) 113.9%
  );

  &:hover {
    background: linear-gradient(
      51.41deg,
      rgba(228, 161, 184, 0.3) -26.91%,
      rgba(179, 125, 213, 0.3) 113.9%
    );
  }
`

type SendStepProps = {
  title: string
  number: string
  complete: boolean
  step: SendStep
  currentStep: SendStep
  setSendStep: (sendStep: SendStep) => void
} & React.ComponentPropsWithoutRef<"div">

// Container component for consumer send steps
const SendStepComponent: React.FC<SendStepProps> = ({
  title,
  number,
  className,
  children,
  complete,
  step,
  currentStep,
  setSendStep,
  ...restProps
}) => {
  const active = step === currentStep
  return (
    <SendStepContainer
      onClick={() => {
        if (active) {
          return
        }
        setSendStep(step)
      }}
      className={(active ? "step-active" : "") + " " + className}
      {...restProps}
    >
      <div
        className="scroll-anchor"
        css="position: relative; top: -4.5rem; left: 0"
      />
      <div tw="flex flex-row mt-8 mx-6 sm:mt-9 sm:mx-9 justify-between">
        <StepHeader text={title} number={number} active={active} />
        <CheckCircleGreenLarge
          tw="transition-opacity"
          css={complete ? tw`opacity-100` : tw`opacity-0`}
        />
      </div>
      {children}
    </SendStepContainer>
  )
}

const StepHeaderContainer = styled.div`
  ${tw`flex flex-row items-center`}
  & > .step-number-bg {
    ${tw`bg-gray-150 w-8 h-8 rounded-full flex items-center justify-center`}
    & > * {
      ${tw`text-gray-400`}
    }
  }

  &.step-active > .step-number-bg {
    background: linear-gradient(51.41deg, #e4a1b8 -26.91%, #b37dd5 113.9%),
      #000000;

    & > * {
      ${tw`text-white`}
    }
  }

  & > .step-header-text {
    ${tw`text-gray-400 ml-4 text-2xl`}
  }

  &.step-active > .step-header-text {
    background: linear-gradient(90deg, rgb(228, 161, 184), rgb(179, 125, 213));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

const RecipientSection = styled.div`
  background: linear-gradient(
    51.41deg,
    rgba(228, 161, 184, 0.1) -26.91%,
    rgba(179, 125, 213, 0.1) 113.9%
  );
`
