import React from "react"
import { Link } from "react-router-dom"
import tw, { css, styled } from "twin.macro"

import { Close, TruckSmall } from "../../assets/icons"
import { useBrowseUrls } from "../../store/StoreHelpers"
import { useCartDrawer } from "../contexts/cartDrawer"
import { formatPrice } from "../format"
import {
  getProductImageThumb,
  getProductName,
  getProductPrice,
  getProductSubtitle,
} from "../gifts"
import { Product } from "../GlobalState"
import { useGiftCart } from "../hooks/giftData"
import { summarizeVariants } from "../utilities"

import { CartProductImage } from "@/common/cart/CartProductImage"

interface Props {
  product: Product
  combinedShipping: boolean
}

const ProductRow = ({ product, combinedShipping }: Props) => {
  const { generateProductUrl } = useBrowseUrls()
  const { closeCart } = useCartDrawer()
  const { removeFromCart, getProductShipping } = useGiftCart()

  const shippingPrice = getProductShipping(product)
  const imageThumb = getProductImageThumb(product)

  const shippingMessage = () => {
    if (combinedShipping) {
      return (
        <div tw="flex flex-row items-center text-[#27AE60]">
          <TruckSmall tw="stroke-current mr-1" />
          Combined
        </div>
      )
    } else if (shippingPrice > 0) {
      return <div tw="text-gray-450">Shipping {formatPrice(shippingPrice)}</div>
    } else {
      return <div tw="text-gray-450">Free shipping</div>
    }
  }

  return (
    <ProductHoverContainer tw="relative">
      <Link
        to={generateProductUrl(product)}
        onClick={closeCart}
        tw="flex flex-row border-b border-gray-150 active:bg-gray-100 transition-colors"
      >
        <div tw="relative">
          <CartProductImage tw="h-36 w-36 max-w-none" product={product} />
          {product.quantity && product.quantity > 1 && (
            <QuantityTag>{product.quantity}</QuantityTag>
          )}
        </div>
        <div tw="flex-1 flex flex-col p-5 justify-between relative">
          <div>
            <ProductNameContainer>
              {getProductName(product)}
            </ProductNameContainer>
            {product.variants && (
              <ProductVariantContainer title={product.variants.join(", ")}>
                {summarizeVariants(product.variants)}
              </ProductVariantContainer>
            )}
            <div tw="text-gray-500">{getProductSubtitle(product)}</div>
          </div>
          <div tw="flex flex-row items-center justify-between">
            <div>{formatPrice(getProductPrice(product))}</div>
            {product.priceShipping > 0 && shippingMessage()}
          </div>
        </div>
      </Link>
      <button
        className="product-close"
        onClick={() => removeFromCart(product, product.variants)}
        tw="bg-white text-gray-400 opacity-0 transition-all hover:bg-gray-200 active:bg-gray-250"
        css={circleCss}
      >
        <Close />
      </button>
    </ProductHoverContainer>
  )
}

const ProductHoverContainer = styled.div`
  ${tw`relative hover:bg-gray-050 transition-colors`}

  &:hover .product-close {
    ${tw`opacity-100`}
  }
`

const ProductNameContainer = styled.div`
  ${tw`font-semibold`}
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`

const ProductVariantContainer = styled.div`
  ${tw`text-gray-600`};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
`

const circleCss = css`
  ${tw`absolute right-2 top-2 bg-white rounded-full flex items-center justify-center w-[28px] h-[28px]`}
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
`

const QuantityTag = styled.div`
  ${circleCss}
  ${tw`text-gray-700`}
`

export default ProductRow
