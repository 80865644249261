import { gql } from "@apollo/client"

import { IMAGE_FRAGMENT } from "@/graphql"

export const CUSTOM_STORE_PRODUCT_DATA_FRAGMENT = gql`
  fragment CustomStore_Product_DataFragment on CustomStoreProduct {
    id
    name
    shippingCountriesFinal
    subtitle
    isAlcohol
    price(customStoreId: $customStoreId)
    isAvailable(customStoreId: $customStoreId)
    isDuplicated(customStoreId: $customStoreId)
    shippingPrice
    priceWithShipping
    brand {
      id
      name
      brandValues
    }
    productImages {
      imageThumb {
        ...Image
      }
      imageLarge {
        ...Image
      }
    }
    giftCardInfo {
      currency
    }
    imagesScalable
  }
  ${IMAGE_FRAGMENT}
`
