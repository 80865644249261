import { gql, useQuery } from "@apollo/client"
import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react"
import nl2br from "react-nl2br"
import { useLocation } from "react-router-dom"
import TimeAgo from "react-timeago"
import tw, { styled } from "twin.macro"

import StatusDisplay from "./StatusDisplay"
import { ReactComponent as CheckedCalendar } from "../../assets/icons/checked-calendar.svg"
import swapIcon from "../../assets/icons/swap-small.svg"
import { ShippingProgress } from "../../assets/images"
import { isBlank } from "../../common/format"
import { usePrevious } from "../../common/hooks/previous"
import { useShipments } from "../../common/hooks/shipments"
import Pagination from "../../common/pagination/Pagination"
import { TRACK_PAGE_GIFT_FRAGMENT } from "../../common/queries/track_page_gift_fragment"
import { RoundedBox } from "../../common/UI"
import {
  formatDateWithoutYear,
  formatDayWithDate,
  isPastDate,
} from "../../common/utilities"

import { GiftSeriesFilter, GiftViewableStatus } from "@/types/graphql-types"
import {
  ProductFragmentFragment,
  ShipmentFragment,
  TrackPageGiftFragment,
  Track_GiftBatchGiftStatusSetQuery,
  Track_GiftBatchGiftStatusSetQueryVariables,
} from "@/types/graphql-types"

const PER_PAGE = 20

interface Props {
  giftBatchID: string
  expiresAt?: Date | null
  filter: GiftSeriesFilter
}

// Displays a set of gifts, usually a group with a certain status (opened,
// notified, or the "multi-status" publicly displayed as "accepted" but is a
// group of multiple statuses that are entered after gift acceptance.
const GiftStatusSet: React.FC<Props> = ({ expiresAt, giftBatchID, filter }) => {
  const [page, setPage] = useState(0)

  const { data, refetch, loading } = useQuery<
    Track_GiftBatchGiftStatusSetQuery,
    Track_GiftBatchGiftStatusSetQueryVariables
  >(TRACK_GIFT_STATUS_SET_QUERY, {
    variables: {
      id: giftBatchID,
      page: page + 1,
      filter: filter,
    },
  })

  const changePage = (page: number) => {
    setPage(page)
    containerRef.current?.scrollIntoView({
      block: "start",
      inline: "nearest",
      behavior: "smooth",
    })
  }

  useEffect(() => {
    if (refetch) {
      refetch({
        page: page + 1,
      })
    }
  }, [page, refetch])

  const previousData = usePrevious(data)

  const displayedData = data || previousData

  const gifts = displayedData?.workspace?.giftBatch?.giftSeries?.items
  const totalCount = displayedData?.workspace?.giftBatch?.giftSeries?.totalCount

  const containerRef = useRef<HTMLDivElement>(null)

  if (!gifts || totalCount === 0) {
    return null
  }

  return (
    <div
      tw="pt-24 transition-opacity"
      ref={containerRef}
      css={[loading && tw`opacity-50`]}
    >
      <div tw="pl-5 pb-8 text-3xl font-medium">{getNameForFilter(filter)}</div>
      {expiresAt && (
        <div tw="pl-5 pb-8 text-xl -mt-4 text-gray-600">
          {isPastDate(expiresAt) ? "Expired" : "Expires"}{" "}
          {formatDayWithDate(expiresAt, true)}
        </div>
      )}
      <div tw="grid md:grid-cols-2 gap-12">
        {gifts?.map((gift) => <GiftBox gift={gift} key={gift.id} />)}
      </div>

      {totalCount && totalCount > PER_PAGE ? (
        <div tw="pt-12">
          <Pagination
            pageIndex={page}
            perPage={PER_PAGE}
            totalCount={totalCount}
            setPageIndex={changePage}
            preventScrollToTop={true}
          />
        </div>
      ) : null}
    </div>
  )
}

const getNameForFilter = (filter: GiftSeriesFilter) => {
  switch (filter) {
    case GiftSeriesFilter.recently_accepted:
      return "Recently accepted"
    case GiftSeriesFilter.opened:
      return "Opened"
    case GiftSeriesFilter.notified:
      return "Notified"
    default:
      return ""
  }
}

interface GiftBoxProps {
  gift: TrackPageGiftFragment
}

// Displays a single gift.
const GiftBox = ({ gift }: GiftBoxProps) => {
  const { hash } = useLocation()
  useEffect(() => {
    if (hash) {
      const giftEl = document.querySelector(hash)

      if (giftEl) {
        giftEl.scrollIntoView({
          behavior: "smooth",
          block: "center",
        })
      }
    }
  }, [hash])
  const elId = `gift_${gift.id}`

  return (
    <GiftBoxContainer
      tw="flex flex-col"
      id={elId}
      isLinkedTo={`#${elId}` === hash}
    >
      <div tw="p-5 flex flex-row items-start justify-between">
        <div
          tw="text-xl font-medium"
          className="data-hj-suppress ph-no-capture fs-mask"
        >
          {[gift.recipientFirstName, gift.recipientLastName]
            .filter(Boolean)
            .join(" ")}
        </div>
        <div tw="pt-1">
          <StatusDisplay status={gift.status} />
        </div>
      </div>
      {gift.status !== GiftViewableStatus.OPENED &&
      gift.status !== GiftViewableStatus.CREATED &&
      gift.thankYouNote &&
      !isBlank(gift.thankYouNote) ? (
        <ThankYouNote className="data-hj-suppress ph-no-capture fs-mask">
          {nl2br(gift.thankYouNote)}
        </ThankYouNote>
      ) : (
        <div
          // When the thank you note (which has flex-1) is not displayed, we
          // want to make sure that the middle takes up the space if this grid
          // is shorter than the grid box in the adjacent column, to ensure the
          // text at the bottom is always bottom-aligned.
          tw="flex-1"
        />
      )}
      {gift.meetingStatus?.meetingScheduled ? (
        <div tw="mt-[13px] ml-2 text-[#059669] bg-[#DFFCED] rounded-[100px] text-sm leading-[139.5%] font-medium py-[5px] px-3 flex items-center max-w-max">
          <CheckedCalendar tw="mr-2 stroke-[#059669] inline h-[18px] w-[18px]" />
          Meeting scheduled
        </div>
      ) : null}
      <div tw="p-5 py-4">
        <GiftSwappedLine gift={gift} />
        <GiftReminderSentAtLine gift={gift} />
        <GiftRecentStatusLine gift={gift} />
        <GiftShippingStatusLines gift={gift} />
      </div>
    </GiftBoxContainer>
  )
}

const GiftReminderSentAtLine: React.FC<{
  gift: TrackPageGiftFragment
}> = ({ gift }) => {
  const { reminder1SentAt, status } = gift

  if (
    reminder1SentAt &&
    (status === GiftViewableStatus.CREATED ||
      status === GiftViewableStatus.OPENED)
  ) {
    const date = new Date(reminder1SentAt).toLocaleString("en-US", {
      // @ts-ignore
      day: "numeric",
      month: "long",
    })
    const time = new Date(reminder1SentAt).toLocaleString("en-US", {
      // @ts-ignore
      hour: "numeric",
      minute: "numeric",
    })
    const relativeTime = <TimeAgo date={reminder1SentAt} />

    return (
      <div tw="text-sm pt-1">
        Reminder sent on {date} at {time} ({relativeTime})
      </div>
    )
  } else {
    return null
  }
}

// Displays information about the swapped product if the gift has been swapped.
const GiftSwappedLine: React.FC<{
  gift: TrackPageGiftFragment
}> = ({ gift }) => {
  if (gift.isSwapped) {
    return (
      <div tw="text-sm text-gray-600 pb-1 flex flex-row items-center">
        <img
          src={swapIcon}
          alt=""
          css={{
            width: 14,
            height: 14,
          }}
        />
        <div tw="pl-1">
          Swapped for {gift.productFragment?.brandName} –{" "}
          {gift.productFragment?.name}
        </div>
      </div>
    )
  }

  return null
}

// Displays a status line corresponding to the current or recent status of a
// gift, such as "opened at X" or "accepted at X". This is the status that has
// most recently happened. When the gift container (GiftBox) is hovered, this
// line is replaced by the Gift ID.
const GiftRecentStatusLine: React.FC<{
  gift: TrackPageGiftFragment
}> = ({ gift }) => {
  let content: ReactNode | null = null
  const expiresAt = gift.expiresAt && new Date(gift.expiresAt)

  if (
    expiresAt &&
    isPastDate(expiresAt) &&
    (gift.status === GiftViewableStatus.CREATED ||
      gift.status === GiftViewableStatus.OPENED)
  ) {
    content = <>Expired on {formatDateWithoutYear(expiresAt, true)}</>
  } else {
    if (gift.status === GiftViewableStatus.CREATED) {
      content = <>Waiting to open</>
    }

    if (gift.status === GiftViewableStatus.OPENED) {
      content = <>Opened {gift.openedAt && <TimeAgo date={gift.openedAt} />}</>
    }

    if (gift.acceptedAt) {
      content = (
        <>
          Accepted <TimeAgo date={gift.acceptedAt} />
        </>
      )
    }
  }

  return (
    <RecentStatusContainer>
      <RecentStatusNormal>{content}</RecentStatusNormal>
      <RecentStatusHover className="data-hj-suppress ph-no-capture fs-mask">
        Gift ID {gift.id}
      </RecentStatusHover>
    </RecentStatusContainer>
  )
}

// Displays information on all shipments
const GiftShippingStatusLines = ({ gift }: { gift: TrackPageGiftFragment }) => {
  const { productFragmentsById, uniqBrandIds, shipmentsByBrandId } =
    useShipments(gift)

  if (!gift.acceptedAt) {
    return null
  }

  return (
    <>
      {uniqBrandIds.map((brandId) =>
        (shipmentsByBrandId[brandId] ?? [null]).map(
          (shipment, shipmentIndex) => (
            <GiftShippingStatusLine
              key={`${brandId}-${shipmentIndex}`}
              gift={gift}
              productFragment={productFragmentsById[brandId]}
              shipment={shipment}
            />
          ),
        ),
      )}
    </>
  )
}

const GiftShippingStatusLine = ({
  gift,
  productFragment,
  shipment,
}: {
  gift: TrackPageGiftFragment
  productFragment: ProductFragmentFragment
  shipment: ShipmentFragment | null
}) => {
  const shipDate = useMemo(
    () => (shipment?.shippedAt ? new Date(shipment.shippedAt) : null),
    [shipment?.shippedAt],
  )

  const etaDate = useMemo(
    () => (shipment?.deliveryEta ? new Date(shipment.deliveryEta) : null),
    [shipment?.deliveryEta],
  )

  const isGiftDelivered = gift.status === GiftViewableStatus.DELIVERED

  const shipText = (() => {
    if (isGiftDelivered) {
      return "Delivered"
    }

    if (shipment) {
      if (shipment.deliveredAt) {
        return "Delivered"
      }

      const dateOptions: Intl.DateTimeFormatOptions = {
        day: "numeric",
        month: "numeric",
      }
      const shipString = shipDate?.toLocaleDateString("en-US", dateOptions)
      const etaString = etaDate?.toLocaleDateString("en-US", dateOptions)

      if (shipDate && etaDate) {
        return `Shipped ${shipString}\u00A0\u00A0·\u00A0\u00A0ETA ${etaString}`
      } else if (shipDate) {
        return `Shipped ${shipString}`
      }
    }

    return "Preparing"
  })()

  const fillPercentage = (() => {
    if (isGiftDelivered) {
      return 100
    }

    if (shipment) {
      if (shipment.deliveredAt) {
        return 100
      } else if (shipDate && etaDate) {
        return (
          Math.max(
            0.1, // @ts-ignore - TS doesn't understand that you can subtract dates when you can
            Math.min(0.9, (new Date() - shipDate) / (etaDate - shipDate)),
          ) * 100
        )
      } else if (shipDate) {
        return 50
      }
    }

    return 0
  })()

  return (
    <div tw="text-sm text-gray-600 pt-1 flex flex-row items-center">
      <div tw="flex-1">{productFragment.brandName}</div>
      <div tw="ml-5">{shipText}</div>
      <StackedContainer tw="ml-5 h-1 w-[92px] rounded-full overflow-hidden">
        <div tw="bg-gray-200" />
        <ShippingProgress
          css={`
            clip-path: polygon(
              0 0,
              ${fillPercentage}% 0,
              ${fillPercentage}% 100%,
              0 100%
            );
          `}
        />
      </StackedContainer>
    </div>
  )
}

const StackedContainer = styled.div`
  ${tw`grid`}

  & > * {
    grid-column: 1 / 1;
    grid-row: 1 / 1;
  }
`

const ThankYouNote = styled.div`
  ${tw`px-5 py-4 text-primary-800 flex-1 text-lg`};
  background: linear-gradient(90deg, #fef7f7 0%, #f6f1fc 100%);
`

const RecentStatusContainer = styled.div`
  ${tw`relative text-sm text-gray-400 pt-1`};
`

const RecentStatusNormal = styled.div`
  ${tw``};

  // Retain text height even when empty to allow gift ID to take up the space
  // on hover
  &::after {
    content: "\feff";
  }
`

const RecentStatusHover = styled.div`
  ${tw`absolute top-0 left-0 bottom-0 right-0 pt-1 bg-white z-10 opacity-0 transition-opacity pointer-events-none`};
`

// Extends RoundedBox by adding a hover effect and causing RecentStatusHover to
// become visible when the box is hovered (which shows the Gift ID).
const GiftBoxContainer = styled(RoundedBox)<{ isLinkedTo: boolean }>`
  ${tw`transition-all`};
  ${({ isLinkedTo }) => isLinkedTo && tw`animate-large-to-normal`};

  &:hover {
    box-shadow:
      0 4px 16px rgba(0, 0, 0, 0.06),
      0 16px 54px rgba(0, 0, 0, 0.08);
    transform: translateY(-4px);
    border-color: #fff;

    ${RecentStatusHover} {
      ${tw`opacity-100 pointer-events-auto`};
    }
  }
`

const TRACK_GIFT_STATUS_SET_QUERY = gql`
  query Track_GiftBatchGiftStatusSet(
    $id: ID!
    $filter: GiftSeriesFilter!
    $page: Int!
  ) {
    workspace {
      giftBatch(id: $id) {
        giftSeries(page: $page, perPage: 20, filter: $filter) {
          items {
            ...TrackPageGift
          }
          totalCount
        }
      }
    }
  }

  ${TRACK_PAGE_GIFT_FRAGMENT}
`

export default GiftStatusSet
