import React from "react"
import Modal from "react-modal"
import { Link } from "react-router-dom"
import tw, { styled } from "twin.macro"

import { useGlobalState } from "./GlobalState"
import GradientButton from "./GradientButton"
import { useFeatureAccess } from "./hooks/featureAccess"
import { level2ModalStyle } from "./modal"
import {
  ProPlanFeature,
  trackProFeatureClick,
  trackProSubscribeClick,
} from "./proPlan"
import { generateRealmPath } from "./realm"
import {
  ProClose,
  ProFeatureCards,
  ProFeatureGiftCards,
  ProFeatureGmail,
  ProFeatureInternational,
  ProFeatureLogo,
  ProFeatureMeetingScheduler,
  ProTagIcon,
  ReportsIcon,
} from "../assets/icons"
import proModalImage from "../assets/images/pro-modal-image.svg"
import LimitedAccessProtected from "../common/LimitedAccessProtected"

interface ProPlanProtectedProps {
  children: React.ReactElement
  feature: ProPlanFeature
  // Disable protection to allow users to do some action that is necessary
  // despite not having the Pro plan.
  disableProtection?: boolean
}

// Wrapper component that replaces the onClick to open the pro plan modal if they don't have the plan
export const ProPlanProtected: React.FC<ProPlanProtectedProps> = ({
  children: child,
  feature,
  disableProtection,
}) => {
  const { hasFeature } = useFeatureAccess()
  const [, setIsProPlanModalOpen] = useGlobalState("isProPlanModalOpen")

  if (hasFeature("pro_plan") || disableProtection) {
    return <LimitedAccessProtected>{child}</LimitedAccessProtected>
  }

  const { children: grandchildren } = child.props

  return React.cloneElement(
    child,
    {
      onClick: () => {
        trackProFeatureClick(feature)
        setIsProPlanModalOpen(true)
      },
    },
    grandchildren,
  )
}

const ProPlanModal: React.FC = () => {
  const [isProPlanModalOpen, setIsProPlanModalOpen] =
    useGlobalState("isProPlanModalOpen")

  return (
    <Modal
      isOpen={isProPlanModalOpen}
      style={level2ModalStyle}
      closeTimeoutMS={500}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      onRequestClose={() => setIsProPlanModalOpen(false)}
    >
      <Container className="modal-content-animated">
        <GradientBackground>
          <div
            tw="hidden md:block px-3 py-0.5 rounded-full border font-semibold"
            css="border-color: rgb(142, 128, 234, .3); color: #9F74F8; font-size: .9375rem"
          >
            Subscribe
          </div>
          <div tw="flex flex-row gap-2 items-center">
            <ProTagIcon
              tw="fill-current"
              css="color: rgba(159, 116, 248, .6);"
              width={28}
              height={28}
            />
            <div
              tw="font-semibold text-2xl"
              css={
                "background: linear-gradient(90deg, #9F73F7 0%, #7A68F6 100%);\n  -webkit-background-clip: text;\n  -webkit-text-fill-color: transparent;"
              }
            >
              Upgrade to Pro
            </div>
          </div>
        </GradientBackground>
        <div tw="flex flex-col p-5 md:p-9 md:pr-16 gap-6 md:gap-12">
          <div tw="grid grid-cols-1 md:grid-rows-4 gap-y-2 md:gap-y-4 md:gap-x-14 md:grid-flow-col">
            <FeatureBox
              icon={<ProFeatureLogo />}
              boldText="Add logo branding"
              regularText="to gift notifications"
            />
            <FeatureBox
              icon={<ProFeatureGiftCards />}
              boldText="Send gift cards"
              regularText=""
            />
            <FeatureBox
              icon={<ProFeatureInternational />}
              boldText="International shipping"
              regularText="and gift cards"
            />
            <FeatureBox
              icon={<ProFeatureMeetingScheduler />}
              boldText="Integrate Calendly"
              regularText="to book meetings via gifts"
            />
            <FeatureBox
              icon={<ProFeatureCards />}
              regularText="Create custom cards"
            />
            <FeatureBox
              icon={<ProFeatureGmail />}
              regularText="Send from your own Gmail account"
            />
            <FeatureBox
              icon={<ReportsIcon />}
              regularText="Report filters and more charts"
            />
          </div>
          <div tw="flex flex-col md:flex-row gap-6 items-center">
            <Link
              to={generateRealmPath("plus", "/subscribe-pro")}
              onClick={() => {
                trackProSubscribeClick()
                setIsProPlanModalOpen(false)
              }}
            >
              <GradientButton
                tw="text-lg font-semibold text-white rounded-lg px-6"
                css="padding-top: 13px; padding-bottom: 13px;"
              >
                Subscribe
              </GradientButton>
            </Link>
            <div tw="md:text-lg text-gray-450">
              <span tw="text-black font-semibold">$20/month</span> billed
              annually
              <span tw="hidden md:inline">
                &nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;
              </span>
              <br tw="inline md:hidden" />
              <span tw="text-black font-semibold">$25/month</span> billed
              monthly
            </div>
          </div>
        </div>
        <ProClose
          tw="absolute right-5 top-5 cursor-pointer"
          onClick={() => setIsProPlanModalOpen(false)}
        />
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  ${tw`rounded-2xl bg-white flex flex-col overflow-hidden`}
  box-shadow: 0px 8px 48px rgba(0, 0, 0, 0.08);
`

const GradientBackground = styled.div`
  background-image: linear-gradient(
      264.32deg,
      rgba(134, 105, 255, 0.39) -200%,
      rgba(255, 215, 251, 0) 105%
    ),
    url(${proModalImage});
  background-position: bottom right;
  background-repeat: no-repeat;
  ${tw`flex flex-col gap-2 items-start p-4 px-5 md:p-9 md:px-9`}
`

interface FeatureBoxProps {
  icon: React.ReactElement
  boldText?: string
  regularText: string
}

const FeatureBox: React.FC<FeatureBoxProps> = ({
  icon,
  boldText,
  regularText,
}) => {
  return (
    <div tw="flex flex-row items-center gap-3 gap-3.5">
      <div tw="h-8 w-8 md:h-9 md:w-9 bg-gray-100 text-[#a1a6af] stroke-current stroke-1.5 rounded-lg flex items-center justify-center">
        {icon}
      </div>
      <div tw="text-sm md:text-lg text-black flex-1">
        {boldText && <span tw="font-semibold">{boldText} </span>}
        {regularText}
      </div>
    </div>
  )
}

export default ProPlanModal
