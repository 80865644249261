import { gql, useMutation } from "@apollo/client"
import queryString from "query-string"
import React, { useEffect, useState } from "react"
import { Redirect, useHistory, useLocation } from "react-router-dom"
import { styled } from "twin.macro"
import tw from "twin.macro"
import { useDebounce } from "use-debounce"

import PlanSelector from "./components/PlanSelector"
import PromotionCode from "./components/PromotionCode"
import SubscriptionPriceEstimate from "./components/SubscriptionPriceEstimate"
import {
  usePromotionCode,
  useSubscriptionPriceEstimate,
} from "./hooks/subscriptions"
import useSubscriptionPlanData from "./useSubscriptionPlanData"
import {
  ProFeatureCards,
  ProFeatureGiftCards,
  ProFeatureGmail,
  ProFeatureLogo,
  ProFeatureMeetingScheduler,
  ProFeatureSchedule,
  ReportsIcon,
} from "../assets/icons"
import { ReactComponent as ArrowRight } from "../assets/icons/arrow-right.svg"
import AddPaymentMethodForm from "../common/billing/AddPaymentMethodForm"
import OmniPaymentMethodSelector from "../common/billing/OmniPaymentMethodSelector"
import useAddPaymentMethod from "../common/billing/useAddPaymentMethod"
import useOmniPaymentMethods from "../common/billing/useOmniPaymentMethods"
import { useGlobalState } from "../common/GlobalState"
import GradientButton from "../common/GradientButton"
import { generateRealmPath } from "../common/realm"
import { errorToast, successToast } from "../common/toast"
import { ROOT_DATA_QUERY } from "../graphql"
import MainContainer from "../signUp/components/MainContainer"
import VerticalPurpleBanner from "../signUp/components/VerticalPurpleBanner"
import Container from "../sites/App/Container"

import { PlusEnrollmentStatus } from "@/types/graphql-types"
import {
  Subscribe_UserSubscriptionCreateMutation,
  Subscribe_UserSubscriptionCreateMutationVariables,
} from "@/types/graphql-types"

export default function SubscribePro() {
  const [user] = useGlobalState("user")
  const [enrollmentStatus] = useGlobalState("enrollmentStatus")

  const { formFields, setFormField, setFormFields, preSubmit } =
    useAddPaymentMethod()

  const { search } = useLocation()
  const queryValues = queryString.parse(search)
  // This value could be an array as well, but we assume it's not.
  let defaultPlan = queryValues.plan as string

  if (
    defaultPlan !== "business_pro_monthly" &&
    defaultPlan !== "business_pro_yearly"
  ) {
    defaultPlan = "business_pro_yearly"
  }

  const isPostSignup = typeof queryValues["post-signup"] !== "undefined"

  const { selectedPlan, alternateIntervalPlan, toggleInterval } =
    useSubscriptionPlanData(defaultPlan)

  // String: payment method ID
  // "NEW" string special-case: new payment method
  // null: not yet loaded
  const [selectedPaymentMethodID, setSelectedPaymentMethodID] = useState<
    string | "NEW" | null
  >(null)

  const [code, setCode] = useState("")
  const { promotionCode, loading: promotionLoading } = usePromotionCode(code)

  // Run price estimate on mount and debounced form fields change.
  const [debouncedFormFields] = useDebounce(formFields, 1000)
  const paymentMethodID =
    selectedPaymentMethodID && selectedPaymentMethodID !== "NEW"
      ? selectedPaymentMethodID
      : null

  const {
    runEffect: runSubscriptionPriceEstimate,
    data: estimateData,
    loading: estimateLoading,
  } = useSubscriptionPriceEstimate({
    paymentMethodID: paymentMethodID,
    price: selectedPlan.price,
    formFields: formFields,
  })
  useEffect(runSubscriptionPriceEstimate, [
    runSubscriptionPriceEstimate,
    debouncedFormFields,
  ])

  let discountAmount = promotionCode?.discountAmount
  if (
    promotionCode &&
    !promotionCode.discountAmount &&
    promotionCode.discountPercentage
  ) {
    discountAmount =
      (selectedPlan.price * promotionCode.discountPercentage) / 100
  }

  const {
    runEffect: runSubscriptionPriceEstimateWithPromo,
    data: estimateWithPromoData,
  } = useSubscriptionPriceEstimate({
    paymentMethodID: paymentMethodID,
    price: Math.max(selectedPlan.price - (discountAmount || 0), 0),
    formFields: formFields,
  })
  useEffect(runSubscriptionPriceEstimateWithPromo, [
    runSubscriptionPriceEstimateWithPromo,
    discountAmount,
  ])

  const history = useHistory()

  const [subscriptionCreateLoading, setSubscriptionCreateLoading] =
    useState(false)

  const { paymentMethods, paymentMethodCount, defaultPaymentMethodID } =
    useOmniPaymentMethods("USER")

  useEffect(() => {
    // Only run this prep function when selectedPaymentMethodID is null, and if
    // we have fully loaded payment methods data.
    if (selectedPaymentMethodID === null && paymentMethods.me) {
      if (defaultPaymentMethodID) {
        setSelectedPaymentMethodID(defaultPaymentMethodID)
      } else {
        // No available payment methods. Set to new form.
        setSelectedPaymentMethodID("NEW")
      }
    }
  }, [paymentMethods, defaultPaymentMethodID, selectedPaymentMethodID])

  const [subscriptionCreate] = useMutation<
    Subscribe_UserSubscriptionCreateMutation,
    Subscribe_UserSubscriptionCreateMutationVariables
  >(USER_SUBSCRIPTION_CREATE_MUTATION)

  useEffect(() => {
    if (enrollmentStatus === PlusEnrollmentStatus.NONE) {
      errorToast(
        "You must be signed up for Goody for Business to subscribe to Pro.",
      )
    }
  }, [enrollmentStatus])

  if (!user) {
    return <Redirect to={generateRealmPath("business", "/signin")} />
  }

  if (enrollmentStatus === PlusEnrollmentStatus.NONE) {
    return <Redirect to={generateRealmPath("business", "/signup")} />
  }

  // Prepare the card, whether it is an existing payment method or a new card.
  //
  // - If a new card, first create an InterimCard by calling VGS through
  //   presubmit(), and pass through the interimCardToken it returns. If it
  //   returns a falsy value for interimCardToken, return false.
  // - If existing payment method, just pass through and return null.
  //
  // This function returns a string for an interimCardValue, null when not
  // creating a new payment method, and false when there's an error creating an
  // interim card token.
  const prepareCard = async (): Promise<string | null | false> => {
    if (selectedPaymentMethodID === "NEW") {
      const interimCardToken = await preSubmit()

      if (!interimCardToken) {
        // Invalid result for creating a new interim card; something must be
        // wrong, so return false instead of null which could be confused with
        // not creating a new payment method
        return false
      }

      return interimCardToken
    } else {
      return null
    }
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    setSubscriptionCreateLoading(true)

    const interimCardToken = await prepareCard()

    if (interimCardToken === false) {
      // Error occurred. Return early. The error is probably already alerted by
      // the function handling prepareCard/preSubmit.
      setSubscriptionCreateLoading(false)
      return
    }

    // At this point, we either have a valid interimCardToken, or a null
    // interimCardToken signifying that we are not creating a new card.

    const variables: Subscribe_UserSubscriptionCreateMutationVariables = {
      sku: selectedPlan.sku,
      promotionCode: code,
    }

    // Set paymentMethodID or cardInput based on the payment mode.
    if (
      !interimCardToken &&
      selectedPaymentMethodID &&
      selectedPaymentMethodID !== "NEW"
    ) {
      variables.paymentMethodID = selectedPaymentMethodID
    } else if (interimCardToken && selectedPaymentMethodID === "NEW") {
      variables.cardInput = {
        interimCardToken,
        address1: formFields.address1,
        address2: formFields.address2,
        addressCity: formFields.city,
        addressState: formFields.state,
        addressPostalCode: formFields.postalCode,
        cardholderName: formFields.name,
      }
    }

    const graphqlResponse = await subscriptionCreate({
      variables,
      refetchQueries: [{ query: ROOT_DATA_QUERY }],
    })

    const data = graphqlResponse?.data?.subscriptionCreate

    if (data?.ok) {
      successToast("Welcome to Goody for Business Pro!")
      if (isPostSignup) {
        history.push(generateRealmPath("plus", "/post-signup/workspace"))
      } else {
        const redirectTo = sessionStorage.getItem("signupRedirectTo")
        if (redirectTo) {
          sessionStorage.removeItem("signupRedirectTo")
          history.push(redirectTo)
        } else {
          history.push(generateRealmPath("plus", "/send"))
        }
      }
    } else {
      if (data?.error) {
        alert(data.error)
      } else {
        alert("Sorry, an unexpected error occurred.")
      }
    }

    setSubscriptionCreateLoading(false)
  }

  return (
    <Container tw="lg:grid grid-cols-8">
      <VerticalPurpleBanner tw="col-span-3 pb-36">
        <div className="text">
          <h1>
            <small>Upgrade your gifting</small>
            Subscribe to Pro
          </h1>
        </div>
        <div tw="relative w-full flex flex-col gap-2 px-6 pb-8">
          <FeatureBox
            icon={<ProFeatureLogo tw="w-6 h-6" />}
            boldText="Add logo branding"
            regularText="to gift notifications"
          />
          <FeatureBox
            icon={<ProFeatureGiftCards tw="w-6 h-6" />}
            boldText="Send gift cards"
            regularText="from hundreds of brands"
          />
          <FeatureBox
            icon={<ProFeatureSchedule tw="w-6 h-6" />}
            boldText="International gifts"
            regularText="and gift cards"
          />
          <FeatureBox
            icon={<ProFeatureMeetingScheduler tw="w-6 h-6" />}
            boldText="Integrate Calendly"
            regularText="to book meetings via gifts"
          />
          <FeatureBox
            icon={<ProFeatureCards tw="w-6 h-6" />}
            regularText="Create custom cards"
          />
          <FeatureBox
            icon={<ProFeatureGmail tw="w-6 h-6" />}
            regularText="Send from your own Gmail account"
          />
          <FeatureBox
            icon={<ReportsIcon />}
            regularText="Report filters and more charts"
          />
        </div>
      </VerticalPurpleBanner>
      <MainContainer tw="col-span-5">
        <FormContainer onSubmit={handleSubmit}>
          <div
            css={{
              display:
                selectedPaymentMethodID && selectedPaymentMethodID !== "NEW"
                  ? "block"
                  : "none",
            }}
          >
            <OmniPaymentMethodSelector
              selectedPaymentMethodID={selectedPaymentMethodID}
              paymentMethods={paymentMethods}
              setSelectedPaymentMethodID={setSelectedPaymentMethodID}
            />
          </div>
          <div
            css={{
              display:
                selectedPaymentMethodID && selectedPaymentMethodID === "NEW"
                  ? "block"
                  : "none",
            }}
          >
            <AddPaymentMethodForm
              setFormField={setFormField}
              setFormFields={setFormFields}
              formFields={formFields}
              showBackButton={
                !!(paymentMethodCount > 0 && defaultPaymentMethodID)
              }
              onClickBack={() =>
                setSelectedPaymentMethodID(defaultPaymentMethodID)
              }
            />
          </div>
          <PlanSelector
            selectedPlan={selectedPlan}
            alternateIntervalPlan={alternateIntervalPlan}
            toggleInterval={toggleInterval}
          />
          <SubscriptionPriceEstimate
            selectedPlan={selectedPlan}
            estimateData={estimateLoading ? null : estimateData}
            isTrial={false}
          />
          <PromotionCode
            onChange={(code) => setCode(code)}
            code={code}
            loading={promotionLoading}
            promotionCode={promotionCode}
            discountAmount={discountAmount}
            priceEstimateWithPromo={estimateWithPromoData}
            selectedPlan={selectedPlan}
          />

          <div tw="pt-8 pb-6 flex flex-row justify-center">
            <GradientButton
              onClick={handleSubmit}
              disabled={subscriptionCreateLoading}
            >
              Upgrade
              <ArrowRight tw="ml-8" />
            </GradientButton>
          </div>
        </FormContainer>
      </MainContainer>
    </Container>
  )
}

const FormContainer = styled.form`
  ${tw`bg-white rounded-xl p-10 w-full`}
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.04), 0 1px 2px rgba(0, 0, 0, 0.03);

  @media (max-width: 1079px) {
    box-shadow: none;
    padding: 0;
  }
`

interface FeatureBoxProps {
  icon: React.ReactElement
  boldText?: string
  regularText: string
}

const FeatureBox: React.FC<FeatureBoxProps> = ({
  icon,
  boldText,
  regularText,
}) => {
  return (
    <div tw="flex flex-row items-center gap-4">
      <div tw="h-12 w-12 bg-[#5e2fcb] text-[#bfacea] stroke-current stroke-1.5 rounded-lg flex items-center justify-center">
        {icon}
      </div>
      <div tw="md:text-xl text-white flex-1">
        {boldText && <span tw="font-semibold">{boldText} </span>}
        {regularText}
      </div>
    </div>
  )
}

const USER_SUBSCRIPTION_CREATE_MUTATION = gql`
  mutation Subscribe_UserSubscriptionCreate(
    $cardInput: PaymentMethodCreditCardInput
    $paymentMethodID: ID
    $sku: String!
    $promotionCode: String
  ) {
    subscriptionCreate(
      cardInput: $cardInput
      paymentMethodId: $paymentMethodID
      sku: $sku
      promotionCode: $promotionCode
    ) {
      ok
      error
    }
  }
`
