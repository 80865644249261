import "twin.macro"
import nl2br from "react-nl2br"

import { ReactComponent as NewIcon } from "@/assets/icons/new.svg"
import { CountryFlag } from "@/common/CountryFlag"
import { ReactComponent as CheckIcon } from "@/common/images/check.svg"
import { ReactComponent as ShippingIcon } from "@/common/images/icon-shipping.svg"
import DevModeProductID from "@/store/pdp/DevModeProductID"
import { Store_PDP_ProductDetailsFragment } from "@/types/graphql-types"

interface Props {
  product: Store_PDP_ProductDetailsFragment
}

const STANDARD_FLEX_GIFT_NAME = "A Goody Gift"

export default function FlexGiftInfo({ product }: Props) {
  const name =
    product.name === STANDARD_FLEX_GIFT_NAME ? "Gift of Choice" : product.name

  const showNumberOneTag = product.name === STANDARD_FLEX_GIFT_NAME

  return (
    <div tw="flex-[0.85]">
      <div tw="flex flex-col xl:flex-row items-start xl:items-center gap-2 xl:gap-6">
        <div tw="text-3xl font-reckless-neue font-light">{name}</div>
        {showNumberOneTag && (
          <div tw="flex flex-row items-center gap-2.5 text-[15px] rounded-full bg-primary-new-050 text-primary-new-550 px-4 py-1.5">
            <NewIcon tw="w-5 h-5 text-primary-new-400 xl:hidden 2xl:block" />
            <span>
              <span tw="pr-0.5">#</span>
              <span tw="tabular-nums">1</span> gift on Goody
            </span>
          </div>
        )}
      </div>
      <div tw="pt-6 text-gray-700">{nl2br(product.subtitle)}</div>
      <div tw="pt-6">
        <div tw="flex flex-row items-center gap-2.5 text-gray-600">
          <div tw="w-5 h-5 flex flex-row items-center justify-center">
            <ShippingIcon tw="stroke-current text-gray-400 mt-0.5" />
          </div>
          Shipping included
        </div>
        <div tw="flex flex-row items-center gap-2.5 text-gray-600 pt-0.5">
          <CheckIcon tw="stroke-current text-gray-400 w-5 h-5 stroke-1.5" />
          No address needed
        </div>
        <div tw="flex flex-row items-center gap-2.5 text-gray-600 pt-0.5">
          <CheckIcon tw="stroke-current text-gray-400 w-5 h-5 stroke-1.5" />
          You only pay for accepted gifts
        </div>
      </div>
      {product.isShippingTierDisabled && (
        <div tw="flex flex-row items-center gap-2.5 text-gray-600 pt-6">
          <CountryFlag code="US" />
          This gift of choice only ships to the United States.
        </div>
      )}
      <DevModeProductID productID={product.id} />
    </div>
  )
}
