import { DocumentNode, gql, useMutation } from "@apollo/client"
import { format, parseISO } from "date-fns"
import { useState } from "react"

import { PurpleGradientButton } from "./PurpleGradientLink"
import { Loader } from "./UI"

import { APITokenTtype } from "@/types/graphql-types"
import {
  APITokenFragment,
  APITokens_CreateMutation,
  APITokens_CreateMutationVariables,
  APITokens_DeactivateMutation,
  APITokens_DeactivateMutationVariables,
} from "@/types/graphql-types"

interface Props {
  apiTokens: APITokenFragment[] | undefined
  queryToRefetch: DocumentNode
  ttype: APITokenTtype
  actionsDisabled?: boolean
  commerceAppID?: string
  onRefetch?: () => void
}

export default function APITokens({
  apiTokens,
  queryToRefetch,
  ttype,
  actionsDisabled,
  commerceAppID,
  onRefetch,
}: Props) {
  const [apiTokenCreate, { loading: apiTokenCreateLoading }] = useMutation<
    APITokens_CreateMutation,
    APITokens_CreateMutationVariables
  >(API_TOKEN_CREATE)
  const [apiTokenDeactivate, { loading: apiTokenDeactivateLoading }] =
    useMutation<
      APITokens_DeactivateMutation,
      APITokens_DeactivateMutationVariables
    >(API_TOKEN_DEACTIVATE)

  // API token created in current session
  const [createdApiToken, setCreatedApiToken] =
    useState<APITokenFragment | null>(null)

  const onClickCreate = async () => {
    const res = await apiTokenCreate({
      variables: {
        ttype,
        commerceAppID: commerceAppID || null,
      },
      refetchQueries: [{ query: queryToRefetch }],
    })
    if (res.data?.apiTokenCreate?.ok) {
      setCreatedApiToken(res.data?.apiTokenCreate.apiToken ?? null)
      onRefetch && onRefetch()
    } else {
      alert(
        `API Key Creation failed. Please contact your account representative.`,
      )
    }
  }

  const onClickDelete = async (id: string) => {
    if (!window.confirm("Are you sure you want to delete this API key?")) {
      return
    }

    const res = await apiTokenDeactivate({
      variables: {
        id,
      },
      refetchQueries: [{ query: queryToRefetch }],
    })
    if (res.data?.apiTokenDeactivate?.ok) {
      if (id === createdApiToken?.id) {
        setCreatedApiToken(null)
      }

      onRefetch && onRefetch()
    } else {
      alert("API Key Deletion failed")
    }
  }

  const listApiTokens =
    apiTokens?.filter((apiToken) => apiToken.id !== createdApiToken?.id) ?? []

  return (
    <>
      {!apiTokens ? (
        <div tw="flex justify-center items-center flex-1 pb-6">
          <Loader />
        </div>
      ) : (
        <>
          <div tw="flex-1 justify-between">
            {createdApiToken && (
              <div tw="self-stretch bg-gray-100 mb-6 py-5 px-6">
                <div tw="font-semibold">Your API key</div>
                <div tw="text-gray-500">
                  For security reasons, you won’t be able to view this token
                  again.
                </div>
                <input
                  tw="mt-3 py-2.5 px-3 bg-white rounded-lg font-mono outline-none w-full text-gray-600"
                  onClick={(e) => e.currentTarget.select()}
                  className="data-hj-suppress ph-no-capture fs-exclude"
                  value={createdApiToken.key || ""}
                />
              </div>
            )}
            {listApiTokens.length > 0 ? (
              <div>
                <div tw="border-b border-gray-200 px-6 pb-3 font-semibold">
                  API keys
                </div>
                {listApiTokens.map((apiToken) => (
                  <div
                    key={apiToken.id}
                    tw="border-b border-gray-200 flex flex-row justify-between px-6 py-3"
                  >
                    <div>Key generated by {apiToken.creatorName}</div>
                    <div>
                      <span tw="text-gray-500">
                        {getDateString(apiToken.createdAt)}
                        &nbsp;&nbsp;·&nbsp;&nbsp;
                      </span>
                      <button
                        tw="cursor-pointer disabled:opacity-50"
                        css="color: #EB5757;"
                        onClick={() => onClickDelete(apiToken.id)}
                        disabled={actionsDisabled || apiTokenDeactivateLoading}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              !createdApiToken && (
                <>
                  {ttype === APITokenTtype.salesforce ? (
                    <div tw="mx-6 mb-6 text-gray-600">
                      During setup, you’ll be asked for an API key to connect
                      your Salesforce organization to Goody.
                      <br />
                      <br />
                      Click <span tw="font-semibold">New API Key</span> below
                      when you’re ready to enter your API key into setup.
                    </div>
                  ) : ttype === APITokenTtype.user ||
                    ttype === APITokenTtype.commerce ? (
                    <div tw="mx-6 mb-6 text-gray-600">
                      Click <span tw="font-semibold">New API Key</span> to
                      create a key.
                    </div>
                  ) : null}
                </>
              )
            )}
          </div>
          {!createdApiToken && (
            <PurpleGradientButton
              onClick={onClickCreate}
              tw="px-5 py-3 m-6 self-start"
              disabled={actionsDisabled || apiTokenCreateLoading}
            >
              New API Key
            </PurpleGradientButton>
          )}
        </>
      )}
    </>
  )
}

const getDateString = (dateString: string) => {
  try {
    return format(parseISO(dateString), "MMMM d, yyyy")
  } catch (e) {
    return ""
  }
}

export const API_TOKEN_FRAGMENT = gql`
  fragment APIToken on APIToken {
    id
    createdAt
    creatorName
    key
    ttype
  }
`

const API_TOKEN_CREATE = gql`
  mutation APITokens_Create($ttype: APITokenTtype!, $commerceAppID: ID) {
    apiTokenCreate(ttype: $ttype, commerceAppId: $commerceAppID) {
      ok
      apiToken {
        ...APIToken
      }
    }
  }
  ${API_TOKEN_FRAGMENT}
`

const API_TOKEN_DEACTIVATE = gql`
  mutation APITokens_Deactivate($id: ID!) {
    apiTokenDeactivate(id: $id) {
      ok
    }
  }
`
