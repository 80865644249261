import { CurrentCustomStore } from "@/common/GlobalState"
import { InternationalShippingTierEnum } from "@/types/graphql-types"

export const filterAvailableStoreOptions = (
  options: CurrentCustomStore["optionList"],
  formFields: CurrentCustomStore,
  selectedShippingCountry: string,
) => {
  return options.filter((option) => {
    if (!option.isAvailable) return false

    // Relevant products to check for shipping locations
    const products: { shippingCountriesFinal: string[] }[] = option.giftOption
      ?.products || [option.product]

    // One of the products needs to ship to the country
    if (
      !products.some((product) =>
        product.shippingCountriesFinal.includes(selectedShippingCountry),
      )
    ) {
      return false
    }

    // If we are in the US, the shipping tier doesn't matter
    if (selectedShippingCountry === "US") return true

    if (
      formFields.internationalShippingTier ===
      InternationalShippingTierEnum.disabled
    ) {
      return false
    }

    if (
      !formFields.settings.giftCardsEnabled &&
      !!option.product.giftCardInfo
    ) {
      return false
    }

    if (
      formFields.internationalShippingTier ===
      InternationalShippingTierEnum.standard
    ) {
      return true
    }

    if (
      formFields.internationalShippingTier ===
      InternationalShippingTierEnum.full
    ) {
      return true
    }

    throw new Error(
      `internationalShippingTier: ${formFields.internationalShippingTier} not supported.`,
    )
  })
}
