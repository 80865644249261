import { useApolloClient } from "@apollo/client"
import { cloneDeep } from "lodash-es"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useDeepCompareMemoize } from "use-deep-compare-effect"

import { WithContext, withContext } from "@/common/requestContext"
import { isSearchActive } from "@/common/SearchField"
import { PriceFilterValue } from "@/store/filters"
import { STOREFRONT_QUERY } from "@/store/graphql"
import { StoreMode } from "@/store/types"
import { priceFilterValueToMinMax } from "@/store/utils"
import {
  BusinessSegment,
  CategorySegment,
  ShippingCountry,
} from "@/types/graphql-types"
import {
  Store_GiftOptionNodeFragment,
  Store_ProductStoreTileFragment,
  Store_StorefrontQuery,
  Store_StorefrontQueryVariables,
} from "@/types/graphql-types"

interface FilterParams {
  brandValues: string[]
  searchTerm: string
  priceFilterValue: PriceFilterValue
  categorySlug: string | null
  segment: BusinessSegment
  selectedShippingCountry: ShippingCountry
  showOnlyDomesticShipping: boolean
  mode?: StoreMode
  storeType?: string
}

interface UseStorefrontPayload {
  results: Store_GiftOptionNodeFragment[] | Store_ProductStoreTileFragment[]
  loading: boolean
  priceFilterMin: number | null
  priceFilterMax: number | null
  decideIfNextPageShouldLoad: (index: number) => void
  nextPageIsLoading: boolean
  hasStartedLoading: boolean
}

const INITIAL_STOREFRONT_STATE: {
  giftOptions: Store_GiftOptionNodeFragment[]
  products: Store_ProductStoreTileFragment[]
  endCursor: string
  hasNextPage: boolean
} = {
  giftOptions: [],
  products: [],
  endCursor: "",
  hasNextPage: true,
}

const ITEMS_LIMIT = 20
const PREFETCH_PAGE_COUNT = 2

// Loads gift options or products based on the provided mode
// The query uses @skip and @include to only fetch the relevant data
export const useStorefront = (params: FilterParams): UseStorefrontPayload => {
  const productMode = params.mode === "products"
  const [hasStartedLoading, setHasStartedLoading] = useState(false)

  const {
    brandValues,
    searchTerm,
    priceFilterValue,
    categorySlug,
    segment,
    selectedShippingCountry,
    showOnlyDomesticShipping,
    storeType,
  } = params

  // Variables for the query. Resets whenever the parameters change
  const { variables, priceFilterMin, priceFilterMax } = useMemo(() => {
    const { priceFilterMin, priceFilterMax } =
      priceFilterValueToMinMax(priceFilterValue)

    let queryVariables: Store_StorefrontQueryVariables = {
      brandValues,
      first: ITEMS_LIMIT,
      searchQuery: searchTerm,
      priceMin: priceFilterMin,
      priceMax: priceFilterMax,
      defaultCategorySegment: CategorySegment.PLUS,
      shippingCountry: selectedShippingCountry.code,
      showOnlyDomesticShipping,
      productMode,
      segment,
      ...(!isSearchActive(searchTerm) && { categorySlug: categorySlug }),
    }

    return {
      variables: queryVariables,
      priceFilterMin,
      priceFilterMax,
    }
  }, [useDeepCompareMemoize(params)])

  // Last index scrolled to - so we know where to prefetch
  const [lastIndexShown, setLastIndexShown] = useState(0)
  const [loading, setLoading] = useState(false)

  const [storefrontState, setStorefrontState] = useState(
    INITIAL_STOREFRONT_STATE,
  )
  const currentVariables = useRef(variables)

  const client = useApolloClient()

  // If variables changed, we need to reset
  useEffect(() => {
    setLastIndexShown(0)
    setLoading(false)
    setStorefrontState(cloneDeep(INITIAL_STOREFRONT_STATE))
    currentVariables.current = variables
  }, [variables])

  const loadMore = async () => {
    // We need to keep track of WHICH set of variables the request was made from so we
    // know whether we can use the results
    setLoading(true)
    setHasStartedLoading(true)

    const getStorefront = () => {
      return client.query<
        Store_StorefrontQuery,
        WithContext<Store_StorefrontQueryVariables>
      >({
        query: STOREFRONT_QUERY,
        variables: withContext(
          {
            afterCursor: storefrontState.endCursor,
            ...variables,
          },
          {
            ...(storeType && { storeType }),
          },
        ),
        fetchPolicy: "cache-first",
      })
    }

    const result = await getStorefront()

    if (currentVariables.current !== variables) {
      return
    }

    const giftOptionsConnection = result.data.giftOptionsConnection

    if (giftOptionsConnection) {
      setStorefrontState((prev) => ({
        ...prev,
        giftOptions: storefrontState.giftOptions.concat(
          giftOptionsConnection.nodes,
        ),
        hasNextPage: giftOptionsConnection.pageInfo.hasNextPage,
        endCursor: giftOptionsConnection.pageInfo.endCursor ?? "",
      }))
    }

    const productsConnection = result.data.productsConnection

    if (productsConnection) {
      setStorefrontState((prev) => ({
        ...prev,
        products: storefrontState.products.concat(productsConnection.nodes),
        hasNextPage: productsConnection.pageInfo.hasNextPage,
        endCursor: productsConnection.pageInfo.endCursor ?? "",
      }))
    }

    setLoading(false)
  }

  // Check to fetch next page
  useEffect(() => {
    if (
      !loading &&
      lastIndexShown >
        (productMode
          ? storefrontState.products.length
          : storefrontState.giftOptions.length) -
          ITEMS_LIMIT * PREFETCH_PAGE_COUNT &&
      storefrontState.hasNextPage
    ) {
      loadMore()
    } // Don't add variables as a dependency here: it will use a stale version of the variables as they are reset above by variables
  }, [loading, lastIndexShown, storefrontState])

  // Sets index of last item displayed so we know whether to prefetch
  const decideIfNextPageShouldLoad = useCallback(
    (index: number) => {
      if (index > lastIndexShown) {
        setLastIndexShown(index)
      }
    },
    [lastIndexShown, setLastIndexShown],
  )

  return {
    results: productMode
      ? storefrontState.products
      : storefrontState.giftOptions,
    nextPageIsLoading:
      storefrontState.hasNextPage &&
      (storefrontState.giftOptions.length > 0 ||
        storefrontState.products.length > 0),
    loading,
    decideIfNextPageShouldLoad,
    priceFilterMin,
    priceFilterMax,
    hasStartedLoading,
  }
}
