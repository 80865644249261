import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Helmet } from "react-helmet-async"
import { Link, Redirect } from "react-router-dom"
import tw, { styled } from "twin.macro"

import FeatureSlideshow from "./components/FeatureSlideshow"
import brandsHero from "./images/brands-hero-2.png"
import brandsHeroMobile from "./images/brands-hero-mobile.png"
import { ReactComponent as BusinessAccent1 } from "./images/business-accent-1.svg"
import { ReactComponent as BusinessAccent2 } from "./images/business-accent-2.svg"
import businessCustomersMobile from "./images/business-customers-mobile.png"
import businessCustomers from "./images/business-customers.png"
import businessHeroMobile from "./images/business-hero-mobile.png"
import businessHero from "./images/business-hero.png"
import { ReactComponent as CurlyArrowLeft } from "./images/curly-arrow-left.svg"
import { ReactComponent as CurlyArrowRight } from "./images/curly-arrow-right.svg"
import Feature1DesktopPng from "./images/feature-1-desktop.png"
import { ReactComponent as FeatureGiftIcon } from "./images/feature-1-icon.svg"
import Feature1MobilePng from "./images/feature-1-mobile.png"
import { ReactComponent as FeatureSendIcon } from "./images/feature-2-icon.svg"
import Feature2 from "./images/feature-2.png"
import { useScreen } from "../common/analytics"
import { useGlobalState } from "../common/GlobalState"
import { useWindowSize } from "../common/hooks/useWindowSize"
import PurpleGradientLink from "../common/PurpleGradientLink"
import RainbowHeading from "../common/RainbowHeading"
import { generateRealmPath } from "../common/realm"
import HeroLogos from "../sites/Public/images/hero-logos.png"
import { ReactComponent as Stars } from "../sites/Public/images/stars.svg"

import { PlusEnrollmentStatus } from "@/types/graphql-types"

const Landing: React.FC = () => {
  const [enrollmentStatus] = useGlobalState("enrollmentStatus")
  const [currentRealm] = useGlobalState("currentRealm")
  const { width } = useWindowSize()
  // 1080px is the lg size on tailwind;
  const isDesktop = width > 1080

  useScreen("Consumer - Home")

  if (
    enrollmentStatus !== PlusEnrollmentStatus.NONE &&
    currentRealm === "plus"
  ) {
    return <Redirect to={generateRealmPath("plus", "/browse")} />
  }

  return (
    <div css={[tw`grid grid-cols-12`, isDesktop && tw`container lg:mx-auto`]}>
      <Helmet>
        <title>Personal Gifts | Goody</title>
        <meta
          name="title"
          property="og:title"
          content="Personal Gifts | Goody"
        />
        <meta
          name="description"
          content="Find thousands of unique gifts for birthdays, holidays or any occasion. Send with only an email or phone number."
        />
      </Helmet>
      <section tw="col-span-12 lg:col-span-7 py-12 md:py-24 pl-6 pr-6">
        <header tw="text-2xl md:text-3xl lg:text-4xl text-gray-800 my-6 mt-8">
          <h1
            css={[
              tw`text-center lg:text-left font-bold mb-3 text-3xl lg:text-4rem lg:leading-4.5rem`,
            ]}
          >
            <div tw="xl:block">Send the perfect gift.</div>
            <RainbowHeading
              as="span"
              css={[tw`font-bold text-3xl lg:text-4rem`]}
            >
              No address needed.
            </RainbowHeading>
          </h1>
        </header>
        <ScrollAnimation
          animateIn="fadeInUp"
          delay={300}
          duration={0.6}
          animateOnce
        >
          <h1 tw="text-xl lg:text-2xl xl:pr-72 text-gray-600 leading-normal text-center lg:text-left">
            Find curated gifts for any occasion. Gift with only an email or
            phone number.
          </h1>
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="fadeInUp"
          delay={500}
          duration={0.6}
          animateOnce
        >
          <div tw="flex flex-col lg:flex-row items-center lg:items-center mt-6 gap-2 lg:gap-4">
            <PurpleGradientLink
              to={generateRealmPath(null, "/browse")}
              rel="noopener"
              tw="lg:text-lg px-10 w-[14rem] lg:w-auto"
            >
              Send a gift
            </PurpleGradientLink>
          </div>
          <div tw="pt-9 text-center lg:text-left">
            <div tw="text-gray-600">More than 1000 five-star reviews</div>
            <div tw="pt-1 flex flex-row items-center justify-center lg:justify-start gap-2">
              <Stars />
              <div tw="text-gray-600">
                <span tw="font-medium text-gray-900">4.8</span> on the App Store
              </div>
            </div>
          </div>
        </ScrollAnimation>
      </section>

      <HeroBrands />

      <section tw="col-span-12 py-16 lg:py-24 lg:pt-48">
        <RainbowHeading
          as="div"
          tw="text-center font-medium text-xl pb-2 lg:pb-5"
        >
          Effortless gifting
        </RainbowHeading>
        <h2 tw="text-center font-bold text-3xl lg:text-5xl">How Goody works</h2>
      </section>

      <section tw="col-span-12 lg:col-span-6 pl-5 pr-5 md:pl-12 md:pr-12 lg:pr-8 lg:mb-12">
        <img
          src={Feature1DesktopPng}
          tw="hidden lg:inline-block w-full max-w-md xl:max-w-xl"
          alt=""
        />
        <img src={Feature1MobilePng} tw="lg:hidden w-full" alt="" />
      </section>
      <section tw="col-span-12 lg:col-span-6 pl-5 pr-5 md:pl-12 md:pr-12 lg:pr-8 mb-24 lg:mb-12 self-center lg:pb-24">
        <FeatureGiftIcon />
        <h2
          tw="pt-4 text-3xl lg:text-5xl font-bold max-w-lg"
          css={{ lineHeight: "1.15 !important" }}
        >
          Choose from our curated gifts for any&nbsp;occasion.
        </h2>
        <div tw="pt-5 text-gray-600 text-xl leading-normal max-w-lg">
          No more searching around the web for gifts. Send in‑trend gifts that
          people love, curated by our experts.
        </div>
      </section>

      {/* Arrow right */}
      <div tw="col-span-12 relative hidden lg:block">
        <div tw="w-full flex flex-row justify-center absolute -top-32 pr-48">
          <CurlyArrowRight />
        </div>
      </div>

      {/* Mobile */}
      <section tw="lg:hidden col-span-12 lg:col-span-6 pl-5 pr-5 md:pl-12 md:pr-12 lg:pr-8">
        <img src={Feature2} tw="w-full" alt="" />
      </section>
      <section tw="col-span-12 lg:col-span-6 pl-5 pr-5 md:pl-12 md:pr-12 lg:pr-8 mb-12 self-center xl:pl-24 lg:pb-24">
        <FeatureSendIcon />
        <h2
          tw="pt-4 text-3xl lg:text-5xl font-bold max-w-lg"
          css={{ lineHeight: "1.15 !important" }}
        >
          Send with only a phone number or email address.
        </h2>
        <div tw="pt-5 text-gray-600 text-xl leading-normal max-w-lg">
          <strong tw="text-gray-900">No mailing address needed.</strong> Your
          recipient provides their shipping info when they accept your gift.
        </div>
      </section>
      {/* Desktop */}
      <section tw="hidden lg:block col-span-12 lg:col-span-6 pl-5 pr-5 md:pl-12 md:pr-12 lg:pr-8 mb-12">
        <img src={Feature2} tw="w-full max-w-md" alt="" />
      </section>

      {/* Arrow left */}
      <div tw="col-span-12 relative hidden xl:block">
        <div tw="w-full flex flex-row justify-center absolute -top-48 pr-48">
          <CurlyArrowLeft />
        </div>
      </div>

      <FeatureSlideshow />

      <section
        tw="col-span-12 lg:rounded-3rem py-16 lg:py-24 pb-16"
        css={{ backgroundColor: "#f5f4ff" }}
      >
        <div tw="px-4 lg:px-16 pb-4">
          <RainbowHeading
            as="div"
            tw="text-center font-medium text-xl pb-2 lg:pb-5"
          >
            Our collection
          </RainbowHeading>
          <h2 tw="text-center font-bold text-3xl lg:text-5xl">
            <span tw="lg:block leading-snug">Quality gifts for every </span>
            occasion and price point.
          </h2>
          <div tw="pt-6 text-gray-600 text-xl text-center lg:w-1/2 mx-auto leading-normal">
            Goody partners with hundreds of on-trend brands. Find and support
            companies with values you believe in, like BIPOC, LGBTQ+, female
            founded, social impact-driven, or sustainable.
          </div>
          <div tw="pt-6 flex flex-row justify-center gap-2 lg:gap-4">
            <PurpleGradientLink
              to={generateRealmPath(null, "/browse")}
              rel="noopener"
              tw="lg:text-lg px-10 w-[14rem] lg:w-auto"
            >
              Browse Gifts
            </PurpleGradientLink>
          </div>
        </div>
        <Link to={generateRealmPath(null, "/browse")} tw="block">
          <img src={brandsHero} tw="hidden lg:block w-full" alt="" />
          <img src={brandsHeroMobile} tw="lg:hidden w-full" alt="" />
        </Link>
      </section>

      <section
        tw="xl:mt-12 col-span-12 lg:rounded-3rem py-16 lg:py-24 pb-36 lg:pb-24 text-white relative overflow-hidden"
        css={{ backgroundColor: "#202125" }}
      >
        <BusinessAccent1 tw="absolute top-0 right-0" css={{ zIndex: 0 }} />
        <BusinessAccent2
          tw="absolute -bottom-24 lg:bottom-0 left-0"
          css={{ zIndex: 0 }}
        />
        <div tw="container mx-auto grid grid-cols-12">
          <section tw="col-span-12 xl:col-span-6 p-6 xl:px-32 xl:py-24">
            <h2 tw="text-3xl xl:text-5xl font-bold text-center xl:text-left">
              Goody for Business
            </h2>
            <h3 tw="pt-4 text-2xl text-gray-300 text-center xl:text-left leading-normal">
              Send one gift or hundreds from our all‑in‑one platform.
            </h3>
            <div tw="-mx-6 md:hidden pt-8">
              <img src={businessHeroMobile} tw="w-full" />
            </div>
            <div tw="-mx-6 hidden md:block xl:hidden pt-8">
              <img src={businessHero} tw="w-full max-w-lg mx-auto" />
            </div>
            <h4 tw="font-bold pt-10 text-xl">Employee gifts</h4>
            <div tw="pt-2 text-xl text-gray-300">
              Employee engagement. Automated birthday and work anniversary
              gifts.
            </div>
            <h4 tw="font-bold pt-10 text-xl">
              Customers, sales prospects, &amp; events
            </h4>
            <div tw="pt-2 text-xl text-gray-300">
              Stand out from the pack. Custom branding and tailored landing
              pages. Built for teams.
            </div>
            <div tw="pt-10">
              <Link
                to={generateRealmPath("business", "/")}
                tw="text-lg bg-gradient-to-r from-gradient-purple-light to-gradient-purple-dark px-10 py-4 text-white font-medium rounded-lg hover:opacity-80 active:opacity-90 transition-opacity inline-block"
                rel="noopener"
              >
                Business Gifts
              </Link>
            </div>
          </section>
          <section tw="hidden xl:flex col-span-12 xl:col-span-6 flex-col justify-center">
            <div tw="-mr-24">
              <img src={businessHero} tw="w-full" />
            </div>
          </section>
        </div>
        <div tw="pt-8 lg:pt-4 text-center px-8">
          <div tw="lg:text-lg text-center font-bold text-gray-300">
            Trusted by thousands of leading companies.
          </div>
          <div tw="pt-6">
            <img
              src={businessCustomers}
              tw="hidden lg:block max-w-full mx-auto z-10"
              css={{ width: "1023px" }}
            />
            <img
              src={businessCustomersMobile}
              tw="lg:hidden max-w-full mx-auto"
              css={{ width: "333px" }}
            />
          </div>
        </div>
      </section>
    </div>
  )
}

// The original image dimensions are is 2x export 1300x1646
// this means that we need to convert it to 1x by diving the
// size getting to an orginal width of 650 and height of 823
// Always check the image dimensions before changing it or it
// will get stretched on the webpage.
const HeroBrands = styled.div`
  --background-height: calc((823 / 650) * 100vw);

  background-image: url(${HeroLogos});
  background-size: 100vw var(--background-height);
  background-repeat: no-repeat;
  width: 100vw;
  height: var(--background-height);

  @media (min-width: 1080px) {
    background-size: 650px 823px;
    width: 740px;
    position: absolute;
    left: 55%;
    top: 0;
    z-index: -1;
  }
`

export default Landing
