import React from "react"
import { Link } from "react-router-dom"
import tw from "twin.macro"

import BalanceMoreText from "./BalanceMoreText"
import MainMenuGroup from "./MainMenuGroup"
import { formatPrice } from "../../../common/format"
import { useGlobalState } from "../../../common/GlobalState"
import { generateRealmPath } from "../../../common/realm"

type Variant = "balance" | "credit"

interface Props {
  variant: Variant
}

export default function MobileBalanceOrCredit({ variant }: Props) {
  const [user] = useGlobalState("user")

  if (!user || (variant === "credit" && user.credit === 0)) {
    return null
  }

  return (
    <MainMenuGroup tw="mx-0 px-5 py-4 border-t border-gray-200 lg:hidden flex flex-row items-center">
      <div
        tw="text-[15px] text-gray-400 font-medium"
        css={[variant === "credit" && tw`text-green-500`]}
      >
        {variant === "credit" ? "Credit" : "Balance"}
      </div>
      <div
        tw="text-lg font-medium"
        css={[variant === "credit" && tw`text-green-500`]}
      >
        {formatPrice(variant === "credit" ? user.credit : user.balance!, true)}
      </div>
      <Link
        to={
          variant === "credit"
            ? generateRealmPath("plus", "/send")
            : generateRealmPath("plus", "/balance")
        }
        tw="ml-auto"
      >
        <BalanceMoreText text={variant === "credit" ? "Use" : "More"} />
      </Link>
    </MainMenuGroup>
  )
}
