import { useQuery } from "@apollo/client"
import { keyBy, pick } from "lodash-es"
import { useCallback, useMemo } from "react"

import { createHookContext } from "./createHookContext"
import { SHIPPING_COUNTRIES_QUERY } from "../queries/ShippingCountriesQuery"

import {
  ShippingCountriesQuery,
  ShippingCountriesQueryVariables,
  ShippingCountryGroupEnum,
} from "@/types/graphql-types"

export const DEFAULT_COUNTRY = {
  code: "US",
  name: "United States",
  groups: [ShippingCountryGroupEnum.domestic],
}
export const GLOBAL_COUNTRY = {
  code: "GLOBAL",
  name: "Global",
  groups: [ShippingCountryGroupEnum.global, ShippingCountryGroupEnum.gift_card],
}

const useShippingCountriesLocal = () => {
  const { data } = useQuery<
    ShippingCountriesQuery,
    ShippingCountriesQueryVariables
  >(SHIPPING_COUNTRIES_QUERY)

  const shippingCountries = useMemo(
    () =>
      data?.shippingCountries
        ? [...data.shippingCountries, GLOBAL_COUNTRY]
        : [],
    [data],
  )

  const indexedCountries = useMemo(
    () => keyBy(shippingCountries, "code"),
    [shippingCountries],
  )

  const findCountryByCode = useCallback(
    (code?: string) => indexedCountries[code || ""],
    [indexedCountries],
  )

  const expandShippingCountries = useCallback(
    (countryCodes: string[]) => {
      return Object.values(pick(indexedCountries, countryCodes))
    },
    [indexedCountries],
  )

  return {
    shippingCountries,
    expandShippingCountries,
    findCountryByCode,
  }
}

export const {
  Provider: ShippingCountriesProvider,
  useHook: useShippingCountries,
} = createHookContext("useShippingCountries", useShippingCountriesLocal)
