import * as Sentry from "@sentry/react"
import { useEffect, useRef } from "react"
import { Helmet } from "react-helmet-async"
import ReactPlaceholder from "react-placeholder/lib"

import { CustomProductsUpsell } from "./CustomProductsUpsell"
import { useRestrictedProducts } from "./hooks/useRestrictedProducts"
import { ProductListItem } from "./ProductListItem"
import { DelayedComponent } from "../components/DelayedComponent"
import { GalleryPlaceholder } from "../components/GalleryPlaceholder"
import { InfiniteScroll } from "../components/InfiniteScroll"
import useStoreContext from "../hooks/useStoreContext"
import { ProductData } from "../StoreHelpers"

export const CustomProducts = () => {
  const { pushNewUrl, generatePdpUrl } = useStoreContext()

  function openPDPModal(product: ProductData) {
    pushNewUrl(
      generatePdpUrl({
        productSlug: product.slug,
        brandSlug: product.brand.slug,
      }),
    )
  }

  const scrollElemRef = useRef<HTMLDivElement | null>(null)

  const { products, hasNextPage, loading, error, loadMore } =
    useRestrictedProducts({
      fetchPolicy: "cache-and-network",
      pageSize: 5 * 3, // 5 rows and 3 cols
    })

  useEffect(() => {
    let seen = new Set()
    const duplicates = products
      ?.map((product) => {
        if (seen.size === seen.add(product.id).size) {
          return product
        } else {
          return undefined
        }
      })
      .filter((product) => !!product)

    if (!!duplicates?.length) {
      Sentry.withScope((scope) => {
        scope.setExtra("duplicates", duplicates)
        Sentry.captureMessage(
          "[Restricted Products]: Duplicated products showing on the page",
        )
      })
    }
  }, [products])

  return (
    <div tw="p-4 lg:ml-16 lg:mt-10 lg:mb-6 col-span-2 overflow-y-auto">
      <Helmet>
        <title>My products | Goody</title>
      </Helmet>
      <div tw="mb-6">
        <h1 tw="font-medium text-2xl">My products</h1>
      </div>
      <InfiniteScroll
        loading={!error && loading}
        hasNextPage={hasNextPage || false}
        loadMore={loadMore}
      >
        <div tw="gap-x-8 gap-y-12 lg:(grid grid-cols-2) xl:(grid-cols-3 p-8 pb-12)">
          <DelayedComponent waitBeforeShow={100}>
            <ReactPlaceholder
              showLoadingAnimation={true}
              ready={!loading}
              customPlaceholder={<GalleryPlaceholder cols={3} rows={2} />}
            >
              {products?.map((product) => (
                <ProductListItem
                  key={product.id}
                  product={product}
                  scrollElemRef={scrollElemRef}
                  onClick={openPDPModal}
                />
              ))}
              {!products?.length && (
                <div tw="col-span-3">
                  <CustomProductsUpsell />
                </div>
              )}
            </ReactPlaceholder>
          </DelayedComponent>
        </div>
      </InfiniteScroll>
    </div>
  )
}
