import { gql } from "@apollo/client"

import { CUSTOM_STORE_PRODUCT_DATA_FRAGMENT } from "./CustomStoreProductDataFragment"
import { CUSTOM_STORE_BASE_FRAGMENT } from "./queries"

import { GIFT_OPTION_INTERFACE_DATA_FRAGMENT } from "@/store/GiftOption/graphql/GiftOptionInterfaceDataFragment"

export const CUSTOM_STORE_FULL_FRAGMENT = gql`
  fragment CustomStoreFull on CustomStore {
    ...CustomStoreBase
    priceEstimate {
      maximumSubtotalWithShipping
      processingFee
      estimatedTaxLow
      estimatedTaxHigh
      estimatedTotalLow
      estimatedTotalHigh
    }
    optionList {
      ...CustomStoreOptionListItem
    }
  }

  fragment CustomStoreOptionListItem on CustomStoreOption {
    id
    position
    isAvailable
    giftCardAmount
    priceMaxWithShipping
    giftOption {
      ...Store_GiftOptionInterfaceData
      priceMaxWithShipping(customStoreId: $customStoreId)
      products(
        customStoreId: $customStoreId
        shippingCountry: $shippingCountry
      ) {
        ...CustomStore_Product_DataFragment
      }
    }
    product {
      ...CustomStore_Product_DataFragment
    }
  }

  ${GIFT_OPTION_INTERFACE_DATA_FRAGMENT}
  ${CUSTOM_STORE_PRODUCT_DATA_FRAGMENT}
  ${CUSTOM_STORE_BASE_FRAGMENT}
`
