import React from "react"
import Modal from "react-modal"
import { Link, useHistory } from "react-router-dom"
import tw, { css, styled } from "twin.macro"

import { GiftBusiness, GiftPersonal } from "../../assets/icons"
import { topModalStyle } from "../modal"
import { generateRealmPath, useSetRealm } from "../realm"

interface Props {
  close: () => void
  isOpen: boolean
}

// Has to go up top to be used under
const modalStylesCopy = {
  overlay: topModalStyle.overlay,
  content: {
    ...topModalStyle.content,
    width: "100%",
  },
}

// Modal to choose realm after selecting a gift
const RealmChooseModal: React.FC<Props> = ({ close, isOpen }) => {
  const setRealm = useSetRealm()
  const history = useHistory()

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      shouldCloseOnOverlayClick={true}
      style={modalStylesCopy}
    >
      <ModalContainer>
        <div
          tw="bg-white m-0.5 flex-1 flex flex-col items-center justify-between p-9"
          css="border-radius: 0.625rem;"
        >
          <TitleText>Is this a personal or business gift?</TitleText>
          <div tw="flex flex-col md:flex-row mt-9 self-stretch gap-2">
            <RealmChoiceButton
              onClick={() => {
                setRealm("consumer")
                history.push(generateRealmPath("consumer", "/send"))
                close()
              }}
              icon={<GiftPersonal tw="w-8 h-8 md:w-12 md:h-12" />}
              title="Personal"
              text1="Make someone’s day"
              text2="No address needed"
            />
            <div tw="w-9" />
            <RealmChoiceButton
              onClick={() => {
                setRealm("plus")
                history.push(generateRealmPath("plus", "/send"))
                close()
              }}
              icon={<GiftBusiness tw="w-8 h-8 md:w-12 md:h-12" />}
              title="Business"
              text1="Send to one or more people"
              text2="Branding and other options"
            />
          </div>
        </div>
      </ModalContainer>
    </Modal>
  )
}

const TitleText = styled.div`
  ${tw`text-2xl font-medium text-center`}
  background: linear-gradient(90deg, #e4a1b8 25%, #b37dd5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const ModalContainer = styled.div`
  ${tw`mx-auto w-full rounded-xl flex items-stretch self-stretch pointer-events-none`}
  max-width: 800px;
  background: linear-gradient(90deg, #e4a1b8 0%, #b37dd5 100%);
`

interface RealmChoiceButtonProps {
  onClick: () => void
  icon: any
  title: string
  text1: string
  text2: string
}

const RealmChoiceButton: React.FC<RealmChoiceButtonProps> = ({
  onClick,
  icon,
  title,
  text1,
  text2,
}) => {
  const content = (
    <>
      {icon}
      <div tw="text-gray-500 text-xl font-medium mt-3 md:mt-5">{title}</div>
      <div tw="text-gray-400 mt-2 md:mt-3 text-center">{text1}</div>
      <div tw="text-gray-400 mt-1 text-center">{text2}</div>
    </>
  )

  return <ChoiceButton onClick={onClick}>{content}</ChoiceButton>
}

const choiceCss = css`
  ${tw`border py-4 flex-1 rounded-xl flex flex-col border-gray-150  items-center justify-center px-4 hover:border-gray-300 active:bg-gray-050 pointer-events-auto`};
  height: 233px;
`

const ChoiceButton = styled.button`
  ${choiceCss}
`

const ChoiceExternalLink = styled.a`
  ${choiceCss}
`

const ChoiceInternalLink = styled(Link)`
  ${choiceCss}
`

export default RealmChooseModal
