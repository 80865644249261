import React from "react"
import { Link } from "react-router-dom"
import tw from "twin.macro"

import BalanceMoreText from "./BalanceMoreText"
import { formatPrice } from "../../../common/format"
import { useGlobalState } from "../../../common/GlobalState"
import { generateRealmPath } from "../../../common/realm"

type Variant = "balance" | "credit"

interface Props {
  variant: Variant
}

export default function AccountDropdownBudget({ variant }: Props) {
  const [user] = useGlobalState("user")

  if (!user || (variant === "credit" && user.credit === 0)) {
    return null
  }

  return (
    <Link
      to={
        variant === "credit"
          ? generateRealmPath("plus", "/send")
          : generateRealmPath("plus", "/balance")
      }
      tw="p-5 py-4 pr-3 flex flex-row items-center justify-between hover:bg-gray-050 active:bg-gray-100 transition-colors border-b border-gray-150"
    >
      <div>
        <div
          tw="text-gray-400 font-medium text-sm uppercase"
          css={[variant === "credit" && tw`text-green-500`]}
        >
          {variant === "credit" ? "Credit" : "Balance"}
        </div>
        <div
          tw="text-gray-700 font-medium text-lg"
          css={[variant === "credit" && tw`text-green-500`]}
        >
          {formatPrice(
            variant === "credit" ? user.credit : user.balance!,
            true,
          )}
        </div>
      </div>
      <BalanceMoreText text={variant === "credit" ? "Use" : "More"} />
    </Link>
  )
}
