import React from "react"
import tw, { styled } from "twin.macro"

import { getCsrfToken } from "@/common/csrf"
import { getEnvVars } from "@/common/environment"
import { isBlank } from "@/common/format"
import { resetIframeAuthIfPresent } from "@/common/hooks/iframeAuth"
import TextInputField from "@/common/TextInputField"
import SSOButton from "@/signIn/SSOButton"

const { apiUrl } = getEnvVars()

interface Props {
  email: string | null
  onEmailChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  fromRequireSSO?: boolean
}
const SSOSignInForm = ({ email, onEmailChange, fromRequireSSO }: Props) => {
  const csrfToken = getCsrfToken()
  const postUrl = `${apiUrl}/users/auth/saml/identity_provider_id`

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    resetIframeAuthIfPresent()
    return true
  }

  return (
    <form
      tw="w-full flex flex-col items-center mb-8"
      action={postUrl}
      method="post"
      target="_self"
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="authenticity_token" value={csrfToken} />
      <input type="hidden" name="email" id="email" value={email ?? ""} />

      {fromRequireSSO ? (
        <SSOButton
          text="Sign in with SSO"
          displayLogo={true}
          onClick={() => {}}
          type="submit"
        />
      ) : (
        <>
          <InputContainer tw="w-full mb-6">
            <TextInputField
              type="text"
              placeholder="Email"
              value={email ?? ""}
              onChange={onEmailChange}
              className="ph-no-capture fs-exclude data-hj-suppress"
              autoFocus={true}
            />
          </InputContainer>
          <StyledButton type="submit" disabled={isBlank(email)}>
            Sign in with SSO
          </StyledButton>
        </>
      )}
    </form>
  )
}

const StyledButton = styled.button`
  ${tw`py-[9px] px-[17px] text-[#1F2937] border border-[#E5E7EB] rounded-lg hover:border-primary-200 focus-visible:border-primary-300 focus:outline-none active:bg-primary-000 transition-colors active:transition-border transition-opacity disabled:cursor-default disabled:active:bg-white disabled:hover:border-primary-100 gap-4 transition-colors`}
  box-shadow: 0px 2px 4px 0px rgba(209, 213, 209, 0.30);
`

const InputContainer = styled.div`
  ${tw`flex flex-row mt-4 first:mt-0 transition`}
  height: calc(1.5em + 2rem + 2px);
  overflow: hidden;
  transition-property: height, margin, opacity;
`

export default SSOSignInForm
