import "twin.macro"
import { useMemo, useRef } from "react"
import tw from "twin.macro"

import { formatPrice } from "@/common/format"
import staticAssetUrl from "@/common/staticAssetUrl"

type Variant = "noCart" | "cart"

interface Props {
  firstName: string
  credit: number
  // Whether they have something in their cart
  variant: Variant
}

function isAppleDevice() {
  const appleDevices = ["iPad", "iPhone", "iPod", "MacIntel"]
  return appleDevices.includes(navigator.platform)
}

// Display a welcome message for the user, based on their name, credit amount,
// and whether they have something in their cart.
export default function OnboardingWelcome({
  firstName,
  credit,
  variant,
}: Props) {
  const isApple = useRef(isAppleDevice()).current

  const subtext = useMemo(() => {
    return constructSubtext(credit, variant)
  }, [credit, variant])

  return (
    <div>
      <div tw="text-center px-5">
        <span
          tw="font-reckless-neue tracking-[-0.72px] text-3xl md:text-[42px] align-middle mr-3 leading-p-130"
          css={[variant === "cart" && tw`text-2xl md:text-4xl`]}
        >
          {firstName.trim()}, welcome to Goody!
        </span>
        {isApple ? (
          <span tw="align-middle text-[32px] hidden md:inline-block">👋</span>
        ) : (
          <img
            src={staticAssetUrl("static/web/onboarding/wave-icon.png")}
            alt=""
            tw="hidden md:inline-block align-middle"
            width={36}
            height={36}
          />
        )}
      </div>
      <div tw="pt-4 px-5">
        <div tw="max-w-[520px] text-gray-500 text-lg md:text-xl text-center mx-auto leading-p-150 md:whitespace-pre-wrap">
          {subtext}
        </div>
      </div>
    </div>
  )
}

function constructSubtext(credit: number, variant: Variant) {
  let text = ""

  if (variant === "noCart") {
    if (credit > 0) {
      text =
        "We’ve dropped a CREDIT_AMOUNT credit in your account, so you can send your first gift, on us. "
    }

    text += "Get started:"
  } else if (variant === "cart") {
    if (credit > 0) {
      text = "We’ve dropped a CREDIT_AMOUNT credit in your account.\n"
    }

    text += "Let’s finish sending your gift."
  }

  // Convert the text into an array, and convert any occurrences of
  // CREDIT_AMOUNT into the JSX version
  //
  // If the array contains CREDIT_AMOUNT, then split the array into three parts
  // with the first part being the text before CREDIT_AMOUNT, the second part
  // being a JSX version of CREDIT_AMOUNT, and the third part being the text
  // after CREDIT_AMOUNT
  //
  // If the array does not contain $20 credit, then return the array as is
  const textSplit = text.split("CREDIT_AMOUNT")

  if (textSplit.length === 2) {
    return [
      textSplit[0],
      <span tw="font-semibold text-gray-700">
        {formatPrice(credit)} credit
      </span>,
      textSplit[1],
    ]
  } else {
    return [text]
  }
}
