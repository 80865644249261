import React from "react"
import tw, { css, styled } from "twin.macro"

import { ReactComponent as AutogiftIcon } from "../../../assets/icons/autogift-alt.svg"
import { ReactComponent as PaymentCard } from "../../../assets/icons/payment-card.svg"
import { ReactComponent as PaymentLater } from "../../../assets/icons/payment-later.svg"
import { ConsumerNewUserPaymentSelection } from "../../../common/GlobalState"
import { SelectionIndicator } from "../../../common/SelectionGroup"
import { getColor } from "../../../common/tailwind"

interface Props {
  option: ConsumerNewUserPaymentSelection
  currentSelection: ConsumerNewUserPaymentSelection
  setSelection: (val: ConsumerNewUserPaymentSelection) => void
}

export default function PaymentOption({
  option,
  currentSelection,
  setSelection,
}: Props) {
  const copy = optionCopy[option]
  const name = copy.name
  const description = copy.description
  const icon = copy.icon
  const selected = option === currentSelection

  return (
    <PaymentOptionButton active={selected} onClick={() => setSelection(option)}>
      <div tw="flex flex-row items-center w-full">
        <SelectionIndicator
          selected={selected}
          color="primaryNew"
          grayUnselected={true}
        />
        <div
          tw="flex-1 text-xl text-gray-900 pr-1"
          css={[selected && tw`text-primary-new-550 font-medium`]}
        >
          {name}
        </div>
        <div tw="text-gray-350" css={[selected && tw`text-primary-new-400`]}>
          {icon}
        </div>
      </div>
      <div tw="text-gray-450 sm:pl-7 pt-2">{description}</div>
    </PaymentOptionButton>
  )
}

interface OptionCopy {
  name: string
  description: React.ReactNode
  icon: React.ReactNode
}

const optionCopy: { [key in ConsumerNewUserPaymentSelection]: OptionCopy } = {
  addCreditCardNow: {
    name: "Add credit card now",
    description: (
      <>
        <div tw="flex flex-row items-center justify-start gap-2">
          Gift is processed quicker <AutogiftIcon />
        </div>
        <div>You only pay when gift is accepted</div>
      </>
    ),
    icon: <PaymentCard tw="stroke-current" />,
  },
  addCreditCardLater: {
    name: "Add credit card later",
    description: (
      <>
        <div>
          We’ll notify you to enter your card details after your gift is
          accepted
        </div>
      </>
    ),
    icon: <PaymentLater tw="stroke-current" />,
  },
}

const PaymentOptionButton = styled.button<{ active: boolean }>`
  ${tw`flex flex-col items-start text-left p-6 flex-1 transition-all rounded-xl active:bg-gray-050`}

  box-shadow: inset 0 0 0 1px ${getColor("gray-150")};

  &:hover {
    box-shadow: inset 0 0 0 1px ${getColor("gray-250")};
  }

  &:active {
    ${tw`bg-gray-050`}
  }

  ${(props) =>
    props.active
      ? css`
          ${tw`active:bg-transparent`}

          box-shadow: 0 0 0 2px ${getColor("primary-new-300")},
            0 1px 4px rgba(0, 0, 0, 0.04), 0 6px 20px rgba(0, 0, 0, 0.06);
          background-color: #fff;

          &:hover {
            box-shadow:
              0 0 0 2px ${getColor("primary-new-300")},
              0 1px 4px rgba(0, 0, 0, 0.04),
              0 6px 20px rgba(0, 0, 0, 0.06);
          }
        `
      : null}
`
