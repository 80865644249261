import React, { useEffect, useRef } from "react"
import useCollapse from "react-collapsed"

import { ReactComponent as PaymentLock } from "../../../assets/icons/payment-lock.svg"
import AddPaymentMethodForm from "../../../common/billing/AddPaymentMethodForm"
import { AddPaymentMethodFormFields } from "../../../common/billing/useAddPaymentMethod"
import { useGlobalState } from "../../../common/GlobalState"
import { useGiftData } from "../../../common/hooks/giftData"
import { imageForCardBrand } from "../../../common/payment"

import { BatchSendMethod } from "@/types/graphql-types"

interface Props {
  formFields: AddPaymentMethodFormFields
  setFormField: (name: string, value: string) => void
  setFormFields: (fields: AddPaymentMethodFormFields) => void
  setPageReady: () => void
  reset: () => void
}

export default function PaymentForm({
  formFields,
  setFormField,
  setFormFields,
  setPageReady,
  reset,
}: Props) {
  const { currentGift } = useGiftData()
  const [consumerSignUpFields] = useGlobalState("consumerSignUpFields")
  const didAutofillName = useRef(false)

  // Construct and destroy VGS hooks when this component is mounted and
  // unmounted. See useAddPaymentMethod.ts.
  useEffect(() => {
    setPageReady()

    return () => {
      reset()
    }
  }, [])

  // Set the default cardholder name from the consumer signup fields
  useEffect(() => {
    if (didAutofillName.current) {
      return
    }

    if (formFields.name === "" && consumerSignUpFields.firstName !== "") {
      setFormField(
        "name",
        [consumerSignUpFields.firstName, consumerSignUpFields.lastName]
          .filter(Boolean)
          .join(" "),
      )
    }

    didAutofillName.current = true
  }, [formFields, consumerSignUpFields])

  const isExpanded =
    currentGift.consumerNewUserPaymentSelection === "addCreditCardNow" ||
    currentGift.sendMethod === BatchSendMethod.direct_send
  const { getCollapseProps } = useCollapse({ isExpanded })

  return (
    <div {...getCollapseProps()}>
      <div tw="mx-auto mt-14 max-w-[500px]" css="max-width: 500px;">
        <div tw="flex flex-row items-center justify-between px-5 py-3">
          <div tw="text-green-500 flex flex-row items-center gap-2">
            <PaymentLock tw="stroke-current" />
            <div tw="text-sm font-medium">Secure form</div>
          </div>
          <div tw="flex flex-row items-center gap-2 opacity-75">
            {imageForCardBrand("visa")}
            {imageForCardBrand("mastercard")}
            {imageForCardBrand("amex")}
            {imageForCardBrand("discover")}
          </div>
        </div>
        <AddPaymentMethodForm
          setFormField={setFormField}
          setFormFields={setFormFields}
          formFields={formFields}
        />
      </div>
    </div>
  )
}
