// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type SalesSvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function SalesSvgIcon(props: SalesSvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 32 32"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M12.959 10.893C8.762 11.96 2.664 9.854 2.664 9.854m23.008 10.342a7.884 7.884 0 003.661-1.223M17.05 27.487c-.202.188-.511.485-.954.86C11.997 32 6.664 21.934 2.664 20.3"
        }
        stroke={"currentColor"}
        strokeWidth={"2"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      ></path>

      <path
        d={
          "M12.434 23.116l3.693 3.825a1.987 1.987 0 003.016-.11l.845-.997m-4.675-5.436l3.693 3.826a1.987 1.987 0 003.016-.11l.845-.997m-4.864-5.639l3.693 3.825a1.993 1.993 0 003.005-.097l.856-1.01a39.3 39.3 0 00-5.334-6.952 2.266 2.266 0 00-3.055.043c-1.902 1.744-5.486 4.388-7.215 1.472a84.153 84.153 0 015.826-6.954c.161-.165.352-.298.563-.392 1.036-.458 4.368-1.435 9.328 1.91"
        }
        stroke={"currentColor"}
        strokeWidth={"2"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      ></path>

      <circle cx={"2.664"} cy={"9.853"} r={"1"} fill={"currentColor"}></circle>

      <path
        d={"M25.672 9.323l3.661-.952"}
        stroke={"currentColor"}
        strokeWidth={"2"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      ></path>
    </svg>
  );
}

export default SalesSvgIcon;
/* prettier-ignore-end */
