import React, { useEffect, useMemo } from "react"
import { useLocation } from "react-router-dom"
import tw, { styled } from "twin.macro"

import Container from "./Container"
import TopMenu, { AccountDropdownMenuPanel } from "./header/TopMenu"
import { Workspace, WorkspacesMenuItems } from "./header/WorkspacesMenuItems"
import RealmToggle, {
  REALM_TOGGLE_TOTAL_WIDTH_SIGNED_IN,
} from "../shared/RealmToggle"

import DropdownMenu from "@/common/DropdownMenu"
import { useGlobalState } from "@/common/GlobalState"
import { UpdateWorkspace } from "@/common/hooks"
import { ReactComponent as ChevronIcon } from "@/common/images/chevron-down.svg"
import { ReactComponent as WorkspaceIcon } from "@/common/images/workspace.svg"

const StyledTopBar = styled.div`
  ${tw`h-8 lg:h-12 hidden lg:block bg-gray-050 relative z-50`}
  ${Container} {
    ${tw`flex items-center justify-between px-5 pl-2 md:pl-5`}
  }

  &.withWorkspaces {
    ${tw`flex`}
  }

  .workspace {
    ${tw`flex items-center gap-2 text-sm lg:text-lg text-gray-500 whitespace-nowrap`}
    > .workspaceIcon {
      ${tw`lg:text-gray-300 stroke-current stroke-1.5 h-3 lg:h-4 w-auto`}
    }

    > .chevronIcon {
      ${tw`w-3 hidden lg:inline-block`}
    }
  }

  .chevronIcon {
    ${tw`stroke-current stroke-2`}
  }

  .switchWorkspace {
    ${tw`flex lg:hidden gap-2 items-baseline text-xs text-gray-400 focus:outline-none`}
    > .chevronIcon {
      ${tw`transform scale-75`}
    }
  }

  > .topMenu {
    ${tw`hidden lg:flex`}
  }
`

interface TopBarProps {
  currentWorkspace: Workspace | null
  workspaces: Workspace[]
  updateCurrentWorkspace: UpdateWorkspace
}

export const TopBar: React.FC<TopBarProps> = ({
  currentWorkspace,
  workspaces,
  updateCurrentWorkspace,
}) => {
  const [signedIn] = useGlobalState("signedIn")

  const workspaceTrigger = (
    <div className="workspace">
      <WorkspaceIcon className="workspaceIcon" />
      {currentWorkspace?.name}
      <ChevronIcon className="chevronIcon" tw="hidden lg:inline-block" />
    </div>
  )

  const workspacesMenu = (
    <WorkspacesDropdownMenu
      trigger={
        <>
          <div tw="hidden lg:block">{workspaceTrigger}</div>
          <div className="switchWorkspace" tw="lg:hidden">
            {workspaceTrigger} <ChevronIcon className="chevronIcon" />
          </div>
        </>
      }
    >
      <WorkspacesDropdownMenuPanel>
        <WorkspacesMenuItems
          currentWorkspace={currentWorkspace}
          workspaces={workspaces}
          updateCurrentWorkspace={updateCurrentWorkspace}
        />
      </WorkspacesDropdownMenuPanel>
    </WorkspacesDropdownMenu>
  )

  useEffect(() => {
    document.body.classList.add("top-bar-visible")

    return () => {
      document.body.classList.remove("top-bar-visible")
    }
  }, [])

  const location = useLocation()
  const hideTopBarBackground = useMemo(() => {
    if (
      location.pathname.startsWith("/plus/send") ||
      location.pathname.startsWith("/browse")
    ) {
      return true
    }

    return false
  }, [location.pathname])

  return (
    <StyledTopBar
      className="withWorkspaces"
      css={[hideTopBarBackground ? tw`lg:bg-transparent` : null]}
    >
      <Container tw="h-full">
        <div tw="lg:hidden">
          <RealmToggle />
        </div>
        <div
          css={`
            width: ${REALM_TOGGLE_TOTAL_WIDTH_SIGNED_IN}px;
          `}
        />
        {workspacesMenu}
        <TopMenu />
      </Container>
    </StyledTopBar>
  )
}

const WorkspacesDropdownMenuPanel = styled(AccountDropdownMenuPanel)`
  ${tw`right-0 left-0`};

  @media (min-width: 1024px) {
    min-width: 15rem;
  }
`
const WorkspacesDropdownMenu = styled(DropdownMenu)`
  ${tw`static lg:relative flex flex-col items-start lg:flex-1 lg:pl-6`};

  @media (min-width: 1024px) {
    min-width: 15rem;
  }
`
