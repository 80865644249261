import React from "react"
import tw, { css } from "twin.macro"

import Button from "../../../common/Button"
import { Loader } from "../../../common/UI"

interface Props {
  isLoading: boolean
  onClickNext: () => void
  onClickBack: () => void
  nextText: string
}

// Forward and back buttons that are shared by Contact Import components
const ContactBottomButtons: React.FC<Props> = ({
  isLoading,
  onClickNext,
  onClickBack,
  nextText,
}) => {
  return (
    <div tw="flex flex-row mt-10 items-center">
      <Button variant="primary" onClick={onClickNext} disabled={isLoading}>
        {nextText}
      </Button>
      <div tw="grid items-center">
        <Loader
          tw="ml-3 transition-opacity"
          css={[stackCss, isLoading ? tw`` : tw`opacity-0`]}
        />
        <button
          onClick={onClickBack}
          tw="px-4 ml-1 text-gray-400 py-2 hover:text-gray-300 transition-colors transition-opacity col-span-1 row-span-1"
          css={[stackCss, isLoading ? tw`pointer-events-none opacity-0` : tw``]}
        >
          Go back
        </button>
      </div>
    </div>
  )
}

// Stacks elements on top of each other in a grid layout
const stackCss = css`
  grid-row: 1;
  grid-column: 1;
`

export default ContactBottomButtons
