import React from "react"

import { StyledButtonLink } from "../../common/Button"
import { useGlobalState } from "../../common/GlobalState"
import { generateRealmPath } from "../../common/realm"
import AccountSection from "../components/AccountSection"

const DataPrivacyRequestButtons = () => {
  const [currentRealm] = useGlobalState("currentRealm")

  return (
    <AccountSection headline="Privacy">
      <StyledButtonLink
        to={generateRealmPath(currentRealm, "/account/request-data-access")}
        tw="mr-4"
      >
        Request my data
      </StyledButtonLink>
      <StyledButtonLink
        to={generateRealmPath(currentRealm, "/account/request-data-deletion")}
      >
        Delete my account
      </StyledButtonLink>
    </AccountSection>
  )
}

export default DataPrivacyRequestButtons
