import React, { useCallback, useEffect, useRef, useState } from "react"
import { TwStyle } from "twin.macro"

import ProductDetailPage from "./ProductDetailPage"

import SwipeableBottomSheet from "@/common/SwipeableBottomSheet"
import ModalCloseHint, {
  hideModalCloseHint,
} from "@/store/components/ModalCloseHint"
import { ModalContainer, Underlay } from "@/store/components/ModalContainer"
import { ProductData } from "@/store/StoreHelpers"
import { onSelectProductIDType } from "@/store/types"

const MODAL_CLOSE_DURATION = 200

interface PDPContainerProps {
  productData: ProductData
  // Override the default (setCurrentGift) for when a product is selected.
  onSelectProductID?: onSelectProductIDType
  // Method to call after selecting a product.
  onAfterSelectProduct?: () => void
  unsetPDPProduct: () => void
  setPDPProduct: (productData: ProductData) => void
  customStoreProductButton?: (productID: string) => React.ReactElement
  customStoreGiftCardButton?: (
    productID: string,
    giftCardAmount: number | null,
  ) => React.ReactElement
  isGiftCardInCustomStore?: (
    productID: string,
    giftCardAmount: number | null,
  ) => boolean
  twStyle?: TwStyle
  useVirtualUrl?: boolean
  storeVisible: boolean
  isEmbeddedCustomStore?: boolean
}

// Renders a common container for "modal" style PDPs.
const PDPModal = ({
  productData,
  onSelectProductID,
  onAfterSelectProduct,
  unsetPDPProduct,
  setPDPProduct,
  customStoreProductButton,
  customStoreGiftCardButton,
  isGiftCardInCustomStore,
  twStyle,
  useVirtualUrl,
  storeVisible,
  isEmbeddedCustomStore,
}: PDPContainerProps) => {
  const [showSheet, setShowSheet] = useState(true)
  const contentRef = useRef<HTMLDivElement>(null)
  const scrollRef = useRef<HTMLDivElement>(null)
  const underlayRef = useRef<HTMLDivElement>(null)

  const scrollToTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0
    }
  }

  const handleClose = useCallback(() => {
    contentRef.current?.classList?.add("scale-slide-out")
    underlayRef.current?.classList?.add("closing")
    setTimeout(() => {
      unsetPDPProduct()
    }, MODAL_CLOSE_DURATION)
  }, [unsetPDPProduct])

  // Handle user pressing the Escape key. Close sheet if open.
  const handleEscapeKey = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        // If PDP contains an element with a pswp--open class, then the
        // Photoswipe modal is open meaning that Photoswipe should handle
        // closing instead of us.
        if (underlayRef.current?.querySelector(".pswp--open")) {
          return false
        }

        e.stopPropagation()
        handleClose()
        return false
      }
    },
    [handleClose],
  )

  useEffect(() => {
    // Set useCapture to be true to prevent the Material Drawer's escape key
    // handler from running (which is used in Autogift configuration) if the PDP
    // sheet is currently open.
    document.addEventListener("keydown", handleEscapeKey, true)

    return () => {
      document.removeEventListener("keydown", handleEscapeKey, true)
    }
  }, [handleEscapeKey])

  const pdp = (
    <ProductDetailPage
      key={productData.slug + "----" + productData.brand.slug}
      productData={productData}
      scrollToTop={scrollToTop}
      onSelectProductID={onSelectProductID}
      onAfterSelectProduct={onAfterSelectProduct}
      setPDPProduct={setPDPProduct}
      customStoreProductButton={customStoreProductButton}
      customStoreGiftCardButton={customStoreGiftCardButton}
      isGiftCardInCustomStore={isGiftCardInCustomStore}
      useVirtualUrl={useVirtualUrl}
      storeVisible={storeVisible}
      onClose={handleClose}
      isEmbeddedCustomStore={isEmbeddedCustomStore}
    />
  )

  return (
    <>
      <Underlay
        tw="fixed inset-0 z-[1400]"
        onClick={(e) => {
          // Don't trigger for inside elements, only underlay.
          if (e.target === e.currentTarget) {
            handleClose()
            hideModalCloseHint()
          }
        }}
        ref={underlayRef}
      >
        <ModalContainer css={[twStyle]} ref={contentRef}>
          <ModalCloseHint />
          <div tw="overflow-y-scroll h-full" ref={scrollRef}>
            {pdp}
          </div>
        </ModalContainer>
        <SwipeableBottomSheet
          swipeableViewsProps={{
            containerStyle: {
              borderTopLeftRadius: 16,
              borderTopRightRadius: 16,
              margin: "0 auto",
              maxWidth: 500,
              overflow: "hidden",
              transition: "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s",
            },
          }}
          open={showSheet}
          fullScreen={true}
          marginTop={150}
          onChange={(isOpen: boolean) => {
            if (!isOpen) {
              setShowSheet(isOpen)
              setTimeout(() => {
                unsetPDPProduct()
              }, 200)
            }
          }}
          tw="lg:hidden"
        >
          <div tw="h-full">{pdp}</div>
        </SwipeableBottomSheet>
      </Underlay>
    </>
  )
}

export default PDPModal
