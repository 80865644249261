import { gql, useQuery } from "@apollo/client"
import { ReactElement } from "react"

import { STANDARD_CARD_FRAGMENT } from "../../../common/occasions"
import { DETAILS_PRODUCT_FRAGMENT } from "../../../common/queries"
import { Loader } from "../../../common/UI"
import { ReactComponent as HandShake } from "../../images/giftTemplates/handshake.svg"
import { ReactComponent as UserSquare } from "../../images/giftTemplates/user-square.svg"

import {
  Details_ProductFragment,
  Send_GiftTemplatesMenuQuery,
  StandardCardFragment,
} from "@/types/graphql-types"

export interface GiftTemplateType {
  id: string
  category: string
  subcategory: string
  card: StandardCardFragment
  message: string
  products: Details_ProductFragment[]
  giftCardAmount?: number | null
  flexGiftPrice?: number | null
}

interface Props {
  onSelect: (giftTemplate: GiftTemplateType) => void
}

export default function GiftTemplatesMenu({ onSelect }: Props) {
  const { data, loading } =
    useQuery<Send_GiftTemplatesMenuQuery>(GIFT_TEMPLATES_QUERY)
  const giftTemplates = data?.me?.giftTemplates || []
  const employeeGiftTemplates = giftTemplates.filter(
    (giftTemplate) => giftTemplate.category === "employee",
  )
  const customerGiftTemplates = giftTemplates.filter(
    (giftTemplate) => giftTemplate.category === "customer",
  )

  const loadGiftTemplate = (giftTemplate: GiftTemplateType) => {
    onSelect(giftTemplate)
  }

  return (
    <div tw="flex flex-col md:flex-row p-7 rounded-lg bg-[#F6FAFF] border border-[#E9F3FF] gap-8">
      <div tw="flex flex-col">
        <GiftTemplatesMenuSection
          category="Employee gifting"
          icon={<UserSquare />}
          onSelect={loadGiftTemplate}
          giftTemplates={employeeGiftTemplates}
          loading={loading}
        />
      </div>
      <div tw="flex flex-col">
        <GiftTemplatesMenuSection
          category="Customer gifting"
          icon={<HandShake />}
          onSelect={loadGiftTemplate}
          giftTemplates={customerGiftTemplates}
          loading={loading}
        />
      </div>
    </div>
  )
}

interface GiftTemplatesMenuSectionProps {
  category: string
  icon: ReactElement
  onSelect: (giftTemplate: GiftTemplateType) => void
  giftTemplates: GiftTemplateType[]
  loading: boolean
}

const GiftTemplatesMenuSection = ({
  category,
  icon,
  onSelect,
  giftTemplates,
  loading,
}: GiftTemplatesMenuSectionProps) => {
  return (
    <div>
      <div tw="flex flex-row mb-2 items-center">
        <div tw="mr-3">{icon}</div>
        <div tw="font-medium">{category}</div>
      </div>
      {loading ? (
        <div tw="h-full w-full flex justify-center items-center p-8">
          <Loader tw="mb-4" />
        </div>
      ) : (
        <div tw="cursor-pointer">
          {giftTemplates.map((giftTemplate) => (
            <div
              key={giftTemplate.id}
              onClick={() => onSelect(giftTemplate)}
              tw="mb-1 text-gray-600 opacity-75 hover:opacity-100"
            >
              {giftTemplate.subcategory}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

const GIFT_TEMPLATES_QUERY = gql`
  query Send_GiftTemplatesMenu {
    me {
      giftTemplates {
        id
        category
        subcategory
        message
        card {
          ...StandardCard
        }
        products {
          ...Details_Product
        }
        giftCardAmount
        flexGiftPrice
      }
    }
  }
  ${STANDARD_CARD_FRAGMENT}
  ${DETAILS_PRODUCT_FRAGMENT}
`
