import { FetchResult, gql, useMutation, useQuery } from "@apollo/client"
import * as Sentry from "@sentry/react"
import { isNil } from "lodash-es"
import { SetStateAction, useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { useIntercom } from "react-use-intercom"

import { useCurrentGift } from "./currentGift"
import { useGiftData } from "./giftData"
import { resetIframeAuthIfPresent } from "./iframeAuth"
import { usePaymentMethods } from "./paymentMethods"
import { useDismissedScreensContext } from "./useDismissedScreensContext"
import { ROOT_DATA_QUERY } from "../../graphql"
import { reset, track } from "../analytics"
import { isBlank, validateAndFormatPhoneE164 } from "../format"
import { useGlobalState } from "../GlobalState"
import { getUtmParms } from "../gtm"

import { hashPhoneNumber } from "@/common/utils/hashPhoneNumber"
import { clearSwagProducts } from "@/SwagIntegrations/utils"
import { PlusEnrollmentStatus } from "@/types/graphql-types"
import {
  App_RootDataQuery,
  AuthSignUpPayload,
  Auth_LoginMutation,
  Auth_LoginMutationVariables,
  Auth_LogoutMutation,
  Auth_SignUpMutation,
  Auth_SignUpMutationVariables,
  Auth_SignUp_PlusMutation,
  Auth_SignUp_PlusMutationVariables,
  Auth_Unlink_OpenidMutation,
  Auth_Unlink_OpenidMutationVariables,
  Auth_VerifyMutation,
  Auth_VerifyMutationVariables,
  BaseMeFragment,
  BaseOrganizationFragment,
} from "@/types/graphql-types"

declare global {
  interface Window {
    dataLayer: Record<string, any>[]
  }
}

const GENERIC_ERROR_MESSAGE =
  "Error. Sorry, an error occurred. Please report this to support@ongoody.com."

// TEMPORARY
const logVerifyAttempt = (
  event: string,
  phone: string,
  properties?: Record<string, any>,
) => {
  const timestamp = new Date().getTime()
  // We don't want to push the actual phone number to analytics because it's sensitive
  // so we hash to a 3 digit space
  hashPhoneNumber(phone).then((hashedPhoneNumber) => {
    track(
      "Auth - Verify Event",
      Object.assign(
        {
          timestamp,
          hashedPhoneNumber,
          event,
        },
        properties,
      ),
    )
  })
}

// Hook to set the global user state when data from the
// BaseMeFragment is pulled in
export const useGlobalUserState = () => {
  const [, setEnrollmentStatus] = useGlobalState("enrollmentStatus")
  const [, setSignedIn] = useGlobalState("signedIn")
  const [, setUser] = useGlobalState("user")
  const [, setOrganization] = useGlobalState("organization")
  const [currentGift] = useCurrentGift()
  const { setDismissedScreens } = useDismissedScreensContext()
  const { setAudience } = useGiftData()

  const setGlobalUserState = (me?: BaseMeFragment | null) => {
    if (me) {
      setSignedIn(true)
      setUser(me)
      setEnrollmentStatus(me.plusEnrollmentStatus)
      setDismissedScreens(me.dismissedScreens)
      Sentry.setUser({ id: me.id })
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "userAuthenticated",
        userId: me.id,
      })

      if (!currentGift.audience && me?.lastSendAudience) {
        setAudience(me?.lastSendAudience)
      }
    } else {
      setSignedIn(false)
      setUser(null)
      setEnrollmentStatus(PlusEnrollmentStatus.NONE)
    }
  }

  const setGlobalOrganizationState = (
    organization?: BaseOrganizationFragment | null,
  ) => {
    if (organization) {
      setOrganization(organization)
    }
  }

  return { setGlobalUserState, setGlobalOrganizationState }
}

// Hook to refresh user data when need be
const useUserData = () => {
  const {
    data: userData,
    loading: userDataLoading,
    refetch: userDataRefresh,
  } = useQuery<App_RootDataQuery>(ROOT_DATA_QUERY, {
    skip: true,
  })

  return { userData, userDataLoading, userDataRefresh }
}

export const useAuth = () => {
  const { refreshPaymentMethods } = usePaymentMethods()
  const { setGlobalUserState } = useGlobalUserState()
  // TODO loading state
  const [login, { loading: loginLoading }] = useMutation<
    Auth_LoginMutation,
    Auth_LoginMutationVariables
  >(AUTH_LOGIN)
  const [unlinkOpenid] = useMutation<
    Auth_Unlink_OpenidMutation,
    Auth_Unlink_OpenidMutationVariables
  >(AUTH_UNLINK_OPENID)
  const [signUpPlus, { loading: signUpPlusLoading }] = useMutation<
    Auth_SignUp_PlusMutation,
    Auth_SignUp_PlusMutationVariables
  >(AUTH_SIGN_UP_PLUS)
  const [signUp, { loading: signUpConsumerLoading }] = useMutation<
    Auth_SignUp_PlusMutation,
    Auth_SignUpMutationVariables
  >(AUTH_SIGN_UP_CONSUMER)
  const [verify, { loading: verifyLoading }] = useMutation<
    Auth_VerifyMutation,
    Auth_VerifyMutationVariables
  >(AUTH_VERIFY)
  const [logout] = useMutation<Auth_LogoutMutation>(AUTH_LOGOUT)
  const { userData, userDataRefresh } = useUserData()
  const { hardShutdown } = useIntercom()

  const signUpLoading = signUpPlusLoading || signUpConsumerLoading

  useEffect(() => {
    if (userData) {
      setGlobalUserState(userData.me)
    }
  }, [userData?.me])

  const handleLogin = async ({
    email,
    password,
    phone,
    onSuccess,
    onError,
    onRequireSSOError,
  }: {
    email?: string
    password?: string
    phone?: string
    onSuccess?: (me: App_RootDataQuery["me"] | null) => void
    onError?: (message?: string) => void
    onRequireSSOError?: (
      error: SetStateAction<string | null>,
      setting: SetStateAction<string | null>,
      email?: string | null,
    ) => void
  }) => {
    resetIframeAuthIfPresent()
    let variables = {}
    if (!!email) {
      variables = {
        email: email,
        password: password,
      }
    } else if (!!phone) {
      let parsedPhone = null

      try {
        parsedPhone = validateAndFormatPhoneE164(phone).toString()
      } catch (e) {
        alert("Phone number not valid. Please try again.")
        return
      }

      if (parsedPhone === null) {
        // Should not be possible
        alert("Phone number not valid. Please try again.")
        return
      }

      variables = { phone: parsedPhone.toString() }
    } else {
      onError?.call(null, "An email or phone is required to sign in.")
      return
    }

    const res = await login({
      variables: variables,
      refetchQueries: [{ query: ROOT_DATA_QUERY }],
    })

    if (res?.data?.authLogin) {
      if (res.data.authLogin.ok) {
        const userData = await userDataRefresh()
        onSuccess?.call(null, userData?.data?.me)
        refreshPaymentMethods()
      } else {
        if (res.data.authLogin.error) {
          onError?.call(null, res.data.authLogin.error)
        } else if (
          res.data.authLogin.requireSsoError &&
          res.data.authLogin.requireSsoSetting
        ) {
          onRequireSSOError?.call(
            null,
            res.data.authLogin.requireSsoError,
            res.data.authLogin.requireSsoSetting,
            res.data.authLogin?.requireSsoEmail,
          )
        } else {
          alert(GENERIC_ERROR_MESSAGE)
        }
      }
    }
  }

  const handleUnlinkOpenid = async ({
    provider,
    onSuccess,
  }: {
    provider: string
    onSuccess?: () => void
  }) => {
    if (isBlank(provider)) {
      return alert("Error: No provider specified")
    }

    const res = await unlinkOpenid({
      variables: { provider },
    })

    const { ok, error } = res?.data?.authUnlinkOpenid || {}

    if (ok) {
      onSuccess?.call(null)
    } else if (error) {
      alert(`Error: ${error}`)
    } else {
      alert(GENERIC_ERROR_MESSAGE)
    }
  }

  const signUpComplete = async ({
    res,
    onSuccess,
    onFail,
    skipRefetch,
  }: {
    res:
      | FetchResult<Auth_SignUp_PlusMutation>
      | FetchResult<Auth_SignUpMutation>
    onSuccess?: (me: App_RootDataQuery["me"] | null) => void
    onFail?: (response?: AuthSignUpPayload) => void
    skipRefetch?: boolean
  }) => {
    if (res?.data?.authSignUp) {
      if (res.data.authSignUp.ok) {
        if (!skipRefetch) {
          const userData = await userDataRefresh()
          onSuccess?.call(null, userData?.data?.me)
          refreshPaymentMethods()
        } else {
          onSuccess?.call(null, null)
        }
      } else {
        onFail?.call(this, res.data.authSignUp)
        if (res.data.authSignUp.error === null) {
          alert(GENERIC_ERROR_MESSAGE)
        }
      }
    } else {
      onFail?.call(this)
    }
  }

  const handleSignUpPlus = async ({
    email,
    password,
    firstName,
    lastName,
    onSuccess,
    onFail,
    skipRefetch,
  }: {
    email: string
    password: string
    firstName: string
    lastName: string
    onSuccess?: (me: App_RootDataQuery["me"] | null) => void
    onFail?: (response?: Auth_SignUpMutation["authSignUp"]) => void
    skipRefetch?: boolean
  }) => {
    resetIframeAuthIfPresent()
    const res = await signUpPlus({
      variables: {
        email: email,
        password: password,
        firstName: firstName,
        lastName: lastName,
        referringGiftId:
          window.localStorage?.getItem("referring_gift_id") ?? null,
        referringCode: window.localStorage.getItem("referring_code") ?? null,
      },
    })

    signUpComplete({ res, onSuccess, onFail, skipRefetch })
  }

  const handleSignUp = async ({
    email,
    phone,
    firstName,
    lastName,
    onSuccess,
    onFail,
    skipRefetch,
  }: {
    email: string
    phone: string
    firstName: string
    lastName: string
    onSuccess?: (me: App_RootDataQuery["me"] | null) => void
    onFail?: (response?: Auth_SignUpMutation["authSignUp"]) => void
    skipRefetch?: boolean
  }) => {
    resetIframeAuthIfPresent()
    let parsedPhone = null

    try {
      parsedPhone = validateAndFormatPhoneE164(phone).toString()
    } catch (e) {
      alert("Phone number not valid. Please try again.")
      onFail?.call(null)
      return
    }

    if (parsedPhone === null) {
      // Should not be possible
      alert("Phone number not valid. Please try again.")
      onFail?.call(this)
      return
    }

    const res = await signUp({
      variables: {
        email,
        phone: parsedPhone,
        firstName: firstName,
        lastName: lastName,
      },
    })

    signUpComplete({ res, onSuccess, onFail, skipRefetch })
  }

  const handleVerify = async ({
    code,
    email,
    phone,
    firstName,
    lastName,
    onSuccess,
    onError,
  }: {
    code?: string
    email?: string
    password?: string
    phone?: string
    firstName?: string
    lastName?: string
    onSuccess?: (me: App_RootDataQuery["me"] | null) => void
    onError?: (message?: string) => void
  }) => {
    // setVerifyCode("");
    if (!phone) {
      alert("No phone set.")
      return
    }

    if (!code) {
      alert("No code set.")
      return
    }

    if (!isNil(phone)) {
      phone = validateAndFormatPhoneE164(phone).toString()
    }

    // So we can group by request easily
    const startTimestamp = new Date().getTime()
    logVerifyAttempt("Start", phone, { startTimestamp })
    const res = await verify({
      variables: {
        email,
        phone,
        code,
        firstName,
        lastName,
        utmParams: getUtmParms(),
      },
      refetchQueries: [
        {
          query: ROOT_DATA_QUERY,
        },
      ],
    })
    logVerifyAttempt("Complete", phone, {
      response: res.data?.authVerify,
      startTimestamp,
    })

    if (res?.data) {
      if (res.data.authVerify.ok) {
        const userData = await userDataRefresh()
        logVerifyAttempt("User data refreshed", phone, {
          validUser: !!userData?.data?.me,
          crmId: userData?.data?.me?.crmId,
          startTimestamp,
        })
        onSuccess?.call(null, userData?.data?.me)
        logVerifyAttempt("On success called", phone, { startTimestamp })
        refreshPaymentMethods()
      } else {
        const isVerificationError = !!res.data.authVerify.errorIsVerify

        if (isVerificationError) {
          // setShowResendCode(true);
        }

        // Should we close the Auth modal when the alert is closed?
        // Only if the error is not a verification error (they can re-enter).
        // const closeAuthOnCloseAlert = !isVerificationError;

        let error = GENERIC_ERROR_MESSAGE

        if (res.data.authVerify.error) {
          error = res.data.authVerify.error
        }

        if (!!onError) {
          onError.call(null, error)
        } else {
          alert("Error: " + error)
        }
        logVerifyAttempt("Error block complete", phone, { startTimestamp })
      }
    }
  }

  const handleLogout = async ({ onSuccess }: { onSuccess?: () => void }) => {
    const res = await logout()
    if (res?.data?.authLogout?.ok) {
      // Hard shutdown intercom because we want to "reset" the user on logout
      hardShutdown()
      reset()
      clearSwagProducts()
      Sentry.configureScope((scope) => scope.setUser(null))
      onSuccess?.call(null)
    } else {
      alert("An error occurred while logging out.")
    }
  }

  return {
    handleLogout,
    handleLogin,
    handleUnlinkOpenid,
    handleVerify,
    handleSignUpPlus,
    handleSignUp,
    signUpLoading,
    loginLoading,
    verifyLoading,
  }
}

export const useAuthErrors = () => {
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)

    if (searchParams.has("auth_error")) {
      alert(`Sign in failed: ${searchParams.get("auth_error")}`)
      searchParams.delete("auth_error")
      location.search = searchParams.toString()
      history.replace(location)
    }
  }, [location])
}

export const useRequireSSO = () => {
  const history = useHistory()
  const location = useLocation()
  const [requireSSOError, setRequireSSOError] = useState<string | null>(null)
  const [requireSSOSetting, setRequireSSOSetting] = useState<string | null>(
    null,
  )
  const [requireSSOEmail, setRequireSSOEmail] = useState<string | null>(null)

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)

    if (
      searchParams.has("require_sso_error") &&
      searchParams.has("require_sso_setting")
    ) {
      setRequireSSOError(searchParams.get("require_sso_error"))
      setRequireSSOSetting(searchParams.get("require_sso_setting"))
      setRequireSSOEmail(searchParams.get("require_sso_email"))

      searchParams.delete("require_sso_error")
      searchParams.delete("require_sso_setting")
      searchParams.delete("require_sso_email")

      location.search = searchParams.toString()
      history.replace(location)
    }
  }, [location])

  return {
    requireSSOError,
    setRequireSSOError,
    requireSSOSetting,
    setRequireSSOSetting,
    requireSSOEmail,
    setRequireSSOEmail,
  }
}

const AUTH_LOGIN = gql`
  mutation Auth_Login($email: String, $password: String, $phone: String) {
    authLogin(email: $email, password: $password, phone: $phone) {
      ok
      error
      requireSsoError
      requireSsoSetting
      requireSsoEmail
    }
  }
`

const AUTH_UNLINK_OPENID = gql`
  mutation Auth_Unlink_Openid($provider: String!) {
    authUnlinkOpenid(provider: $provider) {
      ok
      error
    }
  }
`

const AUTH_SIGN_UP_PLUS = gql`
  mutation Auth_SignUp_Plus(
    $email: String!
    $password: String!
    $firstName: String
    $lastName: String
    $referringGiftId: String
    $referringCode: String
  ) {
    authSignUp(
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
      isBusiness: true
      referringGiftId: $referringGiftId
      referringCode: $referringCode
    ) {
      ok
      error
      errorIsEmail
      errorIsPhone
      errorIsUsername
      errorIsPassword
    }
  }
`

const AUTH_SIGN_UP_CONSUMER = gql`
  mutation Auth_SignUp(
    $phone: String!
    $email: String!
    $firstName: String
    $lastName: String
  ) {
    authSignUp(
      phone: $phone
      email: $email
      firstName: $firstName
      lastName: $lastName
    ) {
      ok
      error
      errorIsEmail
      errorIsPhone
      errorIsUsername
      errorIsPassword
    }
  }
`

const AUTH_VERIFY = gql`
  mutation Auth_Verify(
    $phone: String!
    $email: String
    $code: String!
    $deviceInfo: DeviceInfoInput
    $firstName: String
    $lastName: String
    $utmParams: UtmParams
  ) {
    authVerify(
      phone: $phone
      email: $email
      code: $code
      deviceInfo: $deviceInfo
      firstName: $firstName
      lastName: $lastName
      utmParams: $utmParams
    ) {
      ok
      error
      errorIsAccount
      errorIsVerify
    }
  }
`

const AUTH_LOGOUT = gql`
  mutation Auth_Logout {
    authLogout {
      ok
    }
  }
`
