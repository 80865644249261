import React from "react"
import Modal from "react-modal"

import { ClosePurple } from "../assets/icons"
import { track } from "../common/analytics"
import { useGiftData } from "../common/hooks/giftData"
import { modalStyle } from "../common/modal"
import { useRouteHasTag } from "../common/TaggedRoute"
import { BudgetingDepositContent } from "../organization/budgeting/BudgetingDepositContent"
import { BudgetingBackground } from "../organization/budgeting/common"
import BudgetingHeader from "../organization/budgeting/components/BudgetingHeader"
import Container from "../sites/App/Container"

import { BalanceDepositAccount } from "@/types/graphql-types"

interface Props {
  isOpen: boolean
  close: () => void
  defaultBalance?: number | null
  runPriceEstimate?: () => void
}

export const AddBalanceModal = ({
  isOpen,
  close,
  defaultBalance,
  runPriceEstimate,
}: Props) => {
  const isSendPage = useRouteHasTag("send")
  const { setHasRecentlyAddedBalance } = useGiftData()

  return (
    <Modal
      isOpen={isOpen}
      closeTimeoutMS={500}
      onRequestClose={close}
      onAfterClose={() => {}}
      shouldCloseOnOverlayClick={true}
      style={modalStyle}
      shouldCloseOnEsc={false}
    >
      <div
        className="modal-content-no-width"
        tw="max-h-[100vh] md:max-h-[90vh] overflow-y-auto w-[750px] max-w-[100vw]"
      >
        <BudgetingBackground>
          <Container>
            <div tw="pb-10 px-4 md:px-10 pt-1">
              <BudgetingHeader
                title="Add to balance"
                subtitle="Pre-fund your balance to simplify receipts."
              />
              <BudgetingDepositContent
                fullWidth={true}
                accountType={BalanceDepositAccount.user}
                onSuccess={({ amountInCents, type }) => {
                  if (runPriceEstimate) {
                    runPriceEstimate()
                  }
                  if (isSendPage) {
                    track("Business - Send - Add Account Balance", {
                      addBalanceAmount: amountInCents / 100.0,
                      addBalanceType: type,
                    })
                    setHasRecentlyAddedBalance(true)
                  }
                  close()
                }}
                defaultBalance={defaultBalance}
              />
            </div>
          </Container>
        </BudgetingBackground>
        <button tw="absolute top-6 right-6" onClick={close}>
          <ClosePurple />
        </button>
      </div>
    </Modal>
  )
}
