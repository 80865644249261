import React from "react"

import BasicContainer from "./components/BasicContainer"
import { CopiedHtmlContainer } from "./Ui"

const PrivacyPolicy: React.FC = () => {
  return (
    <BasicContainer title="Privacy Policy">
      <CopiedHtmlContainer className="legal-content">
        <div className="privacy-intro w-richtext">
          <p>
            <strong>Effective Date</strong>: November 20, 2020
          </p>
          <p>
            <strong>Last Updated</strong>: August 11, 2021
          </p>
          <p>
            We at Goody Technologies, Inc. (“<strong>Goody</strong>”, “
            <strong>us</strong>”, “<strong>our</strong>”, or “
            <strong>we</strong>”) are strongly committed to transparency, and we
            want you (“<strong>you</strong>” or “<strong>your</strong>”) to
            understand how we collect, use, share and protect your personal
            information, as well as how you can manage the personal information
            we collect. This Privacy Policy applies to your interactions with us
            or your use of our website (
            <a href="https://www.ongoody.com/">www.ongoody.com</a>), products,
            services, or features, either online or offline (collectively, our “
            <strong>Services</strong>”).
          </p>
          <p>
            By using our Services, you acknowledge the terms of this Privacy
            Policy. If you do not agree to the terms of this Privacy Policy,
            please do not use our Services. If you do not understand, or if you
            have questions about, this Privacy Policy, please{" "}
            <a href="#section-11">contact us</a> before using, or continuing to
            use, our Services.
          </p>
          <p>
            We reserve the right to change our Privacy Policy from time to time
            by posting the changes here. If we choose to amend this Privacy
            Policy, we will revise the Last Updated date at the top of this
            Privacy Policy when we post the updated version. We may also provide
            you with notice by prominently posting on our website, via email or
            both, if we make any significant changes to this Privacy Policy. We
            may also highlight those changes at the top of this Privacy Policy
            and provide a prominent link to it for a reasonable length of time
            following the change. Your use of our Services after we have
            informed you in one of these ways that we made changes to our
            Privacy Policy will mean that you have accepted those changes.
          </p>
          <p>‍</p>
        </div>
        <ol role="list">
          <li>
            <a href="#section-1" className="">
              <strong>TYPES OF INFORMATION WE COLLECT</strong>
            </a>
          </li>
          <li>
            <a href="#section-2" className="">
              <strong>HOW WE COLLECT PERSONAL INFORMATION</strong>
            </a>
          </li>
          <li>
            <a href="#section-3" className="">
              <strong>HOW WE USE PERSONAL INFORMATION</strong>
            </a>
          </li>
          <li>
            <a href="#section-4" className="">
              <strong>HOW WE SHARE PERSONAL INFORMATION</strong>
            </a>
          </li>
          <li>
            <a href="#section-5">
              <strong>HOW WE PROTECT PERSONAL INFORMATION</strong>
            </a>
          </li>
          <li>
            <a href="#section-6">
              <strong>DATA TRANSFERS</strong>
            </a>
          </li>
          <li>
            <a href="#section-7">
              <strong>CHILDREN’S PRIVACY</strong>
            </a>
          </li>
          <li>
            <a href="#section-8">
              <strong>YOUR CALIFORNIA PRIVACY RIGHTS</strong>
            </a>
          </li>
          <li>
            <a href="#section-9">
              <strong>YOUR CHOICES</strong>
            </a>
          </li>
          <li>
            <a href="#section-10">
              <strong>THIRD PARTY WEBSITE AND APPS</strong>
            </a>
          </li>
          <li>
            <a href="#section-11">
              <strong>CONTACT US</strong>
            </a>
          </li>
        </ol>
        <h2 id="section-1" className="legal-h2">
          1. TYPES OF INFORMATION WE COLLECT
        </h2>
        <div className="w-richtext">
          <p>
            We collect two basic types of information from you when you provide
            it to us or when you use or interact with our Services: personal
            information and non-personal information.
          </p>
          <p>
            Personal information includes all information that relates to you or
            which are opinions about you personally and either identifies or may
            be used to identify you personally (collectively, “
            <strong>personal information</strong>”). We may collect the
            following types of personal information from you depending upon the
            device you are using and how you interact with us or use or interact
            with our Services, such as:
          </p>
          <ul role="list">
            <li>
              <strong>Contact Information.</strong> Name, mailing address, email
              address, phone number, and other contact information.
            </li>
            <li>
              <strong>Demographic Information.</strong> Date of birth and
              gender.
            </li>
            <li>
              <strong>Device Information.</strong> IP address, browser type and
              version, browser plug-in types and versions, operating system and
              platform, device type and device identifiers.
            </li>
            <li>
              <strong>Financial Information.</strong> Credit and debit card
              numbers and other financial information necessary to receive
              payment for Services purchased.
            </li>
            <li>
              <strong>Geolocation Information.</strong> Collected via IP
              address.
            </li>
            <li>
              <strong>Gift Recipient Information.</strong> In order to send an
              individual a gift using our Services, we collect information about
              the intended recipient, including their name and phone number.
            </li>
            <li>
              <strong>Gift Messages.</strong> When a user sends someone a gift
              using our Services, they may include a message to the intended
              recipient. In addition, when someone receives a gift, they may
              send a message to the individual who sent them the gift using our
              Services. Please note, the content of these messages may be viewed
              by others and we strongly encourage users to not include any
              sensitive information within any gift-related message.
            </li>
          </ul>
          <p>
            To see all of the categories of personal information we collect,{" "}
            <a href="#appendix">click here</a>.
          </p>
          <p>
            Non-personal information includes information that does not
            personally identify an individual or information that has been
            anonymized (collectively, “<strong>non-personal information</strong>
            ”). When we combine non-personal information with personal
            information, we treat the combined information as personal
            information.
          </p>
          <p>
            You can always refuse to provide your personal information, except
            that it may prevent you from engaging in our Services or receiving
            responses to your inquiries or other information of interest.
          </p>
        </div>
        <h2 id="section-2" className="legal-h2">
          2. HOW WE COLLECT PERSONAL INFORMATION
        </h2>
        <div className="w-richtext">
          <p>
            We need to collect personal information from you in order to provide
            you with our Services, as well as to improve your experience. You
            may provide us with personal information in several ways, including,
            for example when you:
          </p>
          <ul role="list">
            <li>Visit our website or use our Services;</li>
            <li>Register for an account or membership;</li>
            <li>
              Correspond with us in any way, including through surveys and
              promotions;
            </li>
            <li>
              Sign up to receive our newsletter or promotional information;
            </li>
            <li>Ask for customer service, support or other assistance; or</li>
            <li>
              Interact with us in any other way, online or offline, including
              through our Services.
            </li>
          </ul>
          <h5>Cookies</h5>
          <p>
            Like many websites and apps, we use “cookies”, which are small text
            files that are stored on your computer or equipment when you visit
            certain online pages that record your preferences and actions,
            including how you use the website. We use this information for
            analytics purposes which allows us to improve your browsing
            experience. The information we collect through these technologies
            will also be used to manage your session.
          </p>
          <p>
            You can set your browser or device to refuse all cookies or to
            indicate when a cookie is being sent. If you delete your cookies, if
            you opt-out from cookies, or if you set your browser or device to
            decline these technologies, some Services may not function properly.
            Our Services do not currently change the way they operate upon
            detection of a{" "}
            <a href="https://www.consumer.ftc.gov/articles/0042-online-tracking">
              Do Not Track
            </a>{" "}
            or similar signal.
          </p>
          <h5>User Profiles</h5>
          <p>
            When you create an account with Goody, you will be given the
            opportunity to create a “User Profile” and when you do so we may ask
            you to provide information about yourself such as a username, name,
            email address, and phone number. Creating a User Profile is
            optional. Email address and phone numbers are not public and you may
            decide whether you want your user name to be public (as described
            below).
            <br />
          </p>
          <p>
            Once your User Profile is created, you may elect to turn “on” or
            “off” the option to “allow receiving gifts via username”, which will
            make your username publicly searchable. If you turn this option “on”
            then other Goody users will be able to view your User Profile by
            searching for your username and will be able to send you gifts using
            your username. If you turn this option ”off” then your username will
            not be searchable by other Goody users and other Goody users will
            not be able to send you gifts using your username. In either case,
            you will still be able to send and receive gifts using your phone
            number.
          </p>
          <h5>Online Tools and Analytics</h5>
          <p>
            We also use various types of tools and online analytics including
            Google Maps and Google Analytics. Google Analytics is a web
            analytics service provided by Google, Inc. (“<strong>Google</strong>
            ”) that uses cookies or other tracking technologies to help us
            analyze how users interact with and use the website, compile reports
            on the related activities, and provide other services related to
            website and app activity and usage. The technologies used by Google
            may collect information such as your IP address, time of visit,
            whether you are a return visitor, and any referring website or app.
            The information generated by Google Analytics will be transmitted to
            and stored by Google and will be subject to Google’s{" "}
            <a href="https://www.google.com/policies/privacy/partners/">
              privacy policies
            </a>
            . To learn more about Google’s partner services and to learn how to
            opt-out of tracking of analytics by Google{" "}
            <a href="https://tools.google.com/dlpage/gaoptout/">click here</a>.
          </p>
          <h5>Offline Interactions and Other Sources</h5>
          <p>
            We also may collect personal information from other sources, such as
            our partners or third party service providers, or from our offline
            interactions with you for the purposes listed in the{" "}
            <strong>How We Use Personal Information </strong>section below,
            including to enable us to verify or update information contained in
            our records and to better customize the Services for you.
          </p>
          <h5>Social Media Integration</h5>
          <p>
            Our Services may, from time to time, contain links to and from
            social media platforms. You may choose to connect to us through a
            social media platform, such as Facebook, LinkedIn or Twitter, and
            when you do, we may collect additional information from you,
            including the information listed in the{" "}
            <strong>Types of Information We Collect </strong>section above.
            Please be advised that social media platforms may also collect
            information from you. When you click on a social plug-in, such as
            Facebook’s “Like” button or Twitter’s “Tweet” button, that
            particular social network’s plugin will be activated and your
            browser will directly connect to that provider’s servers. We
            encourage you to review the social media platforms’ usage and
            disclosure policies and practices, including the data security
            practices, before using them.
          </p>
          <h5>Surveys</h5>
          <p>
            From time to time, and subject to your consent where required by
            applicable local law, we may provide you with the opportunity to
            participate in surveys, some of which might be sponsored or
            conducted by a third party. Participation in these surveys is
            completely voluntary. If you choose to participate, we will request
            certain personal information from you. If there is a third party
            sponsor involved in the survey, we may provide certain personal
            information to the third party sponsor. Please make sure to review
            the sponsor’s privacy policy and be aware that any surveys offered
            by us may be governed by specific rules and terms and conditions
            that are separate from and in addition to this Privacy Policy. By
            participating in any such surveys you will become subject to those
            rules, including but not limited to receiving emails containing
            survey communications.
          </p>
        </div>
        <h2 id="section-3" className="legal-h2">
          3. HOW WE USE PERSONAL INFORMATION
        </h2>
        <div className="w-richtext">
          <p>
            We may use personal information to do any or all of the following:
          </p>
          <ul role="list">
            <li>provide you with our Services;</li>
            <li>facilitate your interactions and transactions with us;</li>
            <li>
              respond to your requests, communications, suggestions, comments,
              inquiries, and requests (including your feedback about our
              Services);
            </li>
            <li>
              administer our relationship with you, including creating and
              managing your account;
            </li>
            <li>maintain and improve our Services;</li>
            <li>measure performance;</li>
            <li>develop new products, features, and services;</li>
            <li>better understand the preferences of our customers;</li>
            <li>provide personalized Services, including content and ads;</li>
            <li>
              provide you with, and improve, relevant marketing offers or
              information from us or relevant third parties;
            </li>
            <li>
              facilitate your participation in our surveys and promotions;
            </li>
            <li>
              respond to legally binding requests from law enforcement,
              regulatory authorities, or other third parties;
            </li>
            <li>
              defend, protect or enforce our rights or applicable terms of
              service or to fulfill our legal or contractual obligations;
            </li>
            <li>to prevent fraud or the recurrence of fraud;</li>
            <li>assist in the event of an emergency; or</li>
            <li>comply with applicable law.</li>
          </ul>
          <p>
            We may also combine your personal information collected through
            various sources, including information collected through our
            Services, and develop a customer profile that will be used for the
            purposes above.
          </p>
          <p>
            We may retain personal information for as long as we need to use the
            information for the purposes listed above, including, for backup,
            archival, accounting, and/or audit purposes.
          </p>
          <p>
            We may use personal information to create non-personal information.
            We may use non-personal information for any legitimate business
            purpose.
            <br />
          </p>
        </div>
        <h2 id="section-4" className="legal-h2">
          4. HOW WE SHARE PERSONAL INFORMATION
        </h2>
        <div className="w-richtext">
          <p>
            Except as disclosed in this Privacy Policy, we do not share personal
            information with any companies other than our partners and
            affiliates and their directors, officers, employees, agents,
            consultants, advisors or other representatives.
          </p>
          <p>
            We may share personal information with our third party service
            providers, suppliers, vendors, and business partners to help us:
          </p>
          <ul role="list">
            <li>
              with the uses described in the{" "}
              <strong>How We Use Information</strong> section above;
            </li>
            <li>
              in the operation, management, improvement, research and analysis
              of our Services;
            </li>
            <li>
              with our marketing and promotional projects, such as sending you
              information about products and services you may like and other
              promotions (provided you have not unsubscribed from receiving such
              marketing and promotional information from us); and
            </li>
            <li>
              comply with your directions or any consent you have provided us.
            </li>
          </ul>
          <p>
            We may share personal information with law enforcement and
            regulatory authorities or other third parties as required or
            permitted by law for the purpose of:
          </p>
          <ul role="list">
            <li>
              responding to a subpoena, court order, or other legal processes;
            </li>
            <li>defending, protecting, or enforcing our rights;</li>
            <li>assisting in the event of an emergency; and</li>
            <li>complying with applicable law.</li>
          </ul>
          <p>
            We may also transfer or assign personal information to third parties
            as a result of, or in connection with, a sale, merger,
            consolidation, change in control, transfer of assets, bankruptcy,
            reorganization, or liquidation. If we are involved in defending a
            legal claim, we may disclose personal information about you that is
            relevant to the claim to third parties as a result of, or in
            connection with, the associated legal proceedings.
          </p>
          <p>
            To see all of the categories of personal information we share,{" "}
            <a href="#appendix">click here</a>.
          </p>
          <p>
            We share non-personal information with third parties as reasonably
            necessary to meet our business needs.
          </p>
          <p>
            <br />
          </p>
        </div>
        <h2 id="section-5" className="legal-h2">
          5. HOW WE PROTECT PERSONAL INFORMATION
        </h2>
        <div className="w-richtext">
          <p>
            We are dedicated to ensuring the security of your personal
            information. We aim to provide secure transmission of your personal
            information from your devices to our servers. We have processes to
            store personal information that we have collected in secure
            operating environments. Our security procedures mean that we may
            occasionally request proof of identity before we disclose your
            personal information to you. We try our best to safeguard personal
            information once we receive it, but please understand that no
            transmission of data over the Internet or any other public network
            can be guaranteed to be 100% secure. If you suspect an unauthorized
            use or security breach of your personal information, please{" "}
            <a href="#section-11">contact us</a> immediately.
            <br />
          </p>
        </div>
        <h2 id="section-6" className="legal-h2">
          6. DATA TRANSFERS
        </h2>
        <div className="w-richtext">
          <p>
            We are a global business. As such, information we collect may be
            transferred to, stored, and processed in any country or territory
            where one or more of our business partners or service providers are
            based or have facilities. While other countries or territories may
            not have the same standards of data protection as those in your home
            country, we will continue to protect personal information that we
            transfer in line with this Privacy Policy.
          </p>
        </div>
        <h2 id="section-7" className="legal-h2">
          7. CHILDREN’S PRIVACY
        </h2>
        <div className="w-richtext">
          <p>
            Our Services are not for children or those under the age of 13. We
            do not knowingly collect personal information from children or other
            persons who are under 13 years of age. Individuals who are children
            or those under the age of 13 should not attempt to provide us with
            any personal information. If you think we have received personal
            information from children or those under the age of 13, please{" "}
            <a href="#section-11">contact us</a> immediately.
          </p>
        </div>
        <h2 id="section-8" className="legal-h2">
          8. YOUR CALIFORNIA PRIVACY RIGHTS
        </h2>
        <div className="w-richtext">
          <p>
            We do not share personal information with third parties for their
            own direct marketing purposes without your consent. California
            residents under 18 years old, in certain circumstances, may request
            and obtain removal of personal information or content about you that
            you have posted on our Services. Please be mindful that this would
            not ensure complete removal of the content posted by you on our
            Services. To make any request pursuant to California privacy law,
            please <a href="#section-11">contact us</a>
            <strong>.</strong>
            <br />
          </p>
        </div>
        <h2 id="section-9" className="legal-h2">
          9. YOUR CHOICES
        </h2>
        <div className="w-richtext">
          <p>We offer you certain choices in connection with our Services.</p>
          <p>
            <strong>
              <em>Access to your personal information</em>
            </strong>
          </p>
          <p>
            You have the right to request access to the personal information
            that we collect, use, and disclose about you. You also have the
            right to not receive discriminatory treatment for exercising your
            access right. To submit a request, please{" "}
            <a href="#section-11">contact us</a>.
          </p>
          <p>
            <strong>
              <em>Deleting your personal information</em>
            </strong>
          </p>
          <p>
            You have the right to request that we delete your personal
            information, subject to some exceptions. Once we have received and
            confirmed your request, we will delete (and direct our partners and
            service providers to delete) your personal information, unless an
            exception under applicable law applies. You have the right to not
            receive discriminatory treatment for exercising your deletion right.
            To submit a request or designate an authorized agent to make a
            request, please <a href="#section-11">contact us</a>.
          </p>
          <p>
            <strong>
              <em>Updating your personal information</em>
            </strong>
          </p>
          <p>
            The accuracy of the personal information we have about you is very
            important. To submit a request to update or correct your
            information, please{" "}
            <a href="https://www.ongoody.com/privacy#section-11">contact us</a>{" "}
            using the information below.
          </p>
          <p>
            <strong>
              <em>Email Communications / Direct Marketing</em>
            </strong>
          </p>
          <p>
            You may have the opportunity to receive certain email communications
            from us related to our Services. If you provide us with your email
            address in order to receive communications, you can opt-out of
            marketing emails at any time by following the instructions at the
            bottom of our emails and adjusting your email preferences. Please
            note that certain emails may be necessary for the operation of our
            Services. You will continue to receive these necessary emails, if
            lawful and appropriate, even if you unsubscribe from our optional
            marketing communications.
          </p>
          <p>
            <strong>
              <em>Text Messages</em>
            </strong>
          </p>
          <p>
            You may have the opportunity to receive certain text message
            communications from us related to our Services. You can opt-out of
            receiving text messages at any time by following the instructions in
            the message or by replying “STOP”.
          </p>
          <p>
            <strong>
              <em>Cookies / Beacons</em>
            </strong>
          </p>
          <p>
            If you wish to minimize information collected by cookies or beacons,
            you can adjust the settings of your device or browser. You can also
            set your device or browser to automatically reject any cookies. You
            may also be able to install plug-ins and add-ins that serve similar
            functions. However, please be aware that some Services may not work
            properly. In addition, the offers we provide when you visit us may
            not be as relevant to you or tailored to your interests.
          </p>
          <p>
            <strong>
              <em>Network Advertising Initiative</em>
            </strong>
          </p>
          <p>
            Certain websites you visit may provide options regarding
            advertisements you receive. If you wish to minimize the amount of
            targeted advertising you receive, you can opt-out of certain network
            advertising programs through the Network Advertising Initiative
            (NAI) Opt-Out Page. Please note that even if you choose to remove
            your personal information (opt-out) you will still see
            advertisements while you’re browsing online. However, the
            advertisements you see may be less relevant to you. For more
            information or to opt-out of certain online behavioral advertising,
            please visit{" "}
            <a href="http://www.aboutads.info/">http://www.aboutads.info</a>.
          </p>
          <p>
            Additionally, many advertising network programs allow you to view
            and manage the interest categories that they have compiled from your
            online browsing activities. These interest categories help determine
            the types of targeted advertisements you may receive. The NAI
            Opt-Out Page provides a tool that identifies its member companies
            that have cookies on your browser and provides links to those
            companies.
          </p>
          <p>
            <strong>
              <em>Do Not Track</em>
            </strong>
          </p>
          <p>
            Some devices and browsers support a “Do Not Track” (or, DNT)
            feature, a privacy preference that you can set in certain browsers,
            which is intended to be a signal to websites and services that you
            do not wish to be tracked across different websites or online
            services you visit.
          </p>
          <p>
            Please note that we cannot control how third party websites or
            online services you visit through our website respond to Do Not
            Track signals. Check the privacy policies of those third parties for
            information on their privacy practices. Our Services do not
            currently change the way they operate upon detection of a{" "}
            <a href="https://www.consumer.ftc.gov/articles/0042-online-tracking">
              Do Not Track
            </a>{" "}
            or similar signal.
          </p>
        </div>
        <h2 id="section-10" className="legal-h2">
          10. THIRD PARTY WEBSITES AND APPS
        </h2>
        <div className="w-richtext">
          <p>
            Our website and Services may contain links to other websites or apps
            operated by third parties. Please be advised that the practices
            described in this Privacy Policy do not apply to information
            gathered through these third party websites and apps. We have no
            control over, and are not responsible for, the actions and privacy
            policies of third parties and other websites and apps.
          </p>
        </div>
        <h2 id="section-11" className="legal-h2">
          11. CONTACT US
        </h2>
        <div className="w-richtext">
          <p>
            We welcome questions, comments, and feedback on this Privacy Policy
            and our management of personal information. If you have questions,
            concerns, or feedback, you can always contact us by emailing us{" "}
            <a href="mailto:support@ongoody.com">support@ongoody.com</a>. For
            your protection, we may need to verify your identity before
            assisting with your request, such as verifying that the information
            used to contact us matches the information that we have on file.
          </p>
        </div>
        <h2 id="appendix" className="legal-h2">
          California Privacy Law Appendix
        </h2>
        <div className="w-richtext">
          <p>
            To learn more about the categories of personal information we
            collect, how we collect it, why it is collected, with whom we share
            it, and how long we retain it, please see the chart below.
          </p>
        </div>
        <div className="w-embed">
          <div className="table-wrapper">
            <table
              tw="table-auto border align-top"
              cellPadding="8"
              cellSpacing="0"
            >
              <thead>
                <tr>
                  <th>
                    <strong>Category</strong>
                  </th>
                  <th>
                    <strong>What we collect</strong>
                  </th>
                  <th>
                    <strong>How we collect it</strong>
                  </th>
                  <th>
                    <strong>Why we collect it</strong>
                  </th>
                  <th>
                    <strong>With whom we share it</strong>
                  </th>
                  <th>
                    <strong>How long we retain it</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <strong>Identifiers</strong>
                  </td>
                  <td>
                    Name, mailing address, email address, phone number, and
                    other contact information.
                    <br />
                    <br />
                    For more information please see the{" "}
                    <u>Types of Information We Collect</u> section above.
                  </td>
                  <td>
                    Collected online or offline when you directly provide it to
                    us, through your use of our Services, or from third parties.
                  </td>
                  <td>
                    We collect this information for the purposes listed in the{" "}
                    <u>How We Use Information</u> section above.
                  </td>
                  <td>
                    Shared with our affiliates, partners, vendors, and service
                    providers as described in the{" "}
                    <u>How We Share Information</u> section above.
                  </td>
                  <td>
                    We retain information for as long as necessary for the
                    purpose for which it was initially collected, including the
                    purposes listed in the <u>How We Use Information</u> section
                    above.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      Personal information categories listed in the California
                      Customer Records statute (Cal. Civ. Code § 1798.80(e))
                    </strong>
                  </td>
                  <td>
                    Name, mailing address, email address, phone number, and
                    contact information.
                    <br />
                    <br />
                    For more information please see the{" "}
                    <u>Types of Information We Collect</u> section above.
                  </td>
                  <td>
                    Collected online or offline when you directly provide it to
                    us, through your use of our Services, or from third parties.
                  </td>
                  <td>
                    We collect this information for the purposes listed in the{" "}
                    <u>How We Use Information</u> section above.
                  </td>
                  <td>
                    Shared with our affiliates, partners, vendors, and service
                    providers as described in the{" "}
                    <u>How We Share Information</u> section above.
                  </td>
                  <td>
                    We retain information for as long as necessary for the
                    purpose for which it was initially collected, including the
                    purposes listed in the <u>How We Use Information</u> section
                    above.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      Protected classification characteristics under California
                      or federal law
                    </strong>
                  </td>
                  <td>
                    Age and gender.
                    <br />
                    <br />
                    For more information please see the{" "}
                    <u>Types of Information We Collect</u>
                    section above.
                  </td>
                  <td>
                    Collected online or offline when you directly provide it to
                    us, through your use of our Services, or from third parties.
                  </td>
                  <td>
                    We collect this information for the purposes listed in the{" "}
                    <u>How We Use Information</u> section above.
                  </td>
                  <td>
                    Shared with our affiliates, partners, vendors, and service
                    providers as described in the{" "}
                    <u>How We Share Information</u> section above.
                  </td>
                  <td>
                    We retain information for as long as necessary for the
                    purpose for which it was initially collected, including the
                    purposes listed in the <u>How We Use Information</u> section
                    above.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Commercial information</strong>
                  </td>
                  <td>
                    Transactional information such as the products or services
                    purchased.
                    <br />
                    <br />
                    For more information please see the{" "}
                    <u>Types of Information We Collect</u> section above.
                  </td>
                  <td>
                    Collected online or offline when you directly provide it to
                    us, through your use of our Services, or from third parties.
                  </td>
                  <td>
                    We collect this information for the purposes listed in the{" "}
                    <u>How We Use Information</u> section above.
                  </td>
                  <td>
                    Shared with our affiliates, partners, vendors, and service
                    providers as described in the{" "}
                    <u>How We Share Information</u> section above.
                  </td>
                  <td>
                    We retain information for as long as necessary for the
                    purpose for which it was initially collected, including the
                    purposes listed in the <u>How We Use Information</u> section
                    above.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Biometric information</strong>
                  </td>
                  <td>Not Collected</td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>N/A</td>
                </tr>
                <tr>
                  <td>
                    <strong>Internet or other similar network activity</strong>
                  </td>
                  <td>
                    IP address, browser type and version, browser plug-in types
                    and versions, operating system and platform, device type and
                    device identifiers
                    <br />
                    <br />
                    For more information please see the{" "}
                    <u>Types of Information We Collect</u> section above.
                  </td>
                  <td>
                    Collected online when you directly provide it to us, through
                    your use of our Services, or from third parties.
                  </td>
                  <td>
                    We collect this information for the purposes listed in the{" "}
                    <u>How We Use Information</u> section above.
                  </td>
                  <td>
                    Shared with our affiliates, partners, vendors, and service
                    providers as described in the{" "}
                    <u>How We Share Information</u> section above.
                  </td>
                  <td>
                    We retain information for as long as necessary for the
                    purpose for which it was initially collected, including the
                    purposes listed in the <u>How We Use Information</u> section
                    above.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Geolocation data</strong>
                  </td>
                  <td>
                    Collected via IP address.
                    <br />
                    <br />
                    For more information please see the{" "}
                    <u>Types of Information We Collect</u> section above.
                  </td>
                  <td>
                    Collected online when you directly provide it to us, through
                    your use of our Services, or from third parties.
                  </td>
                  <td>
                    We collect this information for the purposes listed in the{" "}
                    <u>How We Use Information</u> section above.
                  </td>
                  <td>
                    Shared with our affiliates, partners, vendors, and service
                    providers as described in the{" "}
                    <u>How We Share Information</u> section above.
                  </td>
                  <td>
                    We retain information for as long as necessary for the
                    purpose for which it was initially collected, including the
                    purposes listed in the <u>How We Use Information</u> section
                    above.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Sensory data</strong>
                  </td>
                  <td>Not collected</td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>N/A</td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      Professional or employment-related information
                    </strong>
                  </td>
                  <td>Not collected</td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>N/A</td>
                </tr>
                <tr>
                  <td>
                    <strong>Non-public education information</strong>
                  </td>
                  <td>Not Collected</td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>N/A</td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      Inferences drawn from other personal information
                    </strong>
                  </td>
                  <td>
                    Information generated from your use of our Services
                    reflecting your preferences
                  </td>
                  <td>
                    Collected online or offline when you directly provide it to
                    us, through your use of our Services, or from third parties.
                  </td>
                  <td>
                    We collect this information for the purposes listed in the{" "}
                    <u>How We Use Information</u> section above.
                  </td>
                  <td>
                    Shared with our affiliates, partners, vendors, and service
                    providers as described in the{" "}
                    <u>How We Share Information</u> section above.
                  </td>
                  <td>
                    We retain information for as long as necessary for the
                    purpose for which it was initially collected, including the
                    purposes listed in the <u>How We Use Information</u> section
                    above.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Sensitive personal information</strong>
                  </td>
                  <td>Not collected</td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>N/A</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </CopiedHtmlContainer>
    </BasicContainer>
  )
}

export default PrivacyPolicy
