import { gql, useQuery } from "@apollo/client"
import { Helmet } from "react-helmet-async"
import tw, { styled } from "twin.macro"

import AccountSection from "./components/AccountSection"
import { ReactComponent as CalculatorIcon } from "./images/calculator.svg"
import { getEnvVars } from "../common/environment"
import { ReactComponent as CalendarIcon } from "../common/images/calendar.svg"
import { ReactComponent as ClockIcon } from "../common/images/clock.svg"
import { Loader } from "../common/UI"

import BalanceReceiptsTable from "@/account/components/receipts/BalanceReceiptsTable"
import BundledReceiptsTable from "@/account/components/receipts/BundledReceiptsTable"
import SubscriptionReceiptsTable, {
  SubscriptionInvoiceRow,
} from "@/account/components/receipts/SubscriptionReceiptsTable"
import { Account_ReceiptsQuery, BalanceDeposit } from "@/types/graphql-types"

const Receipts = () => {
  const { data, loading } = useQuery<Account_ReceiptsQuery>(RECEIPTS_QUERY, {
    fetchPolicy: "cache-first",
  })

  if (data == null || loading) {
    return (
      <div tw="container mx-auto bg-white flex items-center justify-center h-80">
        <Loader />
      </div>
    )
  }

  const { me, organization } = data

  if (me == null) {
    return null
  }

  const {
    bundledReceipts,
    balanceDeposits,
    subscriptionsAll: userSubscriptionsAll = [],
  } = me

  const { subscriptionsAll: organizationSubscriptionsAll = [] } =
    organization ?? {}

  const subscriptionsAll = [
    ...organizationSubscriptionsAll,
    ...userSubscriptionsAll,
  ]

  const subscriptionReceipts: SubscriptionInvoiceRow[] = []
  subscriptionsAll.forEach((subscription) => {
    subscription.invoices?.forEach((invoice) => {
      subscriptionReceipts.push({
        ...invoice,
        planName: subscription.planName,
      })
    })
  })

  const finalizedReceipts = bundledReceipts.filter(
    ({ status, isBalanceDeposit, itemsCount }) =>
      status === "FINALIZED" &&
      (!isBalanceDeposit || (isBalanceDeposit && itemsCount > 1)),
  )
  const pendingReceipts = bundledReceipts.filter((r) => r.status === "PENDING")

  return (
    <>
      <Helmet>
        <title>Account – Goody for Business</title>
      </Helmet>
      <div tw="container mx-auto bg-white">
        <div tw="p-6 pt-12">
          <AccountSection headline="Pending receipt">
            <PendingReceipts>
              <p>
                <CalendarIcon tw="top-[0.2rem] stroke-1.5" /> Receipts are
                generated twice per week.
              </p>
              <p>
                <CalculatorIcon css={{ top: "0.1rem" }} /> Standard merchandise
                purchases are billed in one payment, but gift cards and alcohol
                are billed individually.
              </p>
              <p>
                <ClockIcon css={{ top: "0.1rem" }} tw="stroke-1.5" />
                {pendingReceipts.length > 0 ? (
                  "You can view the pending transactions for your current week’s receipt below."
                ) : (
                  <strong tw="font-semibold">
                    You don’t have any transactions yet.
                  </strong>
                )}
              </p>
              <div>
                <PendingReceiptsButtons receipts={pendingReceipts} />
              </div>
            </PendingReceipts>
          </AccountSection>
          <AccountSection headline="Finalized receipts">
            {finalizedReceipts.length > 0 ? (
              <BundledReceiptsTable data={finalizedReceipts} />
            ) : (
              <div>No receipts yet.</div>
            )}
          </AccountSection>
          {balanceDeposits && balanceDeposits.length > 0 && (
            <AccountSection headline="Balance deposits">
              <BalanceReceiptsTable
                data={balanceDeposits.filter((d): d is BalanceDeposit => !!d)}
              />
            </AccountSection>
          )}
          {subscriptionReceipts.length > 0 && (
            <AccountSection headline="Subscription receipts">
              <SubscriptionReceiptsTable data={subscriptionReceipts} />
            </AccountSection>
          )}
        </div>
      </div>
    </>
  )
}

const receiptLink = ({ accessToken }: { accessToken: string }) =>
  `${getEnvVars().giftsUrl}/business_receipts/${accessToken}`

const PendingReceipts = styled.div`
  ${tw`flex flex-col gap-2`}

  > p {
    ${tw`grid`}
    grid-template-columns: 2rem 1fr;

    > svg {
      ${tw`text-gray-400 relative flex-shrink-0`}
    }
  }
`

interface PendingReceiptsButtonsProps {
  receipts: { accessToken: string; paymentMethodName: string }[]
}
const PendingReceiptsButtons: React.FC<PendingReceiptsButtonsProps> = ({
  receipts,
}) => {
  if (receipts.length === 0) {
    return null
  }

  if (receipts.length === 1) {
    return (
      <PendingReceiptButton href={receiptLink(receipts[0])} tw="mt-3">
        Open this week’s receipt
      </PendingReceiptButton>
    )
  }

  return (
    <div tw="flex flex-wrap gap-3 items-center mt-3">
      <span tw="text-gray-500">Open receipt for</span>
      {receipts.map((receipt) => (
        <PendingReceiptButton
          href={receiptLink(receipt)}
          key={receipt.accessToken}
        >
          {receipt.paymentMethodName}
        </PendingReceiptButton>
      ))}
    </div>
  )
}

const PendingReceiptButton = styled.a.attrs({
  target: "_blank",
  rel: "noreferrer",
})`
  ${tw`bg-primary-500 hover:bg-primary-600 text-white px-4 py-2 rounded-lg inline-block transition-colors`}
`

export default Receipts

export const RECEIPTS_QUERY = gql`
  query Account_Receipts {
    me {
      batchBillingGroups {
        id
        receiptId
        amountNetCharges
        createdAt
      }
      bundledReceipts {
        id
        amountNetCharges
        paymentMethodName
        closeDate
        status
        accessToken
        isBalanceDeposit
        isBatchBillingGroupFailed
        itemsCount
      }
      balanceDeposits {
        id
        createdAt
        finalizedAt
        amount
        bundledReceiptItem {
          id
          accessToken
          bundledReceipt {
            id
            paymentMethodName
          }
        }
      }
      subscriptionsAll {
        id
        planName
        invoices {
          created
          hostedInvoiceUrl
          number
          total
        }
      }
    }
    organization {
      subscriptionsAll {
        id
        planName
        invoices {
          created
          hostedInvoiceUrl
          number
          total
        }
      }
    }
  }
`
