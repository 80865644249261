import { track } from "../../common/analytics"
import RoundedButton from "../../common/RoundedButton"
import staticAssetUrl from "../../common/staticAssetUrl"
import Container from "../../sites/App/Container"

import GradientBackground from "@/common/GradientBackground"

interface Props {
  onClickApply: () => void
}

export default function Header({ onClickApply }: Props) {
  return (
    <div tw="relative">
      <GradientBackground visible />
      <Container tw="relative z-20">
        <div tw="py-12 lg:py-20 text-center">
          <div tw="font-medium text-primary-new-600 text-lg">Goody API</div>
          <h1 tw="pt-2 max-w-[870px] mx-auto font-light font-reckless-neue text-4xl px-4 tracking-[-0.04rem] lg:(font-250 text-[4rem] lg:leading-[1.12] tracking-[-0.08rem])">
            Integrate the magic of gifting into your product
          </h1>
          <h2 tw="pt-6 text-lg lg:text-[1.375rem] text-gray-800 px-6">
            Earn money selling gifts in your website or app.
          </h2>
          <div tw="flex flex-col md:flex-row items-stretch justify-center gap-4 pt-8 px-6">
            <RoundedButton
              variant="primary"
              onClick={() => {
                track("Business Marketing - Commerce API - Click Apply")
                onClickApply()
              }}
            >
              Apply Now
            </RoundedButton>
            <RoundedButton
              variant="secondary"
              element="a"
              href="https://developer.ongoody.com"
              tw="font-medium"
              target="_blank"
              rel="noopener"
              onClick={() =>
                track("Business Marketing - Commerce API - Click Docs")
              }
            >
              Read API Docs
            </RoundedButton>
          </div>
        </div>
      </Container>
      <div tw="relative z-10 -mt-12 md:-mt-32 lg:-mt-52 xl:-mt-60 2xl:-mt-64 border-b border-gray-150 lg:min-h-[400px]">
        <img
          src={staticAssetUrl("static/web/landing/api-anim.svg")}
          tw="mx-auto max-w-[1440px]"
        />
      </div>
    </div>
  )
}
