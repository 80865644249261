import { capitalize } from "lodash-es"
import React from "react"
import { useIntercom } from "react-use-intercom"
import tw, { styled } from "twin.macro"

import { ReactComponent as ChatIcon } from "../../assets/icons/chat-bubble-black.svg"
import { ReactComponent as TeamUpgrade } from "../../assets/images/team-plan-upgrade.svg"
import Button from "../Button"

// These features should be generally lowercase, with the exception of proper
// nouns. They will be capitalized in the right contexts.
const FEATURES = ["Salesforce integration", "Calendly integration", "budgeting"]
type Feature = (typeof FEATURES)[number]

interface Props {
  feature: Feature
  headerFeatureName?: string
  // Override the feature name used in the description.
  descriptionFeatureName?: string
}

export default function TeamUpsell({
  feature,
  headerFeatureName,
  descriptionFeatureName,
}: Props) {
  const { show: showIntercom } = useIntercom()

  const otherFeatures = FEATURES.filter((el) => el != feature)

  return (
    <TeamAccessNoticeDiv>
      <IconColumn>
        <TeamUpgrade />
      </IconColumn>
      <div tw="mx-6 pt-6">
        <div>
          <h1 tw="text-primary-new-550 text-xl leading-[1.5rem] font-semibold">
            {headerFeatureName || capitalize(feature)} is available on the Team
            plan
          </h1>
          <p tw="leading-p-140 mt-[0.66rem] text-gray-600">
            Upgrade to the Team plan to add {descriptionFeatureName || feature}{" "}
            to your organization, plus more features like{" "}
            {otherFeatures.slice(0, 3).join(", ")}, and more.
          </p>
        </div>
        <Button onClick={showIntercom} tw="my-5">
          <ChatIcon />
          Chat with us
        </Button>
      </div>
    </TeamAccessNoticeDiv>
  )
}

const TeamAccessNoticeDiv = styled.div`
  ${tw`flex flex-row bg-white rounded-lg border-[#eceef1] border-solid border`}

  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
`

const IconColumn = styled.div`
  ${tw`p-[0.6625rem]`};

  background: linear-gradient(
    180deg,
    #f2f0ff 0%,
    rgba(242, 240, 255, 0) 156.07%
  );
  border-radius: 0px 7px 7px 0px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
`
