import { gql, useQuery } from "@apollo/client"
import React, { useEffect } from "react"
import { Helmet } from "react-helmet-async"
import { Link, useParams } from "react-router-dom"

import { categoryNameToSlug } from "./category"
import LatestPosts from "./LatestPosts"
import { Container } from "./Post.style"
import SidebarMenu from "./SidebarMenu"
import SidebarRelatedPosts from "./SidebarRelatedPosts"

import Fontstand from "@/common/Fontstand"
import { useGlobalState } from "@/common/GlobalState"
import GradientBackground from "@/common/GradientBackground"
import HTMLRenderer from "@/common/HTMLRenderer"
import LayoutContainer from "@/sites/App/Container"
import {
  GetWebflowPostQuery,
  GetWebflowPostQueryVariables,
} from "@/types/graphql-types"

interface Params {
  slug: string
}

const Post: React.FC = () => {
  const [storedEagerLoadedPost] = useGlobalState("eagerLoadedPost")

  const { slug } = useParams<Params>()

  const eagerLoadedPost =
    storedEagerLoadedPost && storedEagerLoadedPost.slug === slug
      ? storedEagerLoadedPost
      : null

  const { data, refetch, loading } = useQuery<
    GetWebflowPostQuery,
    GetWebflowPostQueryVariables
  >(GET_WEBFLOW_POST, {
    variables: { slug },
  })

  useEffect(() => {
    refetch({
      slug,
    })
  }, [slug])

  const post = data?.webflowPost

  const title = (eagerLoadedPost || post)?.title
  const overview = (eagerLoadedPost || post)?.overview
  const excerpt = (eagerLoadedPost || post)?.excerpt
  const metaDescription = overview || excerpt
  const thumbnail = eagerLoadedPost?.thumbnailUrl || post?.thumbnailUrl

  return (
    <>
      <Helmet>
        <title>{title ? `${title} | Goody` : "Blog – Goody"}</title>
        <meta
          name="title"
          property="og:title"
          content={title ? `${title} | Goody` : "Blog – Goody"}
        />
        {metaDescription && (
          <meta name="description" content={metaDescription} />
        )}
        {thumbnail && (
          <meta name="image" property="og:image" content={thumbnail} />
        )}
      </Helmet>
      <Fontstand />
      <Container tw="relative">
        <GradientBackground visible />
        <LayoutContainer>
          <div tw="px-5 pt-8 z-20 relative pt-12">
            <div tw="flex flex-col md:flex-row w-full">
              <div tw="w-full md:w-64">
                <SidebarMenu postSlug={slug} />
              </div>
              <div tw="flex-1">
                {thumbnail ? (
                  <img
                    src={thumbnail}
                    alt={eagerLoadedPost?.title || post?.title}
                    sizes="(max-width: 479px) 90vw, 94vw"
                    className="blog-primary-image blog-primary-image-post"
                  />
                ) : loading ? (
                  <div tw="h-64 bg-gray-200 animate-pulse rounded-xl" />
                ) : null}
                <div className="post-title" tw="pt-8">
                  {post?.category?.name && (
                    <div tw="mb-4">
                      <Link
                        to={`/blog#${categoryNameToSlug(post.category.name)}`}
                        tw="font-medium text-primary-new-550 hover:text-primary-new-600"
                      >
                        {post.category.name}
                      </Link>
                    </div>
                  )}
                  <h1 tw="font-arizona-mix mb-4! mt-0!">
                    {title
                      ? title
                      : loading && (
                          <div tw="h-[1em] bg-gray-200 animate-pulse w-full rounded-lg"></div>
                        )}
                  </h1>
                  {post?.overview && (
                    <div tw="font-arizona-mix mb-4 text-2xl text-gray-500 leading-normal">
                      {post?.overview}
                    </div>
                  )}
                </div>
                <div className="rich-text-block blog-content w-richtext">
                  <HTMLRenderer html={post?.html || ""} />
                </div>
              </div>
              <div tw="xl:w-[24rem] md:hidden xl:block">
                <SidebarRelatedPosts postSlug={slug} />
              </div>
            </div>

            <LatestPosts postSlug={slug} />
          </div>
        </LayoutContainer>
      </Container>
    </>
  )
}

export const GET_WEBFLOW_POST = gql`
  query GetWebflowPost($slug: String!) {
    webflowPost(slug: $slug) {
      title
      thumbnailUrl
      html
      overview
      excerpt
      category {
        name
      }
    }
  }
`

export default Post
