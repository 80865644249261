import update from "immutability-helper"
import { lowerCase } from "lodash-es"
import moment from "moment"
import React from "react"
import tw, { styled } from "twin.macro"

import { ReactComponent as ClearIcon } from "../../assets/icons/icon-clear.svg"
import Button from "../../common/Button"
import { formatPhoneIfPossible, isBlank } from "../../common/format"
import { BatchRecipient } from "../../common/GlobalState"
import { useCurrentGift } from "../../common/hooks/currentGift"
import { useFeatureAccess } from "../../common/hooks/featureAccess"
import { generateEmptyRecipients } from "../../common/recipient"
import RoundedTable from "../../common/RoundedTable"
import CollapsedRecipients from "../components/CollapsedRecipients"
import { EventAttachmentIcon } from "../components/PageBanner"
import RecipientAddressInput from "../components/RecipientAddressInput"
import {
  RecipientFieldError,
  RecipientNameInput,
  RecipientTextInput,
} from "../components/recipientInputs"
import { RecipientPhoneInput } from "../components/RecipientPhoneInput"
import { ReactComponent as DiscardEventIcon } from "../images/x.svg"

import uploadCSV from "@/assets/icons/upload-csv.svg"
import { BatchSendMethod } from "@/types/graphql-types"

interface Props {
  scheduledEventAttached: boolean
  addRecipientRows: (i: number) => void
  clearRecipientEvent: () => void
  recipients: BatchRecipient[]
  setRecipients: (recips: BatchRecipient[]) => void
  collapseRecipients: boolean
  isAutogift: boolean
  showPhoneField?: boolean
  onClickUploadCSV: () => void
}

export default function Recipients({
  scheduledEventAttached,
  addRecipientRows,
  clearRecipientEvent,
  recipients,
  setRecipients,
  collapseRecipients,
  isAutogift,
  showPhoneField,
  onClickUploadCSV,
}: Props) {
  const [currentGift] = useCurrentGift()
  const { hasFeature } = useFeatureAccess()

  const isDirectSend = currentGift.sendMethod === BatchSendMethod.direct_send

  if (collapseRecipients) {
    return (
      <CollapsedRecipients
        count={recipients.length}
        recipients={recipients}
        onClearRecipients={() => {
          setRecipients(generateEmptyRecipients(1))
        }}
      />
    )
  }

  return (
    <form
      autoComplete="off"
      onSubmit={(e) => e.preventDefault()}
      tw="flex-col flex"
    >
      <Button
        tw="text-gray-600 mb-6 lg:mb-0 lg:mx-6 lg:mt-6 self-stretch shadow-none hover:shadow-none flex flex-row justify-center gap-2"
        onClick={(e) => {
          e.preventDefault()
          onClickUploadCSV()
        }}
      >
        <img src={uploadCSV} alt="Upload CSV" />
        Upload CSV
      </Button>
      {recipients.length > 0 ? (
        <RecipientsTable isDirectSend={isDirectSend} tw="relative z-[1]">
          <tbody>
            {recipients.map((recipient, i) => {
              const emailAndPhoneInput = (
                <>
                  <RecipientTextInput
                    value={recipient.email}
                    placeholder={
                      isDirectSend && !currentGift.landingPageSendNotifs
                        ? "Email (optional)"
                        : "Email"
                    }
                    autoComplete="none"
                    onChange={(e) => {
                      setRecipients(
                        update(recipients, {
                          [i]: {
                            email: { $set: e.target.value },
                            errors: {
                              email: { $set: null },
                            },
                          },
                        }),
                      )
                    }}
                    className={
                      recipient.errors.email
                        ? "field-error data-hj-suppress ph-no-capture fs-exclude"
                        : "data-hj-suppress ph-no-capture fs-exclude"
                    }
                    disabled={isAutogift}
                  />
                  {recipient.errors.email && (
                    <RecipientFieldError>
                      {recipient.errors.email}
                    </RecipientFieldError>
                  )}
                  {showPhoneField || !isBlank(recipient.phone) ? (
                    <>
                      <div tw="mt-2" />
                      <RecipientPhoneInput
                        recipient={recipient}
                        onChange={(e) => {
                          const out = formatPhoneIfPossible(e.target.value)
                          setRecipients(
                            update(recipients, {
                              [i]: {
                                phone: { $set: out },
                                errors: {
                                  phone: { $set: null },
                                },
                              },
                            }),
                          )
                        }}
                        onKeyDown={(e) => {
                          // Allow tabbing after the last input to create a new row.
                          if (
                            e.key === "Tab" &&
                            i === recipients.length - 1 &&
                            hasFeature("pro_plan")
                          ) {
                            addRecipientRows(1)
                          }
                        }}
                        disabled={isAutogift}
                      />
                      {recipient.errors.phone && (
                        <RecipientFieldError>
                          {recipient.errors.phone}
                        </RecipientFieldError>
                      )}
                    </>
                  ) : null}
                  {i === 0 && !currentGift.landingPageSendNotifs ? (
                    <div tw="pt-3 pl-4 text-sm text-gray-500">
                      Notifications are disabled. You can add an email or phone
                      for internal purposes, but they won’t receive any
                      notifications.
                    </div>
                  ) : null}
                </>
              )

              return (
                <React.Fragment key={recipient.id}>
                  <tr id={`recipient-row-${i}`}>
                    <td tw="text-center p-4 py-3 flex items-center items-start">
                      {!scheduledEventAttached && (
                        <div tw="absolute right-0 lg:top-9 lg:(right-[-60px] p-4 top-5)">
                          <ClearRecipientButton
                            onClick={() => {
                              setRecipients(
                                update(recipients, {
                                  $splice: [[i, 1]],
                                }),
                              )
                            }}
                          />
                        </div>
                      )}
                      <div tw="lg:(absolute left-[-120px] w-[100px] text-right top-9 text-gray-500)">
                        {i + 1}
                      </div>
                    </td>
                    <td>
                      <div tw="flex flex-row items-center gap-2 w-full">
                        <div tw="flex-1">
                          <RecipientNameInput
                            value={recipient.firstName}
                            placeholder="First name"
                            autoComplete="none"
                            onChange={(e) => {
                              setRecipients(
                                update(recipients, {
                                  [i]: {
                                    firstName: { $set: e.target.value },
                                  },
                                }),
                              )
                            }}
                            css={[tw`rounded-lg w-full`]}
                            className={
                              recipient.errors.firstName
                                ? "field-error data-hj-suppress ph-no-capture fs-exclude"
                                : "data-hj-suppress ph-no-capture fs-exclude"
                            }
                            disabled={isAutogift}
                          />
                          {recipient.errors.firstName && (
                            <RecipientFieldError>
                              {recipient.errors.firstName}
                            </RecipientFieldError>
                          )}
                        </div>
                        <div tw="flex-1">
                          <RecipientNameInput
                            value={recipient.lastName}
                            placeholder="Last name"
                            autoComplete="none"
                            onChange={(e) => {
                              setRecipients(
                                update(recipients, {
                                  [i]: {
                                    lastName: { $set: e.target.value },
                                  },
                                }),
                              )
                            }}
                            css={[tw`rounded-lg w-full`]}
                            className={
                              recipient.errors.lastName
                                ? "field-error data-hj-suppress ph-no-capture fs-exclude"
                                : "data-hj-suppress ph-no-capture fs-exclude"
                            }
                            disabled={isAutogift}
                          />
                          {recipient.errors.lastName && (
                            <RecipientFieldError>
                              {recipient.errors.lastName}
                            </RecipientFieldError>
                          )}
                        </div>
                      </div>
                      {isDirectSend ? (
                        <div tw="pt-6">
                          <RecipientAddressInput
                            sendV3={true}
                            recipient={recipient}
                            setField={(field: string, value: string) => {
                              const recip = update(recipients, {
                                [i]: {
                                  mailingAddress: {
                                    $apply: function (
                                      mailingAddress?: BatchRecipient["mailingAddress"],
                                    ) {
                                      return {
                                        ...(mailingAddress || {
                                          address1: "",
                                          address2: null,
                                          city: "",
                                          state: "",
                                          postalCode: "",
                                        }),
                                        [field]: value,
                                      }
                                    },
                                  },
                                  // We don't clear errors here since we don't
                                  // clear errors on the recipient errors area
                                  // until the calculate button is clicked again
                                } as any,
                              })

                              setRecipients(recip)
                            }}
                            setFields={(
                              fields: BatchRecipient["mailingAddress"],
                            ) => {
                              const recip = update(recipients, {
                                [i]: {
                                  mailingAddress: { $set: fields },
                                  errors: {
                                    mailingAddress: { $set: null },
                                  },
                                } as any,
                              })

                              setRecipients(recip)
                            }}
                          />
                        </div>
                      ) : null}
                      {isDirectSend && (
                        <div tw="pt-6">{emailAndPhoneInput}</div>
                      )}
                      {scheduledEventAttached && (
                        <RecipientEventAttachmentContent
                          recipient={recipient}
                          onClick={() => clearRecipientEvent()}
                        />
                      )}
                    </td>
                    {!isDirectSend && <td>{emailAndPhoneInput}</td>}
                  </tr>
                  {scheduledEventAttached && (
                    <RecipientEventAttachmentRow
                      className="event-attachment"
                      recipient={recipient}
                      onClick={() => clearRecipientEvent()}
                    />
                  )}
                </React.Fragment>
              )
            })}
          </tbody>
        </RecipientsTable>
      ) : (
        <div tw="h-6" />
      )}
    </form>
  )
}

interface ClearRecipientButtonProps {
  onClick: React.MouseEventHandler
  className?: string
}
const ClearRecipientBaseButton: React.FC<ClearRecipientButtonProps> = ({
  onClick,
  className,
}) => (
  <button className={className} type="button" onClick={onClick}>
    <ClearIcon />
  </button>
)

const ClearRecipientButton = styled(ClearRecipientBaseButton)`
  ${tw`z-10 p-2 rounded bg-none text-primary-300 hover:bg-primary-100 transition-colors`}

  svg {
    ${tw`stroke-current`}
  }
`

const RecipientsTable = styled(RoundedTable)<{
  isDirectSend: boolean
}>`
  ${tw`border-0`}

  thead tr {
    ${tw`last:mb-0`};
  }

  thead {
    ${tw`border-b`}
  }

  tr {
    ${tw`relative mb-14 lg:mb-0 rounded-xl lg:rounded-none shadow-1.5xl lg:shadow-none p-4 lg:p-0 border-b-0`}

    &:not(.event-attachment) {
      grid-template-columns: ${(props) =>
        props.isDirectSend ? "0% 1fr" : "0% 1.5fr 1.5fr"};

      &:hover ${ClearRecipientButton} {
        ${tw`opacity-100 scale-100`}
      }
    }

    &:not(.row-header):not(.event-attachment) {
      ${tw`border-t first:border-t-0`}
    }

    &.event-attachment {
      @media only screen and (max-width: 1023px) {
        ${tw`hidden`}
      }
    }
  }

  td.event-attachment {
    ${tw`pt-0 pb-6`};

    @media only screen and (min-width: 1024px) {
      &:not(.row) {
        ${tw`hidden`}
      }
    }

    @media only screen and (max-width: 1023px) {
      ${tw`flex justify-center w-full py-4`}

      grid-column: span 2;
    }
  }

  @media only screen and (max-width: 1079px) {
    tr {
      &:not(.event-attachment) {
        ${tw`mt-20 first:mt-10`};

        grid-template-columns: 1fr 1fr;

        ${ClearRecipientButton} {
          ${tw`mr-3 text-gray-400 opacity-100 scale-100`}

          &:before {
            ${tw`absolute top-0 bottom-0 right-7`};
            content: "remove";
            line-height: 22px;
          }

          &:focus {
            outline: none;
          }

          &:hover {
            background: none;
          }
        }

        /**
          This creates layer on top of the gray section that contains the recipient
          number and "remove" button. This is necessary in order to create the effect
          of the card being on top of the gray section.
         */
        &:before {
          ${tw`absolute bg-white h-4 w-full z-20`};
          content: "";
        }
      }
    }

    tbody td {
      ${tw`bg-white p-1.5 z-30`};
      padding: 0.375rem;

      &:nth-child(1) {
        ${tw`absolute left-0 right-0 z-10 pl-6 text-gray-500 bg-gray-100 h-14 rounded-t-xl -top-10`};
        padding-bottom: 22px;

        &:before {
          content: "#";
        }
      }

      /**
        The padding-left on nth-child(2) and padding-right on nth-child(3) was needed
        in order to hide the bottom part of the shadow of the layer created to be on top
        of the gray recipient number section.
       */
      &:nth-child(2) {
        ${tw`-ml-4`};
        grid-column: span 2;
        padding-left: 22px;
      }
      &:nth-child(3) {
        grid-column: span 2;
      }
      &:nth-child(4) {
        grid-column: span 2;
      }
      &:nth-child(5) {
        ${tw`relative h-12 px-0 mt-2`}
        grid-column: span 2;
      }
    }
  }

  tbody {
    ${tw`border-0`}
  }

  thead {
    ${tw`hidden lg:block`}
  }

  // Index
  td:nth-child(1) {
    ${tw`justify-start text-black lg:justify-center`};
  }

  // Name
  td,
  th {
    &:nth-child(2) {
      ${tw`pr-1 pl-6`};

      ${(props) => props.isDirectSend && tw`lg:pr-6`};
    }
  }

  // Email
  td,
  th {
    &:nth-child(3) {
      ${tw`lg:pr-6`}
    }
  }

  ${ClearRecipientButton} {
    ${tw`mr-2 opacity-0 transition-all duration-75 transform scale-0`}
  }

  th {
    font-weight: 500;
  }
`

const EventAttachment = styled.div`
  ${tw`flex items-center px-4 py-2 bg-white rounded-lg text-primary-500`};

  box-shadow:
    0px 1px 4px rgba(79, 31, 137, 0.06),
    0px 4px 12px rgba(79, 31, 137, 0.08);
`

interface RecipientEventAttachmentProps {
  onClick: React.MouseEventHandler
  recipient: BatchRecipient
  className?: string
}

const RecipientEventAttachmentRowBase: React.FC<
  RecipientEventAttachmentProps
> = ({ onClick, recipient, className }) => {
  return recipient.scheduledEventKind ? (
    <tr className={className}>
      <td />
      <RecipientEventAttachmentContent
        onClick={onClick}
        recipient={recipient}
        className="row"
      />
    </tr>
  ) : (
    <></>
  )
}

const RecipientEventAttachmentContent: React.FC<
  RecipientEventAttachmentProps
> = ({ onClick, recipient, className }) => {
  const eventInPast = moment(recipient.eventDate).isBefore(moment(), "day")
  const eventInOverYear = moment(recipient.eventDate).isAfter(
    moment().add(1, "years"),
    "day",
  )

  const eventNotUpcoming =
    eventInPast || eventInOverYear
      ? ` on ${moment(recipient.eventDate).format("MMM D, YYYY")}${
          eventInPast ? " (past)" : ""
        }`
      : ""

  return (
    <td className={`event-attachment ${className ? className : ""}`}>
      <EventAttachment>
        <EventAttachmentIcon eventKind={recipient.scheduledEventKind} />
        <div tw="pl-3 pr-7" className="data-hj-suppress ph-no-capture fs-mask">
          {`Gift for ${recipient.firstName} ${recipient.lastName}’s ${lowerCase(
            recipient.scheduledEventKind || "",
          )}${eventNotUpcoming}`}
        </div>
        <button onClick={onClick}>
          <DiscardEventIcon />
        </button>
      </EventAttachment>
    </td>
  )
}

const RecipientEventAttachmentRow = styled(RecipientEventAttachmentRowBase)`
  ${tw`grid`}

  grid-template-columns: 5% max-content;
`
