import React from "react"

import { generateRealmPath } from "../common/realm"
import BudgetingDeposit from "../organization/budgeting/BudgetingDeposit"

import { BalanceDepositAccount } from "@/types/graphql-types"

export const BalanceDeposit = () => (
  <BudgetingDeposit
    title="Add to balance"
    subtitle="Pre-fund your balance to simplify receipts."
    donePath={generateRealmPath("plus", "/balance")}
    accountType={BalanceDepositAccount.user}
    backText="Back to Balance"
  />
)
