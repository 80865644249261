import { gql } from "@apollo/client"

import { PRODUCT_FRAGMENT_FRAGMENT } from "./hooks/gifts"

import { IMAGE_FRAGMENT, VARIANT_GROUP_FRAGMENT } from "@/graphql"
import { GIFT_SETTINGS_FRAGMENT } from "@/graphql/GiftSettingsFragment"
import { SHIPPING_COUNTRY_GROUP_FRAGMENT } from "@/graphql/ShippingCountryGroupFragment"

export const GIFT_BATCH_PRODUCT_FRAGMENT = gql`
  fragment GiftBatch_ProductFragment on Product {
    id
    name
    slug
    price
    brand {
      id
      slug
      name
      shippingPrice
      hideMadeBySubtitle
      freeShippingMinimum
      segments
    }
    productImages {
      imageLarge {
        ...Image
      }
      imageThumb {
        ...Image
      }
      imageXlarge {
        ...Image
      }
    }
    cartEligible
    isFlexGift
    isShippingTierDisabled
    isBook
    isAlcohol
    forceSwapEnabled
    customStore {
      hasCreditExcludedItems
    }
    swapStoreSettings {
      priceType
    }
    giftCardInfo {
      currency
    }
    imagesScalable
    ecommerceIntegrationType
    shippingCountryGroup {
      ...ShippingCountryGroup
    }
  }
  ${IMAGE_FRAGMENT}
  ${SHIPPING_COUNTRY_GROUP_FRAGMENT}
`

export const BASE_GIFT_BATCH_FRAGMENT = gql`
  fragment Base_GiftBatch on GiftBatch {
    id
    sendStatus
    fromName
    message
    flexGiftPrice
    internationalShippingTier
    settings {
      ...GiftSettings
    }
    customEmailSubject
    card {
      id
      name
      image {
        url
      }
    }
    customStoreImageThumb {
      ...Image
    }
    giftCardAmount
    cartProducts {
      id
      quantity
      productTitle
      product {
        ...GiftBatch_ProductFragment
      }
      productDetails {
        isBook
      }
      productFragment {
        ...ProductFragment
      }
      bookResult {
        contributors
        imageAvailable
        imageLarge
        title
        isbn
        ean
        price
      }
      presetVariants
    }
  }
  ${GIFT_BATCH_PRODUCT_FRAGMENT}
  ${PRODUCT_FRAGMENT_FRAGMENT}
  ${IMAGE_FRAGMENT}
  ${GIFT_SETTINGS_FRAGMENT}
`

export const DETAILS_PRODUCT_FRAGMENT = gql`
  fragment Details_Product on Product {
    id
    brand {
      id
      brandValues
      name
      logoImageUrl
      logoImage {
        ...Image
      }
      slug
      subtitle
      hideMadeBySubtitle
      shippingPrice
      freeShippingMinimum
      segments
    }
    shippingCountriesFinal
    shippingCountryGroup {
      ...ShippingCountryGroup
    }
    status
    description
    isAlcohol
    name
    price
    cartEligible
    productAttributes {
      label
      content
      displayType
    }
    productImages {
      id
      imageLarge {
        ...Image
      }
      imageXlarge {
        ...Image
      }
      imageThumb {
        ...Image
      }
    }
    shippingPrice
    shippingStates
    slug
    subtitle
    subtitleShort
    variants {
      name
      subtitle
      imageMedium {
        ...Image
      }
      imageThumb {
        ...Image
      }
    }
    variantGroups {
      ...VariantGroup
    }
    variantsLabel
    variantsLabelDowncase
    variantsNumSelectable
    isAlcohol
    isBook
    isFlexGift
    isShippingTierDisabled
    isGiftCollection
    swapStoreHeaderImage {
      url
    }
    customSwapMinimumPrice
    swapStoreSettings {
      showCategories
      priceType
    }
    forceSwapEnabled
    customStore {
      hasCreditExcludedItems
    }
    swagIntegration {
      id
    }
    swagIntegrationActions
    isOfflineProduct
    hasVariablePricing
    imagesScalable
    ecommerceIntegrationType
  }
  ${IMAGE_FRAGMENT}
  ${VARIANT_GROUP_FRAGMENT}
  ${SHIPPING_COUNTRY_GROUP_FRAGMENT}
`
