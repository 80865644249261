import React from "react"
import tw, { styled } from "twin.macro"

import { ArrowOpen } from "../../assets/icons"

import { StandardCardFragment } from "@/types/graphql-types"

type Props = {
  card: StandardCardFragment
  onClick: () => void
} & React.ComponentProps<"button">

// Button to preview gift with card as the image
const CardPreview: React.FC<Props> = ({ card, onClick, css, ...restProps }) => {
  return card.imageLarge?.url ? (
    <button
      css={[tw`flex flex-col hover:opacity-75 transition-opacity`, css]}
      onClick={onClick}
      {...restProps}
    >
      <CardImage src={card.imageLarge.url} />
      <div
        tw="flex flex-row rounded bg-primary-500 text-white self-center text-sm z-10 items-center px-2"
        css="height: 28px; margin-top: -14px"
      >
        <ArrowOpen />
        <div tw="ml-0.5">Open card</div>
      </div>
    </button>
  ) : null
}

const CardImage = styled.img`
  ${tw`rounded-lg`}
  width: 114px;
  min-width: 114px;
  height: 160px;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.06))
    drop-shadow(0px 8px 30px rgba(0, 0, 0, 0.14));
`

export default CardPreview
