import React from "react"
import tw, { styled } from "twin.macro"

import CartIcon from "./CartIcon"
import { gradientStyle } from "../../common/GradientButton"

interface Props {
  openCart: () => void
  cartItemCount: number
}

const CartButtonBase = ({ openCart, cartItemCount }: Props) => {
  const hasItems = cartItemCount > 0

  return (
    <Container hasItems={hasItems} onClick={openCart}>
      {hasItems && <div tw="w-4" />}
      <CartIcon hasItems={hasItems} />
      {hasItems && <QuantityIcon>{cartItemCount}</QuantityIcon>}
    </Container>
  )
}

const QuantityIcon = styled.div`
  ${tw`h-8 w-8 flex items-center justify-center m-2 rounded-full text-white text-base font-semibold`}
  background-color: rgba(36, 11, 147, .3);
  box-shadow: 0px 2px 4px rgba(45, 24, 173, 0.2);
`

const Container = styled.button<{ hasItems: boolean }>`
  ${({ hasItems }) =>
    hasItems
      ? gradientStyle
      : tw`border-primary-new-100 border hover:bg-primary-new-000 active:(scale-95) transition-all`}
  ${tw`h-12 rounded-full flex items-center justify-center flex-row p-0 min-w-[3rem] lg:ml-0`}
`

export default CartButtonBase
