type EnvironmentType =
  | "development"
  | "staging"
  | "sandbox"
  | "production"
  | "bot"

interface EnvironmentInfo {
  apiUrl: string
  giftsUrl: string
  stripeApiKey: string
  googleClientId: string
  sentryDsn: string | null
  intercomAppId: string
  vgsVaultId: string
  vgsCname: string
  name: EnvironmentType
  checkoutUrl: string
  businessUrl: string
  ripplingAppUrl: string
  amplitudeApiKey: string
  statsigSdkKey: string
  statsigEnvironment: string
  fullstoryOrgId: string | null
  recommenderExplainUrl: string
  frigadeKey: string
}

export const env = import.meta.env

// The same Statsig SDK key is used for all environments. statsigEnvironment
// segments production from non-production.
const STATSIG_SDK_KEY = "client-MHyWOfqYMo10Jo5bV9wqgC5HhjVE9IJty7HVttOHEdo"

const environments: { [id in EnvironmentType]: EnvironmentInfo } = {
  development: {
    apiUrl: env.VITE_API_URL || "http://localhost:3500",
    giftsUrl: "http://localhost:3500",
    stripeApiKey: env.VITE_STRIPE_API_KEY || "",
    googleClientId: env.VITE_GOOGLE_CLIENT_ID || "",
    sentryDsn: null,
    intercomAppId: "cdesrus0",
    vgsVaultId: "tntkleyrkdc",
    vgsCname: "vgs-test-dev.ongoody.com",
    name: "development",
    checkoutUrl: "http://localhost:3500",
    businessUrl: "http://localhost:3000",
    ripplingAppUrl: "https://app.ripplingsandbox.com/apps/PLATFORM/Goody",
    amplitudeApiKey: "68fce41a9ebea054c461462dafbc0d49",
    statsigSdkKey: STATSIG_SDK_KEY,
    statsigEnvironment: "development",
    fullstoryOrgId: null,
    recommenderExplainUrl: "http://localhost:3001/api/completion",
    frigadeKey:
      "api_public_q0xSQ79RWm8fV7STJqEU78DeiufokdmPnRSPXHZsKIqSxZLVt4xs9vuMuLaJOvGP",
  },
  staging: {
    apiUrl: "https://app.staging.ongoody.com",
    giftsUrl: "https://app.staging.ongoody.com",
    googleClientId: env.VITE_GOOGLE_CLIENT_ID || "",
    stripeApiKey:
      "pk_test_51HHgifEBhdl1PooM1Uc8VI5B4zFIQG054sqmHjM0Wxw7ghPtJXUMRheAD79R1QbjkwO7ilXWdMhSJNbOvhoBDnwJ00z8pPuvOj",
    sentryDsn:
      "https://91cc571c28a2343b07926bfcff12bd85@o430500.ingest.us.sentry.io/4506662453182464",
    intercomAppId: "r1zyaep2",
    vgsVaultId: "tntm0hrbivk",
    vgsCname: "vgs-staging.ongoody.com",
    name: "staging",
    checkoutUrl: "https://app.staging.ongoody.com",
    businessUrl: "https://staging.ongoody.com",
    ripplingAppUrl: "https://app.ripplingsandbox.com/apps/PLATFORM/Goody",
    amplitudeApiKey: "2c2c3de7395cd5a0e3eaff2aa658370c",
    statsigSdkKey: STATSIG_SDK_KEY,
    statsigEnvironment: "staging",
    fullstoryOrgId: null,
    recommenderExplainUrl: "http://localhost:3001/api/completion",
    frigadeKey:
      "api_public_q0xSQ79RWm8fV7STJqEU78DeiufokdmPnRSPXHZsKIqSxZLVt4xs9vuMuLaJOvGP",
  },
  sandbox: {
    apiUrl: "https://api.sandbox.ongoody.com",
    giftsUrl: "https://api.sandbox.ongoody.com",
    googleClientId: env.VITE_GOOGLE_CLIENT_ID || "",
    stripeApiKey:
      "pk_test_51HHgifEBhdl1PooM1Uc8VI5B4zFIQG054sqmHjM0Wxw7ghPtJXUMRheAD79R1QbjkwO7ilXWdMhSJNbOvhoBDnwJ00z8pPuvOj",
    sentryDsn:
      "https://a379ea793a4a40db90be100fa47e68ab@o430500.ingest.sentry.io/5787373",
    intercomAppId: "r1zyaep2",
    vgsVaultId: "tntfizwd8io",
    vgsCname: "vgs-sandbox.ongoody.com",
    name: "sandbox",
    checkoutUrl: "https://api.sandbox.ongoody.com",
    businessUrl: "https://sandbox.ongoody.com",
    ripplingAppUrl: "https://app.ripplingsandbox.com/apps/PLATFORM/Goody",
    amplitudeApiKey: "2c2c3de7395cd5a0e3eaff2aa658370c",
    statsigSdkKey: STATSIG_SDK_KEY,
    statsigEnvironment: "sandbox",
    fullstoryOrgId: "Y6ZCY",
    recommenderExplainUrl: "http://localhost:3001/api/completion",
    frigadeKey:
      "api_public_q0xSQ79RWm8fV7STJqEU78DeiufokdmPnRSPXHZsKIqSxZLVt4xs9vuMuLaJOvGP",
  },
  production: {
    apiUrl: "https://api.ongoody.com",
    giftsUrl: "https://gifts.ongoody.com",
    googleClientId: env.VITE_GOOGLE_CLIENT_ID || "",
    stripeApiKey:
      "pk_live_51HHgifEBhdl1PooMqYyQab5kZ5ZXSyn7xNnJe7X0SxaBBoUTk7jOpXn3ntYBHWX1tp0NSNFfpUa4QcptsvBzoNYv00LiU6KoFX",
    sentryDsn:
      "https://98c1c2446d1b4002ae37dff0826297db@o430500.ingest.sentry.io/5678397",
    intercomAppId: "dxwm9nze",
    vgsVaultId: "tnt4fsnx8xx",
    vgsCname: "vgs-production.ongoody.com",
    name: "production",
    checkoutUrl: "https://checkout.ongoody.com",
    businessUrl: "https://ongoody.com",
    ripplingAppUrl: "https://app.rippling.com/app-shop/app/Goody",
    amplitudeApiKey: "7403460292d284cb5b0b3065e4b31d38",
    statsigSdkKey: STATSIG_SDK_KEY,
    statsigEnvironment: "production",
    fullstoryOrgId: "Y6ZCY",
    recommenderExplainUrl:
      "https://recommender-explain.vercel.app/api/completion",
    frigadeKey:
      "api_public_qRLrJt31dVZrzQYswWcRpdPBv1VeKFalCWs5n4ojtrxhmwT0WaTGZBJzEOZBwEtb",
  },
  bot: {
    apiUrl: "https://api.ongoody.com",
    giftsUrl: "https://gifts.ongoody.com",
    stripeApiKey:
      "pk_test_51HHgifEBhdl1PooM1Uc8VI5B4zFIQG054sqmHjM0Wxw7ghPtJXUMRheAD79R1QbjkwO7ilXWdMhSJNbOvhoBDnwJ00z8pPuvOj",
    googleClientId: "",
    sentryDsn: "",
    intercomAppId: "",
    vgsVaultId: "",
    vgsCname: "vgs-production.ongoody.com",
    name: "bot",
    checkoutUrl: "https://checkout.ongoody.com",
    businessUrl: "https://ongoody.com",
    ripplingAppUrl: "https://app.rippling.com/app-shop/app/Goody",
    amplitudeApiKey: "",
    statsigSdkKey: STATSIG_SDK_KEY,
    statsigEnvironment: "bot",
    fullstoryOrgId: null,
    recommenderExplainUrl: "http://localhost:3001/api/completion",
    frigadeKey:
      "api_public_q0xSQ79RWm8fV7STJqEU78DeiufokdmPnRSPXHZsKIqSxZLVt4xs9vuMuLaJOvGP",
  },
}

export function getEnvVars() {
  if (env.PROD) {
    if (isBot()) {
      return environments["bot"]
    } else if (window.location.origin === "https://sandbox.ongoody.com") {
      return environments["sandbox"]
    } else if (window.location.origin === "https://staging.ongoody.com") {
      return environments["staging"]
    } else {
      return environments["production"]
    }
  }

  return environments["development"]
}

export const isBot = () =>
  window.navigator.userAgent.match(/(Prerender|HeadlessChrome|PhantomJS)/i)
