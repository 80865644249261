import React from "react"

import Button from "@/common/Button"
import Image from "@/common/Image"

interface UpsellSectionProps {
  title: string
  subtext: string
  icon: string
  cta: string
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

const UpsellSection = ({
  title,
  subtext,
  icon,
  cta,
  onClick,
}: UpsellSectionProps) => (
  <div tw="mx-6 pt-6 mb-6">
    <div tw="mb-5">
      <h1 tw="text-primary-new-550 text-xl leading-[1.5rem] font-semibold mb-3">
        {title}
      </h1>
      <p tw="leading-p-140 text-gray-600">{subtext}</p>
    </div>
    <Button
      onClick={onClick}
      tw="px-4 py-2 flex flex-row items-center border border-primary-100 text-gray-800"
      css={["box-shadow: 0px 2px 4px 0px rgba(228, 216, 244, 0.30)"]}
    >
      <Image src={icon} />
      <p tw="leading-[21.28px]">{cta}</p>
    </Button>
  </div>
)

export default UpsellSection
