import { useState } from "react"

import GiftOptionShippingLabel from "./GiftOptionShippingLabel"

import { useFeatureAccess } from "@/common/hooks/featureAccess"
import { DEFAULT_COUNTRY } from "@/common/hooks/useShippingCountries"
import DomesticShippingModal from "@/store/components/modals/DomesticShippingModal"
import GlobalShippingModal from "@/store/components/modals/GlobalShippingModal"
import { DomesticShippingTag } from "@/store/components/shipping/DomesticShippingTag"
import { GlobalShippingTag } from "@/store/components/shipping/GlobalShippingTag"
import { useShippingCountriesSelector } from "@/store/hooks/useShippingCountriesSelector"
import { GiftOptionType } from "@/store/types"

interface Props {
  giftOption: GiftOptionType
}

const GiftOptionShipping = ({ giftOption }: Props) => {
  const hasProPlan = useFeatureAccess().hasFeature("pro_plan")
  const { selectedShippingCountry, findCountryByCode } =
    useShippingCountriesSelector()

  const [openDomesticModal, setOpenDomesticModal] = useState(false)
  const [openGlobalModal, setOpenGlobalModal] = useState(false)

  const showShippingTag = selectedShippingCountry.code !== DEFAULT_COUNTRY.code
  const domesticCountries = giftOption.shippingCountryGroup.domestic?.filter(
    (code) => code !== DEFAULT_COUNTRY.code,
  )
  const domesticCountry = domesticCountries?.includes(
    selectedShippingCountry.code,
  )
    ? selectedShippingCountry
    : findCountryByCode(domesticCountries?.[0])
  const displayGlobalShipping =
    showShippingTag &&
    giftOption.shippingCountryGroup.global?.includes(
      selectedShippingCountry.code,
    )
  const displayDomesticShipping =
    showShippingTag &&
    !!domesticCountries?.length &&
    domesticCountry &&
    !displayGlobalShipping

  const swapStoreSettings = giftOption.isFlexGift
    ? giftOption.products[0]?.swapStoreSettings
    : null

  return (
    <>
      {showShippingTag && (
        <div tw="pt-6">
          {displayDomesticShipping && (
            <DomesticShippingTag
              country={domesticCountry}
              onClick={() => setOpenDomesticModal(true)}
            />
          )}
          {displayGlobalShipping && (
            <GlobalShippingTag
              showProUpsell={!hasProPlan}
              onClick={() => setOpenGlobalModal(true)}
            />
          )}
        </div>
      )}

      <GiftOptionShippingLabel
        giftOption={giftOption}
        swapStorePriceType={swapStoreSettings?.priceType || null}
        withGlobalShipping={displayGlobalShipping}
        withDomesticShipping={!!domesticCountries?.length}
      />

      <DomesticShippingModal
        open={openDomesticModal}
        onClose={() => setOpenDomesticModal(false)}
      />
      <GlobalShippingModal
        open={openGlobalModal}
        onClose={() => setOpenGlobalModal(false)}
        withProUpsell={!hasProPlan}
      />
    </>
  )
}

export default GiftOptionShipping
