import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import tw, { styled } from "twin.macro"

import { RealmPersonal, RealmPlus } from "../../assets/icons"
import { useGlobalState } from "../../common/GlobalState"
import { Realm, generateRealmPath, useSetRealm } from "../../common/realm"

import { PlusEnrollmentStatus } from "@/types/graphql-types"

// The width of the realm toggle button differs between signed out and signed in.
// The signed out version includes the suffix "Gifting".
// This width considers only the unselected button, which does not include
// the icon which is only shown with the selected button.
export const REALM_TOGGLE_BUTTON_WIDTH_SIGNED_IN = 82
export const REALM_TOGGLE_BUTTON_WIDTH_SIGNED_OUT = 134

// The padding inside each button is 0.75rem on the left and right sides.
export const REALM_TOGGLE_BUTTON_INNER_PADDING = 16 * 0.75 * 2

// The icon width is 18px + 8px total padding.
const REALM_TOGGLE_ICON_WIDTH = 26

// The gap between the two buttons is 4px.
const REALM_TOGGLE_BUTTON_GAP = 4

// The total width of the entire component for signed out / signed in
export const REALM_TOGGLE_TOTAL_WIDTH_SIGNED_IN =
  REALM_TOGGLE_BUTTON_WIDTH_SIGNED_IN * 2 +
  REALM_TOGGLE_ICON_WIDTH +
  REALM_TOGGLE_BUTTON_GAP
export const REALM_TOGGLE_TOTAL_WIDTH_SIGNED_OUT =
  REALM_TOGGLE_BUTTON_WIDTH_SIGNED_OUT * 2 +
  REALM_TOGGLE_ICON_WIDTH +
  REALM_TOGGLE_BUTTON_GAP

export const useRealmRedirect = (
  onSetRealm?: (realm: Realm | null) => void,
) => {
  const [currentRealm] = useGlobalState("currentRealm")
  const [enrollmentStatus] = useGlobalState("enrollmentStatus")
  const history = useHistory()
  const setRealm = useSetRealm()

  const onPersonalClick = () => {
    if (currentRealm !== "consumer") {
      onSetRealm?.call(null, "consumer")
      setRealm("consumer")
      if (
        !history.location.pathname.startsWith(
          generateRealmPath("business", "/browse"),
        )
      ) {
        history.push(generateRealmPath("consumer", "/"))
      }
    }
  }

  const onBusinessClick = () => {
    if (currentRealm !== "plus" && currentRealm !== "business") {
      if (enrollmentStatus === PlusEnrollmentStatus.NONE) {
        history.push(generateRealmPath("business", "/"))
        onSetRealm?.call(null, "business")
        setRealm("business")
      } else {
        if (
          !history.location.pathname.startsWith(
            generateRealmPath("business", "/browse"),
          )
        ) {
          history.push(generateRealmPath("plus", "/send"))
        }

        setRealm("plus")
        onSetRealm?.call(null, "plus")
      }
    }
  }

  return { onPersonalClick, onBusinessClick }
}

const RealmToggle: React.FC = () => {
  const [currentRealm] = useGlobalState("currentRealm")
  const [signedIn] = useGlobalState("signedIn")

  // Local variable for faster animation on toggle
  const [localCurrentRealm, setLocalCurrentRealm] = useState<Realm | null>(
    currentRealm,
  )

  useEffect(() => {
    setLocalCurrentRealm(currentRealm)
  }, [currentRealm, setLocalCurrentRealm])

  const { onPersonalClick, onBusinessClick } =
    useRealmRedirect(setLocalCurrentRealm)

  // Effective state is either consumer or business (plus is included in business), or null.
  const effectiveState =
    localCurrentRealm === "plus" ? "business" : localCurrentRealm

  const [button0Hovered, setButton0Hovered] = useState(false)
  const [button1Hovered, setButton1Hovered] = useState(false)
  const hoveredButton = button0Hovered ? 0 : button1Hovered ? 1 : null

  // Whether the indicated button (assumed to be the hovered button) is currently pressed.
  const [indicatedIsPressed, setIndicatedIsPressed] = useState(false)

  // The indicated button is whatever has the indicator background behind it.
  const indicatedButton =
    hoveredButton !== null
      ? hoveredButton
      : effectiveState === "consumer"
      ? 1
      : 0

  // The text width is the width of the button without the inner padding.
  const buttonTextWidth =
    (signedIn
      ? REALM_TOGGLE_BUTTON_WIDTH_SIGNED_IN
      : REALM_TOGGLE_BUTTON_WIDTH_SIGNED_OUT) -
    REALM_TOGGLE_BUTTON_INNER_PADDING

  return (
    <StyledToggle>
      <button
        onClick={onBusinessClick}
        onMouseEnter={() => setButton0Hovered(true)}
        onMouseLeave={() => {
          setButton0Hovered(false)
          setIndicatedIsPressed(false)
        }}
        className={effectiveState === "business" ? "selected" : undefined}
        onMouseDown={() => setIndicatedIsPressed(true)}
        onMouseUp={() => setIndicatedIsPressed(false)}
      >
        <div
          css={{
            width: effectiveState === "business" ? REALM_TOGGLE_ICON_WIDTH : 0,
            overflow: "hidden",
            transition: "all 0.2s ease-out",
          }}
        >
          <RealmPlus tw="stroke-current" />
        </div>
        <div
          css={{
            width: buttonTextWidth,
          }}
        >
          Business{signedIn ? "" : " Gifting"}
        </div>
      </button>
      <button
        onClick={onPersonalClick}
        onMouseEnter={() => setButton1Hovered(true)}
        onMouseLeave={() => {
          setButton1Hovered(false)
          setIndicatedIsPressed(false)
        }}
        className={effectiveState === "consumer" ? "selected" : undefined}
        onMouseDown={() => setIndicatedIsPressed(true)}
        onMouseUp={() => setIndicatedIsPressed(false)}
      >
        <div
          css={{
            width: effectiveState === "consumer" ? REALM_TOGGLE_ICON_WIDTH : 0,
            overflow: "hidden",
            transition: "all 0.2s ease-out",
          }}
        >
          <RealmPersonal tw="stroke-current" />
        </div>
        <div
          css={{
            width: buttonTextWidth,
          }}
        >
          Personal{signedIn ? "" : " Gifting"}
        </div>
      </button>
      <IndicatorBackground
        indicatedIsPressed={indicatedIsPressed}
        indicatedButton={indicatedButton}
        selectedButton={effectiveState === "consumer" ? 1 : 0}
        currentRealm={localCurrentRealm}
        buttonWidth={
          signedIn
            ? REALM_TOGGLE_BUTTON_WIDTH_SIGNED_IN
            : REALM_TOGGLE_BUTTON_WIDTH_SIGNED_OUT
        }
      />
    </StyledToggle>
  )
}

interface IndicatorBackgroundProps {
  // The index of the button either being hovered, or the effective realm, if nothing is hovered.
  indicatedButton: number
  // Whether the indicated button (assumed to be the hovered one) is pressed.
  indicatedIsPressed: boolean
  // The index of the currently selected (active) button, in actuality (not the hovered one).
  selectedButton: number
  currentRealm: Realm | null
  buttonWidth: number
}

const IndicatorBackground = styled.div<IndicatorBackgroundProps>`
  ${tw`absolute top-0 h-8 rounded-lg duration-300 z-[-10]`};

  // The width of the indicator is the width of the button, plus the width of the icon if it's selected.
  width: ${(props) =>
    props.buttonWidth +
    (props.selectedButton === props.indicatedButton
      ? REALM_TOGGLE_ICON_WIDTH
      : 0)}px;

  // If the indicated button is not index 0, then we need to translate the indicator to the right.
  // The translation is the width of the button, plus, if the selected button is 0, the width of the icon.
  // An additional gap is added to the translation.
  translate: ${(props) =>
      props.indicatedButton === 0
        ? 0
        : props.buttonWidth +
          (props.selectedButton === 0 ? REALM_TOGGLE_ICON_WIDTH : 0) +
          REALM_TOGGLE_BUTTON_GAP}px
    0;

  // This transition overshoots slightly for translate/width.
  transition:
    all 0.3s ease-out,
    translate 0.3s cubic-bezier(0.34, 1.26, 0.64, 1),
    width 0.3s cubic-bezier(0.34, 1.26, 0.64, 1);

  // In the plus realm, use a white background with a drop shadow since the
  // top bar background is gray.
  background-color: ${(props) =>
    props.indicatedIsPressed
      ? "#eceef1"
      : props.currentRealm === "plus"
      ? "#ffffff"
      : "#f6f7f9"};

  ${(props) =>
    props.currentRealm === "plus" &&
    !props.indicatedIsPressed &&
    tw`shadow-min`};

  // Do not show the background or drop shadow on mobile.
  @media (max-width: 1079px) {
    background-color: transparent;
    box-shadow: none;
  }
`

const StyledToggle = styled.div`
  ${tw`h-8 relative flex flex-row items-stretch gap-1`};

  button {
    ${tw`flex flex-row items-center text-gray-400 font-medium px-3 text-[15px] pointer-events-auto`};

    &.selected {
      ${tw`text-gray-500 hover:text-gray-500`}
    }
  }
`

export default RealmToggle
