import SendV3 from "./SendV3"

import { SendBusinessProviders } from "@/send/components/SendBusinessProviders"

export const SendBusiness = () => {
  return (
    <SendBusinessProviders>
      <SendV3 />
    </SendBusinessProviders>
  )
}
