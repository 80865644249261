import React from "react"

import SendPage from "./consumer/SendPage"
import SendComplete from "./SendComplete"
import SendScheduled from "./SendScheduled"
import RealmRoute from "../common/RealmRoute"

import { ConsumerSendProvider } from "@/common/hooks/send"

// Component with routes for consumer send an consumer send complete
const SendConsumer: React.FC = () => {
  return (
    <ConsumerSendProvider>
      <RealmRoute exact path="/send/scheduled-gift" realm="consumer">
        <SendScheduled />
      </RealmRoute>
      <RealmRoute exact path="/send/:id" realm="consumer">
        <SendComplete />
      </RealmRoute>
      <RealmRoute exact path="/send" realm="consumer">
        <SendPage />
      </RealmRoute>
    </ConsumerSendProvider>
  )
}

export default SendConsumer
