import { useQuery } from "@apollo/client"
import { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import tw from "twin.macro"

import InternationalShippingCountriesModal from "./components/InternationalShippingCountriesModal"
import InternationalShippingCountrySummary from "./components/InternationalShippingCountrySummary"
import { SEND_PRODUCT_CUSTOM_STORE_QUERY } from "./graphql/SendProductCustomStoreQuery"
import { useGiftShippingCountries } from "./hooks/useGiftShippingCountries"
import { OptionsContext } from "../../OptionsContext"
import { ReactComponent as Icon } from "../icons/international-shipping.svg"
import OptionBase from "../OptionBase"

import Callout from "@/common/Callout"
import { useFeatureAccess } from "@/common/hooks/featureAccess"
import { useGiftCart, useGiftData } from "@/common/hooks/giftData"
import InternationalShippingOptions from "@/common/InternationalShippingOptions"
import { ProTag } from "@/common/ProTag"
import { generateRealmPath } from "@/common/realm"
import {
  SendProductCustomStoreQuery,
  SendProductCustomStoreQueryVariables,
} from "@/types/graphql-types"

export default function InternationalShipping() {
  const { currentGift, isDirectSend } = useContext(OptionsContext)
  const { cartQuantity, hasCustomStore, hasSpecialtyFlexGiftItem } =
    useGiftCart()
  const customStoreProduct = hasCustomStore ? currentGift.cart[0] : null

  const { data } = useQuery<
    SendProductCustomStoreQuery,
    SendProductCustomStoreQueryVariables
  >(SEND_PRODUCT_CUSTOM_STORE_QUERY, {
    variables: { productId: customStoreProduct?.id || "" },
    skip: !hasCustomStore,
  })
  const customStore = data?.product?.customStore

  const { setInternationalShippingTier, setGiftSettings } = useGiftData()
  const {
    shipsToCountries,
    swapsToCountries,
    shippingSummaryText,
    cartIsGloballyEligible,
    shipsToGlobalCountries,
    swapsToGlobalCountries,
    standardTierMessage,
  } = useGiftShippingCountries()

  const { hasFeature } = useFeatureAccess()
  const hasPro = hasFeature("pro_plan")

  const [openGlobalModal, setOpenGlobalModal] = useState(false)

  useEffect(() => {
    if (!customStore) return

    if (
      customStore.internationalShippingTier !==
      currentGift.internationalShippingTier
    ) {
      setInternationalShippingTier(customStore.internationalShippingTier)
    }

    const giftCardsEnabled = customStore.settings.giftCardsEnabled
    if (giftCardsEnabled !== currentGift.settings.giftCardsEnabled) {
      setGiftSettings({ giftCardsEnabled })
    }
  }, [customStore, currentGift])

  if (isDirectSend) {
    return null
  }

  const showProUpsell = !hasPro

  return (
    <OptionBase
      name="International shipping"
      icon={<Icon />}
      preview={
        <div
          tw="flex flex-row items-center gap-3"
          // When non-pro, account for the extra padding added by ProTag
          css={[showProUpsell && tw`mt-[-2px]`]}
        >
          {shippingSummaryText}
          {showProUpsell && (
            <ProTag feature="international_shipping" stopPropagation={true} />
          )}
        </div>
      }
      fullWidth={shipsToCountries.length > 0}
    >
      {hasCustomStore && (
        <div tw="mb-5">
          <Link
            to={generateRealmPath(
              "plus",
              `/store/custom/edit/${customStore?.id}`,
            )}
          >
            <Callout
              variant="info"
              title="Your international shipping settings are set on the Custom Store"
              subtext="To change this, edit your Custom Store."
            />
          </Link>
        </div>
      )}
      <div tw="flex flex-col sm:flex-row gap-6">
        <InternationalShippingOptions
          disabled={hasCustomStore}
          showDisabledTier={hasCustomStore}
          internationalShippingTier={currentGift.internationalShippingTier}
          setInternationalShippingTier={setInternationalShippingTier}
          giftCardsEnabled={currentGift.settings.giftCardsEnabled || false}
          setGiftCardsEnabled={(giftCardsEnabled) =>
            setGiftSettings({ giftCardsEnabled })
          }
          swapType={currentGift.swapType}
          disableStandardTier={hasSpecialtyFlexGiftItem}
          disableGlobalTier={cartQuantity >= 2}
          cartIsGloballyEligible={cartIsGloballyEligible}
          onGlobalCountriesClick={() => setOpenGlobalModal(true)}
          customStandardTierMessage={standardTierMessage}
        />
        <InternationalShippingCountrySummary
          shipsToCountries={shipsToCountries}
          swapsToCountries={swapsToCountries}
          internationalShippingTier={currentGift.internationalShippingTier}
          onGlobalInfoClick={() => setOpenGlobalModal(true)}
        />
        <InternationalShippingCountriesModal
          open={openGlobalModal}
          onClose={() => setOpenGlobalModal(false)}
          shipsToGlobalCountries={shipsToGlobalCountries}
          swapsToGlobalCountries={swapsToGlobalCountries}
        />
      </div>
    </OptionBase>
  )
}
