import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"

import Index from "./Index"
import { useGlobalState } from "../common/GlobalState"
import { useCurrentWorkspace } from "../common/hooks"
import { generateRealmPath } from "../common/realm"
import RealmRoute from "../common/RealmRoute"

import { useFeatureFlags } from "@/common/hooks/featureFlags"
import { GiftBatchV2 } from "@/track/components/giftBatch"
import SmartLinkApproval from "@/track/SmartLinkApproval"
import { PlusEnrollmentStatus } from "@/types/graphql-types"

const Track: React.FC = () => {
  const [enrollmentStatus] = useGlobalState("enrollmentStatus")
  const history = useHistory()

  const { currentWorkspace } = useCurrentWorkspace()
  const { hasFeatureFlag, loading } = useFeatureFlags()

  useEffect(() => {
    if (enrollmentStatus === PlusEnrollmentStatus.NONE) {
      history.replace(generateRealmPath("business", "/signup"))
    }
  }, [enrollmentStatus, history])

  if (enrollmentStatus === PlusEnrollmentStatus.NONE) {
    return null
  }

  // HACK: These pages have some race condition where the Apollo query is made
  // before the currentWorkspace is available
  if (!currentWorkspace) {
    return null
  }

  return (
    <div>
      <RealmRoute path="/track/:giftBatchId" realm="plus">
        <GiftBatchV2 />
      </RealmRoute>
      <RealmRoute exact path="/track" realm="plus">
        <Index />
      </RealmRoute>
      <RealmRoute
        exact
        path="/track/:giftBatchId/gifts/:giftId/:action"
        realm="plus"
      >
        <SmartLinkApproval />
      </RealmRoute>
    </div>
  )
}

export default Track
