import "twin.macro"
import React from "react"
import tw from "twin.macro"

import { ReactComponent as HideIcon } from "./images/hide.svg"
import { ReactComponent as MinimizeIcon } from "./images/minimize.svg"

interface Props {
  minimized: boolean
  toggleMinimized: () => void
  toggleDismissConfirmation: () => void
}

export default function Header({
  minimized,
  toggleMinimized,
  toggleDismissConfirmation,
}: Props) {
  return (
    <div
      tw="flex flex-row justify-between items-center p-4 pb-0 transition-all"
      css={[minimized && tw`pt-3`]}
    >
      <div tw="flex font-medium text-black p-2 py-1">Get started</div>
      <div>
        <div tw="flex gap-px" style={{ color: "#838A97" }}>
          <ActionButton onClick={toggleDismissConfirmation}>
            <HideIcon />
          </ActionButton>
          <ActionButton onClick={toggleMinimized}>
            <MinimizeIcon
              tw="transition-transform"
              css={[minimized && tw`transform rotate-180`]}
            />
          </ActionButton>
        </div>
      </div>
    </div>
  )
}

const ActionButton = tw.button`w-8 h-8 rounded-lg flex items-center justify-center bg-transparent hover:bg-gray-100 active:(bg-gray-200 scale-90) transition-all`
