import { gql, useMutation, useQuery } from "@apollo/client"
import React from "react"
import { Helmet } from "react-helmet-async"
import { match, useParams, useRouteMatch } from "react-router-dom"

import { TRACK_GIFT_BATCH_GIFT_LINK_FRAGMENT } from "./components/giftLink/GiftLink"
import NavText from "./components/NavText"
import OpenInReportsButton from "./components/OpenInReportsButton"
import Tabs, { TabLink } from "./components/Tabs"
import Overview from "./Overview"
import { STANDARD_GIFT_DISPENSER_FRAGMENT } from "./queries"
import Recipients from "./Recipients"
import { isBlank } from "../common/format"
import { ReactComponent as LayoutIcon } from "../common/images/layout.svg"
import { ReactComponent as UserIcon } from "../common/images/user.svg"
import InlineEditField from "../common/InlineEditField"
import MeetingNotRequiredButton from "../common/MeetingNotRequiredButton"
import { generateRealmPath } from "../common/realm"
import RealmRoute from "../common/RealmRoute"
import ResendBatchGiftButton from "../common/Resend"
import {
  GradientHeader,
  GradientHeaderTextMain,
  GradientHeaderTextSub,
} from "../common/UI"

import { getRecipientCount } from "@/common/utils/giftBatches"
import {
  GiftBatch_GiftBatchUpdateSentBatchMutation,
  GiftBatch_GiftBatchUpdateSentBatchMutationVariables,
  Track_GiftBatchQuery,
  Track_GiftBatchQueryVariables,
} from "@/types/graphql-types"

interface Params {
  giftBatchId: string
}

const GiftBatch: React.FC = () => {
  const routeMatch = useRouteMatch()
  const { giftBatchId } = useParams<Params>()

  return (
    <>
      <RealmRoute exact path={`${routeMatch.url}/recipients`} realm="plus">
        <TrackGiftBatchPage routeMatch={routeMatch} giftBatchId={giftBatchId} />
      </RealmRoute>
      <RealmRoute exact path={`${routeMatch.url}`} realm="plus">
        <TrackGiftBatchPage routeMatch={routeMatch} giftBatchId={giftBatchId} />
      </RealmRoute>
    </>
  )
}

interface TrackGiftBatchPageProps {
  routeMatch: match
  giftBatchId: string
}

const TrackGiftBatchPage: React.FC<TrackGiftBatchPageProps> = ({
  routeMatch,
  giftBatchId,
}) => {
  const { data, loading, refetch } = useQuery<
    Track_GiftBatchQuery,
    Track_GiftBatchQueryVariables
  >(GIFT_BATCH_QUERY, {
    variables: {
      id: giftBatchId,
    },
    fetchPolicy: "cache-and-network",
  })

  const [giftBatchUpdateSentBatch, { loading: giftBatchUpdateLoading }] =
    useMutation<
      GiftBatch_GiftBatchUpdateSentBatchMutation,
      GiftBatch_GiftBatchUpdateSentBatchMutationVariables
    >(UPDATE_GIFT_BATCH_MUTATION)

  const loadingVisible = loading && !data
  const batch = data?.workspace?.giftBatch

  let title = ""
  let subtitle = ""
  let fallbackBatchName = ""
  if (batch) {
    fallbackBatchName = batch.batchNameFallback
    title = batch.batchName || ""
    subtitle += "Sent "

    if (isBlank(batch.batchName)) {
      // Use fallback for title if batch name is blank
      title = fallbackBatchName
    }
    // When the batch name is not blank, then the date is not present in the
    // title, so we want to show the date in the subtitle.
    subtitle += new Date(batch.createdAt).toLocaleString("en-US", {
      // @ts-ignore
      dateStyle: "full",
    })
    subtitle += " "

    if (!isBlank(batch.batchName) || batch.isSmartLink) {
      const recipientCount = getRecipientCount(batch)

      subtitle += `to ${recipientCount} recipient${
        recipientCount !== 1 ? "s" : ""
      }`
    }
  }

  const handleNameChange = async (value: string) => {
    if (batch) {
      const result = await giftBatchUpdateSentBatch({
        variables: {
          giftBatch: {
            id: batch.id,
            batchName: value,
          },
        },
      })

      if (
        !result.data?.giftBatchUpdateSentBatch ||
        !result.data?.giftBatchUpdateSentBatch.ok
      ) {
        alert("An error occurred.")
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>
          {title !== "" ? title + " – " : ""}Gift Batch – Goody for Business
        </title>
      </Helmet>
      <GradientHeader tw="pt-12 md:pt-20 pb-16 md:pb-20">
        <GradientHeaderTextMain className="data-hj-suppress ph-no-capture fs-mask">
          {loadingVisible ? (
            <span tw="opacity-50">Loading…</span>
          ) : batch?.isSmartLink ? (
            <div>{title}</div>
          ) : (
            <div>
              <InlineEditField
                label={title}
                emptyValue={fallbackBatchName}
                disabled={giftBatchUpdateLoading}
                initialValue={batch?.batchName || ""}
                onSubmit={handleNameChange}
              />
            </div>
          )}
        </GradientHeaderTextMain>
        <GradientHeaderTextSub>{subtitle}</GradientHeaderTextSub>
        <div tw="place-content-center mt-8 flex gap-3 items-center">
          <ResendBatchGiftButton giftBatchId={giftBatchId} />
          <OpenInReportsButton giftBatchId={giftBatchId} />
          {batch?.meetingSettings?.giftSetting == "MEETING_REQUIRED" ? (
            <MeetingNotRequiredButton giftBatchId={giftBatchId} />
          ) : (
            <></>
          )}
        </div>
      </GradientHeader>
      <div tw="container mx-auto">
        <Tabs>
          <TabLink exact to={generateRealmPath("plus", `${routeMatch.url}`)}>
            <LayoutIcon />
            <NavText>Overview</NavText>
          </TabLink>
          <TabLink
            to={generateRealmPath("plus", `${routeMatch.url}/recipients`)}
          >
            <UserIcon />
            <NavText>Recipients</NavText>
          </TabLink>
        </Tabs>
        <div>
          <RealmRoute exact path={`${routeMatch.url}/recipients`} realm="plus">
            <Recipients giftBatchId={giftBatchId} />
          </RealmRoute>
          <RealmRoute exact path={`${routeMatch.url}`} realm="plus">
            <Overview
              giftBatchID={giftBatchId}
              data={data}
              loading={loading}
              loadingVisible={loadingVisible}
              refetch={refetch}
            />
          </RealmRoute>
        </div>
      </div>
    </>
  )
}

export const GIFT_BATCH_FRAGMENT = gql`
  fragment Track_GiftBatchFragment on GiftBatch {
    id
    sendStatus
    sendMethod
    customEmailSubject
    batchName
    batchNameFallback
    expiresAt
    recipientCount
    failureMessages {
      title
      message
    }
    pendingSends
    createdAt
    giftDispenser {
      ...StandardGiftDispenser
    }
    meetingSettings {
      giftSetting
    }
    ...Track_GiftBatchGiftLink
  }
  ${STANDARD_GIFT_DISPENSER_FRAGMENT}
  ${TRACK_GIFT_BATCH_GIFT_LINK_FRAGMENT}
`

export const GIFT_BATCH_QUERY = gql`
  query Track_GiftBatch($id: ID!) {
    workspace {
      giftBatch(id: $id) {
        ...Track_GiftBatchFragment
      }
    }
  }
  ${GIFT_BATCH_FRAGMENT}
`

const UPDATE_GIFT_BATCH_MUTATION = gql`
  mutation GiftBatch_GiftBatchUpdateSentBatch(
    $giftBatch: GiftBatchUpdateSentBatchInput!
  ) {
    giftBatchUpdateSentBatch(giftBatchInfo: $giftBatch) {
      ok
    }
  }
`

export default GiftBatch
