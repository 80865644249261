import { createGlobalState } from "react-hooks-global-state"

import {
  AutogiftExpireAtOption,
  AutogiftRuleTenureClassification,
  BusinessSendStep,
  ConsumerNewUserPaymentSelection,
  ConsumerSignUpFields,
  ExpireAtOption,
  GlobalStateType,
  Product,
  RecipientType,
} from "./globalStateTypes"
import { getSavedRealm } from "../realm"
import { generateEmptyRecipients } from "../recipient"
import { daysFromNow, sixWeeksFromNowNoon } from "../utilities"

import {
  AutogiftRuleAnchorDateSendOptionEnum,
  AutogiftRuleAnchorDateTypeEnum,
  AutogiftRuleStatus,
  BatchSendMethod,
  BrandValuesDisplay,
  EmailDeliveryMethods,
  GiftSwapTypeEnum,
  InternationalShippingTierEnum,
  MultipleSelectModeEnum,
} from "@/types/graphql-types"

export const initialCurrentAutogiftRuleState = Object.freeze({
  id: null,
  contactListId: null,
  contactListName: null,
  status: AutogiftRuleStatus.PAUSED,
  eventType: "birthday",
  productList: [],
  card: null,
  fromName: "",
  message: "",
  expireAtOption: "sixWeeks" as AutogiftExpireAtOption,
  autopayPaymentMethodID: null,
  autopayPaymentMethodName: null,
  swapType: GiftSwapTypeEnum.swap_single,
  userPublicId: null,
  maximumProductPrice: null,
  processingFee: null,
  estimatedTaxLow: null,
  estimatedTaxHigh: null,
  estimatedTotalLow: null,
  estimatedTotalHigh: null,
  flexGiftPrice: null,
  tenureClassification: AutogiftRuleTenureClassification.ALL,
  tenureMin: null,
  tenureMax: null,
  contactsWithEventToday: [],
  anchorDateSendOption:
    AutogiftRuleAnchorDateSendOptionEnum.send_on_anchor_date,
  anchorDateType: AutogiftRuleAnchorDateTypeEnum.start_date,
  anchorDateNumberOfDaysDelta: null,
  internationalShippingTier: InternationalShippingTierEnum.standard,
  settings: { giftCardsEnabled: false },
})

const sharedInitialCurrentGiftState = Object.freeze({
  expiresAt: null,
  cart: [] as Product[],
  card: null,
  fromName: "",
  message: "",
  batchName: null,
  customExpirationDate: sixWeeksFromNowNoon(),
  autopayPaymentMethodID: null,
  autopayPaymentMethodName: null,
  autopayEnabled: true,
  paymentMethod: null,
  isAlcohol: false,
  swapType: GiftSwapTypeEnum.swap_single,
  landingPageEnabled: false,
  landingPageSendNotifs: true,
  scheduledSendOnOption: false,
  scheduledSendOnDate: daysFromNow(7),
  scheduledSendOnTime: "12 PM",
  giftBatchId: null,
  userPublicId: null,
  recipientType: "manual" as RecipientType,
  isGiftDispenser: false,
  giftDispenserQuantity: 10,
  emailDeliveryMethod: EmailDeliveryMethods.goody,
  settings: { giftCardsEnabled: false },
  referringCategoryID: null,
  giftCalendarSetting: null,
  isAutogift: null,
  consumerNewUserPaymentSelection:
    "addCreditCardNow" as ConsumerNewUserPaymentSelection,
  businessSendStep: "start" as BusinessSendStep,
  audience: null,
  sendMethod: null,
  lastSelectedLinkSendMethod: BatchSendMethod.link_unspecified,
  customEmailSubject: null,
  giftTemplate: null,
  isSmartLink: false,
  smartLinkQuantity: 10,
  smartLinkApprovalRequired: true,
  hasRecentlyAddedBalance: false,
  hasRecentlyAddedCreditCard: false,
  topUpAmount: null,
  topUpPaymentMethod: null,
})

export const initialConsumerCurrentGiftState = Object.freeze({
  ...sharedInitialCurrentGiftState,
  internationalShippingTier: InternationalShippingTierEnum.disabled,
  omitCredit: false,
  expireAtOption: "none" as ExpireAtOption,
})

export const initialBusinessCurrentGiftState = Object.freeze({
  ...sharedInitialCurrentGiftState,
  internationalShippingTier: InternationalShippingTierEnum.standard,
  omitCredit: true,
  expireAtOption: "sixWeeks" as ExpireAtOption,
})

export const initialCustomStoreState = Object.freeze({
  name: "Welcome to the team!",
  subtitle: "Choose your favorite treat!",
  desktopImage: { file: null, url: null },
  mobileImage: { file: null, url: null },
  optionList: [],
  internationalShippingTier: InternationalShippingTierEnum.standard,
  brandValuesDisplay: BrandValuesDisplay.options,
  multipleSelectMode: MultipleSelectModeEnum.disabled,
  multipleSelectCount: 1,
  multipleSelectPrice: 10000,
  settings: { giftCardsEnabled: false },
})

const initialConsumerSignUpFieldsState: ConsumerSignUpFields = Object.freeze({
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
})

const initialState: GlobalStateType = Object.freeze({
  selectedCategoryName: null,
  isCustomCategorySelected: false,
  signedIn: false,
  enrollmentStatus: null,
  user: null,
  organization: null,
  openLogin: false,
  consumerCurrentGift: initialConsumerCurrentGiftState,
  businessCurrentGift: initialBusinessCurrentGiftState,
  currentAutogiftRule: initialCurrentAutogiftRuleState,
  recipients: generateEmptyRecipients(3),
  currentWorkspace: null,
  giftGuideMode: "emailForm",
  giftGuideEnteredEmail: null,
  sendPageMode: "createGiftBatch",
  promoCode: null,
  storeSelections: {},
  consumerRecipient: {
    name: "",
    recipientInfo: "",
    mailingAddress: {
      firstName: "",
      lastName: "",
      address1: "",
      address2: null,
      city: "",
      state: "",
      postalCode: "",
    },
  },
  currentRealm: getSavedRealm(),
  sendPageRecipientIndex: 0,
  isAuthorizeGmailModalOpen: false,
  isProPlanModalOpen: false,
  isLearnAboutFullVsLimitedModalOpen: false,
  isLimitedMemberFeatureGateModalOpen: false,
  isBusinessWelcomeModalOpen: false,
  eagerLoadedPost: null,
  referringCategoryID: null,
  consumerSignUpFields: initialConsumerSignUpFieldsState,
  realmChoiceModalOpen: false,
  referringSearchRecordID: null,
  hideMenuItems: false,
})

export const { useGlobalState } = createGlobalState(initialState)
