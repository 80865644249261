import { gql } from "@apollo/client"

import { CUSTOM_STORE_BASE_FRAGMENT } from "./queries"

export const CREATE_CUSTOM_STORE_MUTATION = gql`
  mutation Store_CustomStoreCreate(
    $name: String!
    $subtitle: String!
    $desktopImage: CustomStoreImage!
    $mobileImage: CustomStoreImage!
    $internationalShippingTier: InternationalShippingTierEnum!
    $brandValuesDisplay: BrandValuesDisplay!
    $multipleSelectMode: MultipleSelectModeEnum
    $multipleSelectCount: Int
    $multipleSelectPrice: Int
    $settings: GiftsSettingsInput
  ) {
    customStoreCreate(
      name: $name
      subtitle: $subtitle
      desktopImage: $desktopImage
      mobileImage: $mobileImage
      internationalShippingTier: $internationalShippingTier
      brandValuesDisplay: $brandValuesDisplay
      multipleSelectMode: $multipleSelectMode
      multipleSelectCount: $multipleSelectCount
      multipleSelectPrice: $multipleSelectPrice
      settings: $settings
    ) {
      ok
      errors
      customStore {
        ...CustomStoreBase
      }
    }
  }
  ${CUSTOM_STORE_BASE_FRAGMENT}
`
