import { isNil } from "lodash-es"

import { BrandValuesSetting } from "./components/BrandValuesSetting"
import { InternationalShippingSetting } from "./components/InternationalShippingSetting"
import { MultipleGiftSelectionSetting } from "./components/MultipleGiftSelectionSetting/MultipleGiftSelectionSetting"

import { CurrentCustomStore } from "@/common/GlobalState"
import { SelectionGroupColorEnum } from "@/common/SelectionGroup/types"
import { CustomStoreFormFieldValue } from "@/store/custom/common"
import {
  BrandValuesDisplay,
  InternationalShippingTierEnum,
} from "@/types/graphql-types"

interface Props {
  formFields: CurrentCustomStore
  setFormField: (name: string, value: CustomStoreFormFieldValue) => void
}

export const CustomStoreOptions = ({ formFields, setFormField }: Props) => {
  if (isNil(formFields)) {
    return null
  }

  return (
    <div>
      <InternationalShippingSetting
        selectedTier={formFields.internationalShippingTier}
        setInternationalShippingTier={(
          internationalShippingTier: InternationalShippingTierEnum,
        ) => {
          setFormField("internationalShippingTier", internationalShippingTier)
        }}
        giftCardsEnabled={formFields.settings.giftCardsEnabled || false}
        setGiftCardsEnabled={(giftCardsEnabled: boolean) => {
          setFormField("settings", {
            ...formFields.settings,
            giftCardsEnabled,
          })
        }}
        color={SelectionGroupColorEnum.primaryNew}
        alignPreviewName="right"
        isExpandable={true}
      />
      <BrandValuesSetting
        brandValuesDisplay={formFields.brandValuesDisplay}
        setBrandValuesDisplay={(brandValuesDisplay: BrandValuesDisplay) =>
          setFormField("brandValuesDisplay", brandValuesDisplay)
        }
        isExpandable={true}
        color={SelectionGroupColorEnum.primaryNew}
        alignPreviewName="right"
      />
      <MultipleGiftSelectionSetting
        isExpandable={true}
        color={SelectionGroupColorEnum.primaryNew}
        alignPreviewName="right"
      />
    </div>
  )
}
