import React from "react"
import tw from "twin.macro"

import { useGiftData } from "./hooks/giftData"
import { BlankPlus, Checkmark } from "../assets/icons"

import {
  USER_MANUALLY_OMITTED_CREDIT_SESSION_STORAGE_KEY,
  USER_MANUALLY_OMITTED_CREDIT_SESSION_STORAGE_VALUE,
} from "@/common/userManuallyOmittedCreditSessionStorage"

const ApplyCreditButton = ({
  amount,
  creditOmitted,
  isBusinessSend,
}: {
  amount: string
  creditOmitted: boolean
  isBusinessSend?: boolean
}) => {
  const { setOmitCredit } = useGiftData()

  function handleClick() {
    if (creditOmitted) {
      setOmitCredit(false)
      window.sessionStorage.removeItem(
        USER_MANUALLY_OMITTED_CREDIT_SESSION_STORAGE_KEY,
      )
    } else {
      setOmitCredit(true)
      window.sessionStorage.setItem(
        USER_MANUALLY_OMITTED_CREDIT_SESSION_STORAGE_KEY,
        USER_MANUALLY_OMITTED_CREDIT_SESSION_STORAGE_VALUE,
      )
    }
  }

  return (
    <div
      css={[
        tw`relative flex justify-center items-center px-3 py-[9px] bg-[#EDFAF1] rounded-lg whitespace-nowrap flex-nowrap cursor-pointer transition-all`,
        isBusinessSend
          ? tw`border border-[#D7F3DF] hover:bg-[#E6F7EB] active:(scale-[.97] bg-[#D7F3DF])`
          : tw`hover:bg-[#E6F7EB] active:scale-95 lg:ml-[-12px]`,
      ]}
      onClick={handleClick}
    >
      <>
        {" "}
        {creditOmitted ? (
          <div tw="flex flex-row items-center text-[#16A34A]">
            <BlankPlus tw="inline-block mr-2 stroke-current" />
            <div tw="text-base">Apply credit</div>
            <span tw="text-[rgba(22, 163, 74, 0.6)] leading-5 font-normal text-base ml-3">
              {amount}
            </span>
          </div>
        ) : (
          <div tw="flex flex-row items-center text-[#16A34A]">
            <Checkmark tw="inline-block mr-2 stroke-current" />
            <div tw="text-base">Credit applied</div>
          </div>
        )}
      </>
    </div>
  )
}

export default ApplyCreditButton
