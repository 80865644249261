import ShippingCountryListFilter from "./ShippingCountryListFilter/ShippingCountryListFilter"

import { useShippingCountriesSelector } from "@/store/hooks/useShippingCountriesSelector"
import { ShippingCountryGroup } from "@/types/graphql-types"

interface Props {
  shippingCountryGroup: ShippingCountryGroup
}

const PopoverShippingCountryList = ({ shippingCountryGroup }: Props) => {
  const {
    selectedShippingCountry,
    setSelectedShippingCountry,
    expandShippingCountries,
  } = useShippingCountriesSelector()

  return (
    <ShippingCountryListFilter
      countries={{
        domestic: expandShippingCountries(shippingCountryGroup.domestic || []),
        global: expandShippingCountries(shippingCountryGroup.global || []),
      }}
      selectedCountry={selectedShippingCountry}
      setSelectedCountry={setSelectedShippingCountry}
      withinScrollable={true}
    />
  )
}

export default PopoverShippingCountryList
