import React from "react"
import tw, { styled } from "twin.macro"

interface Props {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  image?: string | JSX.Element | null
  text?: string
  afterText?: string | JSX.Element
  secondaryText?: string | JSX.Element
  tertiaryText?: string
  selected?: boolean
  textLine2?: string
  button?: JSX.Element | null
}
export const PaymentMethodButton = ({
  onClick,
  image,
  text,
  afterText,
  secondaryText,
  tertiaryText,
  selected,
  textLine2,
  button,
}: Props) => (
  <ButtonContainer selected={selected} hasButton={!!button} onClick={onClick}>
    <div
      tw="grid gap-x-4 gap-y-1 items-center justify-items-start flex-1"
      css="grid-template-columns: max-content 1fr max-content"
    >
      {image}
      <div tw="flex flex-row items-center gap-3">
        <span
          tw="font-medium tabular-nums"
          css={selected ? tw`text-primary-600` : tw`text-gray-800`}
        >
          {text}
          {afterText}
        </span>
        {secondaryText ? (
          <span css={selected ? tw`text-primary-600` : tw`text-gray-500`}>
            {secondaryText}
            {tertiaryText && (
              <span css={selected ? tw`text-primary-600` : tw`text-gray-400`}>
                {tertiaryText}
              </span>
            )}
          </span>
        ) : null}
      </div>
      {textLine2 ? (
        <div tw="col-start-2 text-sm text-gray-450 text-left">{textLine2}</div>
      ) : null}
    </div>
    {/*NOTE: If you move this button, you'll have to change the CSS to make sure the click state is only applied to the button*/}
    {button}
  </ButtonContainer>
)

const ButtonContainer = styled.button<{
  selected?: boolean
  hasButton?: boolean
}>`
  ${tw`active:scale-[.98] transition-all flex flex-row items-start rounded-[10px] p-[18px] border-2 box-content items-center`}
  ${({ selected }) =>
    selected
      ? tw`bg-white border-primary-300`
      : tw`bg-[#f6f7f9] hover:bg-gray-100 border-transparent`}
  
  // This makes it so the click state is only applied to the button, not the whole container
  &:has(:last-child:active) {
    ${({ hasButton }) => (hasButton ? tw`active:scale-100` : null)};
  }
`
