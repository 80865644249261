import { Bag, GiftsIcon } from "@/assets/icons"
import { useCartDrawer } from "@/common/contexts/cartDrawer"
import GradientButton, { buttonStyle } from "@/common/GradientButton"
import { useGiftCart } from "@/common/hooks/giftData"
import { Store_PDP_ProductDetailsFragment } from "@/types/graphql-types"

interface Props {
  product: Store_PDP_ProductDetailsFragment
  disabled: boolean
  onClick: () => void
}

export default function FlexGiftSendGiftButton({
  product,
  disabled,
  onClick,
}: Props) {
  const { openCart } = useCartDrawer()

  const { getProductInCart } = useGiftCart()

  const productInCart = getProductInCart({
    productId: product.id,
    variants: null,
  })

  return (
    <>
      {!productInCart ? (
        <GradientButton
          tw="flex justify-center lg:mb-0 xl:pl-6 xl:pr-8"
          onClick={onClick}
          disabled={disabled}
        >
          <GiftsIcon tw="mr-2 xl:mr-5" />
          <span>
            Send<span tw="hidden xl:inline"> gift</span>
          </span>
        </GradientButton>
      ) : (
        <button
          onClick={openCart}
          css={[buttonStyle, "height: 49px;"]}
          tw="rounded-xl flex flex-row items-center justify-center text-2xl font-semibold text-primary-500 bg-primary-050 px-6"
        >
          <Bag tw="stroke-current mr-4" />
          In bag
        </button>
      )}
    </>
  )
}
