import React, { useCallback } from "react"
import { ValueType } from "react-select"
import tw, { css, styled } from "twin.macro"

import ContactBottomButtons from "./ContactBottomButtons"
import CustomSelect from "./CustomSelect"
import { FIELD_LIST, FieldType, ImportStep } from "./shared"

// Select options are "None" + all fields
// Using a map to have an ordered k/v store
const SELECT_LABELS = new Map([
  ["None", { value: null, label: "None" }],
  ...FIELD_LIST.map((field) => [
    field.name,
    {
      value: field.name,
      label: field.name,
    },
  ]),
] as any) as Map<string, { value: string; label: string }>

// Array to pass into select
const SELECT_LABEL_LIST = Array.from(SELECT_LABELS.values())

// Number of header columns
const HEADER_COLUMN_COUNT = 3

interface Props {
  importStep: ImportStep
  goNext: () => void
  goPrevious: () => void
  rows: string[][] | null
  rowHeaders: string[] | null
  matchedFields: (FieldType | null)[] | null
  setMatchedFields: (headers: (FieldType | null)[]) => void
  isLoading: boolean
}

const ContactMatchFields: React.FC<Props> = ({
  importStep,
  goNext,
  goPrevious,
  rows,
  rowHeaders,
  matchedFields,
  setMatchedFields,
  isLoading,
}) => {
  const onOptionChange = useCallback(
    (
      index: number,
      option: ValueType<{ label: FieldType; value: FieldType }, false>,
    ) => {
      if (matchedFields) {
        // Copy fields and replace value
        const newMatchedFields = [...matchedFields]
        newMatchedFields[index] = option?.value ?? null
        setMatchedFields(newMatchedFields)
      }
    },
    [matchedFields, setMatchedFields],
  )

  return importStep === "match-columns" ? (
    <div tw="flex flex-col mt-12">
      <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
        {/*Grid needs this to be flat*/}
        <RecipientGrid hasHeaders={!!rowHeaders}>
          <HeaderItem>File column</HeaderItem>
          <HeaderItem>Match To</HeaderItem>
          <HeaderItem tw="col-span-3">Example values</HeaderItem>
          {(rowHeaders ?? rows?.[0])?.map((_, i) => (
            <React.Fragment key={i}>
              <MatchHeaderItem hasHeaders={!!rowHeaders}>
                <MobileHeaderItem>File column</MobileHeaderItem>
                {rowHeaders?.[i]}
              </MatchHeaderItem>
              <SelectContainer hasHeaders={!!rowHeaders}>
                <MobileHeaderItem tw="mb-1">Match to</MobileHeaderItem>
                <SelectWidthConstraint>
                  <CustomSelect
                    options={SELECT_LABEL_LIST}
                    value={SELECT_LABELS.get(matchedFields?.[i] ?? "None")}
                    onChange={(option: any) => onOptionChange(i, option)}
                  />
                </SelectWidthConstraint>
              </SelectContainer>
              <SampleDataItem hasHeaders={!!rowHeaders}>
                <MobileHeaderItem tw="mb-1">Example value</MobileHeaderItem>
                <div>{rows?.[0]?.[i]}</div>
              </SampleDataItem>
              <SampleDataItem hasHeaders={true}>
                <div>{rows?.[1]?.[i]}</div>
              </SampleDataItem>
              <SampleDataItem hasHeaders={true}>
                <div>{rows?.[2]?.[i]}</div>
              </SampleDataItem>
            </React.Fragment>
          ))}
        </RecipientGrid>
      </form>
      <ContactBottomButtons
        nextText="Continue"
        isLoading={isLoading}
        onClickNext={goNext}
        onClickBack={goPrevious}
      />
      {/* space at the bottom for the selects */}
      <div tw="h-44" />
    </div>
  ) : null
}

const rowCss = css`
  ${tw`flex flex-col justify-center`}
  background-color: #f6f7f9;
`

// Hide the first column if we have headers
const gridColumns = ({ hasHeaders }: { hasHeaders: boolean }) => css`
  grid-template-columns:
    minmax(0, ${hasHeaders ? "1fr" : "0"}) minmax(240px, 2fr)
    minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
`

// Make cell take no space
const shrinkCell = css`
  ${tw`p-0 overflow-hidden`}
`

// Styles the column at an index for every row. Skips
// header columns in the grid.
const styleColumn = ({
  style,
  columnIndex,
  startRowIndex,
}: {
  style: any
  columnIndex: number
  startRowIndex?: number
}) => {
  const rowIndex = startRowIndex ?? 0

  return css`
    &
      > div:nth-child(
        5n + ${HEADER_COLUMN_COUNT + columnIndex + 1 + rowIndex * 5}
      ) {
      ${style}
    }
  `
}

const RecipientGrid = styled.div<{ hasHeaders: boolean }>`
  ${tw`grid items-stretch sm:gap-y-2`};

  // Mobile
  @media (max-width: 639px) {
    grid-template-columns: 1fr;

    ${({ hasHeaders }) =>
      styleColumn({
        style: tw`mt-5`,
        columnIndex: hasHeaders ? 0 : 1,
        startRowIndex: 1,
      })}

    ${({ hasHeaders }) =>
      styleColumn({
        style: tw`rounded-tl-xl rounded-tr-xl`,
        columnIndex: hasHeaders ? 0 : 1,
      })}

    ${({ hasHeaders }) =>
      styleColumn({
        style: tw`rounded-bl-xl rounded-br-xl`,
        columnIndex: hasHeaders ? 1 : 2,
      })}
  }

  // Full width
  @media (min-width: 640px) {
    ${gridColumns}

    ${styleColumn({
      style: tw`rounded-tr-xl rounded-br-xl`,
      columnIndex: 4,
    })}

    ${({ hasHeaders }) =>
      styleColumn({
        style: tw`rounded-tl-xl rounded-bl-xl`,
        columnIndex: hasHeaders ? 0 : 1,
      })}

    ${({ hasHeaders }) =>
      styleColumn({
        style: hasHeaders ? "" : shrinkCell,
        columnIndex: 0,
      })}
    
    & > div:nth-child(1) {
      ${({ hasHeaders }) => (hasHeaders ? "" : shrinkCell)};
    }
  }
`

const BaseHeaderItem = styled.div`
  ${tw`text-gray-500 text-left text-sm font-medium`}
`

const HeaderItem = styled(BaseHeaderItem)`
  ${tw`pb-0.5 mb-0 px-6 hidden sm:block self-center`}
`

const MobileHeaderItem = styled(BaseHeaderItem)`
  ${tw`block sm:hidden`}
`

const MatchHeaderItem = styled.div<{ hasHeaders: boolean }>`
  ${rowCss}
  ${tw`p-6 col-span-5 sm:col-span-1`}
  ${({ hasHeaders }) => (hasHeaders ? "" : tw`hidden sm:flex`)}
`

const SampleDataItem = styled.div<{ hasHeaders: boolean }>`
  ${rowCss}
  ${({ hasHeaders }) => (hasHeaders ? tw`hidden sm:flex` : "")}
  & > div {
    ${tw`text-sm text-gray-500 truncate whitespace-nowrap overflow-hidden p-6`}
    ${({ hasHeaders }) =>
      hasHeaders ? "" : tw`pt-0 first:pb-0 sm:pt-6 sm:first:pt-6`}
  }
`

const SelectContainer = styled.div<{ hasHeaders: boolean }>`
  ${tw`p-6 sm:pr-0 flex flex-col justify-center col-span-5 sm:col-span-1`}
  ${({ hasHeaders }) =>
    hasHeaders ? tw`pt-0 sm:pt-6 sm:pl-3 ` : tw`pl-6 pt-6`}
  ${rowCss}
`

const SelectWidthConstraint = styled.div`
  max-width: 235px;
  ${tw`sm:max-w-none`}
`

export default ContactMatchFields
