import "twin.macro"
import * as Frigade from "@frigade/react"
import React from "react"

interface Props {
  currentStep: number
  totalSteps: number
  minimized: boolean
}

export default function Progress({
  currentStep,
  totalSteps,
  minimized,
}: Props) {
  return (
    <div tw="px-6 pb-4 transition-all">
      <div tw="flex items-center">
        <div tw="flex text-sm w-8 text-gray-450">
          {currentStep}/{totalSteps}
        </div>
        <Frigade.Progress.Bar
          total={totalSteps}
          current={currentStep}
          css={{
            display: "flex",
            flex: "1 1 0",
            height: "8px !important",
            backgroundColor: "#F2F0FF !important",
            ".fr-progress-bar-fill": {
              backgroundColor: "#B3A1FF",
            },
          }}
        />
      </div>
    </div>
  )
}
