import React from "react"

import BasicContainer from "./components/BasicContainer"
import {
  BasicAnswer,
  BasicHeader,
  BasicList,
  BasicListItem,
  BasicParagraphStart,
  BasicQuestion,
  BasicSection,
  BasicSectionStart,
  PrimarySpan,
} from "./Ui"

const ReferralTerms: React.FC = () => {
  return (
    <BasicContainer title="Workspace Referral Terms">
      <BasicSection>
        <BasicHeader>FAQ</BasicHeader>
        <BasicQuestion>How do workspace referrals work?</BasicQuestion>
        <BasicAnswer>
          You can refer Goody to workspace members, who are your coworkers and
          are employed by the same company, by inviting them from the Workspace
          page on Goody. If that workspace member does not already have a Goody
          for Business account, you will receive a $50 credit when that
          workspace member signs up for Goody for Business, joins your workspace
          and successfully sends and pays for a Goody gift (minimum order value
          $35, inclusive of any applicable promotions); provided that they sign
          up with a valid employee email address, their email is not from a free
          email service, the domain of their email address matches the domain of
          your email address (i.e. you work for the same employer), you have
          previously referred fewer than 10 workspace members, and you’re not
          adding new users to a Goody Team paid plan subscription.
        </BasicAnswer>
        <BasicQuestion>
          What's the maximum amount of referral credit I can get?
        </BasicQuestion>
        <BasicAnswer>
          You can refer and receive credit for up to 10 workspace members. After
          10, you can still invite other workspace members, but will no longer
          receive referral credit.
        </BasicAnswer>
        <BasicQuestion>How can I use referral credits?</BasicQuestion>
        <BasicAnswer>
          Referral credits can be used like any other credit on Goody and can be
          applied to qualifying purchases. Credits do not apply to subscription
          purchases. There might be restrictions on when credits can be used,
          for example certain products that credits cannot be applied toward.
        </BasicAnswer>
      </BasicSection>
      <BasicSection>
        <BasicHeader>Terms & Conditions</BasicHeader>
        <BasicSectionStart>
          <PrimarySpan>Effective Date</PrimarySpan>: 4/12/2022
        </BasicSectionStart>
        <BasicParagraphStart>
          Participants in the Goody Workspace Referral Program (“Program”) are
          subject to Goody’s{" "}
          <a
            href="https://www.ongoody.com/terms"
            target="_blank"
            rel="noopener noreferrer"
            tw="underline"
          >
            Terms of Use
          </a>{" "}
          and{" "}
          <a
            href="https://www.ongoody.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
            tw="underline"
          >
            Privacy Policy
          </a>{" "}
          (both available on our website), as well as the following additional
          Terms & Conditions for the Program. By participating in the Program,
          each participant represents and warrants that he/she has read and
          agrees to be bound by the{" "}
          <a
            href="https://www.ongoody.com/terms"
            target="_blank"
            rel="noopener noreferrer"
            tw="underline"
          >
            Terms of Use
          </a>
          ,{" "}
          <a
            href="https://www.ongoody.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
            tw="underline"
          >
            Privacy Policy
          </a>{" "}
          and these Terms & Conditions for the Program.
        </BasicParagraphStart>
        <BasicList>
          <BasicListItem>
            <PrimarySpan>Eligibility.</PrimarySpan> Goody for Business users are
            eligible to invite other people in their company to a Goody
            workspace under this Program.
          </BasicListItem>
          <BasicListItem>
            <PrimarySpan>Referral.</PrimarySpan> To refer eligible people to
            Goody, send them an invite via email to their email address.
          </BasicListItem>
          <BasicListItem>
            <PrimarySpan>Referral Award.</PrimarySpan> For every person that you
            refer via your code that (a) creates a Goody for Business account as
            a new user, (b) uses the workspace invite link that is associated
            with your account as the sender of the invite, (c) joins your Goody
            for Business workspace, (d) has, after signing up, a Goody account
            that is a work email and is not a free email account, (e) has, after
            signing up, a Goody account that has a domain (after the @ symbol)
            that matches the domain of your user account email, and (f) has not
            already created an account with Goody using a different email
            address, (g) is not being added on paid seats at a Goody Team
            account, (h) has successfully sent and paid for a Goody gift
            (minimum order value $35, inclusive of any applicable promotions),
            you will receive a $50 account credit from Goody, up to a maximum of
            10 referrals per account. $50 account credits may be used at any
            time on qualifying purchases. After you have referred 10 people to
            your workspace on your account, recipients will continue to be able
            to receive invites, but you will not receive additional credit for
            the referral once you have reached this maximum. If two or more
            individuals refer the same person, referral credit will be given
            based on who sent the workspace invite that was used to redeem the
            referral. Credit award decisions are in Goody's sole discretion.
            Credits may not be transferred; no cash or other substitutions.
          </BasicListItem>
          <BasicListItem>
            <PrimarySpan>Referral Messages.</PrimarySpan> Referral messages
            should be sent only through the Program as described in these Terms
            & Conditions. Please send your referrals only to eligible people,
            and limit your referrals to a reasonable number. Use only truthful,
            non-misleading statements that accurately reflect Goody's services
            and its Referral Program when sending a referral message. Referring
            persons must comply with all applicable laws, regulations and
            guidelines, including up-to-date “SPAM” laws. For example, the
            emails that you enter to invite others should be the emails of
            individuals that work at the same company as you. Bulk email
            distribution and any use of automated devices or third party
            services is prohibited. Any use of the Referral program that could
            constitute unsolicited commercial email or "spam" under any
            applicable law or regulation, or that otherwise violates any
            restriction imposed by Goody or is otherwise inappropriate as
            determined by Goody in its sole discretion, is expressly prohibited
            and will be grounds for immediate exclusion from the Program.
          </BasicListItem>
          <BasicListItem>
            <PrimarySpan>Right to Cancel Program or Change Terms.</PrimarySpan>{" "}
            Goody reserves the right to cancel the Referral Program or to change
            these Terms & Conditions at any time in its sole discretion. Any
            unclaimed referral rewards will be forfeited at that time. Goody
            reserves the right to disqualify anyone from this Program if any
            participant abuses this Program, as determined by Goody in its sole
            discretion.
          </BasicListItem>
          <BasicListItem>
            <PrimarySpan>Severability.</PrimarySpan> If any provision in these
            terms are held to be invalid, void, or unenforceable, such provision
            (or the part of it that is making it invalid, void or unenforceable)
            will be struck and not affect the validity of and enforceability of
            the remaining provisions.
          </BasicListItem>
        </BasicList>
      </BasicSection>
    </BasicContainer>
  )
}

export default ReferralTerms
