import React from "react"
import Modal from "react-modal"
import tw, { styled } from "twin.macro"

import GradientButton, { buttonStyle } from "@/common/GradientButton"
import { topModalStyle } from "@/common/modal"
import { useGiftPreview } from "@/send/hooks/giftPreview"
import CircleYellowLarge from "@/send/images/circle-yellow-large.svg"
import CircleYellowSmall from "@/send/images/circle-yellow-small.svg"
import TriangleAlert from "@/send/images/triangle-alert.svg"

export const GiftPreviewNoCartModal = () => {
  const { noCartModalOpen, setNoCartModalClosed, openPreview } =
    useGiftPreview()

  return (
    <Modal
      isOpen={noCartModalOpen}
      closeTimeoutMS={500}
      onRequestClose={setNoCartModalClosed}
      onAfterClose={() => noCartModalOpen}
      shouldCloseOnOverlayClick={true}
      style={topModalStyle}
    >
      <div
        className="modal-content modal-content-wide"
        tw="flex flex-col bg-white text-center w-full items-stretch overflow-hidden"
      >
        <StackedItemContainer tw="h-[72px] bg-[#FEFCE8]">
          <ImageContainer>
            <img src={CircleYellowLarge} alt="Circle Yellow Small" />
          </ImageContainer>
          <ImageContainer>
            <img src={CircleYellowSmall} alt="Circle Yellow Small" />
          </ImageContainer>
          <ImageContainer>
            <img src={TriangleAlert} alt="Triangle Alert" />
          </ImageContainer>
        </StackedItemContainer>
        <div tw="flex flex-col items-center p-8">
          <div tw="font-medium text-2xl">You haven’t selected a gift yet</div>
          <div tw="mt-4">
            Do you want to see a preview with a sample product?
          </div>
          <div tw="flex flex-row gap-4 mt-8 self-stretch">
            <button
              onClick={() => setNoCartModalClosed()}
              css={[buttonStyle, "height: 52px;"]}
              tw="flex-1 text-base text-gray-500 text-center justify-center font-normal bg-white border border-gray-200"
            >
              No, go back
            </button>
            <GradientButton
              onClick={() => {
                setNoCartModalClosed()
                openPreview(true)
              }}
              tw="flex-1 text-center text-base justify-center"
              css="height: 52px;"
            >
              Yes, see sample
            </GradientButton>
          </div>
        </div>
      </div>
    </Modal>
  )
}

const StackedItemContainer = styled.div`
  ${tw`grid items-center justify-center`}
  & > * {
    grid-column: 1 / 1;
    grid-row: 1 / 1;
  }
`

const ImageContainer = tw.div`flex flex-col items-center justify-center`
