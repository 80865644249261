import { useEffect, useMemo, useRef } from "react"
import tw from "twin.macro"

import CountryListFilterButton from "./components/CountryListFilterButton"
import CountryListSelector from "./components/CountryListSelector/CountryListSelector"

import { useToggle } from "@/common/hooks/useToogle"
import TractorBeam from "@/common/TractorBeam"
import TransitionHideable from "@/common/TransitionHideable"
import { ShippingCountry } from "@/types/graphql-types"

interface Props {
  selectedCountry: ShippingCountry
  setSelectedCountry: (country: ShippingCountry) => void
  countries: Record<"domestic" | "global", ShippingCountry[]>
  withProUpsell?: boolean
  withinScrollable?: boolean
}

const EXCLUDED_COUNTRY_GROUPS = ["GIFT_CARD"]
const SORTED_COUNTRIES = ["GLOBAL", "US"]

const ShippingCountryListFilter = ({
  countries,
  selectedCountry,
  setSelectedCountry,
  withProUpsell,
  withinScrollable = false,
}: Props) => {
  const ref = useRef(null)
  const [open, toggleOpen, setOpen] = useToggle(false)

  // If loaded with #global, then trigger open and remove hash
  useEffect(() => {
    if (window.location.hash === "#global") {
      setOpen(true)

      // This component can be rendered multiple times, especially when using
      // StrictMode. This causes the hash to be removed and not available on
      // the next render. So, only clear it after a delay.
      setTimeout(() => {
        if (window.location.hash === "#global") {
          window.history.replaceState({}, "", window.location.pathname)
        }
      }, 500)
    }
  }, [])

  const onSelectCountry = (country: ShippingCountry) => {
    setSelectedCountry(country)
    setOpen(false)
  }

  const groupedCountries = useMemo(
    () => ({
      domestic: sortCountries(countries.domestic || []),
      global: sortCountries(countries.global || []),
    }),
    [countries],
  )

  const countryListProps = {
    groupedCountries,
    selectedCountry,
    onSelectCountry,
    withProUpsell,
  }

  return (
    <>
      <div
        css={[
          tw`fixed top-0 right-0 bg-white z-10`,
          open ? tw`opacity-40 h-screen w-screen` : tw`opacity-0 h-0 w-0`,
          tw`transition-opacity duration-500 ease-out`,
        ]}
        onClick={() => setOpen(false)}
      />
      <div ref={ref}>
        <TractorBeam anchor={ref} withinScrollable={withinScrollable}>
          <TransitionHideable
            hidden={!open}
            twBase={[
              tw`transition-all duration-200 ease-out`,
              tw`absolute w-max top-[calc(100% + 8px)] right-0`,
              tw`z-[1300]`,
            ]}
            twHidden={[tw`opacity-0 scale-90`]}
            twVisible={tw`opacity-100 scale-100`}
          >
            <CountryListSelector {...countryListProps} />
          </TransitionHideable>
        </TractorBeam>
        <CountryListFilterButton
          country={selectedCountry}
          open={open}
          onOpen={toggleOpen}
        />
      </div>
    </>
  )
}

const sortCountries = (countries: ShippingCountry[]) => {
  return countries
    .slice()
    .sort((a, b) =>
      SORTED_COUNTRIES.includes(a.code)
        ? -1
        : SORTED_COUNTRIES.includes(b.code)
        ? 1
        : a.name.localeCompare(b.name),
    )
    .filter((country) => !EXCLUDED_COUNTRY_GROUPS.includes(country.code))
}

export default ShippingCountryListFilter
