import tw from "twin.macro"

import { SettingBase } from "./SettingBase"

import { useFeatureAccess } from "@/common/hooks/featureAccess"
import InternationalShippingOptions from "@/common/InternationalShippingOptions"
import { ProTag } from "@/common/ProTag"
import { SelectionGroupColorEnum } from "@/common/SelectionGroup/types"
import { ReactComponent as Icon } from "@/send/v3/options/icons/international-shipping.svg"
import { InternationalShippingTierEnum } from "@/types/graphql-types"

interface Props {
  selectedTier: InternationalShippingTierEnum
  setInternationalShippingTier: (
    internationalShippingTier: InternationalShippingTierEnum,
  ) => void
  giftCardsEnabled: boolean
  setGiftCardsEnabled: (giftCardsEnabled: boolean) => void
  hidden?: boolean
  isExpandable?: boolean
  color?: SelectionGroupColorEnum
  alignPreviewName?: string
  leftPadChildrenContainer?: boolean
}

export const InternationalShippingSetting = ({
  selectedTier,
  setInternationalShippingTier,
  giftCardsEnabled,
  setGiftCardsEnabled,
  hidden,
  isExpandable,
  color,
  alignPreviewName,
  leftPadChildrenContainer,
}: Props) => {
  const { hasFeature } = useFeatureAccess()
  const hasPro = hasFeature("pro_plan")

  if (hidden) {
    return null
  }

  const getPreviewText = (selectedTier: InternationalShippingTierEnum) => {
    switch (selectedTier) {
      case InternationalShippingTierEnum.disabled:
        return "Disabled"
      case InternationalShippingTierEnum.standard:
        return "Standard"
      case InternationalShippingTierEnum.full:
        return "Global"
      default:
        return null
    }
  }

  const showProUpsell = !hasPro

  return (
    <SettingBase
      name="International shipping"
      icon={<Icon />}
      preview={
        <div
          tw="flex flex-row items-center gap-3"
          // When non-pro, account for the extra padding added by ProTag
          css={[
            showProUpsell && tw`mt-[-2px]`,
            alignPreviewName === "right" && tw`md:justify-end`,
          ]}
        >
          {getPreviewText(selectedTier)}
          {showProUpsell && (
            <ProTag feature="international_shipping" stopPropagation={true} />
          )}
        </div>
      }
      color={color}
      isExpandable={isExpandable}
      leftPadChildrenContainer={leftPadChildrenContainer}
    >
      <InternationalShippingOptions
        showDisabledTier
        internationalShippingTier={selectedTier}
        setInternationalShippingTier={setInternationalShippingTier}
        giftCardsEnabled={giftCardsEnabled}
        setGiftCardsEnabled={setGiftCardsEnabled}
        color={color}
      />
    </SettingBase>
  )
}
