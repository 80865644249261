import pluralize from "pluralize"
import { useEffect } from "react"
import tw from "twin.macro"

import VariantButton from "./VariantButton"
import VariantGroups from "./VariantGroups"
import { errorToast } from "../../common/toast"

import { Details_ProductFragment } from "@/types/graphql-types"

interface Props {
  variants: Details_ProductFragment["variants"]
  variantsLabel: string | null | undefined
  variantsNumSelectable: number | null | undefined
  selectedVariants: string[] | null
  setSelectedVariants: (variants: string[] | null) => void
  variantGroups: Details_ProductFragment["variantGroups"]
  hideLetRecipientDecide?: boolean
  hideLabel?: boolean
  horizontalPopupPosition?: "left" | "right"
  imagesScalable?: boolean
}

// Renders an interactive product variant selector.
//
// Supports regular variants, variants with variantsNumSelectable > 1, and variant groups.
// Sends selected variants to the caller in setSelectedVariants.
//
// When using variant groups, the setSelectedVariants callback will only be
// called with a single variant, but that variant might be invalid (incomplete)
// since the user might be in the middle of selecting separate parts of a
// variant group.
const InteractiveProductVariants = ({
  variants,
  variantsLabel,
  variantsNumSelectable,
  selectedVariants,
  setSelectedVariants,
  variantGroups,
  hideLetRecipientDecide,
  hideLabel,
  horizontalPopupPosition,
  imagesScalable,
}: Props) => {
  const isMultiple = !!(variantsNumSelectable && variantsNumSelectable > 1)

  let variantsDisplayLabel = generateVariantsDisplayLabel(
    variantsLabel,
    variantsNumSelectable,
  )

  const usingVariantGroups = variantGroups && variantGroups.length > 0

  const canIncrement =
    isMultiple &&
    (!selectedVariants || selectedVariants.length < variantsNumSelectable!)
  const canDecrement = !!(
    isMultiple &&
    selectedVariants &&
    selectedVariants.length > 0
  )

  useEffect(() => {
    if (selectedVariants && selectedVariants.length === 0) {
      setSelectedVariants(null)
    }
  }, [selectedVariants])

  function increment(variant: string) {
    if (!canIncrement) {
      errorToast(
        `You’ve reached the maximum of ${variantsNumSelectable} options.`,
      )
      return
    }

    setSelectedVariants([...(selectedVariants || []), variant])
  }

  function decrement(variant: string) {
    if (!canDecrement) {
      return
    }

    if (selectedVariants) {
      const newSelections = [...selectedVariants]
      const foundIndex = selectedVariants.indexOf(variant)
      if (foundIndex >= 0) {
        newSelections.splice(foundIndex, 1)
        setSelectedVariants(newSelections)
      }
    }
  }

  return (
    <div tw="pt-6">
      {!usingVariantGroups && !hideLabel && (
        <div tw="font-medium pb-2">{variantsDisplayLabel}</div>
      )}
      <div
        tw="flex flex-row flex-wrap relative"
        css={[isMultiple && tw`flex-col max-w-[350px]`]}
      >
        {!hideLetRecipientDecide && (
          <VariantButton
            key="let_recipient_decide"
            variant={null}
            count={selectedVariants === null ? 1 : 0}
            onClick={() => {
              setSelectedVariants(null)
            }}
            isMultiple={isMultiple}
            imagesScalable={imagesScalable}
          />
        )}
        {usingVariantGroups ? (
          <VariantGroups
            selections={selectedVariants || undefined}
            variants={variants}
            data={variantGroups!}
            setVariant={(variant) => {
              setSelectedVariants([variant])
            }}
            selectedVariants={selectedVariants}
            disabled={false}
            imagesScalable={imagesScalable}
            selectable={!hideLetRecipientDecide}
          />
        ) : (
          variants.map((variant) => (
            <VariantButton
              key={variant.name}
              variant={variant}
              // count is number of variant.name in selectedVariants
              count={
                selectedVariants?.filter((v) => v === variant.name).length || 0
              }
              onClick={() => {
                if (isMultiple) {
                  increment(variant.name)
                } else {
                  setSelectedVariants([variant.name])
                }
              }}
              isMultiple={isMultiple}
              onIncrement={() => {
                increment(variant.name)
              }}
              onDecrement={() => {
                decrement(variant.name)
              }}
              canIncrement={canIncrement}
              canDecrement={canDecrement}
              horizontalPopupPosition={horizontalPopupPosition}
              imagesScalable={imagesScalable}
            />
          ))
        )}
      </div>
    </div>
  )
}

export function generateVariantsDisplayLabel(
  variantsLabel: string | null | undefined,
  variantsNumSelectable: number | null | undefined,
  plural = true,
) {
  let label = plural
    ? pluralize.plural(variantsLabel || "Variant")
    : variantsLabel
  if (variantsNumSelectable && variantsNumSelectable > 1) {
    label += ` (select ${variantsNumSelectable})`
  }

  return label
}

export default InteractiveProductVariants
