import React from "react"
import { Redirect } from "react-router-dom"

import AccountSection from "./components/AccountSection"
import AccountSupport from "./components/AccountSupport"
import DataPrivacyRequestButtons from "./dataPrivacyComponents/DataPrivacyRequestButtons"
import RequestDataAccess from "./dataPrivacyComponents/RequestDataAccess"
import RequestDataDeletion from "./dataPrivacyComponents/RequestDataDeletion"
import { useGlobalState } from "../common/GlobalState"
import { ReactComponent as UserIcon } from "../common/images/user.svg"
import { generateRealmPath } from "../common/realm"
import RealmRoute from "../common/RealmRoute"
import Container from "../sites/App/Container"

import PaymentMethods from "@/common/payments/PaymentMethods"

const AccountConsumer = () => {
  const [user] = useGlobalState("user")

  if (!user) {
    return <Redirect to={generateRealmPath("consumer", "/")} />
  }

  return (
    <>
      <RealmRoute exact path="/account/request-data-deletion" realm="consumer">
        <RequestDataDeletion />
      </RealmRoute>
      <RealmRoute exact path="/account/request-data-access" realm="consumer">
        <RequestDataAccess />
      </RealmRoute>
      <RealmRoute exact realm="consumer" path="/account">
        <div tw="border-t border-primary-100">
          <Container tw="px-5">
            <div tw="pt-10">
              <div tw="flex flex-row items-center">
                <UserIcon tw="stroke-current text-gray-400 stroke-1.5 w-7 h-7" />
                <div tw="pl-3 text-2xl font-medium">Account</div>
              </div>
            </div>
          </Container>
          <div tw="container mx-auto bg-white ">
            <div tw="p-6 pt-12 lg:w-9/12">
              <AccountSection headline="Payment">
                <PaymentMethods title={undefined} balance={false} />
              </AccountSection>
              <DataPrivacyRequestButtons />
              <AccountSupport supportEmail="support@ongoody.com" />
            </div>
          </div>
        </div>
      </RealmRoute>
    </>
  )
}

export default AccountConsumer
