import React from "react"
import { Link } from "react-router-dom"
import { useIntercom } from "react-use-intercom"
import tw, { styled } from "twin.macro"

import AccountDropdownBalanceOrCredit from "./AccountDropdownBudget"
import AccountMenuItems from "./AccountMenuItems"
import MenuGroup from "./MenuGroup"
import { MenuItemHeaderNavLink } from "./MenuItem"
import { TopMenuItemHeaderNavLink } from "./TopMenuItem"
import UserDropdownTrigger from "./UserDropdownTrigger"
import { ReactComponent as BuildingIcon } from "../../../assets/icons/building-transparent.svg"
import { ReactComponent as HelpIcon } from "../../../assets/icons/help-circle.svg"
import { ReactComponent as ReportsIcon } from "../../../assets/icons/reports.svg"
import DropdownMenu, { DropdownMenuPanel } from "../../../common/DropdownMenu"
import { useGlobalState } from "../../../common/GlobalState"
import { UpgradeToProHeaderTag } from "../../../common/ProTag"
import { generateRealmPath } from "../../../common/realm"

const TopMenu: React.FC = () => {
  const [user] = useGlobalState("user")
  const { show: showIntercom } = useIntercom()

  return (
    <StyledTopMenu>
      <UpgradeToProHeaderTag />
      <div tw="hidden xl:flex">
        <Link to={generateRealmPath("plus", "/account/referrals")}>
          <span tw="font-medium text-[15px]">Refer & Earn</span>
        </Link>
      </div>
      <button onClick={showIntercom}>
        <HelpIcon /> <span tw="font-medium text-[15px]">Support</span>
      </button>
      <Link to={generateRealmPath("plus", "/reports")}>
        <ReportsIcon /> <span tw="font-medium text-[15px]">Reports</span>
      </Link>
      {user?.isOrganizationAdmin && (
        <Link to={generateRealmPath("plus", "/organization")}>
          <BuildingIcon />{" "}
          <span tw="font-medium text-[15px]">Organization</span>
        </Link>
      )}
      {user && (
        <DropdownMenu trigger={<UserDropdownTrigger />}>
          <AccountDropdownMenuPanel>
            <AccountDropdownBalanceOrCredit variant="credit" />
            <AccountDropdownBalanceOrCredit variant="balance" />
            <MenuGroup>
              <AccountMenuItems />
            </MenuGroup>
          </AccountDropdownMenuPanel>
        </DropdownMenu>
      )}
    </StyledTopMenu>
  )
}

const StyledTopMenu = styled.div`
  ${tw`hidden lg:flex gap-7 items-center font-text`}

  > a, > button, .user, div > a {
    ${tw`flex gap-2 text-gray-450 hover:text-gray-600 active:text-gray-400 transition-colors items-center outline-none`}

    &:hover {
      svg {
        ${tw`text-gray-500`}
      }
    }

    &:active {
      svg {
        ${tw`text-gray-300`}
      }
    }
  }

  svg {
    ${tw`text-gray-400 stroke-current transition-colors`}
  }

  .user {
    ${tw`text-gray-500 transition-colors`}

    > svg {
      ${tw`text-gray-400 stroke-current stroke-2 bg-gray-200 rounded-full p-1 transition-colors`}
    }
  }

  nav {
    .trigger {
      ${tw`flex items-center`}

      &:hover {
        .user {
          ${tw`text-gray-700`}

          > svg {
            ${tw`text-white stroke-current stroke-2 bg-purple-500 rounded-full p-1`}
          }
        }
      }
    }

    &.expanded .trigger {
      .user {
        ${tw`text-gray-700`}

        > svg {
          ${tw`text-white stroke-current stroke-2 bg-purple-500 rounded-full p-1`}
        }
      }
    }
  }
`

export const AccountDropdownMenuPanel = styled(DropdownMenuPanel)`
  ${tw`absolute z-50 block hidden shadow-none shadow-lg border-none lg:border right-0 top-8 lg:top-10`}
  min-width: 14rem;

  ${MenuItemHeaderNavLink}, ${TopMenuItemHeaderNavLink} {
    ${tw`text-base text-gray-600 gap-4 w-full`}

    &:hover {
      &,
      svg {
        ${tw`text-primary-500`}
      }
    }

    svg {
      ${tw`text-gray-400 w-6`}
    }
  }
`

export default TopMenu
