import { gql, useLazyQuery, useMutation } from "@apollo/client"
import * as Sentry from "@sentry/react"
import { useEffect, useState } from "react"

import AutogiftSameDayAvailabilityMessage from "./AutogiftSameDayAvailabilityMessage"
import { useGlobalState } from "../../common/GlobalState"
import { getAutogiftProductPrice } from "../helpers/getAutogiftProductPrice"

import { InternationalShippingTierEnum } from "@/types/graphql-types"
import {
  AutogiftRuleProduct,
  AutogiftRule_PriceEstimateMutation,
  AutogiftRule_PriceEstimateMutationVariables,
  Autogift_ProductQuery,
  Autogift_ProductQueryVariables,
} from "@/types/graphql-types"

interface MaxAutogiftProduct {
  id: string
  flexGiftPrice: number | null
  giftCardPrice: number | null
}

const AutogiftReview = () => {
  const [currentAutogiftRule, setCurrentAutogiftRule] = useGlobalState(
    "currentAutogiftRule",
  )

  const [maxProduct, setMaxProduct] = useState<MaxAutogiftProduct | null>(null)
  const [priceEstimateMutation] = useMutation<
    AutogiftRule_PriceEstimateMutation,
    AutogiftRule_PriceEstimateMutationVariables
  >(GET_PRICE_ESTIMATE_MUTATION)

  const [getProduct] = useLazyQuery<
    Autogift_ProductQuery,
    Autogift_ProductQueryVariables
  >(AUTOGIFT_PRODUCT_QUERY, {
    onCompleted: (data) => {
      handleMaxProduct(data)
    },
  })

  // Handles logic to set the max product state variable
  const handleMaxProduct = (data: Autogift_ProductQuery) => {
    if (!data || !data.product) {
      return
    }

    // Product is not a flex gift or gift card
    if (!data.product?.isFlexGift && !data.product?.isGiftCard) {
      setMaxProduct({
        id: data.product.id,
        flexGiftPrice: null,
        giftCardPrice: null,
      })
      return
    }

    // Product is either a flex gift or gift card, so get user selected amount
    const userAmount = getAutogiftProductPrice(
      data.product.id,
      currentAutogiftRule.productList,
    )

    setMaxProduct({
      id: data.product.id,
      flexGiftPrice: data.product?.isFlexGift ? userAmount : null,
      giftCardPrice: data.product?.isGiftCard ? userAmount : null,
    })
  }

  const runPriceEstimateAndLoadReview = async (
    productId: string | null,
    flexGiftPrice: number | null,
    giftCardAmount: number | null,
  ) => {
    if (productId) {
      const { data } = await priceEstimateMutation({
        variables: {
          productID: productId,
          flexGiftPrice: flexGiftPrice,
          giftCardAmount: giftCardAmount,
        },
      })

      if (data?.priceEstimate && data.priceEstimate.ok) {
        await loadPriceEstimateIntoCurrentAutogiftRule(data?.priceEstimate)
      } else {
        alert("An error occurred when generating a price estimate.")
        Sentry.captureException(
          new Error(
            `Error generating price estimate: ${data?.priceEstimate?.error}`,
          ),
        )
      }
    }
  }

  const loadPriceEstimateIntoCurrentAutogiftRule = async (
    priceEstimate: AutogiftRule_PriceEstimateMutation["priceEstimate"],
  ) => {
    if (
      priceEstimate &&
      priceEstimate.priceProduct !== null &&
      priceEstimate.priceProduct !== undefined &&
      priceEstimate.priceShipping !== null &&
      priceEstimate.priceShipping !== undefined &&
      priceEstimate.priceEstTaxLow !== null &&
      priceEstimate.priceEstTaxLow !== undefined &&
      priceEstimate.priceEstTaxHigh !== null &&
      priceEstimate.priceEstTaxHigh !== undefined &&
      priceEstimate.priceEstTotalLow !== null &&
      priceEstimate.priceEstTotalLow !== undefined &&
      priceEstimate.priceEstTotalHigh !== null &&
      priceEstimate.priceEstTotalHigh !== undefined &&
      priceEstimate.priceProcessingFee !== null &&
      priceEstimate.priceProcessingFee !== undefined
    ) {
      setCurrentAutogiftRule({
        ...currentAutogiftRule,
        maximumProductPrice:
          priceEstimate?.priceProduct +
          priceEstimate?.priceShipping +
          priceEstimate.priceProcessingFee,
        processingFee: priceEstimate.priceProcessingFee,
        estimatedTaxLow: priceEstimate.priceEstTaxLow,
        estimatedTaxHigh: priceEstimate.priceEstTaxHigh,
        estimatedTotalLow: priceEstimate.priceEstTotalLow,
        estimatedTotalHigh: priceEstimate.priceEstTotalHigh,
      })
    }
  }

  const getMaximumPricedProductId = (productList: AutogiftRuleProduct[]) => {
    let maxPrice: number | null = null
    let maxPricedProductId = null

    productList.forEach((product) => {
      const priceToCompare =
        product.priceEstimate.priceProduct +
        product.priceEstimate.priceShipping +
        product.priceEstimate.priceProcessingFee

      if (maxPrice === null || priceToCompare > maxPrice) {
        maxPrice = priceToCompare
        maxPricedProductId = product.productId
      }
    })

    return maxPricedProductId
  }

  // When the product list changes, calculate the max priced product and query that product
  useEffect(() => {
    if (currentAutogiftRule.productList) {
      const maxPricedProductId = getMaximumPricedProductId(
        currentAutogiftRule.productList,
      )

      if (maxPricedProductId) {
        getProduct({
          variables: {
            id: maxPricedProductId,
            allowAllVisibility: true,
          },
        })
      }
    }
  }, [currentAutogiftRule.productList])

  // When either the max priced product, the max gift card, or the flex gift price changes,
  //   run price estimate and update pricing in the review
  useEffect(() => {
    if (maxProduct !== null) {
      runPriceEstimateAndLoadReview(
        maxProduct.id,
        maxProduct.flexGiftPrice,
        maxProduct.giftCardPrice,
      )
    }
  }, [maxProduct])

  return (
    <div>
      <div
        css={{
          width: 530,
          lineHeight: "19px",
          paddingLeft: 60,
          paddingRight: 60,
        }}
      >
        <div tw="text-xl text-gray-700 mb-5">Review</div>
        {currentAutogiftRule.maximumProductPrice !== null &&
        currentAutogiftRule.processingFee !== null &&
        currentAutogiftRule.estimatedTaxLow !== null &&
        currentAutogiftRule.estimatedTaxHigh !== null &&
        currentAutogiftRule.estimatedTotalLow !== null &&
        currentAutogiftRule.estimatedTotalHigh !== null ? (
          <div>
            <div tw="flex flex-row mb-5">
              <div>
                Maximum subtotal with shipping
                {currentAutogiftRule.internationalShippingTier ===
                  InternationalShippingTierEnum.full && (
                  <div tw="text-sm text-gray-500 mt-1">
                    Additional fee of up to $50 applies for international
                    shipping
                  </div>
                )}
              </div>
              <div tw="ml-auto">
                $
                {(currentAutogiftRule.maximumProductPrice -
                  currentAutogiftRule.processingFee) /
                  100}
              </div>
            </div>
            <div tw="flex flex-row mb-5">
              <div>Processing fee (5%)</div>
              <div tw="ml-auto">
                ${(currentAutogiftRule.processingFee / 100).toFixed(2)}
              </div>
            </div>
            <div tw="flex flex-row mb-5">
              <div>Estimated tax (0-10%)</div>
              <div tw="ml-auto">
                ${currentAutogiftRule.estimatedTaxLow / 100} – $
                {(currentAutogiftRule.estimatedTaxHigh / 100).toFixed(2)}
              </div>
            </div>
            <div tw="flex flex-row mb-8 font-medium">
              <div>Estimated total per recipient</div>
              <div tw="ml-auto">
                ${(currentAutogiftRule.estimatedTotalLow / 100).toFixed(2)} – $
                {(currentAutogiftRule.estimatedTotalHigh / 100).toFixed(2)}
              </div>
            </div>
            <div tw="mb-5 text-gray-500" css={{ lineHeight: "140%" }}>
              You will only be charged for each gift when the recipient accepts.
              You can cancel pending gifts or pause this autogift at any time.
            </div>
            <div tw="mb-6 text-gray-500" css={{ lineHeight: "140%" }}>
              If a product’s price changes and exceeds your maximum subtotal,
              another product in your list of gift options at or under that
              subtotal will be chosen.
            </div>
          </div>
        ) : (
          <div tw="mb-4">
            Please select a product to see the price estimate.
          </div>
        )}
        <AutogiftSameDayAvailabilityMessage />
      </div>
    </div>
  )
}

const GET_PRICE_ESTIMATE_MUTATION = gql`
  mutation AutogiftRule_PriceEstimate(
    $productID: ID!
    $flexGiftPrice: Int
    $giftCardAmount: Int
  ) {
    priceEstimate(
      productId: $productID
      flexGiftPrice: $flexGiftPrice
      giftCardAmount: $giftCardAmount
      isPlus: true
      plusRecipients: 1
      omitCredit: true
    ) {
      ok
      error
      priceProduct
      priceShipping
      priceEstTaxLow
      priceEstTaxHigh
      priceEstTotalLow
      priceEstTotalHigh
      priceProcessingFee
      isFlexGift
    }
  }
`

export const AUTOGIFT_PRODUCT_QUERY = gql`
  query Autogift_Product($id: ID!, $allowAllVisibility: Boolean!) {
    product(productId: $id, allowAllVisibility: $allowAllVisibility) {
      id
      isFlexGift
      isGiftCard
    }
  }
`

export default AutogiftReview
