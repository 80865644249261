import Drawer from "@mui/material/Drawer"
import React, { Dispatch, SetStateAction, useEffect, useState } from "react"

import AutogiftConfigDrawer from "./AutogiftConfigDrawer"
import AutogiftIndex from "./AutogiftIndex"

interface AutogiftDrawerProps {
  open: boolean
  contactListId: string
  contactListName: string | undefined
  contactListHRISSynced: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

const AutogiftIndexDrawer: React.FC<AutogiftDrawerProps> = ({
  open,
  contactListId,
  contactListName,
  contactListHRISSynced,
  setOpen,
}) => {
  const [indexOpen, setIndexOpen] = useState(false)
  const [configOpen, setConfigOpen] = useState(false)

  const openIndexDrawer = () => {
    setIndexOpen(true)
  }

  // close drawer and hide child components
  const closeIndexDrawer = () => {
    setIndexOpen(false)

    // To reset the open prop to false when closing the drawer
    // This must be called here also since it doesn't get called on the onClose callback when using this method
    // to close the drawer
    setOpen(false)
  }

  // when the open prop changes, open / close the drawer based on the prop
  // this will allow for opening/closing the drawer from outside the component
  useEffect(() => {
    if (open) {
      openIndexDrawer()
    } else {
      closeIndexDrawer()
    }
  }, [open])

  return (
    <>
      <Drawer
        anchor="right"
        open={indexOpen}
        onClose={() => {
          // To reset the open prop to false when closing the drawer
          setOpen(false)
        }}
      >
        {indexOpen && (
          <AutogiftIndex
            contactListId={contactListId}
            contactListName={contactListName}
            contactListHRISSynced={contactListHRISSynced}
            onSelectAutogiftRule={() => {
              setConfigOpen(true)
            }}
            onSelectNewAutogift={() => {
              setConfigOpen(true)
            }}
            closeAutogiftDrawer={closeIndexDrawer}
          />
        )}
      </Drawer>
      <AutogiftConfigDrawer
        open={configOpen}
        onClose={() => setConfigOpen(false)}
        contactListID={contactListId}
        contactListName={contactListName}
      />
    </>
  )
}

export default AutogiftIndexDrawer
