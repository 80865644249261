import { gql } from "@apollo/client"

import { SHIPPING_COUNTRY_GROUP_FRAGMENT } from "@/graphql/ShippingCountryGroupFragment"

export const GIFT_OPTION_PREVIEW_FRAGMENT = gql`
  fragment Store_GiftOptionPreview on GiftOption {
    id
    slug
    name
    subtitle
    description
    isAlcohol
    brand {
      id
      slug
      brandValues
      shippingPrice
      shippingCountriesFinal
      name
      restrictedStatesByCountry
      freeShippingMinimum
      hideMadeBySubtitle
      segments
    }
    products {
      customSwapMinimumPrice
      swapStoreSettings {
        priceType
        showCategories
      }
      swapStoreHeaderImage {
        url
      }
      price
    }
    primaryImage {
      imageLarge {
        url
        width
        height
      }
    }
    priceMin
    isFlexGift
    logoImage {
      url
      height
      width
    }
    colors {
      primary
      background
    }
    shippingCountryGroup {
      ...ShippingCountryGroup
    }
  }
  ${SHIPPING_COUNTRY_GROUP_FRAGMENT}
`
