import React from "react"
import tw, { styled } from "twin.macro"

import SendErrorAlertBubble from "@/send/SendErrorAlertBubble"

interface Props {
  card: {
    id: string
    image: string
  } | null
  setCardsModalOpen: (open: boolean) => void
}

// Section for selecting a card. Shared between consumer/plus
const CardSection: React.FC<Props> = ({ card, setCardsModalOpen }) => {
  return (
    <div tw="flex flex-col items-center">
      {card ? (
        <CardImage src={card.image} />
      ) : (
        <SendErrorAlertBubble
          text="Please select a card"
          subtext="A greeting card for your gift is required"
          error="card"
          position="below"
        >
          <button
            onClick={() => setCardsModalOpen(true)}
            css="width: 152px; height: 216px;"
            tw="border border-gray-200 flex flex-col items-center justify-center hover:border-primary-400 transition-colors rounded-xl"
          >
            <div tw="text-sm text-gray-600">Select card</div>
          </button>
        </SendErrorAlertBubble>
      )}
      <EditCardButton
        onClick={() => setCardsModalOpen(true)}
        css={card ? tw`` : tw`opacity-0 pointer-events-none cursor-pointer`}
      >
        Edit
      </EditCardButton>
    </div>
  )
}

const CardImage = styled.img`
  ${tw`rounded-xl w-[152px] h-[216px]`}
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.04))
    drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.04));
`

const EditCardButton = styled.button`
  ${tw`bg-white w-20 h-10 rounded-full border transition-all z-10 text-gray-500 mb-1 border-[#ECEEF1] hover:(border-primary-new-100 bg-primary-new-000 text-primary-new-500) active:scale-95`}
  margin-top: -1.25rem;
  box-shadow:
    0px 1px 4px rgba(0, 0, 0, 0.05),
    0px 4px 32px rgba(0, 0, 0, 0.04);
`

export default CardSection
