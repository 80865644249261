import { gql, useQuery } from "@apollo/client"
import React, { useEffect } from "react"
import { Helmet } from "react-helmet-async"
import { useHistory } from "react-router-dom"
import { Link } from "react-router-dom"

import AllPosts from "./AllPosts"
import { Container } from "./Blog.style"
import Hero from "./Hero"
import { WebflowBlogPost, mountUrl } from "./utils"

import Fontstand from "@/common/Fontstand"
import { useGlobalState } from "@/common/GlobalState"
import { GetWebflowPostsQuery } from "@/types/graphql-types"

const Blog = () => {
  const { data } = useQuery<GetWebflowPostsQuery>(GET_WEBFLOW_POSTS)
  const [_, setEagerLoadedPost] = useGlobalState("eagerLoadedPost")
  const history = useHistory()

  const posts = data?.webflowPosts || []

  const goToPost = (
    event: React.MouseEvent<HTMLAnchorElement>,
    post: WebflowBlogPost,
  ) => {
    event.preventDefault()

    setEagerLoadedPost(post)
    history.push(mountUrl(post))
  }

  useEffect(() => {
    // if the hash contains something, scroll to #blog-posts
    if (window.location.hash) {
      const element = document.getElementById("blog-posts")
      if (element) {
        element.scrollIntoView()
      }
    }
  })

  return (
    <>
      <Helmet>
        <title>Blog – Goody</title>
        <link rel="canonical" href="https://www.ongoody.com/blog" />
      </Helmet>
      <Fontstand />
      <Container>
        <Hero posts={posts} goToPost={goToPost} />
        <div id="blog-posts" />
        <AllPosts
          posts={posts}
          goToPost={goToPost}
          defaultCategorySlug={window.location.hash.replace("#", "")}
        />
        <Cta />
      </Container>
    </>
  )
}

const Cta = () => {
  return (
    <div className="content-section blog-cta-section">
      <div className="container">
        <div className="blog-cta" style={{ paddingBottom: 50 }}>
          <img
            src="https://assets.website-files.com/61be62f73beca575b799e3f5/61be62f73beca5615799e503_cta-gift.svg"
            loading="lazy"
            alt=""
            className="blog-cta-image"
            style={{ margin: "auto" }}
          />
          <h1 className="blog-cta-heading">START GIFTING TODAY.</h1>
          <Link to="/" className="blog-cta-button w-button" tw="font-text">
            Goody for Personal Gifting
          </Link>
          <Link
            to="/business"
            className="blog-cta-button dark w-button"
            tw="font-text"
          >
            Goody for Business
          </Link>
        </div>
      </div>
    </div>
  )
}

export const GET_WEBFLOW_POSTS = gql`
  query GetWebflowPosts {
    webflowPosts {
      title
      slug
      readLabel
      thumbnailUrl
      isRecentHit
      isFeatured
      overview
      excerpt
      category {
        name
        slug
      }
    }
  }
`

export default Blog
