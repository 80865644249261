import { gql, useMutation } from "@apollo/client"
import React from "react"

import { LandingPageContainer, LandingPageLink } from "./common"
import Button from "../../common/Button"
import { useCurrentWorkspace } from "../../common/hooks"
import { successToast } from "../../common/toast"
import { GIFT_BATCH_RECIPIENTS_QUERY } from "../queries"

import { GiftDispenserStatus, GiftSeriesFilter } from "@/types/graphql-types"
import {
  StandardGiftDispenserFragment,
  Track_GiftDispenser_CloseMutation,
  Track_GiftDispenser_CloseMutationVariables,
} from "@/types/graphql-types"

interface Props {
  giftDispenser: StandardGiftDispenserFragment
  giftBatchId: string
}

const GiftDispenserInfo: React.FC<Props> = ({ giftDispenser, giftBatchId }) => {
  const [closeGiftDispenser] = useMutation<
    Track_GiftDispenser_CloseMutation,
    Track_GiftDispenser_CloseMutationVariables
  >(GIFT_DISPENSER_CLOSE_MUTATION)
  const { currentWorkspace } = useCurrentWorkspace()

  const closeGiveaway = async () => {
    if (
      window.confirm(
        "Are you sure you want to close this giveaway? Any reserved gifts can still be accepted until they expire, but no new gifts can be reserved.",
      )
    ) {
      if (giftDispenser) {
        const res = await closeGiftDispenser({
          variables: {
            id: giftDispenser.id,
          },
          refetchQueries: [
            {
              query: GIFT_BATCH_RECIPIENTS_QUERY,
              variables: {
                id: giftBatchId,
                page: 1,
                filter: GiftSeriesFilter.all,
              },
              context: {
                workspaceId: currentWorkspace?.id,
              },
            },
          ],
        })

        if (res?.data?.giftDispenserClose?.ok) {
          successToast("Closed giveaway.")
        } else {
          alert("An error occurred.")
        }
      }
    }
  }

  return (
    <div tw="p-6 pt-0 mt-16 lg:pt-6">
      <LandingPageContainer>
        <div>
          <div tw="text-left pb-3 text-base pb-3 font-medium flex-1 whitespace-nowrap text-primary-500">
            {giftDispenser.quantityTotal}-gift giveaway
          </div>
          <LandingPageLink
            href={giftDispenser.sharedLink}
            previewHref={giftDispenser.sharedLink}
            previewClass="full-width"
            color="gray"
          />
          <div tw="pt-3 text-sm text-gray-500 text-left">
            When a gift is reserved, the recipient has 10 minutes to accept the
            gift until it expires and becomes available to reserve again.
          </div>
        </div>
        <div tw="w-1/4 pt-3 lg:pt-0 lg:pl-24 text-black text-left">
          <div tw="text-gray-500 pt-2">
            <span tw="font-medium text-gray-900">
              {giftDispenser.quantityAccepted}
            </span>{" "}
            accepted
          </div>
          {giftDispenser.quantityPendingNotExpired > 0 && (
            <div tw="text-gray-500 pt-2">
              <span tw="font-medium text-gray-900">
                {giftDispenser.quantityPendingNotExpired}
              </span>{" "}
              reserved
            </div>
          )}
          <div tw="text-gray-500 pt-2">
            <span tw="font-medium text-gray-900">
              {giftDispenser.quantityAvailable}
            </span>{" "}
            available to reserve
          </div>
        </div>
        <div tw="w-1/4 flex flex-row justify-end">
          {giftDispenser.status === GiftDispenserStatus.CLOSED ? (
            <div>Closed</div>
          ) : (
            <Button onClick={() => closeGiveaway()}>Close giveaway</Button>
          )}
        </div>
      </LandingPageContainer>
    </div>
  )
}

const GIFT_DISPENSER_CLOSE_MUTATION = gql`
  mutation Track_GiftDispenser_Close($id: ID!) {
    giftDispenserClose(id: $id) {
      ok
    }
  }
`

export default GiftDispenserInfo
