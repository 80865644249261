import React, { useState } from "react"
import Modal from "react-modal"
import { styled } from "twin.macro"

import Gift from "./Gift"
import { formatScheduledSendDate } from "../../common/format"
import { modalStyle } from "../../common/modal"
import GiftBatchEditModal from "../../track/components/GiftBatchEditModal"
import { giftContainerCss } from "../shared"

import { CartProductShipmentFragment } from "@/types/graphql-types"

interface Props {
  giftBatchId: string
  cartQuantities: number[]
  recipientFullName?: string | null
  brandName: string
  productTitle?: string
  scheduledSendOn: string
  refetchGifts: () => void
  cartProduct: CartProductShipmentFragment
}

const ScheduledGift = ({
  giftBatchId,
  cartQuantities,
  recipientFullName,
  brandName,
  productTitle,
  scheduledSendOn,
  refetchGifts,
  cartProduct,
}: Props) => {
  const [giftBatchEditModalOpen, setGiftBatchEditModalOpen] =
    useState<boolean>(false)

  const closeGiftBatchEditModal = () => {
    setGiftBatchEditModalOpen(false)
  }

  return (
    <>
      <GiftContainer
        onClick={() => {
          setGiftBatchEditModalOpen(true)
        }}
      >
        <Gift
          cartQuantities={cartQuantities}
          recipientFullName={recipientFullName}
          brandName={brandName}
          productTitle={productTitle}
          sendDateText={`Scheduled for ${formatScheduledSendDate(
            scheduledSendOn,
          )}`}
          isScheduledSend={true}
          giftStatus="Scheduled"
          cartProduct={cartProduct}
        />
      </GiftContainer>
      <Modal
        isOpen={giftBatchEditModalOpen}
        closeTimeoutMS={500}
        onRequestClose={closeGiftBatchEditModal}
        shouldCloseOnOverlayClick={true}
        style={modalStyle}
      >
        <GiftBatchEditModal
          giftBatchId={giftBatchId}
          giftBatchName={recipientFullName ?? ""}
          scheduledSendOn={formatScheduledSendDate(scheduledSendOn)}
          closeGiftBatchEditModal={closeGiftBatchEditModal}
          cancelOptionOnly={true}
          refetchGifts={refetchGifts}
        />
      </Modal>
    </>
  )
}

const GiftContainer = styled.button`
  ${giftContainerCss}
`

export default ScheduledGift
