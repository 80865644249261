import { gql, useMutation, useQuery } from "@apollo/client"
import { useState } from "react"
import { toast } from "react-hot-toast"
import tw, { styled } from "twin.macro"

import BackArrow from "../../common/BackArrow"
import Button from "../../common/Button"
import CalendarGiftSettings from "../../common/components/CalendarGiftSettings"
import ProUpsell from "../../common/components/ProUpsell"
import { useFeatureAccess } from "../../common/hooks/featureAccess"
import { useGiftData } from "../../common/hooks/giftData"
import useIsLimitedMember from "../../common/hooks/useIsLimitedMember"
import LimitedMemberFeatureGateBanner from "../../common/members/LimitedMemberFeatureGateBanner"
import { Loader } from "../../common/UI"

import { gift_meeting_setting } from "@/types/graphql-types"
import {
  CalendarIntegration,
  CalendarIntegrationQuery,
} from "@/types/graphql-types"

const Calendly = () => {
  const { data } = useQuery<CalendarIntegrationQuery>(CALENDLY_QUERY)
  const { hasFeature } = useFeatureAccess()

  const hasSubscription = hasFeature("pro_plan")

  return (
    <div tw="flex flex-col mt-[-1rem]">
      <BackArrow label="Integrations" />
      {!data ? (
        <div tw="flex justify-center items-center flex-1">
          <Loader />
        </div>
      ) : (
        <CalendlyContent
          hasSubscription={hasSubscription}
          calendarIntegrationData={data?.me?.calendarIntegration ?? null}
        />
      )}
    </div>
  )
}

const CalendlyContent = ({
  calendarIntegrationData,
  hasSubscription,
}: {
  calendarIntegrationData: CalendarIntegration | null
  hasSubscription: boolean
}) => {
  const isLimitedMember = useIsLimitedMember()
  const [updateIntegration] = useMutation(CALENDLY_INTEGRATION_UPDATE)
  const [calendlyApiUrl, setCalendlyApiUrl] = useState(
    calendarIntegrationData?.calendlyApiUrl ?? "",
  )

  const [giftSetting, setGiftSetting] = useState<gift_meeting_setting>(
    calendarIntegrationData?.giftSetting ?? gift_meeting_setting.NO_MEETING,
  )
  const { updateGiftCalendarSetting } = useGiftData()

  const updateCalendlyIntegration = async () => {
    const response = await updateIntegration({
      variables: {
        calendlyApiUrl: calendlyApiUrl,
        giftMeetingSetting: giftSetting,
      },
    })
    if (response.data.calendarIntegrationUpdate.ok) {
      toast.success("Saved calendar settings")
      updateGiftCalendarSetting(giftSetting)
    } else if (response.data.calendarIntegrationUpdate.errors) {
      alert(
        "Only Calendly links are supported at this time. To request another scheduling provider, please start a chat with us.",
      )
    }
  }

  const hasCalendlyIntegrationAccess = hasSubscription && !isLimitedMember

  const UpgradeBanner = () => {
    if (!hasSubscription) {
      return (
        <div tw="m-5">
          <ProUpsell
            feature="Calendly integration"
            headerFeatureName="Meeting calendar"
            descriptionFeatureName="calendar scheduling"
          />
        </div>
      )
    } else if (isLimitedMember) {
      return (
        <div tw="p-5">
          <LimitedMemberFeatureGateBanner />
        </div>
      )
    } else {
      return null
    }
  }

  return (
    <div tw="flex flex-col md:flex-row pt-3">
      <div tw="sm:mb-4 sm:px-2 md:px-0 md:mb-0 md:w-[29%] pt-9">
        <h1 tw="text-[28px] leading-[34px] font-semibold">Meeting Calendar</h1>
        <p tw="text-gray-500 text-base leading-p-140 font-normal mt-3">
          Set your meeting calendar link here, and choose the default setting
          for when your meeting calendar is shown on your gifts.
        </p>
      </div>
      <IntegrationFormContainer>
        <UpgradeBanner />
        <div
          css={[
            tw`p-9`,
            hasCalendlyIntegrationAccess
              ? ""
              : tw`opacity-50 pointer-events-none`,
          ]}
        >
          <div tw="mb-9">
            <div tw="font-semibold text-base leading-p-140">
              Your meeting calendar link
            </div>
            <div tw="text-sm leading-p-140 text-gray-450 font-normal mt-1">
              Supports Calendly
            </div>
            <CalendlyApiUrlInput
              className="data-hj-suppress ph-no-capture fs-exclude"
              onChange={(e) => setCalendlyApiUrl(e.target.value)}
              value={calendlyApiUrl}
              placeholder="https://calendly.com/calendly_username/30min"
            />
          </div>

          <div tw="md:max-w-[36.375rem]">
            <div tw="font-semibold text-base leading-p-140">
              Default calendar setting
            </div>
            <div tw="text-sm leading-p-140 text-gray-450 font-normal mt-1 mb-4">
              You can override this on the Send page for each gift. Applies for
              new gifts going forward.
            </div>
            <CalendarGiftSettings
              setGiftSetting={setGiftSetting}
              giftSetting={giftSetting}
            />

            <Button
              disabled={!hasCalendlyIntegrationAccess || calendlyApiUrl === ""}
              onClick={updateCalendlyIntegration}
              variant="updated"
              tw="mt-9"
            >
              Save
            </Button>
          </div>
        </div>
      </IntegrationFormContainer>
    </div>
  )
}

const CalendlyApiUrlInput = styled.input`
  ${tw`placeholder:text-gray-400 w-full max-w-[34.0625rem] rounded-lg outline-none leading-p-140 font-normal p-4 pt-[1.0625rem] mt-3 border-solid border border-[#eceef1] bg-white`}
`

const IntegrationFormContainer = styled.div`
  ${tw`w-full md:ml-[2.5625rem] border-solid border border-[#eceef1] rounded-xl bg-white`}

  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
`

export const CALENDLY_QUERY = gql`
  query CalendarIntegration {
    me {
      calendarIntegration {
        calendlyApiUrl
        giftSetting
      }
    }
  }
`

const CALENDLY_INTEGRATION_UPDATE = gql`
  mutation CalendarIntegration_Update(
    $calendlyApiUrl: String!
    $giftMeetingSetting: gift_meeting_setting!
  ) {
    calendarIntegrationUpdate(
      calendlyApiUrl: $calendlyApiUrl
      giftSetting: $giftMeetingSetting
    ) {
      ok
      errors
      calendarIntegration {
        calendlyApiUrl
        giftSetting
      }
    }
  }
`

export default Calendly
