import "twin.macro"
import { useEffect } from "react"
import { useHistory } from "react-router-dom"

import { track } from "@/common/analytics"
import { useFeatureAccess } from "@/common/hooks/featureAccess"
import { generateRealmPath } from "@/common/realm/utils"

const MOBILE_THRESHOLD = 768

// Redirects to the correct onboarding path based on whether the user has access
// to Onboarding V2.
export default function OnboardingRedirect() {
  const { hasFeature } = useFeatureAccess()
  const history = useHistory()

  useEffect(() => {
    if (hasFeature("onboarding_v2")) {
      track("Business - Onboarding - Start", {
        version: 2,
        is_mobile: window.innerWidth < MOBILE_THRESHOLD,
      })

      history.push(generateRealmPath("plus", "/post-signup/invite-v2"))
    } else {
      track("Business - Onboarding - Start", {
        version: 1,
        is_mobile: window.innerWidth < MOBILE_THRESHOLD,
      })

      history.push(generateRealmPath("plus", "/post-signup/company"))
    }
  }, [])

  return (
    <div tw="fixed inset-0 flex flex-row items-center justify-center text-gray-500 bg-white z-[100]">
      Loading…
    </div>
  )
}
