import { sha256 } from "crypto-hash"

export const hashPhoneNumber = async (phoneNumber: string) => {
  const cleanedPhoneNumber = phoneNumber.replaceAll(/\D/g, "")
  const hash = await sha256(cleanedPhoneNumber)

  const hashInt = parseInt(hash.slice(0, 8), 16)

  return hashInt % 1000
}
