import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import React from "react"
import Modal from "react-modal"

import AddButton from "./AddButton"
import newCard from "../../assets/images/payment-cards/new-card.svg"
import { track, useSegmentPrefix } from "../../common/analytics"
import { getEnvVars } from "../../common/environment"
import { useGiftData } from "../../common/hooks/giftData"
import { modalStyle } from "../../common/modal"
import { useRouteHasTag } from "../../common/TaggedRoute"
import AddPaymentMethodModal from "../AddPaymentMethodModal"
import addFundsPlus from "../images/add-funds-plus.svg"
import { PaymentMethodButton } from "../PaymentMethodButton"

const stripePromise = loadStripe(getEnvVars().stripeApiKey)

interface Props {
  onComplete?: () => void
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  textLine2?: string | undefined
}

export const AddCreditCard = ({
  onComplete,
  open,
  setOpen,
  textLine2,
}: Props) => {
  const { setHasRecentlyAddedCreditCard } = useGiftData()
  const segmentPrefix = useSegmentPrefix()
  const isSendPage = useRouteHasTag("send")

  const button = (
    <AddButton>
      <img src={addFundsPlus} alt="Add Card" tw="mr-1" />
      Add card
    </AddButton>
  )
  const text = "Credit Card"

  return (
    <>
      <PaymentMethodButton
        onClick={() => {
          setOpen(true)
          track(`${segmentPrefix}Payment Selector - Click Add Card`)
        }}
        image={<img src={newCard} alt={text} tw="w-[33px] h-[20px]" />}
        text={text}
        textLine2={textLine2}
        button={button}
      />
      <Modal
        isOpen={open}
        closeTimeoutMS={500}
        onRequestClose={() => {
          setOpen(false)
        }}
        onAfterClose={() => {}}
        shouldCloseOnOverlayClick={true}
        style={modalStyle}
        shouldCloseOnEsc={false}
      >
        <Elements stripe={stripePromise}>
          <AddPaymentMethodModal
            isOpen={open}
            onComplete={() => {
              if (onComplete) {
                onComplete()
              }
              if (isSendPage) {
                track("Business - Send - Add Credit Card")
                setHasRecentlyAddedCreditCard(true)
              }
              setOpen(false)
            }}
          />
        </Elements>
      </Modal>
    </>
  )
}
