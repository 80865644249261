import { useFeatureFlags } from "@/common/hooks/featureFlags"
import { BaseMeFragment } from "@/types/graphql-types"

export type ExperimentName = keyof typeof EXPERIMENTS

// eslint-disable-next-line unused-imports/no-unused-vars
interface ExperimentCallbackParameterType {
  user: BaseMeFragment | null
  hasFeatureFlag: ReturnType<typeof useFeatureFlags>["hasFeatureFlag"]
}

// Need at least one in here to fix typescript
export const EXPERIMENTS = {
  "send_-_gift_preview_-_new_users": {
    defaults: {
      gift_preview_enabled: false,
      group_name: null as string | null,
    },
    active: ({ user }: ExperimentCallbackParameterType) => !!user,
  },
} satisfies {
  [experimentName: string]: {
    defaults: {
      group_name: string | null
      loading?: never
    } & { [key: string]: any }
    active?: boolean | ((params: ExperimentCallbackParameterType) => boolean)
  }
}
