import React, { useEffect, useState } from "react"
import tw, { styled } from "twin.macro"

import EmailGateStarLarge from "./images/email-gate-star-large.svg"
import EmailGateStar from "./images/email-gate-star.svg"
import { disableScrolling, enableScrolling } from "../../../common/scrolling"

import { HubSpot } from "@/common/Script"
import { StackedItemContainer } from "@/common/UI"
import { useHolidayEmailGate } from "@/store/components/HolidayGiftGuideScreen/hooks/useHolidayEmailGate"

const EMAIL_GATE_TIMEOUT = 5000
const DISTANCE_ALLOWED_BEFORE_MODAL = 200

const HolidayGiftGuideEmailGate = () => {
  const [hubspotLoaded, setHubspotLoaded] = useState(false)

  const { requiresEmailGate, showEmailGate, onFormSubmit, tryAccessGiftGuide } =
    useHolidayEmailGate()

  useEffect(() => {
    if (showEmailGate) {
      disableScrolling()
    } else {
      enableScrolling()
    }
  }, [showEmailGate])

  useEffect(() => {
    if (hubspotLoaded) {
      // @ts-ignore
      window.hbspt.forms.create({
        region: "na1",
        portalId: "9308919",
        formId: "7987b419-7223-475c-bc2a-a8e3c0c19486",
        target: "#holiday-2024-form-container",
        onFormSubmit,
      })
    }
  }, [hubspotLoaded])

  useEffect(() => {
    setTimeout(() => tryAccessGiftGuide(), EMAIL_GATE_TIMEOUT)

    window.addEventListener("scroll", openModalIfScrolling, {
      passive: true,
    })
  }, [])

  const openModalIfScrolling = () => {
    if (window.pageYOffset >= DISTANCE_ALLOWED_BEFORE_MODAL) {
      tryAccessGiftGuide()
    }
  }

  return requiresEmailGate ? (
    <div
      css={[
        tw`h-full w-full z-[29] transition-opacity`,
        showEmailGate ? tw`opacity-100` : tw`opacity-0 pointer-events-none`,
      ]}
    >
      <>
        <HubSpot onLoad={() => setHubspotLoaded(true)} />
        <StackedItemContainer tw="h-full w-full">
          <div tw="opacity-80 bg-[#F6F8FA] relative" />
          <div>
            <PopupContainer>
              <div tw="absolute inset-0 pointer-events-none">
                <img
                  height={650}
                  width={650}
                  src={EmailGateStarLarge}
                  css="transform: translateX(-50%);"
                  tw="absolute top-[64px] md:top-[136px] left-[50%] bottom-0 my-auto z-[-1] max-w-[650px] max-h-[650px]"
                />
                <img
                  src={EmailGateStar}
                  tw="absolute top-0 left-[-60px] bottom-0 my-auto z-[-1]"
                />
                <img
                  src={EmailGateStar}
                  tw="absolute top-0 left-[50%] right-0 mx-auto top-[-54px] md:top-[-33px] z-[-1]"
                />
              </div>
              <div
                tw="text-5xl"
                css="font-family: ivyora-text; line-height: 58px"
              >
                <span tw="sm:whitespace-pre">Unwrap the</span>{" "}
                <span tw="md:whitespace-pre">
                  <span tw="sm:whitespace-pre">
                    <span tw="italic">Ultimate</span> Holiday
                  </span>{" "}
                  <span tw="sm:whitespace-pre">Gift Guide</span>
                </span>
              </div>
              <div tw="mt-6 text-xl">
                Explore hundreds of unique gift ideas for employees, clients,
                and more.
              </div>
              <FormSection tw="flex flex-row gap-4 mt-9 self-stretch">
                <div id="holiday-2024-form-container" tw="w-full" />
              </FormSection>
            </PopupContainer>
          </div>
        </StackedItemContainer>
      </>
    </div>
  ) : null
}

const PopupContainer = styled.div`
  ${tw`bg-white mx-4 md:mx-10 mt-32 md:mt-[200px] p-10 rounded-[20px] sticky top-[94px] md:top-[118px] lg:top-[184px] overflow-hidden`}
  background: linear-gradient(180deg, #c4ddff 0%, #ffffff 132.36%);
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.15);
`

const FormSection = styled.div`
  #holiday-2024-form-container > form {
    ${tw`flex flex-col md:flex-row items-stretch gap-4 w-full`}

    .hs-form-field {
      ${tw`flex-1`}
    }

    label,
    legend,
    .hs_error_rollup,
    .hs-form-required {
      ${tw`hidden invisible h-0`}
    }

    label.hs-error-msg,
    .hs-error-msgs {
      ${tw`block visible h-auto`}
    }

    label.hs-error-msg {
      ${tw`mt-1 text-xs text-red-600`}
    }

    input {
      ${tw`bg-white border border-[#4B5563] outline-none p-4 rounded-lg placeholder-[#4B5563] flex-1 w-full`}

      &.invalid {
        ${tw`border-red-600`}
      }
    }

    input::placeholder,
    select.is-placeholder,
    textarea::placeholder {
      ${tw`color[#4B5563]`}
    }

    .hs-button {
      ${tw`active:bg-[#801A1A] hover:bg-[#B55365] rounded-full py-4 px-6 font-semibold bg-[#9D2322] text-white text-center min-w-[200px] cursor-pointer border-0 transition-all`}
    }
  }
`

export default HolidayGiftGuideEmailGate
