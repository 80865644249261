import React from "react"
import { RectShape, TextBlock } from "react-placeholder/lib/placeholders"
import tw, { styled } from "twin.macro"

import { CurrentCustomStore } from "../../common/GlobalState"
import staticAssetUrl from "../../common/staticAssetUrl"

import {
  CustomStoreFullFragment,
  CustomStoreOption,
  GiftsSettingsInput,
} from "@/types/graphql-types"

export const NUM_CUSTOM_STORE_OPTIONS_TO_SHOW = 3

export const DefaultDesktopHeaderURL = staticAssetUrl(
  "static/custom_stores/default-header-desktop.png",
)
export const DefaultMobileHeaderURL = staticAssetUrl(
  "static/custom_stores/default-header-mobile.png",
)

export type CustomStoreFormFieldValue =
  | string
  | number
  | HeaderImageProps
  | CustomStoreOption[]
  | GiftsSettingsInput

export function numCollapsedOptions(numHidden: number) {
  if (numHidden <= 0) {
    return ""
  } else {
    return `+${numHidden}`
  }
}

export function fieldsChanged(
  customStore: CustomStoreFullFragment,
  formFields: CurrentCustomStore,
) {
  // Image changed if image exists on customStore but form field URL is different OR
  // there is no header image but form field file exists
  const desktopImageChanged =
    (!!customStore.desktopHeaderImage &&
      formFields.desktopImage?.url !== customStore.desktopHeaderImage?.url) ||
    (!customStore.desktopHeaderImage && !!formFields.desktopImage?.file)

  const mobileImageChanged =
    (!!customStore.mobileHeaderImage &&
      formFields.mobileImage?.url !== customStore.mobileHeaderImage?.url) ||
    (!customStore.mobileHeaderImage && !!formFields.mobileImage?.file)

  return (
    formFields.name !== customStore.name ||
    formFields.subtitle !== customStore.subtitle ||
    desktopImageChanged ||
    mobileImageChanged
  )
}

export interface HeaderImageProps {
  file: File | null
  url: string | null
}

export const StorePlaceholderList = () => (
  <>
    <StorePlaceholder />
    <StorePlaceholder />
  </>
)

export const StorePlaceholder = () => (
  <CustomStoreCard className="show-loading-animation placeholder">
    <div
      className="rect-shape"
      tw="bg-[#F6F7F9] w-full aspect-w-3 aspect-h-2 lg:rounded-xl outline-none"
    ></div>
    <br />
    <RectShape
      color="#F6F7F9"
      style={{
        width: "40%",
        height: 30,
        marginLeft: "5%",
        marginRight: "5%",
      }}
    />
    <TextBlock
      color="#F6F7F9"
      rows={1}
      style={{
        width: "90%",
        height: 20,
        marginLeft: "5%",
        marginRight: "5%",
        marginTop: "3%",
      }}
    />
    <TextBlock
      color="#F6F7F9"
      rows={1}
      style={{
        width: "90%",
        height: 20,
        marginLeft: "5%",
        marginRight: "5%",
        marginTop: "-0%",
      }}
    />
  </CustomStoreCard>
)

export const UpsellContainer = tw.div`
  grid grid-cols-1 rounded-xl m-8 shadow-sm
  lg:(m-0 w-full)
  xl:(pr-12 grid-cols-[auto 28rem])
`

export const Container = tw.div`container mx-auto sm:max-w-full md:max-w-full`

export const CustomStoreStyled = styled.div`
  ${tw`px-2 pb-6 grid gap-9`}
  grid-template-columns: 1fr;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 2fr;
  }
`

export const CustomStoreCard = styled.div`
  ${tw`lg:w-96 grid grid-cols-1 rounded-xl box-border p-1 self-start`}
  grid-template-rows: min-content;

  &:not(.placeholder) {
    ${tw`border border-gray-150`}
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  }
`

export interface CustomStoreBrowserProps {
  customStoreProductButton: (productID: string) => React.ReactElement
  customStoreGiftOptionButton: (
    giftOptionID: string,
    isSingleProduct: boolean,
  ) => React.ReactElement
  customStoreGiftCardButton: (
    productID: string,
    giftCardAmount: number | null,
  ) => React.ReactElement
  isGiftCardInCustomStore: (
    productID: string,
    giftCardAmount: number | null,
  ) => boolean
}

export const customStoreBrowserDefaults = {
  customStoreProductButton: undefined,
  customStoreGiftOptionButton: undefined,
  customStoreGiftCardButton: undefined,
  isGiftCardInCustomStore: undefined,
}
