// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type StarSvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function StarSvgIcon(props: StarSvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M15.497 10.457L19.2 12l-3.704 1.543a3.824 3.824 0 00-2.107 2.341L12 20l-1.388-4.116a3.824 3.824 0 00-2.107-2.34L4.801 12l3.704-1.543a3.823 3.823 0 002.107-2.341L12 4l1.389 4.116a3.823 3.823 0 002.107 2.34zm-9.783 7.964L7 19l-1.286.579a1.336 1.336 0 00-.454.354c-.126.15-.22.33-.278.524L4.5 22l-.482-1.543a1.465 1.465 0 00-.278-.524 1.336 1.336 0 00-.454-.354L2 19l1.286-.579c.173-.082.327-.202.454-.354.126-.15.22-.33.278-.524L4.5 16l.482 1.543c.058.195.152.373.278.524.127.152.281.272.454.354zm15-14L22 5l-1.286.579a1.337 1.337 0 00-.454.354c-.126.15-.22.33-.278.524L19.5 8l-.482-1.543a1.466 1.466 0 00-.278-.524 1.337 1.337 0 00-.454-.354L17 5l1.286-.579c.173-.082.327-.202.454-.354.126-.15.22-.33.278-.524L19.5 2l.482 1.543c.058.194.152.373.278.524.127.152.281.272.454.354z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default StarSvgIcon;
/* prettier-ignore-end */
