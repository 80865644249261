import { compact } from "lodash-es"
import React, { useEffect, useState } from "react"
import { Link, matchPath, useLocation } from "react-router-dom"
import { CSSTransition } from "react-transition-group"
import { styled } from "twin.macro"
import tw from "twin.macro"

import Container from "./Container"
import AccountMenuItems from "./header/AccountMenuItems"
import CreateOrganizationBanner from "./header/components/CreateOrganizationBanner"
import MainMenuGroup from "./header/MainMenuGroup"
import { MenuItem, MenuItemHeaderNavLink } from "./header/MenuItem"
import MobileBalanceOrCredit from "./header/MobileBalanceOrCredit"
import { TopMenuItem, TopMenuItemHeaderNavLink } from "./header/TopMenuItem"
import { ReactComponent as Burger } from "./images/burger.svg"
import { ReactComponent as MenuBrowse } from "./images/menu-browse.svg"
import { ReactComponent as MenuContacts } from "./images/menu-contacts.svg"
import { ReactComponent as MenuDashboard } from "./images/menu-dashboard.svg"
import { ReactComponent as MenuGift } from "./images/menu-gift.svg"
import { ReactComponent as MenuTrack } from "./images/menu-track.svg"
import { TopBar } from "./TopBar"
import { ReactComponent as BuildingIcon } from "../../assets/icons/building-transparent.svg"
import { ReactComponent as HelpIcon } from "../../assets/icons/help-circle.svg"
import LogoIcon from "../../assets/images/logo-icon.svg"
import Logo from "../../assets/images/logo.svg"
import { useGlobalState } from "../../common/GlobalState"
import HolidayGiftGuideMenuItem from "../../common/HolidayGiftGuideMenuItem"
import { useCurrentWorkspace } from "../../common/hooks"
import { useWindowSize } from "../../common/hooks/useWindowSize"
import { UpgradeToProHeaderTag } from "../../common/ProTag"
import { generateRealmPath } from "../../common/realm"
import BrowseGiftsDropdown from "../Public/components/BrowseGiftsDropdown"
import DropdownMenuItem from "../Public/components/DropdownMenuItem"
import CartButton from "../shared/CartButton"
import StaffAccessBanner from "../shared/StaffAccessBanner"

interface HeaderMenuItem {
  exact?: boolean
  icon: JSX.Element | React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  name: string
  path: string
  dropdownContent?: React.ReactElement
  blockReclicks?: boolean // Signals whether or not reclicking on the menu item returns it to the original path
}

const menuItems: HeaderMenuItem[] = compact([
  {
    name: "Dashboard",
    icon: <MenuDashboard />,
    path: generateRealmPath("plus", "/dashboard"),
    exact: true,
  },
  {
    name: "Send",
    icon: <MenuGift />,
    path: generateRealmPath("plus", "/send"),
  },
  {
    name: "Browse",
    icon: <MenuBrowse />,
    dropdownContent: <BrowseGiftsDropdown />,
    path: generateRealmPath("plus", "/browse"),
    blockReclicks: true,
  },
  {
    name: "Contacts",
    icon: <MenuContacts />,
    path: generateRealmPath("plus", "/contacts"),
  },
  {
    name: "Track",
    icon: <MenuTrack />,
    path: generateRealmPath("plus", "/track"),
  },
])

const Header: React.FC = () => {
  const [menuActive, setMenuActive] = useState(false)
  const [user] = useGlobalState("user")
  const { currentWorkspace, updateCurrentWorkspace } = useCurrentWorkspace()
  const workspaces = user?.workspaces || []

  const toggleMenu = () => setMenuActive((previous) => !previous)
  const closeMenu = () => setMenuActive(false)

  const location = useLocation()
  useEffect(() => {
    closeMenu() // whenever the location changes
  }, [location])

  const activeMenuItem =
    menuItems.find((item) =>
      matchPath(location.pathname, { path: item.path, exact: item.exact }),
    ) || null
  const menuBarItem = (
    <TopMenuItem
      onClick={toggleMenu}
      icon={activeMenuItem?.icon || <Burger tw="stroke-current" />}
      name={activeMenuItem?.name || "Menu"}
      active={!!activeMenuItem}
      trigger
    />
  )
  return (
    <>
      <HeaderWrapper>
        <StaffAccessBanner />
        <TopBar
          currentWorkspace={currentWorkspace}
          workspaces={workspaces}
          updateCurrentWorkspace={updateCurrentWorkspace}
        />
        <Container tw="flex items-center justify-between h-16 lg:h-24 relative">
          <Link
            to={generateRealmPath("plus", "/send")}
            tw="ml-5 hidden lg:block"
          >
            <img tw="hidden xl:block" src={Logo} alt="Goody" />
            <img tw="xl:hidden" src={LogoIcon} alt="Goody" />
          </Link>
          <MainMenu active={menuActive} />
          <MenuBar active={menuActive}>{menuBarItem}</MenuBar>
        </Container>
        <CSSTransition in={menuActive} timeout={500}>
          <MenuShade onClick={closeMenu} />
        </CSSTransition>
      </HeaderWrapper>
      <CreateOrganizationBanner />
    </>
  )
}

const HeaderWrapper = styled.header`
  ${tw`w-full bg-white lg:bg-transparent fixed lg:relative top-0 z-20`}
`

const MenuShade = styled.div`
  ${tw`absolute w-screen h-screen bg-gray-300 transition-opacity duration-500 hidden`}

  &.enter,
  &.exit {
    ${tw`block opacity-0`}
  }

  &.enter-active,
  &.enter-done {
    ${tw`block opacity-50`}
  }
`

interface MenuBarProps {
  active: boolean
}
const MenuBar: React.FC<MenuBarProps> = ({ active, children }) => {
  return (
    <StyledMenuBar className={active ? "active" : undefined}>
      <div className="mainBar">
        <Link to={generateRealmPath("plus", "/dashboard")} tw="lg:hidden">
          <img src={LogoIcon} alt="Goody" />
        </Link>
        {children}
        <div tw="ml-auto flex">
          <ChatWithSalesLink
            to={generateRealmPath("business", "/business/book-a-call-sales")}
          >
            Talk to sales
          </ChatWithSalesLink>
          <CartButton />
        </div>
      </div>
    </StyledMenuBar>
  )
}

interface MainMenuProps {
  active: boolean
}
const MainMenu: React.FC<MainMenuProps> = ({ active }) => {
  const [user] = useGlobalState("user")
  const windowSize = useWindowSize()
  const isMobile = windowSize.lessThen("lg")

  return (
    <MainMenuContainer className={active ? "active" : undefined}>
      <MainMenuGroup>
        {menuItems.map((item) => {
          return item.dropdownContent && !isMobile ? (
            <DropdownMenuItem
              content={item.dropdownContent}
              overrideTriggerElement={
                <TopMenuItem {...item} key={item.name || ""} />
              }
              key={item.name || ""}
            />
          ) : (
            <TopMenuItem {...item} key={item.name || ""} />
          )
        })}
        <div tw="hidden lg:block">
          <HolidayGiftGuideMenuItem />
        </div>
      </MainMenuGroup>
      <div tw="lg:hidden mb-5">
        <HolidayGiftGuideMenuItem />
      </div>
      <MobileBalanceOrCredit variant="credit" />
      <MobileBalanceOrCredit variant="balance" />
      <MainMenuSecondaryGroup>
        <UpgradeToProHeaderTag />
        {user?.isOrganizationAdmin && (
          <MenuItem
            name="Organization"
            icon={<BuildingIcon />}
            path={generateRealmPath("plus", "/organization")}
          />
        )}
        <MenuItem name="Support" icon={<HelpIcon />} path="/support" />
        <AccountMenuItems />
      </MainMenuSecondaryGroup>
    </MainMenuContainer>
  )
}

const StyledMenuBar = styled.div`
  ${tw`w-full lg:w-auto border-b border-transparent order-last relative z-20 lg:z-0 transition-colors ease-in duration-500`}

  > .mainBar {
    ${tw`px-5 h-16 flex items-center justify-start lg:justify-between bg-white lg:bg-transparent`}

    // logo
    > svg {
      ${tw`h-7 w-auto lg:h-auto`}
    }

    // menu trigger
    > button:first-child {
      ${tw`p-0`}
    }
  }

  ${MenuItemHeaderNavLink}, ${TopMenuItemHeaderNavLink} {
    ${tw`lg:hidden active:outline-none focus:outline-none`}
  }

  &.active {
    //${tw`border-primary-100`}
  }
`

const MainMenuContainer = styled.div`
  ${tw`bg-white lg:bg-transparent flex-1 w-full lg:w-auto flex flex-col lg:flex-row lg:items-center justify-between absolute transition-all duration-500 transform -translate-y-full opacity-50 lg:opacity-100 scale-y-50 top-full z-20 shadow-lg lg:shadow-none overflow-y-auto lg:overflow-visible lg:static lg:ml-3 xl:ml-0 xl:absolute xl:left-[50%] xl:-translate-x-1/2 lg:top-0 xl:justify-center lg:scale-y-100 lg:-translate-y-0 max-h-[calc(80vh - 5rem)] lg:h-[6rem]`}

  &.active {
    ${tw`translate-y-0 scale-y-100 opacity-100`}
  }

  ${MenuItemHeaderNavLink}, ${TopMenuItemHeaderNavLink} {
    ${tw`p-0 lg:px-5 lg:py-2`}
  }
`

const MainMenuSecondaryGroup = styled(MainMenuGroup)`
  ${tw`mx-0 px-5 grid grid-cols-2 border-t border-gray-200 lg:hidden`}

  ${MenuItemHeaderNavLink}, ${TopMenuItemHeaderNavLink} {
    ${tw`text-sm`}

    svg {
      ${tw`static text-current stroke-1`}
      width: 18px;
      height: 18px;
    }
  }
`

const ChatWithSalesLink = styled(Link)`
  ${tw`
    hidden lg:flex
    border-primary-new-100 border hover:bg-primary-new-000 active:(scale-95) text-primary-new-500
    lg:text-lg text-center transition-all rounded-3xl h-[3rem] px-5 mr-3 items-center
  `}
`
export default Header
