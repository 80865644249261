import React, { useCallback, useEffect, useRef, useState } from "react"
import ReactModal from "react-modal"
import tw, { css, styled } from "twin.macro"

import { ReactComponent as CalculatorIcon } from "@/account/images/calculator.svg"
import { ReactComponent as ArrowUpRight } from "@/assets/icons/arrow-up-right.svg"
import { ReactComponent as ChevronLeftIcon } from "@/assets/icons/chevron-left.svg"
import { ReactComponent as CircleClose } from "@/assets/icons/circle-close.svg"
import { getEnvVars } from "@/common/environment"
import { formatPrice } from "@/common/format"
import { useGlobalState } from "@/common/GlobalState"
import { Loader } from "@/common/UI"
import FlexGiftCalculator from "@/store/pdp/flexGift/FlexGiftCalculator"
import { ProductData } from "@/store/StoreHelpers"
import {
  GiftCollectionTierColorEnum,
  flex_gift_product_price_type,
} from "@/types/graphql-types"
import { Store_GiftOptionDataFragment } from "@/types/graphql-types"

const MINIMUM_FLEX_GIFT_PRICE_IN_CENTS = 1500
const DEFAULT_FLEX_GIFT_PRICE_IN_CENTS = 5000
const FLEX_GIFT_SELECTION_PRICES_IN_CENTS = [3000, 5000, 10000, 15000]

type StoreGiftCollectionTier =
  Store_GiftOptionDataFragment["products"][0]["giftCollectionTiers"][0]

interface PreviewParameterData {
  amount: number
  productID: string
}

interface FlexGiftProps {
  priceType?: flex_gift_product_price_type | null
  customDescription?: string | null
  productPrice: number
  productID: string
  tiers: StoreGiftCollectionTier[]
  onGiftToTier: (tier: StoreGiftCollectionTier) => void
  setPDPProduct: (productPreviewData: ProductData) => void
  disableTierProductButtons?: boolean
}

const FlexGiftSelection = ({
  productID,
  priceType,
  productPrice,
  tiers,
  onGiftToTier,
  setPDPProduct,
  disableTierProductButtons,
}: FlexGiftProps) => {
  const [previewStoreIsOpen, setPreviewStoreIsOpen] = useState(false)
  const [showError, setShowError] = useState(false)
  const [storeSelections, setStoreSelections] =
    useGlobalState("storeSelections")
  const customAmountInput = useRef<HTMLInputElement>(null)

  const setAmount = useCallback(
    (customAmountCents: number | undefined, fromButton = false) => {
      setStoreSelections({
        ...storeSelections,
        flexGiftPrice: customAmountCents,
      })

      if (fromButton) {
        setShowError(false)
        setCustomAmount(null)
      }
    },
    [setStoreSelections, storeSelections],
  )

  const amountInDollars =
    priceType === flex_gift_product_price_type.FIXED
      ? productPrice / 100
      : (storeSelections?.flexGiftPrice || DEFAULT_FLEX_GIFT_PRICE_IN_CENTS) /
        100

  // Set amount as default on selections if not set
  useEffect(() => {
    if (!storeSelections.flexGiftPrice) {
      setAmount(DEFAULT_FLEX_GIFT_PRICE_IN_CENTS)
    }
  }, [setAmount, storeSelections.flexGiftPrice])

  const [customAmount, setCustomAmount] = useState<number | null>(null)

  useEffect(() => {
    const amountMeetsThreshold =
      amountInDollars >= MINIMUM_FLEX_GIFT_PRICE_IN_CENTS / 100
    const customAmountEmpty = customAmountInput.current?.innerHTML === ""

    if (amountMeetsThreshold && customAmountEmpty) {
      setShowError(false)
    }
  }, [amountInDollars])

  const setCustomAmountOnInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawCustomAmount = e.target.value.replace("$", "")
    if (!rawCustomAmount) {
      setShowError(false)
      setAmount(undefined)
      setCustomAmount(null)
      return
    }

    const parsedCustomAmount = parseInt(rawCustomAmount)
    if (isNaN(parsedCustomAmount)) {
      setCustomAmount(null)
      setAmount(undefined)
      return
    }

    setCustomAmount(parsedCustomAmount)

    const customAmountCents = parsedCustomAmount * 100

    if (customAmountCents < MINIMUM_FLEX_GIFT_PRICE_IN_CENTS) {
      setShowError(true)
      setAmount(undefined)
    } else {
      setAmount(customAmountCents)
      setShowError(false)
    }
  }

  const isActive = (buttonAmountInCents: number) =>
    buttonAmountInCents === amountInDollars * 100

  const [currentRealm] = useGlobalState("currentRealm")
  const isConsumer = currentRealm === "consumer"

  const [calculatorMode, setCalculatorMode] = useState(false)

  return (
    <div tw="mt-8 mx-0 flex flex-col">
      <div tw="flex flex-col flex-1 justify-around space-y-3">
        {calculatorMode ? (
          <>
            <CalculatorButton
              tw="self-start"
              onClick={() => {
                setCalculatorMode(false)
              }}
            >
              <ChevronLeftIcon tw="stroke-current text-primary-new-500 w-4 h-4" />
              Back to amounts
            </CalculatorButton>
            <FlexGiftCalculator
              amount={amountInDollars}
              setAmount={(newAmount) => {
                setAmount(newAmount * 100)
                setCustomAmount(newAmount)
              }}
            />
          </>
        ) : (
          priceType === flex_gift_product_price_type.VARIABLE && (
            <>
              <div tw="flex space-x-3">
                {FLEX_GIFT_SELECTION_PRICES_IN_CENTS.slice(0, 2).map(
                  (priceInCents) => (
                    <PriceSelection
                      key={priceInCents}
                      priceInCents={priceInCents}
                      isActive={isActive(priceInCents)}
                      setAmount={setAmount}
                    />
                  ),
                )}
              </div>
              <div tw="flex space-x-3">
                {FLEX_GIFT_SELECTION_PRICES_IN_CENTS.slice(2).map(
                  (priceInCents) => (
                    <PriceSelection
                      key={priceInCents}
                      priceInCents={priceInCents}
                      isActive={isActive(priceInCents)}
                      setAmount={setAmount}
                    />
                  ),
                )}
              </div>

              <CustomInputContainer selected={customAmount !== null}>
                <div tw="w-full h-full bg-white rounded-xl">
                  <CustomInput
                    tw="w-full"
                    ref={customAmountInput}
                    placeholder="Custom amount"
                    onChange={setCustomAmountOnInput}
                    value={customAmount ? `$${customAmount}` : ""}
                  />
                </div>
              </CustomInputContainer>
            </>
          )
        )}
      </div>
      {showError && (
        <Error>
          The minimum is {formatPrice(MINIMUM_FLEX_GIFT_PRICE_IN_CENTS)}
        </Error>
      )}
      {priceType !== flex_gift_product_price_type.TIERS ? (
        <>
          {!isConsumer && !calculatorMode && (
            <CalculatorButton
              onClick={() => {
                setCalculatorMode(true)
              }}
            >
              <CalculatorIcon tw="stroke-current opacity-75 w-5 h-5" />
              Calculate from total budget
            </CalculatorButton>
          )}

          <Preview onClick={() => setPreviewStoreIsOpen(true)}>
            Preview ${amountInDollars} gifts <ArrowUpRight tw="inline" />
          </Preview>
          <PreviewStore
            setIsOpen={setPreviewStoreIsOpen}
            isOpen={previewStoreIsOpen}
            previewParams={{ amount: amountInDollars * 100, productID }}
          />
        </>
      ) : (
        <div tw="flex flex-col gap-8">
          {tiers.map((tier) => (
            <GiftCollectionTier
              key={tier.id}
              tier={tier}
              onGiftToTier={onGiftToTier}
              setPDPProduct={setPDPProduct}
              disableTierProductButtons={disableTierProductButtons}
            />
          ))}
        </div>
      )}
      {priceType === flex_gift_product_price_type.VARIABLE && (
        <Subtitle tw="mt-6 text-sm self-center text-center text-gray-400">
          Recipients don’t see the amount you select,
          <br /> only the matching gifts for that price.
        </Subtitle>
      )}
    </div>
  )
}

const PriceSelection = ({
  priceInCents,
  isActive,
  setAmount,
}: {
  priceInCents: number
  isActive: boolean
  setAmount: (customAmountCents: number, fromButton: boolean) => void
}) => {
  const priceInDollars = priceInCents / 100

  return (
    <Selection
      isActive={isActive}
      onClick={() => setAmount(priceInCents, true)}
    >
      <span>${priceInDollars}</span>
    </Selection>
  )
}

const GiftCollectionTier = ({
  tier,
  onGiftToTier,
  setPDPProduct,
  disableTierProductButtons,
}: {
  tier: StoreGiftCollectionTier
  onGiftToTier: (tier: StoreGiftCollectionTier) => void
  setPDPProduct: (productPreviewData: ProductData) => void
  disableTierProductButtons?: boolean
}) => {
  const [storeSelections, setStoreSelections] =
    useGlobalState("storeSelections")

  const [
    tierColor,
    tierTextColor,
    buttonTextColor,
    buttonBackground,
    buttonBackgroundHover,
  ] = (() => {
    switch (tier.colorToken) {
      case GiftCollectionTierColorEnum.gold:
        return ["#CFAD67", "#CFAD67", "#FFFFFF", "#CFAD67", "#CAA558"]
      case GiftCollectionTierColorEnum.purple:
        return ["#BA94E8", "#BA94E8", "#FFFFFF", "#BA94E8", "#B389E6"]
      case GiftCollectionTierColorEnum.grey:
        return ["#D1D5DB", "#6B7280", "#6B7280", "#EDEFF1", "#E5E8EB"]
      default:
        return ["", "", "", "", ""]
    }
  })()

  const ContainerComponent =
    tier.products.length === 4 ? FourItemsContainer : ItemsContainer

  return (
    <div
      tw="rounded-xl border-2 flex flex-col items-center p-3"
      css={`
        border-color: ${tierColor};
      `}
    >
      <div tw="flex flex-row justify-between self-stretch mt-1 mx-2">
        <div
          tw="font-semibold text-xl"
          css={`
            color: ${tierTextColor};
          `}
        >
          {tier.name}
        </div>
        <div tw="text-gray-500 text-xl">
          <span tw="opacity-50">max</span> {formatPrice(tier.priceMax)}
        </div>
      </div>
      <div tw="mt-4 h-px bg-gray-500 opacity-30 self-stretch mx-2" />
      <div tw="text-gray-500 opacity-75 mt-4 text-sm">
        Recipient selects one of
      </div>
      <ContainerComponent>
        {tier.products.map((product) => (
          <ProductContainer
            key={product.id}
            onClick={() => setPDPProduct(product)}
            css={[disableTierProductButtons && tw`pointer-events-none`]}
          >
            <img
              src={product.productImages[0].imageThumb.url}
              alt={product.name}
              css={`
                height: 4.6875rem;
                width: ${(4.6875 *
                  (product.productImages[0].imageThumb.width ?? 1)) /
                (product.productImages[0].imageThumb.height ?? 1)}rem;
              `}
            />
            <div tw="text-gray-600 mt-4">{product.name}</div>
            <div tw="text-gray-400">{formatPrice(product.price, true)}</div>
          </ProductContainer>
        ))}
      </ContainerComponent>
      <button
        onClick={() => {
          setStoreSelections({
            ...storeSelections,
            flexGiftPrice: tier.priceMax,
          })
          onGiftToTier(tier)
        }}
        tw="self-stretch rounded-lg text-lg font-semibold mt-5 text-center transition-all"
        css={`
          height: 2.75rem;
          background: ${buttonBackground};
          color: ${buttonTextColor};

          &:hover {
            background: ${buttonBackgroundHover};
            --tw-scale-x: 1.02;
            --tw-scale-y: 1.02;
            transform: var(--tw-transform);
          }

          &:active {
            background: ${buttonBackgroundHover};
            --tw-scale-x: 0.98;
            --tw-scale-y: 0.98;
            transform: var(--tw-transform);
          }
        `}
      >
        Gift
      </button>
    </div>
  )
}

// https://css-irl.info/controlling-leftover-grid-items/

const FourItemsContainer = styled.div`
  ${tw`grid grid-cols-2 gap-2`}
`

const ItemsContainer = styled.div`
  ${tw`grid grid-cols-6 gap-2`}

  & > * {
    grid-column: span 2;

    &:last-child:nth-child(3n - 1) {
      grid-column: span 3;
    }

    &:nth-last-child(2):nth-child(3n + 1) {
      grid-column: span 3;
    }

    &:last-child:nth-child(3n - 2) {
      grid-column: span 6;
    }
  }
`

const ProductContainer = styled.button`
  ${tw`flex flex-col text-center text-sm mt-5 items-center hover:scale-105 transition-transform`}

  &:active {
    transform: scale(1.02);
  }
`

export const PreviewStore = ({
  isOpen,
  setIsOpen,
  previewParams,
}: {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  previewParams: PreviewParameterData
}) => {
  const [currentUser] = useGlobalState("user")
  const [loading, setLoading] = useState(true)
  const params = new URLSearchParams()
  params.append("amount", `${previewParams.amount}`)
  params.append("productID", `${previewParams.productID}`)

  params.append(
    "senderName",
    `${currentUser?.firstName} ${currentUser?.lastName}`,
  )

  const previewUrlWithParams =
    getEnvVars().apiUrl + `/flex-preview?${params.toString()}`

  return (
    <ReactModal
      onRequestClose={() => {
        setIsOpen(false)
      }}
      style={{ overlay: { zIndex: 1400 } }}
      isOpen={isOpen}
      shouldCloseOnOverlayClick={true}
      contentElement={(props, children) => (
        <ModalContent className="ReactModal__Content">{children}</ModalContent>
      )}
    >
      <div tw="h-16">
        <CircleCloseWithTransition
          isLoading={loading}
          onClick={() => {
            setIsOpen(false)
          }}
          tw="float-right"
        />
      </div>
      <div tw="h-full w-full">
        {loading && (
          <div tw="w-full h-full flex justify-center">
            <Loader tw="self-center" />
          </div>
        )}
        <IFrame
          isLoading={loading}
          title="Preview"
          onLoad={() => setLoading(false)}
          src={previewUrlWithParams}
        />
      </div>
    </ReactModal>
  )
}

interface LoadingProps {
  isLoading: boolean
}

const transitionCSS = css`
  @keyframes fadeScaleIn {
    from {
      opacity: 0;
      transform: scale(0.1);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  animation: fadeScaleIn 0.6s ease-out forwards;
`

const loadingDisplayToggle = (props: LoadingProps) =>
  props.isLoading &&
  css`
    display: none;
  `

const CircleCloseWithTransition = styled(CircleClose)<LoadingProps>`
  ${transitionCSS}
  ${loadingDisplayToggle}
`

const IFrame = styled.iframe<LoadingProps>`
  ${tw`w-full h-full overflow-hidden rounded-2xl`}
  ${transitionCSS}
  ${loadingDisplayToggle}
`

const ModalContent = styled.div`
  @media only screen and (max-width: 1024px) {
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    height: 80vh;
  }
  @media only screen and (min-width: 1024px) {
    right: calc(50vw - 512px);
    width: 58rem;
  }

  @media only screen and (min-width: 1280px) {
    right: calc(50vw - 640px);
  }

  @media only screen and (min-width: 1440px) {
    right: calc(50vw - 720px);
  }

  top: 5%;
  width: 25rem;
  height: 85%;
  border-radius: 16px;
  padding: 0;
  border: 0;
  background: transparent;
  position: absolute;
  filter: drop-shadow(0px 16px 32px rgba(0, 0, 0, 0.1));
  z-index: 50;
  transition: all ease 0.2s;
`

const Error = styled.div`
  ${tw`text-sm text-center mt-2`}
  line-height: 1.4rem;
  color: #f2994a;
  height: 1.25rem;
  margin-bottom: 0.5625rem;
`

const Subtitle = styled.div`
  line-height: 1.4rem;
  max-width: 18.125rem;
`

interface SelectionProps {
  isActive: boolean
}

const gradientBackground = css`
  box-shadow:
    0 8px 24px rgba(145, 89, 214, 0.08),
    0 1px 4px rgba(145, 89, 214, 0.08);
  border-width: 0;

  & > span {
    background: linear-gradient(to left, #cb99fd, #b1afff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    z-index: 2;
    position: relative;
  }

  &::before {
    position: absolute;
    background: white;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 2px;
    z-index: 1;
    outline: none;
    border-radius: 10px;
  }

  &::after {
    background: linear-gradient(to left, #dfd0fe, #d0ddff);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: "";
    z-index: 0;
    outline: none;
    border-radius: 0.75rem;
  }
`

const Selection = styled.button<SelectionProps>`
  ${tw`w-1/2 px-2 border text-gray-400 border-gray-200`}
  border-radius: .75rem;
  font-size: 1.25rem;
  line-height: 1.27rem;
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
  position: relative;
  background: white;
  outline: none;
  height: 3.875rem;
  &:focus {
    outline: none;
  }

  ${(props) =>
    props.isActive &&
    css`
      ${tw`font-medium`}
      ${gradientBackground}
    `}

  ${(props) =>
    !props.isActive &&
    css`
      &:hover {
        ${tw`border border-gray-300`}
        &::after {
          background: white;
        }
      }
    `}
`

interface CustomInputContainerProps {
  selected: boolean
}

const CustomInputContainer = styled.div<CustomInputContainerProps>`
  background: linear-gradient(to left, #dfd0fe, #d0ddff);
  border-radius: 0.875rem;
  &:focus-within {
    padding: 0.125rem;
    box-shadow:
      0px 8px 24px rgba(145, 89, 214, 0.08),
      0px 1px 4px rgba(145, 89, 214, 0.08);
  }
  outline: none;
  ${(props) =>
    props.selected &&
    css`
      & > div > div {
        border: 0;
      }
      & > div > div:not(:empty) {
        ${tw`font-medium`}
        background: linear-gradient(to left, #cb99fd, #b1afff);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      padding: 0.125rem;
      box-shadow:
        0px 8px 24px rgba(145, 89, 214, 0.08),
        0px 1px 4px rgba(145, 89, 214, 0.08);
    `};
`

const CustomInput = styled.input`
  ${tw`w-full px-2 border focus:border-0 text-gray-500`}
  border-radius: 0.75rem;
  font-size: 1.25rem;
  line-height: 1.27rem;
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
  position: relative;
  outline: none;

  &:focus {
    outline: none;
  }
  text-align: center;

  &:empty::before {
    ${tw`text-gray-400`};
    content: "Custom Amount";
  }

  &:empty:focus::before {
    content: "$";
    padding-right: 8px;
    ${tw`font-medium`}
    background: linear-gradient(to left, #cb99fd, #b1afff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &:before,
  &:focus::before {
    content: "$";
    padding: 0;
  }
`

export const Preview = styled.button`
  ${tw`w-min flex text-primary-500 mt-5 self-center`}
  background: linear-gradient(267.87deg, rgba(128, 215, 255, 0.2) -40.9%, rgba(233, 128, 252, 0.2) 159.5%);
  border-radius: 6.25rem;
  white-space: nowrap;
  padding-right: 1.0625rem;
  padding-left: 0.9375rem;
  padding-top: 0.6875rem;
  padding-bottom: 0.625rem;
  outline: none;
  &:focus {
    outline: none;
  }

  &:active {
    opacity: 75%;
  }

  svg {
    margin-left: 1.125rem;
  }
`

const CalculatorButton = tw.button`px-4 py-2.5 rounded-full outline-none focus:outline-none whitespace-nowrap w-min flex flex-row items-center gap-2 text-gray-600 mt-5 self-center bg-white border border-gray-200 hover:(border-gray-300 bg-gray-050) active:scale-95 transition-all`

export default FlexGiftSelection
