import { useGlobalState } from "../GlobalState"

type AvailableFeatures =
  | "contacts"
  | "plus_gift_dispensers"
  | "payment_stored_value"
  | "plus_hr_integration"
  | "category_sets"
  | "gift_option_product_revamp"
  | "subcategories"
  | "pro_plan"
  | "salesforce_integration"
  | "custom_stores"
  | "international_shipping"
  | "onboarding_autogift"
  | "budgeting"
  | "budgeting_unrestricted"
  | "send_v2_new_user_population"
  | "recommender"
  | "send_v3_exp_new_user_population"
  | "send_v3_exp_exclude"
  | "send_payment_method_exp_new_user_population"
  | "store_product_mode_default_exp_new_user_population"
  | "store_product_mode_default_exp_exclude"
  | "store_flex_gift_ui_v2_exp_new_user_population"
  | "templates_vs_gift_of_choice_new_user_population"
  | "exclude_from_experiments"
  | "smart_links"
  | "saml"
  | "onboarding_v2"

export const useFeatureAccess = () => {
  const [user] = useGlobalState("user")
  const hasFeature = (feature: AvailableFeatures) => {
    if (user?.featureAccess) {
      return user?.featureAccess.indexOf(feature) >= 0
    }

    return false
  }

  return { hasFeature }
}
