import { useQuery } from "@apollo/client"
import { uniqBy } from "lodash-es"
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { Helmet } from "react-helmet-async"
import Modal from "react-modal"
import ReactPaginate from "react-paginate"
import tw, { styled } from "twin.macro"

import ContactCard, { cardAnimationDuration } from "./components/ContactCard"
import ContactImport from "./components/ContactImport"
import ContactsMain from "./components/ContactsMain"
import ContactsSidebar from "./components/ContactsSidebar"
import ContactsTable from "./components/ContactsTable"
import ContactView from "./components/ContactView"
import EditContactForm from "./components/EditContactForm"
import NewContactForm from "./components/NewContactForm"
import { CardMode, ContactList, useContactLists } from "./lib"
import { CONTACTS_QUERY } from "./queries"
// Imports from common
import { BatchRecipient, useGlobalState } from "../common/GlobalState"
import { useCurrentWorkspace } from "../common/hooks"
import { useFeatureAccess } from "../common/hooks/featureAccess"
import useIsLimitedMember from "../common/hooks/useIsLimitedMember"
import { ALL_EMPLOYEES, ALL_SYNCED_EMPLOYEES, UNASSIGNED } from "../common/hris"
import { ReactComponent as ChevronIcon } from "../common/images/chevron-down.svg"
import LimitedMemberFeatureGateBanner from "../common/members/LimitedMemberFeatureGateBanner"
import { modalStyle } from "../common/modal"
import SearchField from "../common/SearchField"
import SmallButton from "../common/SmallButton"
import TeamPlanUpsell from "../common/TeamPlanUpsell"
import { successToast } from "../common/toast"
import { filterOutBlankRecipients } from "../common/utilities"

import {
  Contacts_ContactsQuery,
  Contacts_ContactsQueryVariables,
  StandardContactFragment,
} from "@/types/graphql-types"

const CONTACTS_LIMIT = 30 // Hard coded value for contacts per page
const CONTACTS_SEND_ALL_LIMIT = 1000 // Hard coded value for recipient send all maximum

const Contacts: React.FC = () => {
  const [cardMode, setCardMode] = useState<CardMode>("view")
  const [cardShown, setCardShown] = useState(false)

  // Import mode means we're currently importing contacts
  const [importMode, setImportMode] = useState(false)

  const { currentWorkspace } = useCurrentWorkspace()
  const { hasFeature } = useFeatureAccess()

  const { contactLists, currentList, setCurrentListID } = useContactLists()
  const [, setRecipients] = useGlobalState("recipients")
  const [recipientConfirmationModalOpen, setRecipientConfirmationModalOpen] =
    useState(false)
  const [sendAllLoading, setSendAllLoading] = useState(false)
  const isLimitedMember = useIsLimitedMember()

  const currentListID = currentList?.id || null

  const {
    contacts,
    contactsCount,
    isLoading,
    page,
    searchTerm,
    selectedContact,
    setPage,
    setSearchTerm,
    setSelectedContactID,
    selectedContactID,
    refetchContacts,
  } = useContacts(currentListID)

  // A temporary state variable for when the contact panel is closing. We
  // want to keep the contact data displayed in the panel during the animation
  // even when the contact itself is unset.
  const [displayContact, setDisplayContact] =
    useState<StandardContactFragment | null>(null)

  const setViewMode = useCallback(() => {
    setCardMode("view")
  }, [setCardMode])

  const closeCard = useCallback(() => {
    setCardShown(false)
    setDisplayContact(selectedContact)
    setSelectedContactID((currentContactId) =>
      // Unset contact only if the contact hasn't changed.
      currentContactId === selectedContact?.id ? null : currentContactId,
    )

    // Only unset display contact after fadeout animation has finished
    setTimeout(() => {
      setDisplayContact(null)
    }, cardAnimationDuration)
  }, [setSelectedContactID, selectedContact])

  // Whenever the workspace or list changes, close modal.
  useEffect(closeCard, [currentWorkspace, currentListID])

  // We use selectedContactID instead of selectedContact to determine
  // whether to show the card because selectedContact will change based
  // on the current list and selectedContactID is just a state variable.
  // see useContacts and how selectedContact is generated.
  useEffect(() => {
    if (selectedContactID != null) {
      setCardShown(true)
      setViewMode()
    }
  }, [selectedContactID, setViewMode])

  const editContact = useCallback(() => {
    setCardMode("edit")
  }, [setCardMode])

  const newContact = useCallback(() => {
    setCardMode("edit")
    setSelectedContactID(null)
    setCardShown(true)
  }, [setCardMode, setSelectedContactID])

  const onClickImport = useCallback(() => {
    setImportMode(true)
  }, [])

  const loadAndSetRecipients = async () => {
    setSendAllLoading(true)

    const { data } = await refetchContacts({
      listID: currentListID,
      limit: CONTACTS_SEND_ALL_LIMIT,
    })
    const contacts = data?.workspace?.contacts || []
    setRecipients((recipients: BatchRecipient[]) =>
      uniqBy(
        [
          ...filterOutBlankRecipients(recipients),
          ...contacts.map((contact) => ({
            id: contact.id,
            firstName: contact.firstName || "",
            lastName: contact.lastName || "",
            phone: contact.phone || "",
            email: contact.email || "",
            scheduledEventId: null,
            scheduledEventKind: null,
            eventDate: null,
            errors: {},
          })),
        ],
        (contact) => contact.id,
      ),
    )
    // Success toast
    successToast(
      `${
        contactsCount > CONTACTS_SEND_ALL_LIMIT
          ? CONTACTS_SEND_ALL_LIMIT
          : contactsCount
      } ${
        contactsCount === 1 ? "contact has" : "contacts have"
      } been added to the recipient list on the send page`,
    )
    setSendAllLoading(false)
  }

  const onClickSendGiftToAll = () => {
    setRecipientConfirmationModalOpen(true)
  }

  const ConfirmOverMaxSend = ({
    modalIsOpen,
    closeModal,
  }: {
    modalIsOpen: boolean
    closeModal: () => void
  }) => (
    <Modal
      isOpen={modalIsOpen}
      closeTimeoutMS={500}
      onAfterClose={() => {}}
      shouldCloseOnOverlayClick={true}
      style={modalStyle}
    >
      <div className="modal-content">
        <div tw="p-6">
          <div tw="pb-3 text-center text-xl font-medium text-primary-500">
            {contactsCount > CONTACTS_SEND_ALL_LIMIT
              ? `You can only gift to up to ${CONTACTS_SEND_ALL_LIMIT.toLocaleString()} contacts at once. The first ${CONTACTS_SEND_ALL_LIMIT.toLocaleString()} contacts will be selected.`
              : `This will add ${contactsCount} ${
                  contactsCount === 1 ? "contact" : "contacts"
                } to your recipient list.`}
          </div>
          <div tw="pb-6 text-gray-500 text-center">
            Do you want to continue?
          </div>
          <div tw="flex justify-center">
            <div tw="mr-2">
              <SmallButton
                label="No, go back"
                onClick={closeModal}
                hideArrow={true}
                light={true}
              />
            </div>
            <SmallButton
              label="Yes, Send"
              onClick={() => {
                loadAndSetRecipients()
                closeModal()
              }}
              hideArrow={true}
            />
          </div>
        </div>
      </div>
    </Modal>
  )

  const viewContact = useCallback(
    (contactId: string) => {
      setSelectedContactID(contactId)
      setViewMode()
    },
    [setSelectedContactID, setViewMode],
  )
  const selectListWithId = useCallback(
    (listId: string | null) => {
      setCurrentListID(listId)
      // If we click on a list, we exit import mode
      setImportMode(false)
    },
    [setCurrentListID, setImportMode],
  )

  const renderedContact = selectedContact ?? displayContact

  const handlePageChange = useCallback(
    ({ selected }: { selected: number }) => {
      setPage(selected)
    },
    [setPage],
  )

  const handleSearch = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(value)
    },
    [setSearchTerm],
  )

  const cardContent = useMemo(
    () =>
      getCardContent({
        cardMode,
        contact: renderedContact,
        onClose: closeCard,
        onEdit: editContact,
        onView: setViewMode,
        currentListID: currentListID,
      }),
    [
      cardMode,
      renderedContact,
      closeCard,
      editContact,
      currentListID,
      setViewMode,
    ],
  )

  const userAddedContactLists = useMemo(
    () => contactLists.filter((list) => !list.hrisSynced),
    [contactLists],
  )

  const hrisSyncedContactLists = useMemo(
    () => getHrisSyncedLists(contactLists),
    [contactLists],
  )

  if (!hasFeature("contacts") || isLimitedMember) {
    const UpsellBanner = !hasFeature("contacts") ? (
      <TeamPlanUpsell eventPrefix="Contacts -" />
    ) : (
      <LimitedMemberFeatureGateBanner />
    )

    return (
      <div tw="border-t border-primary-100">
        <Container>
          <div tw="flex flex-row items-center justify-center">
            <div tw="max-w-5xl pt-16">{UpsellBanner}</div>
          </div>
        </Container>
      </div>
    )
  }

  return (
    <div tw="border-t border-primary-100">
      <ConfirmOverMaxSend
        modalIsOpen={recipientConfirmationModalOpen}
        closeModal={() => setRecipientConfirmationModalOpen(false)}
      />
      <Container>
        <Helmet>
          <title>Contacts – Goody for Business</title>
        </Helmet>
        <ContactsContainer>
          <ContactsSidebar
            lists={userAddedContactLists}
            hrisLists={hrisSyncedContactLists}
            currentListId={currentListID}
            onSelectListId={selectListWithId}
          />
          <div tw="flex-1 relative">
            {!importMode ? (
              <>
                <ContactsMain
                  list={currentList}
                  onClickNew={newContact}
                  onClickImport={onClickImport}
                  onClickSendGiftToAll={onClickSendGiftToAll}
                  sendAllLoading={sendAllLoading}
                  contactsCount={contactsCount}
                  search={
                    <SearchField
                      onChange={handleSearch}
                      value={searchTerm}
                      tw="w-48 hidden lg:flex"
                    />
                  }
                >
                  <ContactsTable
                    contacts={contacts || []}
                    onContactClick={viewContact}
                    selectedContact={renderedContact}
                    loading={isLoading}
                  />
                </ContactsMain>
                <ContactsMainFooter>
                  {/* Unfortunately hiding margin pages isn't possible with pure CSS,
                  so we need a condition on the window width here. */}
                  <ContactsPagination
                    pageCount={Math.ceil(contactsCount / CONTACTS_LIMIT)}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={window.innerWidth > 1080 ? 1 : 0}
                    forcePage={page}
                    onPageChange={handlePageChange}
                    previousLabel={<ChevronIcon tw="rotate-90" />}
                    nextLabel={<ChevronIcon tw="-rotate-90" />}
                    breakLabel="⋯"
                  />
                </ContactsMainFooter>
              </>
            ) : (
              <ContactImport
                lists={contactLists}
                currentListId={currentListID}
                onSelectListId={selectListWithId}
              />
            )}
          </div>
        </ContactsContainer>
        <ContactCard onClose={closeCard} shown={cardShown}>
          {cardContent}
        </ContactCard>
      </Container>
    </div>
  )
}

const ContactsMainFooter = styled.footer`
  ${tw`absolute bottom-0 w-full flex justify-center lg:pb-6`}
  background: linear-gradient(0deg, rgba(255, 255, 255, 100%) 0%, rgba(255, 255, 255, 0%) 100%);
`

const ContactsPagination = styled(ReactPaginate)`
  ${tw`flex items-center justify-center bg-white p-1 rounded-full lg:w-9/12 select-none`}
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05), 0 6px 32px rgba(0, 0, 0, 0.05);
  max-width: 30rem;

  > li {
    > a {
      ${tw`flex w-10 h-10 items-center justify-center rounded-lg text-gray-400 bg-white transition-colors hover:text-primary-500 active:text-primary-700`}
    }

    // Hide break symbol and additional 4th number on small displays.
    &.break,
    &:nth-child(5):not(.next) {
      ${tw`hidden lg:block`}
    }

    &.selected > a {
      ${tw`text-primary-600 bg-primary-000`}
    }

    &.previous,
    &.next {
      ${tw`flex flex-grow`}

      > a {
        ${tw`w-12 h-12 flex-initial rounded-full hover:bg-primary-000 active:bg-primary-050 duration-300`}
      }
    }

    &.previous {
      ${tw`justify-start`}
    }

    &.next {
      ${tw`justify-end`}
    }

    &.disabled {
      ${tw`text-gray-300 pointer-events-none`}

      svg {
        ${tw`text-gray-200`}
      }
    }
  }

  svg {
    ${tw`stroke-current text-primary-400 stroke-2 scale-90`}
  }
`

interface GetCardContentArgs {
  cardMode: CardMode
  contact: StandardContactFragment | null
  onClose: () => void
  onEdit: () => void
  onView: () => void
  currentListID: string | null
}
const getCardContent = ({
  cardMode,
  contact,
  onClose,
  onEdit,
  onView,
  currentListID,
}: GetCardContentArgs) => {
  if (contact == null) {
    switch (cardMode) {
      case "view":
        return null
      case "edit":
        return (
          <NewContactForm onSuccess={onClose} currentListID={currentListID} />
        )
    }
  }

  switch (cardMode) {
    case "view":
      return <ContactView contact={contact} onEdit={onEdit} />
    case "edit":
      return (
        <EditContactForm
          contact={contact}
          onSuccess={onView}
          onBack={onView}
          onDelete={onClose}
          currentListID={currentListID}
        />
      )
  }
}

const Container = tw.div`bg-white container mx-auto relative`

const ContactsContainer = styled.div`
  ${tw`flex flex-col lg:flex-row items-stretch relative`};
  @media only screen and (min-width: 1024px) {
    height: calc(100vh - 170px);
  }
`

export const useContacts = (currentListId: string | null) => {
  const [selectedContactID, setSelectedContactID] = useState<string | null>(
    null,
  )
  const [page, setPage] = useState(0)
  const [searchTerm, setSearchTerm] = useState("")
  // We store contacts and contacts count in state so we're still able
  // to display them while a new or filtered list is loaded.
  const [contactsCount, setContactsCount] = useState(0)
  const [contacts, setContacts] = useState<StandardContactFragment[]>([])

  const {
    data: contactsData,
    loading: isLoading,
    refetch: refetchContacts,
  } = useQuery<Contacts_ContactsQuery, Contacts_ContactsQueryVariables>(
    CONTACTS_QUERY,
    {
      variables: {
        listID: currentListId,
        limit: CONTACTS_LIMIT,
        page: page,
        searchTerm,
      },
    },
  )

  useEffect(() => {
    refetchContacts({
      listID: currentListId,
    })
  }, [currentListId, refetchContacts])

  useEffect(() => {
    if (!isLoading) {
      setContacts(contactsData?.workspace?.contacts || [])
      setContactsCount(contactsData?.workspace?.contactsCount || 0)
    }
  }, [contactsData, isLoading])

  // Reset pagination on list or search term change
  useEffect(() => {
    setPage(0)
  }, [currentListId, searchTerm])

  const selectedContact =
    contacts.find((contact) => contact.id === selectedContactID) || null

  return {
    contacts,
    contactsCount,
    isLoading,
    page,
    searchTerm,
    selectedContact,
    selectedContactID,
    setPage,
    setSearchTerm,
    setSelectedContactID,
    refetchContacts,
  }
}

const getHrisSyncedLists = (contactLists: ContactList[]) => {
  const allEmployeesList = (list: ContactList) =>
    list.name === ALL_EMPLOYEES || list.name === ALL_SYNCED_EMPLOYEES

  const hrisSyncedLists = contactLists
    .filter((list) => list.hrisSynced)
    .sort((a, b) => {
      if (allEmployeesList(a) || allEmployeesList(b)) {
        return allEmployeesList(a) ? -1 : 1 // We always want All (synced) employees to be first
      } else if (a.name === UNASSIGNED || b.name === UNASSIGNED) {
        return a.name === UNASSIGNED ? 1 : -1 // And Unassigned to be last
      } else if (a.name < b.name) {
        return -1
      } else if (a.name > b.name) {
        return 1
      }
      return 0
    })

  return hrisSyncedLists
}

export default Contacts
