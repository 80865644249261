import React from "react"

import { useCopy } from "@/common/hooks/copy"

interface Props {
  copiedIcon: React.ReactElement
  preCopiedIcon: React.ReactElement
  copyText: string
}
const TextCopy = ({ copiedIcon, preCopiedIcon, copyText }: Props) => {
  const { copied, copy, canCopy } = useCopy()

  if (!canCopy) {
    return null
  }

  return (
    <div tw="flex flex-row items-center">
      {copied ? (
        copiedIcon
      ) : (
        <button
          tw="flex flex-row items-center"
          onClick={() => {
            copy(copyText)
          }}
        >
          {preCopiedIcon}
        </button>
      )}
    </div>
  )
}

export default TextCopy
