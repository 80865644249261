import { useEffect, useMemo, useRef, useState } from "react"
import { useHistory } from "react-router-dom"

import { createHookContext } from "@/common/hooks/createHookContext"
import {
  DEFAULT_COUNTRY,
  useShippingCountries,
} from "@/common/hooks/useShippingCountries"
import { useToggle } from "@/common/hooks/useToogle"
import {
  ShippingCountry,
  ShippingCountryGroupEnum,
} from "@/types/graphql-types"

const useShippingCountriesSelectorLocal = () => {
  const { shippingCountries, findCountryByCode, expandShippingCountries } =
    useShippingCountries()

  const history = useHistory()
  const initialSearchParams = useRef(history.location.search)

  const searchParams = useMemo(
    () => new URLSearchParams(history.location.search),
    [history.location.search],
  )
  const searchParamsCountryCode = useMemo(
    () => searchParams.get("country")?.toUpperCase() || DEFAULT_COUNTRY.code,
    [searchParams],
  )

  const [selectedShippingCountry, setSelectedShippingCountry] =
    useState<ShippingCountry>(
      findCountryByCode(searchParamsCountryCode) || DEFAULT_COUNTRY,
    )

  const [
    showOnlyDomesticShipping,
    toogleShowOnlyDomesticShipping,
    setToogleShowOnlyDomesticShipping,
  ] = useToggle(searchParams.get("domestic") === "true")

  useEffect(() => {
    if (
      searchParams.get("domestic") !== "true" ||
      !searchParamsCountryCode ||
      selectedShippingCountry.code === "US" ||
      !selectedShippingCountry.groups.includes(
        ShippingCountryGroupEnum.domestic,
      )
    ) {
      setToogleShowOnlyDomesticShipping(false)
    }
  }, [selectedShippingCountry, setToogleShowOnlyDomesticShipping])

  useEffect(() => {
    if (shippingCountries.length) {
      const country = findCountryByCode(searchParamsCountryCode)
      setSelectedShippingCountry(country || shippingCountries[0])
    }
  }, [shippingCountries, findCountryByCode])

  useEffect(() => {
    if (shippingCountries.length === 0) return

    const selectedCountryCode = selectedShippingCountry.code
    const usSelected = selectedCountryCode === "US"

    if (usSelected) {
      searchParams.delete("country")
    } else {
      searchParams.set("country", selectedCountryCode.toLowerCase())
    }

    if (showOnlyDomesticShipping && !usSelected) {
      searchParams.set("domestic", "true")
    } else {
      searchParams.delete("domestic")
    }

    history.replace({
      pathname: history.location.pathname,
      search: searchParams.toString(),
    })
  }, [
    selectedShippingCountry,
    history,
    showOnlyDomesticShipping,
    shippingCountries,
  ])

  useEffect(() => {
    const search = initialSearchParams.current
    return () => history.replace({ search })
  }, [])

  return {
    shippingCountries,
    selectedShippingCountry,
    setSelectedShippingCountry,
    showOnlyDomesticShipping,
    toogleShowOnlyDomesticShipping,
    findCountryByCode,
    expandShippingCountries,
  }
}

export const {
  Provider: ShippingCountriesSelectorProvider,
  useHook: useShippingCountriesSelector,
} = createHookContext(
  "useShippingCountriesSelector",
  useShippingCountriesSelectorLocal,
)
