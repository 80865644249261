import React, { useEffect, useMemo } from "react"
import { Helmet } from "react-helmet-async"
import { Redirect, useHistory, useRouteMatch } from "react-router-dom"
import tw, { styled } from "twin.macro"

import WorkspacesListItem from "./components/WorkspacesListItem"
import NewWorkspace from "./NewWorkspace"
import Workspace from "./Workspace"
import Button from "../common/Button"
import { useGlobalState } from "../common/GlobalState"
import { useQueryParams } from "../common/gtm"
import { generateRealmPath } from "../common/realm"
import RealmRoute from "../common/RealmRoute"
import { isIndividual, sortWorkspaces } from "../common/workspaces"
import Container from "../sites/App/Container"

import {
  PlusEnrollmentStatus,
  WorkspaceMembershipRole,
} from "@/types/graphql-types"

const Workspaces: React.FC = () => {
  const [user] = useGlobalState("user")
  const [enrollmentStatus] = useGlobalState("enrollmentStatus")

  // Remove individual workspaces
  const workspaces = sortWorkspaces(user?.workspaces || []).filter(
    (workspace) => {
      return !isIndividual(workspace.ttype)
    },
  )

  const match = useRouteMatch<{ id: string }>("/workspaces/:id")
  const hasInvite = useQueryParams().has("invite")

  const history = useHistory()

  useEffect(() => {
    if (
      enrollmentStatus === PlusEnrollmentStatus.NONE &&
      window.location.hash !== "#debug"
    ) {
      history.replace(generateRealmPath("business", "/signup"))
    }
  }, [enrollmentStatus])

  const redirectWorkspaceID = useMemo(
    () =>
      workspaces.find(
        (workspace) =>
          !hasInvite || workspace.role == WorkspaceMembershipRole.MANAGER,
      )?.id,
    [workspaces, hasInvite],
  )
  return (
    <>
      <Helmet>
        <title>Workspaces – Goody for Business</title>
      </Helmet>
      <div tw="lg:border-t border-primary-100">
        <Container
          css={[tw`bg-white lg:grid grid-cols-4`, { minHeight: "80vh" }]}
        >
          <Sidebar>
            <h1
              css={[
                tw`pb-8 text-2xl font-medium px-6 lg:px-12`,
                workspaces.length === 0 ? tw`lg:px-6` : null,
              ]}
            >
              Workspaces
            </h1>
            {workspaces.length > 0 ? (
              <div tw="px-6 pb-6">
                <ListSeparator />
                {workspaces.map((workspace) => (
                  <React.Fragment key={workspace.id}>
                    <WorkspacesListItem
                      to={generateRealmPath(
                        "plus",
                        `/workspaces/${workspace.id}`,
                      )}
                      selected={workspace.id === match?.params?.id}
                      name={workspace.name}
                    />
                    <ListSeparator />
                  </React.Fragment>
                ))}
              </div>
            ) : (
              <div tw="px-6 pb-4 text-gray-500">
                You aren't part of any workspaces.
              </div>
            )}
            <div tw="px-6 pb-3">
              <Button
                onClick={() =>
                  history.push(generateRealmPath("plus", "/workspaces/new"))
                }
              >
                Create workspace
              </Button>
            </div>
          </Sidebar>
          <div tw="col-span-3 pt-12">
            <RealmRoute exact path="/workspaces/new" realm="plus">
              <NewWorkspace />
            </RealmRoute>
            <RealmRoute path="/workspaces/:id" realm="plus">
              <Workspace />
            </RealmRoute>
            <RealmRoute exact path="/workspaces" realm="plus">
              {redirectWorkspaceID ? (
                <Redirect
                  to={generateRealmPath(
                    "plus",
                    `/workspaces/${redirectWorkspaceID}${
                      hasInvite ? "?invite" : ""
                    }`,
                  )}
                />
              ) : (
                <Redirect to={generateRealmPath("plus", `/workspaces/new`)} />
              )}
            </RealmRoute>
          </div>
        </Container>
      </div>
    </>
  )
}

export const ListSeparator = styled.div`
  ${tw`border-b border-gray-100`}
`

export const Sidebar = styled.div`
  ${tw`py-12 border-b md:border-b-0 border-gray-100`};

  @media (min-width: 1024px) {
    box-shadow:
      1px 4px 4px rgba(0, 0, 0, 0.05),
      0 4px 4rem rgba(0, 0, 0, 0.04);
    clip-path: inset(0 -4rem 0 0);
  }
`

export default Workspaces
