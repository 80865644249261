import "twin.macro"
import { Flow } from "@frigade/react"
import React, { useState } from "react"

import { useGlobalState } from "@/common/GlobalState"
import { useFeatureAccess } from "@/common/hooks/featureAccess"
import ChecklistUI from "@/onboarding/ChecklistUI/ChecklistUI"

export const FLOW_ID = "flow_UVcGBvTb"
const MOBILE_THRESHOLD = 768

// This component checks if the onboarding checklist should load (i.e. user has
// Onboarding V2 feature access), and if so, renders the flow. It also hides
// the checklist on non-Plus realms.
export default function OnboardingChecklist() {
  const { hasFeature } = useFeatureAccess()
  const [currentRealm] = useGlobalState("currentRealm")

  const hasOnboardingV2 = hasFeature("onboarding_v2")
  const isPlusRealm = currentRealm === "plus"

  const [viewportWidthOnLoad] = useState(window.innerWidth)

  // The checklist is hidden on mobile devices.
  // Do not try to load the flow on mobile, to avoid starting the flow for a
  // user who can't even see it (which would mess up analytics).
  const isMobile = viewportWidthOnLoad < MOBILE_THRESHOLD

  if (!hasOnboardingV2 || !isPlusRealm || isMobile) {
    return null
  }

  return (
    <Flow as={"div"} flowId={FLOW_ID}>
      {({ flow }) => {
        if (!flow.isVisible) {
          return null
        }

        // This flow starts on mount
        flow.start()

        return <ChecklistUI flow={flow} />
      }}
    </Flow>
  )
}
