import React from "react"

import { getCsrfToken } from "@/common/csrf"
import { getEnvVars } from "@/common/environment"
import { isBlank } from "@/common/format"

const { apiUrl } = getEnvVars()

interface Props {
  email: string
  text: string
}

const SSOSignInLink = ({ email, text }: Props) => {
  const csrfToken = getCsrfToken()
  const postUrl = `${apiUrl}/users/auth/saml/identity_provider_id`

  return (
    <form action={postUrl} method="post" target="_self">
      <input type="hidden" name="authenticity_token" value={csrfToken} />
      <input type="hidden" name="email" id="email" value={email} />

      <button type="submit" tw="ml-10" disabled={isBlank(email)}>
        {text}
      </button>
    </form>
  )
}

export default SSOSignInLink
