import "twin.macro"
import { useEffect, useState } from "react"

import { useGlobalState } from "@/common/GlobalState"
import GradientBackground from "@/common/GradientBackground"
import { useGiftCart } from "@/common/hooks/giftData"
import OnboardingWelcome from "@/send/components/OnboardingWelcome"
import {
  ONBOARDING_SHOW_WELCOME_WITH_CART_LOCAL_STORAGE_KEY,
  ONBOARDING_SHOW_WELCOME_WITH_CART_TRUE_VALUE,
} from "@/send/components/UserHadCartAtOnboarding"

interface Props {}

// When a user has an item in their cart at the start of onboarding, then they
// started the signup process from adding an item to their cart and trying to
// check out. For these users, we display the onboarding welcome message on the
// Send page once, and never again (to get them to focus on sending).
//
// When the user starts onboarding (on the InviteV2 component), we check if the
// user has an item in their cart, and if so, we set a localStorage flag
// indicating this. Then, when we display the Send page, if the user has
// something in their cart, we check if they have this localStorage flag set
// (and are a new user), and display this message if they do. We also remove
// the localStorage flag, so on the next render, this is no longer displayed.
export default function OnboardingWelcomeWithCart({}: Props) {
  const [user] = useGlobalState("user")

  // Argument to useState is only evaluated once, so we can avoid a flash of
  // uninitialized state had we used useEffect instead.
  const [show] = useState(() => {
    if (user?.hasCreatedAGiftBatch) {
      return false
    }

    const lsValue = localStorage.getItem(
      ONBOARDING_SHOW_WELCOME_WITH_CART_LOCAL_STORAGE_KEY,
    )

    return lsValue === ONBOARDING_SHOW_WELCOME_WITH_CART_TRUE_VALUE
  })

  const { cartQuantity } = useGiftCart()

  useEffect(() => {
    if (show) {
      localStorage.removeItem(
        ONBOARDING_SHOW_WELCOME_WITH_CART_LOCAL_STORAGE_KEY,
      )
    }
  }, [show])

  if (!show || cartQuantity === 0 || !user?.firstName) {
    return null
  }

  return (
    <div tw="px-6 mt-9">
      <div tw="rounded-xl shadow-card">
        <div tw="z-[-10] relative rounded-xl overflow-hidden">
          <GradientBackground
            visible={true}
            gradientStartPercent={40}
            gradientHeight={250}
            scrimHeight={350}
          />
          <div tw="z-[5] relative p-6 md:p-9">
            <OnboardingWelcome
              firstName={user.firstName}
              credit={user.credit}
              variant="cart"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
