import tw from "twin.macro"

import { ProductImage } from "../../../ProductImage"
import { useBrowseUrls } from "../../../StoreHelpers"

import { ShipsGloballyTag } from "@/store/custom/ShipsGloballyTag"
import { ProductListFragment } from "@/types/graphql-types"

interface Props {
  product: ProductListFragment
  onClick?: (product: ProductListFragment) => void
}

export const CustomProductPreview = ({ product, onClick }: Props) => {
  const image = product?.productImages[0].imageLarge
  const { generatePdpUrl } = useBrowseUrls()

  return (
    <Container
      onClick={(e) => {
        if (onClick) {
          e.preventDefault()
          onClick(product)
          e.stopPropagation()
        }
      }}
      href={generatePdpUrl({
        productSlug: product.slug,
        brandSlug: product.brand.slug,
      })}
    >
      <div tw="m-1">
        <ProductImage
          image={image}
          tw="w-full h-full rounded-lg"
          alt=""
          scalable={product.imagesScalable}
        />
      </div>

      <Title tw="m-5 lg:m-4 ">{product.name}</Title>

      <ShipsGloballyTag product={product} variant="tag" />
    </Container>
  )
}

const Title = tw.h3`font-medium text-base leading-5 text-black`

const Container = tw.a`
  pb-6 w-full	block cursor-pointer shadow-sm
  transition-colors transition-all duration-300 ease-out
  border border-gray-150 rounded-xl
  hover:(border-primary-new-100)
  active:(
    border-primary-new-100 bg-primary-new-100
    bg-gradient-to-t from-primary-new-000 to-primary-new-000
  )
`
