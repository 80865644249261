import React from "react"

import BasicContainer from "./components/BasicContainer"
import { BasicAnswer, BasicQuestion, BasicSection } from "./Ui"

const CoinsTerms: React.FC = () => {
  return (
    <BasicContainer title="Goody Coin Rewards Terms and Conditions">
      <BasicSection>
        <BasicQuestion>How do Goody Coins work?</BasicQuestion>
        <BasicAnswer>
          You can earn Goody Coins for setting up birthdays for your contacts in
          the Goody app. Once you've granted contact permissions, you can add
          birthdays for your contacts, or you can request birthdays from your
          contacts through a text. If you request birthdays from your contacts
          via SMS and/or MMS text messaging, standard message and data rates may
          apply. For each birthday you add, you get 10 Goody Coins, up to a
          maximum of 100 Goody Coins. In the future, we might add other ways to
          earn Goody Coins as well.
        </BasicAnswer>
        <BasicQuestion>
          When do I get Goody Coins if I request birthdays from contacts?
        </BasicQuestion>
        <BasicAnswer>
          When you request birthdays, you get Goody Coins when the request
          recipient replies with a valid birthday.
        </BasicAnswer>
        <BasicQuestion>
          What is the maximum amount of Goody Coins I can get?
        </BasicQuestion>
        <BasicAnswer>
          You can earn up to 100 Goody Coins as a reward for adding birthdays.
        </BasicAnswer>
        <BasicQuestion>What can I do with Goody Coins?</BasicQuestion>
        <BasicAnswer>
          You can redeem 10 Goody Coins for $1 in Goody promotional credit.
          Goody Coins have no fixed or cash value and may not be redeemed for
          cash.
        </BasicAnswer>
        <BasicQuestion>How do I redeem Goody Coins?</BasicQuestion>
        <BasicAnswer>
          Goody Coins may be redeemed solely in connection with in-app purchases
          of gifts by redeeming them from the Coins page in the app. Upon
          redemption of Goody Coins, the corresponding number of Goody Coins
          will be automatically deducted from your account. Once redeemed, Goody
          Coins cannot be added back to your account.
        </BasicAnswer>
        <BasicQuestion>
          Are there any fees for redeeming Goody Coins?
        </BasicQuestion>
        <BasicAnswer>
          No, you may redeem your Goody Coins at any time in connection with the
          purchase of a gift through the Goody app.
        </BasicAnswer>
        <BasicQuestion>
          Can I earn more Goody Coins if I have 100 coins and redeem them, and
          then add more birthdays?
        </BasicQuestion>
        <BasicAnswer>
          No, you can earn a maximum of 100 Goody Coins from adding birthdays to
          your account.
        </BasicAnswer>
        <BasicQuestion>Are Goody Coins transferable?</BasicQuestion>
        <BasicAnswer>
          No. Any transfer or attempt to transfer Goody Coins may result in
          automatic cancellation of your Goody Coins.
        </BasicAnswer>
        <BasicQuestion>Do Goody Coins expire?</BasicQuestion>
        <BasicAnswer>
          No, coins do not expire, except upon cancellation or deactivation of
          your account.
        </BasicAnswer>
        <BasicQuestion>
          Can Goody change the terms and conditions relating to Goody Coins?
        </BasicQuestion>
        <BasicAnswer>
          Yes, Goody may, at any time and without notice, change, eliminate, or
          terminate earning and/or redemption procedures and offerings for Goody
          Coins. Any such modifications will be reflected in these terms and
          conditions.
        </BasicAnswer>
      </BasicSection>
    </BasicContainer>
  )
}

export default CoinsTerms
