import React, { useState } from "react"
import { Helmet } from "react-helmet-async"

import ApplyModal from "./ApplyModal"
import Automate from "./automation/Automate"
import HowItWorksAutomation from "./automation/HowItWorksAutomation"
import International from "./automation/International"
import NextStepsAutomation from "./automation/NextStepsAutomation"
import UseCases from "./automation/UseCases"
import Brands from "./Brands"
import Catalog from "./Catalog"
import ChooseAPI from "./ChooseAPI"
import BrandsCommerce from "./commerce/BrandsCommerce"
import HowItWorksCommerce from "./commerce/HowItWorksCommerce"
import NextStepsCommerce from "./commerce/NextStepsCommerce"
import PostableCaseStudy from "./commerce/PostableCaseStudy"
import Revshare from "./commerce/Revshare"
import Header from "./Header"
import { track } from "../../common/analytics"
import AnalyticsScreen from "../../common/AnalyticsScreen"

export type APIVariant = "commerce" | "automation"

interface Props {
  defaultVariant: APIVariant
}

export default function API({ defaultVariant }: Props) {
  const [variant, setVariant] = useState<APIVariant>(defaultVariant)
  const [applyModalOpen, setApplyModalOpen] = useState(false)

  function onClickApply() {
    setApplyModalOpen(true)
  }

  return (
    <>
      <Header onClickApply={onClickApply} />
      <Brands />
      <ChooseAPI
        variant={variant}
        onSelectVariant={(newVariant) => {
          if (newVariant === variant) return

          track("Business Marketing - API - Select Variant", {
            variant: newVariant,
          })

          setVariant(newVariant)
        }}
      />
      {variant === "commerce" ? (
        <>
          <Helmet>
            <title>Goody Gift API | Integrate Gifting Into Your Product</title>
            <meta
              name="title"
              property="og:title"
              content="Goody Gift API | Integrate Gifting Into Your Product"
            />
            <meta
              name="description"
              content={
                "Goody's gift API lets you sell 300+ curated gift brands in your app or website. Earn from sales. Automate gifts to users and customers."
              }
            />
          </Helmet>
          <AnalyticsScreen name="Business Marketing - API - Commerce" />
          <BrandsCommerce />
          <HowItWorksCommerce />
          <Catalog />
          <Revshare />
          <NextStepsCommerce onClickApply={onClickApply} />
          <PostableCaseStudy />
          <NextStepsCommerce onClickApply={onClickApply} />
        </>
      ) : variant === "automation" ? (
        <>
          <Helmet>
            <title>
              Goody Automation API | Automate Gifts with API or Zapier
            </title>
            <meta
              name="title"
              property="og:title"
              content="Goody Automation API | Automate Gifts with API or Zapier"
            />
            <meta
              name="description"
              content={
                "Goody's Automation API allows you to automate gifting from any app. Send gifts based on triggers you define, using REST API or Zapier."
              }
            />
          </Helmet>
          <AnalyticsScreen name="Business Marketing - API - Automation" />
          <Automate />
          <HowItWorksAutomation />
          <Catalog />
          <International />
          <UseCases />
          <NextStepsAutomation onClickApply={onClickApply} />
        </>
      ) : null}
      <ApplyModal
        applyModalOpen={applyModalOpen}
        onClose={() => setApplyModalOpen(false)}
      />
    </>
  )
}
