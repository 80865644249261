import { gql, useQuery } from "@apollo/client"

import { GetTeamPlanEnabledQuery } from "@/types/graphql-types"

const useTeamPlan = () => {
  const { data, loading } = useQuery<GetTeamPlanEnabledQuery>(GET_TEAM_PLAN)
  return {
    onEEPlan: !!data?.workspace?.organization?.subscription?.isEe,
    onTeamPlan: !!data?.workspace?.organization?.subscription?.isTeamPlan,
    hasSubscription: !!data?.workspace?.organization?.subscription,
    organizationId: data?.workspace?.organization?.id,
    loading,
  }
}

export const GET_TEAM_PLAN = gql`
  query GetTeamPlanEnabled {
    workspace {
      organization {
        id
        subscription {
          id
          isTeamPlan
          isEe
        }
      }
    }
  }
`

export default useTeamPlan
