import { useGiftBatchSettings } from "./hooks"

import { Alert, AlertColor, OptionBase } from "./index"

import { useGlobalState } from "@/common/GlobalState"
import { useGiftCart } from "@/common/hooks/giftData"
import InternationalShippingOptions from "@/common/InternationalShippingOptions"
import { InternationalShippingTierEnum } from "@/types/graphql-types"

export const InternationalShipping = () => {
  const { settings, setSettings } = useGiftBatchSettings()
  const { cartQuantity, hasSpecialtyFlexGiftItem } = useGiftCart()
  const [currentUser] = useGlobalState("user")

  const handleSetInternationalShippingTier = (
    newInternationShippingTier: InternationalShippingTierEnum,
  ) => {
    setSettings((currentSettings) => ({
      ...currentSettings,
      internationalShippingTier: newInternationShippingTier,
    }))
  }

  const handleSetGiftCardsEnabled = (giftCardsEnabled: boolean) => {
    setSettings((currentSettings) => ({
      ...currentSettings,
      settings: {
        ...currentSettings.settings,
        giftCardsEnabled,
      },
    }))
  }

  const currentUserIsNotGiftCreator =
    settings.userPublicId !== currentUser?.publicId

  const alert = (
    <Alert
      primaryMessage={
        "Only the creator of this gift, " +
        settings.userFullName +
        ", can edit this field."
      }
      color={AlertColor.yellow}
    />
  )

  if (settings.isDirectSend) {
    return null
  }

  return (
    <OptionBase
      name="International shipping"
      disabled={currentUserIsNotGiftCreator}
      alert={currentUserIsNotGiftCreator ? alert : null}
    >
      <InternationalShippingOptions
        internationalShippingTier={settings.internationalShippingTier}
        setInternationalShippingTier={handleSetInternationalShippingTier}
        giftCardsEnabled={settings.settings.giftCardsEnabled || false}
        setGiftCardsEnabled={handleSetGiftCardsEnabled}
        swapType={settings.swapType}
        disableGlobalTier={cartQuantity >= 2}
        disableStandardTier={hasSpecialtyFlexGiftItem}
      />
    </OptionBase>
  )
}
