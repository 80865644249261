import { gql } from "@apollo/client"

export const SEND_PRODUCT_CUSTOM_STORE_QUERY = gql`
  query SendProductCustomStore($productId: ID!) {
    product(productId: $productId) {
      id
      customStore {
        id
        internationalShippingTier
        settings {
          giftCardsEnabled
        }
      }
    }
  }
`
