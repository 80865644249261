import tw, { styled } from "twin.macro"

import { DomesticShippingTag } from "../components/shipping/DomesticShippingTag"
import { GlobalShippingTag } from "../components/shipping/GlobalShippingTag"

import { ReactComponent as ChevronRight } from "@/assets/icons/chevron-right-gray.svg"
import {
  DEFAULT_COUNTRY,
  useShippingCountries,
} from "@/common/hooks/useShippingCountries"
import Image from "@/common/Image"
import { ReactComponent as ShippingIcon } from "@/common/images/icon-shipping.svg"
import BrandValueBadge, {
  isBrandValueKey,
  sortBrandValuesBySelected,
} from "@/store/components/BrandValueBadge"
import {
  ShippingCountry,
  ShippingCountryGroup,
  Store_ProductStoreTileFragment,
} from "@/types/graphql-types"

interface Props {
  onClick: () => void
  href: string
  imageUrl?: string | null
  priceLabel?: string | null
  shippingLabel?: string | null
  name?: string | null
  subtitle?: string | null
  brandName?: string | null
  brandValues?: string[]
  brandValuesFilterValue?: string[]
  showShippingPrice?: boolean
  variantsLabel?: string | null
  isGiftCardProduct?: boolean
  shouldScale?: boolean
  product?: Store_ProductStoreTileFragment
  selectedShippingCountry: ShippingCountry
  shippingCountryGroup: ShippingCountryGroup
}

export default function StoreTile({
  onClick,
  href,
  imageUrl,
  priceLabel,
  shippingLabel,
  name,
  subtitle,
  brandName,
  brandValues,
  brandValuesFilterValue,
  showShippingPrice,
  variantsLabel,
  isGiftCardProduct,
  shouldScale,
  product,
  selectedShippingCountry,
  shippingCountryGroup,
}: Props) {
  const { findCountryByCode } = useShippingCountries()

  const showShippingTag =
    selectedShippingCountry &&
    selectedShippingCountry.code !== DEFAULT_COUNTRY.code

  const domesticCountries = shippingCountryGroup.domestic?.filter(
    (code) => code !== DEFAULT_COUNTRY.code,
  )
  const domesticCountry = domesticCountries?.includes(
    selectedShippingCountry.code,
  )
    ? selectedShippingCountry
    : findCountryByCode(domesticCountries?.[0])
  const displayDomesticShipping =
    showShippingTag && !!domesticCountries?.length && domesticCountry
  const displayGlobalShipping = showShippingTag && !domesticCountries?.length

  // We display the shipping row under a product if:
  const displayShippingOrSubtitleRow =
    // The shipping price is being shown
    (showShippingPrice && shippingLabel) ||
    // The shipping tag (international shipping options) is hidden but it is
    // a gift option and has a subtitle
    (!showShippingTag && !product && !!subtitle) ||
    // We are showing the shipping tag
    (showShippingTag && (displayDomesticShipping || displayGlobalShipping))

  return (
    <TileContainer
      onClick={(e) => {
        e.preventDefault()
        onClick()
      }}
      href={href}
    >
      {imageUrl && (
        <Image
          tw="overflow-hidden rounded-xl object-cover w-full"
          css={[
            { aspectRatio: isGiftCardProduct ? "16 / 10" : "5 / 4" },
            shouldScale && tw`object-contain bg-white`,
          ]}
          src={imageUrl}
          alt=""
          width={460}
        />
      )}

      {isGiftCardProduct ? (
        <div tw="pt-2 font-medium text-center">{name}</div>
      ) : (
        <>
          <div tw="flex flex-col mt-5">
            <div tw="flex flex-row justify-between">
              <h3 tw="font-medium text-lg">{name}</h3>

              {priceLabel && <div tw="font-text text-lg">{priceLabel}</div>}
            </div>

            {brandName ? (
              <>
                <div tw="flex flex-row justify-between items-start mt-1">
                  <Subtitle tw="pt-0.5">{brandName}</Subtitle>

                  {showShippingPrice && shippingLabel && (
                    <div tw="font-text flex flex-row items-end items-center text-gray-400">
                      <ShippingIcon tw="stroke-current text-gray-300 flex-shrink-0" />
                      <div tw="ml-1.5">
                        {!displayGlobalShipping && shippingLabel}
                        {displayGlobalShipping &&
                          (shippingLabel !== "Free"
                            ? `${shippingLabel} + Global`
                            : "Global")}
                      </div>
                    </div>
                  )}
                </div>

                <div tw="my-2">
                  {showShippingTag && (
                    <div>
                      {displayDomesticShipping && (
                        <DomesticShippingTag
                          country={domesticCountry}
                          twStyle={tw`flex-shrink`}
                        />
                      )}
                      {displayGlobalShipping && <GlobalShippingTag />}
                    </div>
                  )}
                </div>

                {subtitle && <Subtitle>{subtitle}</Subtitle>}
              </>
            ) : (
              <>
                {displayShippingOrSubtitleRow && (
                  <div tw="flex flex-row space-x-2 justify-between items-start my-2">
                    <div>
                      {showShippingTag && (
                        <div>
                          {displayDomesticShipping && (
                            <DomesticShippingTag
                              country={domesticCountry}
                              twStyle={tw`flex-shrink`}
                            />
                          )}
                          {displayGlobalShipping && <GlobalShippingTag />}
                        </div>
                      )}

                      {!showShippingTag && !product && subtitle && (
                        <Subtitle tw="flex-shrink">{subtitle}</Subtitle>
                      )}
                    </div>

                    {showShippingPrice && shippingLabel && (
                      <div tw="flex flex-shrink-0">
                        <div tw="font-text flex flex-row items-end items-center text-gray-400 flex-shrink-0">
                          <ShippingIcon tw="stroke-current text-gray-300" />
                          <div tw="ml-1.5">
                            {!displayGlobalShipping && shippingLabel}
                            {displayGlobalShipping &&
                              (shippingLabel !== "Free"
                                ? `${shippingLabel} + Global`
                                : "Global")}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {(showShippingTag || (!showShippingTag && product)) && (
                  <Subtitle tw="mt-1.5">{subtitle}</Subtitle>
                )}
              </>
            )}
          </div>
        </>
      )}

      {variantsLabel && (
        <div tw="pt-2 flex flex-row items-center gap-1 text-gray-450 text-[15px]">
          {variantsLabel}
          <ChevronRight tw="w-4 h-4 pt-[2px]" />
        </div>
      )}
      {brandValues &&
        brandValues.length > 0 &&
        brandValuesFilterValue &&
        brandValuesFilterValue.length > 0 && (
          <div tw="flex flex-wrap -mx-1 pt-3">
            {sortBrandValuesBySelected(
              brandValues,
              brandValuesFilterValue || [],
            ).map(
              (brandValue) =>
                isBrandValueKey(brandValue) && (
                  <BrandValueBadge
                    active={brandValuesFilterValue.includes(brandValue)}
                    value={brandValue}
                    small={true}
                    key={brandValue}
                  />
                ),
            )}
          </div>
        )}
    </TileContainer>
  )
}

const TileContainer = styled.a`
  ${tw`
    relative z-[1] w-full h-full lg:rounded-2xl transition-colors outline-none transition-all duration-150 ease-out block
    active:scale-[0.98]
    after:(content[''] z-[-10] absolute inset-[-1rem] rounded-2xl bg-transparent border border-transparent transition-all duration-300 ease-out)
  `}

  &:hover {
    &:after {
      ${tw`bg-[#FCFBFF]! border-primary-new-100`};
    }
  }

  &:active {
    &:after {
      ${tw`after:bg-primary-new-000`};
    }
  }
`

const Subtitle = tw.div`text-gray-600 text-[15px]`
