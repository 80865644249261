import "twin.macro"
import { Flow } from "@frigade/js"
import React from "react"
import { Link } from "react-router-dom"
import tw from "twin.macro"

import { track } from "@/common/analytics"
import Button from "@/common/Button"
import { generateRealmPath } from "@/common/realm"
import ChecklistIcon from "@/onboarding/ChecklistUI/ChecklistIcon"

interface Props {
  flow: Flow
  minimized: boolean
  dismissConfirmationVisible: boolean
  setDismissConfirmationVisible: (visible: boolean) => void
}

export default function Content({
  flow,
  minimized,
  dismissConfirmationVisible,
  setDismissConfirmationVisible,
}: Props) {
  // Selected index is the first item that is not completed
  const selectedIndex = Array.from(flow.steps).findIndex(
    ([, step]) => !step.$state.completed,
  )

  function dismiss() {
    track("Business - Onboarding Checklist - Dismiss")
    flow.skip()
  }

  return (
    <div
      tw="overflow-hidden max-h-[350px] opacity-100 transition-all duration-300 pt-1 relative"
      css={[minimized && tw`max-h-0 opacity-0`]}
    >
      {dismissConfirmationVisible && (
        <div tw="absolute inset-0 z-10 bg-gray-050 m-2 mb-3 rounded-lg flex flex-col items-center justify-center gap-4">
          <div>Exit the guide?</div>
          <div tw="flex flex-row gap-2 justify-center items-stretch">
            <Button onClick={() => setDismissConfirmationVisible(false)}>
              No
            </Button>
            <Button onClick={dismiss} variant="primary-new">
              Yes
            </Button>
          </div>
        </div>
      )}
      <div tw="px-2 pt-1 pb-3 gap-px flex flex-col items-stretch">
        {Array.from(flow.steps).map(([id, step]) => {
          const selected = selectedIndex === step.order
          const completed = step.$state.completed

          return (
            <div key={id} tw="flex flex-col">
              <Link
                // All roads lead to...
                to={generateRealmPath("plus", "/send")}
                tw="flex flex-col items-start hover:bg-gray-050 p-3 py-2.5 px-4 rounded-[10px] transition-all"
                css={[
                  selected && tw`bg-primary-new-000 hover:bg-primary-new-050`,
                  !selected && tw`text-gray-500 pointer-events-none`,
                ]}
                onClick={() => {
                  track("Business - Onboarding Checklist - Click Step", {
                    step: step.order + 1,
                  })
                }}
              >
                <div tw="flex flex-row items-center gap-3">
                  <ChecklistIcon completed={completed} selected={selected} />
                  <div css={[selected && tw`text-primary-new-600 font-medium`]}>
                    {step.title}
                  </div>
                </div>
                {selected && (
                  <div tw="leading-[150%] text-gray-600 text-sm pl-8 pt-1">
                    {step.subtitle}
                  </div>
                )}
              </Link>
            </div>
          )
        })}
      </div>
    </div>
  )
}
