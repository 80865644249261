import React from "react"
import tw, { styled } from "twin.macro"

import CircleUserIcon from "@/assets/icons/circle-user.svg"
import { getCsrfToken } from "@/common/csrf"
import { getEnvVars } from "@/common/environment"
import { isBlank } from "@/common/format"
import { resetIframeAuthIfPresent } from "@/common/hooks/iframeAuth"
import Image from "@/common/Image"
import { setRestoreToken } from "@/signUp/restoreToken"

const { apiUrl } = getEnvVars()

interface Props {
  email: string | null
  token: string | null
  origin?: string
}

const SSOSignUpButton = ({ email, token }: Props) => {
  const csrfToken = getCsrfToken()

  const postUrl = `${apiUrl}/users/auth/saml/identity_provider_id?autoenroll=true`

  const handleSubmit = () => {
    resetIframeAuthIfPresent()

    // Before opening the identity provider login, if there is a token
    // present, save the token in session storage so it can be restored.
    if (token) {
      setRestoreToken(token)
    }

    return true
  }

  return (
    <form
      action={postUrl}
      method="post"
      target="_self"
      onSubmit={handleSubmit}
      tw="w-full"
    >
      <input type="hidden" name="authenticity_token" value={csrfToken} />
      <input type="hidden" name="email" id="email" value={email ?? ""} />

      <StyledButton type="submit" disabled={isBlank(email)}>
        <Image src={CircleUserIcon} tw="sm:mr-[212px]" />
        Sign up with SSO
      </StyledButton>
    </form>
  )
}

const StyledButton = styled.button`
  ${tw`flex flex-row items-center py-[17px] px-[21px] text-[#1F2937] border border-[#E5E7EB] rounded-lg font-semibold w-full mb-8`}
  ${tw`hover:border-primary-200 focus-visible:border-primary-300 focus:outline-none active:bg-primary-000 transition-colors active:transition-border transition-opacity disabled:cursor-default disabled:active:bg-white disabled:hover:border-primary-100 transition-colors`}
  box-shadow: 0 2px 4px rgba(228, 216, 244, 0.3);
`

export default SSOSignUpButton
