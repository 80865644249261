import useTeamPlan from "@/common/hooks/teamPlan"
import BillingEmailForm from "@/organization/settings/receipts/BillingEmailForm"
import BillingEmailUpsell from "@/organization/settings/receipts/BillingEmailUpsell"
import Section from "@/organization/settings/Section"

const Receipts = () => {
  const { onTeamPlan, onEEPlan, organizationId, loading } = useTeamPlan()

  if (loading) return null

  return (
    <Section title="Receipts">
      {onTeamPlan || onEEPlan ? (
        <BillingEmailForm organizationId={organizationId} />
      ) : (
        <BillingEmailUpsell />
      )}
    </Section>
  )
}

export default Receipts
