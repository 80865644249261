import React from "react"
import tw from "twin.macro"

import { useGiftData } from "./hooks/giftData"

import {
  USER_MANUALLY_OMITTED_CREDIT_SESSION_STORAGE_KEY,
  USER_MANUALLY_OMITTED_CREDIT_SESSION_STORAGE_VALUE,
} from "@/common/userManuallyOmittedCreditSessionStorage"

const RemoveCreditButton = ({ small }: { small?: boolean }) => {
  const { setOmitCredit } = useGiftData()

  return (
    <span
      tw="text-gray-450 hover:text-gray-600 active:text-gray-700 transition-colors leading-5 font-normal text-base cursor-pointer whitespace-nowrap"
      onClick={() => {
        setOmitCredit(true)

        window.sessionStorage.setItem(
          USER_MANUALLY_OMITTED_CREDIT_SESSION_STORAGE_KEY,
          USER_MANUALLY_OMITTED_CREDIT_SESSION_STORAGE_VALUE,
        )
      }}
      css={[small && tw`text-sm`]}
    >
      <span tw="px-1.5">·</span>Remove
    </span>
  )
}

export default RemoveCreditButton
