import { gql, useQuery } from "@apollo/client"
import { useCallback, useState } from "react"
import Modal from "react-modal"
import { useHistory } from "react-router-dom"
import tw, { styled } from "twin.macro"

import { CopyIcon } from "./assets/icons"

import { ReactComponent as AlertIcon } from "@/assets/icons/alert.svg"
import { ReactComponent as RecipientIcon } from "@/assets/icons/recipient.svg"
import { ReactComponent as CloseX } from "@/assets/icons/x.svg"
import Button from "@/common/Button"
import { useGlobalState } from "@/common/GlobalState"
import { useGiftCart } from "@/common/hooks/giftData"
import { modalStyle } from "@/common/modal"
import { BASE_GIFT_BATCH_FRAGMENT } from "@/common/queries"
import { generateRealmPath } from "@/common/realm"
import { Loader } from "@/common/UI"
import { generateUUID } from "@/common/utilities"
import {
  Resend_GiftBatchQuery,
  Resend_GiftBatchQueryVariables,
} from "@/types/graphql-types"

type ResendGiftBatch = NonNullable<
  NonNullable<Resend_GiftBatchQuery["workspace"]>["giftBatch"]
>

interface Props {
  giftBatchId: string
}

export default function ResendBatchGiftButton({ giftBatchId }: Props) {
  const [isModalOpen, setModalOpen] = useState(false)

  return (
    <>
      <ResendModal
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        giftBatchId={giftBatchId}
      />
      <Button tw="text-gray-600" onClick={() => setModalOpen(true)}>
        <CopyIcon tw="self-center stroke-current" />
        Clone
      </Button>
    </>
  )
}

const useResendGiftCardAndMessage = (giftBatch: ResendGiftBatch) => {
  const history = useHistory()

  const { resetWithGiftBatch } = useGiftCart()
  const sendWithNamesMessageCard = () => {
    resetWithGiftBatch(giftBatch)
    history.push(generateRealmPath("plus", "/send"))
  }

  return { sendWithNamesMessageCard }
}

const ErrorNotice = ({ text }: { text: string }) => (
  <ErrorDiv>
    <span tw="align-middle">{text}</span>
  </ErrorDiv>
)

const useNameMessageCardErrorText = ({
  cardExists,
  productExists,
  brandName,
  productName,
}: {
  cardExists: boolean
  productExists: boolean
  brandName: string
  productName: string
}) => {
  let errorMessage = null

  if (!cardExists && !productExists) {
    errorMessage =
      "Gift and card are no longer available. You can still copy over the sender name and message."
  }

  if (!cardExists && productExists) {
    errorMessage =
      "Card no longer available. You can still select another card."
  }

  if (cardExists && !productExists) {
    errorMessage = `Gift no longer available (${brandName} – ${productName}). You can still select another gift.`
  }

  return errorMessage
}

const NameMessageCardImage = ({
  cardExists,
  productExists,
  productName,
  cardImage,
  productImage,
}: {
  cardExists: boolean
  productExists: boolean
  productName: string
  cardImage?: string
  productImage?: string
}) => {
  if (!cardExists && !productExists) {
    return (
      <div tw="mb-4 md:mb-0 mx-auto h-20">
        <div tw="align-middle items-center inline-flex">
          <AlertIcon
            tw="m-auto"
            css={{
              filter:
                "drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.08)) drop-shadow(0px 12px 24px rgba(0, 0, 0, 0.07))",
            }}
          />
        </div>
        <span tw="h-full align-middle inline-block" />
      </div>
    )
  }

  if (!cardExists && productExists) {
    return (
      <div tw="m-auto justify-center my-0 self-center justify-center flex flex-row h-20">
        <div tw="place-self-center flex justify-center items-center">
          <Image
            tw="m-auto rounded-lg block max-h-full h-18 w-18 object-cover align-middle"
            alt={productName}
            className="resend-image"
            src={productImage}
          />
        </div>
      </div>
    )
  }

  if (cardExists && !productExists) {
    return (
      <div tw="m-auto justify-center my-0 self-center justify-center flex flex-row h-20">
        <div tw="place-self-center flex justify-center items-center">
          <Image
            alt="card"
            tw="m-auto rounded-lg block h-18 object-scale-down align-middle"
            className="resend-image"
            src={cardImage}
          />
        </div>
      </div>
    )
  }

  return (
    <div tw="flex flex-row space-x-1 justify-center h-20 self-center">
      <div tw="place-self-center flex justify-center items-center">
        <Image
          alt={productName}
          tw="rounded-lg block object-cover h-18 w-18 align-middle"
          className="resend-image"
          src={productImage}
        />
      </div>
      <div tw="place-self-center flex justify-center items-center">
        <Image
          tw="transform h-18 origin-left rounded-lg block object-scale-down align-middle"
          alt="card"
          className="resend-image"
          src={cardImage}
        />
      </div>
    </div>
  )
}

const NameMessageCardButton = ({ data }: { data: ResendGiftBatch }) => {
  const { sendWithNamesMessageCard } = useResendGiftCardAndMessage(data)
  const product = data.cartProducts[0].product
  const card = data.card
  const productImage =
    product?.productImages[0]?.imageThumb?.url ||
    data.customStoreImageThumb?.url
  const cardImage = card?.image.url
  const brandName = data.cartProducts[0].productFragment.brandName
  const productName = data.cartProducts[0].productTitle
  const errorMessage = useNameMessageCardErrorText({
    cardExists: !!card,
    productExists: !!product,
    brandName: brandName,
    productName: productName,
  })

  return (
    <DataSelectionButton onClick={() => sendWithNamesMessageCard()}>
      <NameMessageCardImage
        cardExists={!!card}
        productExists={!!product}
        productName={productName}
        productImage={productImage}
        cardImage={cardImage}
      />
      <div tw="flex flex-col self-center pt-8">
        <div tw="text-gray-500">
          <SelectionTitle>Clone gift, card, and message</SelectionTitle>
          {!!product && (
            <div>
              <div tw="pt-3 text-sm leading-p-130">
                {brandName}
                <br />
                {productName}
              </div>
            </div>
          )}
        </div>
        <div>{errorMessage && <ErrorNotice text={errorMessage} />}</div>
      </div>
    </DataSelectionButton>
  )
}

export const useResendRecipients = (giftBatch: ResendGiftBatch) => {
  const history = useHistory()
  const [recipients, setRecipients] = useGlobalState("recipients")
  const sendWithRecipients = useCallback(() => {
    const recipients = giftBatch.recipients.map((recipient: any) => ({
      id: generateUUID(),
      firstName: recipient.firstName,
      lastName: recipient.lastName,
      email: recipient.email,
      phone: recipient.phone,
      scheduledEventId: null,
      scheduledEventKind: null,
      eventDate: null,
      errors: {},
    }))
    setRecipients(recipients)
    history.push(generateRealmPath("plus", "/send"))
  }, [giftBatch, history, setRecipients])

  return { sendWithRecipients, recipients }
}

const RecipientsButton = ({ data }: { data: ResendGiftBatch }) => {
  const { sendWithRecipients, recipients } = useResendRecipients(data)
  const recipientCount = data.recipients.length

  const oldRecipientsExist =
    recipients.filter(
      (recipient: any) =>
        recipient.firstName ||
        recipient.lastName ||
        recipient.email ||
        recipient.phone,
    ).length > 0

  return (
    <DataSelectionButton
      disabled={data.isSmartLink ?? false}
      tw="pb-4 md:pb-0"
      onClick={() => sendWithRecipients()}
    >
      <div tw="w-full h-20">
        <div tw="inline-flex items-center align-middle h-full">
          <RecipientIcon
            tw="m-auto"
            css={{
              filter:
                "drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.08)) drop-shadow(0px 12px 24px rgba(0, 0, 0, 0.07))",
            }}
          />
        </div>
      </div>
      <div tw="w-full pt-8">
        <SelectionTitle tw="text-gray-500">Clone recipients</SelectionTitle>
        {!data.isSmartLink && (
          <div tw="text-gray-500 pt-3 text-sm">{recipientCount} recipients</div>
        )}
        {oldRecipientsExist && (
          <ErrorNotice text="This will replace your current recipients." />
        )}
      </div>
    </DataSelectionButton>
  )
}

const ResendModalBody = ({
  data,
  close,
}: {
  data?: ResendGiftBatch | null
  close: () => void
}) => {
  if (!data) {
    return (
      <div tw="justify-center p-6 items-center text-center flex flex-row">
        <Loader tw="m-16 place-self-center self-center" />
      </div>
    )
  }

  return (
    <div tw="justify-center items-center text-center h-full">
      <div tw="flex flex-col h-full">
        <div tw="h-12">
          <CloseX tw="float-right h-4 w-4 mt-6 mr-6" onClick={() => close()} />
        </div>
        <div tw="pb-12">
          <div tw="text-2xl font-medium text-primary-500 mb-4">
            Select what you want to clone
          </div>
          <div tw="text-gray-500 leading-p-130">
            You can customize and make edits afterwards.
          </div>
        </div>
        <div tw="flex flex-wrap flex-row justify-evenly max-w-full">
          <NameMessageCardButton data={data} />
          <RecipientsButton data={data} />
        </div>
      </div>
    </div>
  )
}

const ResendModal = ({
  isModalOpen,
  setModalOpen,
  giftBatchId,
}: {
  isModalOpen: boolean
  setModalOpen: (isModalOpen: boolean) => void
  giftBatchId: string
}) => {
  const { data } = useGiftBatch(giftBatchId)

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={() => {
        setModalOpen(false)
      }}
      shouldCloseOnOverlayClick={true}
      style={modalStyle}
    >
      <div
        className="modal-content"
        style={{ maxWidth: "656px" }}
        tw="overflow-y-auto h-80v md:h-auto pb-12"
      >
        <ResendModalBody data={data} close={() => setModalOpen(false)} />
      </div>
    </Modal>
  )
}

const Image = styled.img`
  box-shadow:
    0px 1px 4px rgba(0, 0, 0, 0.08),
    0px 12px 24px rgba(0, 0, 0, 0.07);
`

const SelectionTitle = styled.div`
  ${tw`text-xl`};
`

const DataSelectionButton = styled.button<{ disabled?: boolean }>`
  ${tw`border transition-shadow pb-4 pt-6 mb-8 md:m-0 focus:outline-none focus-visible:border-primary-300 enabled:active:bg-gray-050 flex-col inline-flex rounded-2xl w-full mx-12 md:w-64 disabled:cursor-default disabled:opacity-50`};
  &:enabled:hover {
    box-shadow:
      0px 1px 4px rgba(0, 0, 0, 0.06),
      0px 12px 32px rgba(0, 0, 0, 0.07);
    ${SelectionTitle} {
      color: #a372df;
    }
  }
`

const ErrorDiv = styled.div`
  ${tw`text-red-500 py-2 mt-4 mb-4 self-center text-sm w-5/6 mx-auto px-2 bg-red-50 rounded-lg leading-p-130`};
`

const useGiftBatch = (giftBatchId: string) => {
  const { data } = useQuery<
    Resend_GiftBatchQuery,
    Resend_GiftBatchQueryVariables
  >(GIFT_BATCH_QUERY, {
    variables: {
      id: giftBatchId,
    },
    fetchPolicy: "cache-and-network",
  })
  return { data: data?.workspace?.giftBatch }
}

const GIFT_BATCH_QUERY = gql`
  query Resend_GiftBatch($id: ID!) {
    workspace {
      giftBatch(id: $id) {
        ...Base_GiftBatch
        gifts {
          amountProduct
          senderViewAccessId
        }
        recipients {
          id
          firstName
          lastName
          email
          phone
        }
        isSmartLink
      }
    }
  }
  ${BASE_GIFT_BATCH_FRAGMENT}
`
