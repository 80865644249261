import { track } from "../../../common/analytics"
import RoundedButton from "../../../common/RoundedButton"
import Section, { SectionContainer } from "../Section"

interface Props {
  onClickApply: () => void
}

export default function NextStepsAutomation({ onClickApply }: Props) {
  return (
    <SectionContainer>
      <Section tw="bg-[#f9f7ff]">
        <div tw="flex flex-col md:flex-row items-center gap-8">
          <div tw="md:w-3/5 text-left">
            <div tw="font-reckless-neue text-[2rem] text-primary-new-600 tracking-[-0.64px] leading-tight font-350">
              Got questions? We’re here to help.
            </div>
            <div tw="text-lg md:text-xl text-gray-800 pt-4 leading-[150%]!">
              Tell us about yourself. A member of our team will get in touch to
              set up a quick introductory call.
            </div>
          </div>
          <div tw="flex-1 w-full">
            <div tw="flex flex-col gap-4 lg:pl-24 w-full">
              <RoundedButton
                tw="px-6 py-[14px]"
                variant="primary"
                onClick={() => {
                  track("Business Marketing - Automation API - Click Apply")
                  onClickApply()
                }}
              >
                Let’s chat
              </RoundedButton>
              <RoundedButton
                tw="px-6 py-[14px] font-medium"
                variant="secondary"
                element="a"
                href="https://developer.ongoody.com"
                target="_blank"
                rel="noopener"
                onClick={() =>
                  track("Business Marketing - Automation API - Click Docs")
                }
              >
                Read API Docs
              </RoundedButton>
            </div>
          </div>
        </div>
      </Section>
    </SectionContainer>
  )
}
