import { gql, useMutation } from "@apollo/client"
import React, { useCallback, useMemo, useState } from "react"
import tw, { styled } from "twin.macro"

import ContactFormattingCheck from "./ContactImport/ContactFormattingCheck"
import ContactMatchFields from "./ContactImport/ContactMatchFields"
import ContactRunImport from "./ContactImport/ContactRunImport"
import ContactUpload from "./ContactImport/ContactUpload"
import {
  FieldType,
  ImportStep,
  Issue,
  getFieldError,
  getFieldInfo,
  getImportListName,
  getMatchedFieldError,
  getSuggestedField,
  resultUpdated,
} from "./ContactImport/shared"
import { useCurrentWorkspace } from "../../common/hooks"
import { ContactList } from "../lib"
import { CONTACT_LISTS_QUERY } from "../queries"

import { ContactInput } from "@/types/graphql-types"
import {
  ContactUploadResult,
  Contact_BatchUpdateMutation,
  Contact_BatchUpdateMutationVariables,
} from "@/types/graphql-types"

interface Props {
  lists: ContactList[]
  currentListId: string | null
  onSelectListId: (listId: string | null) => void
}

const ContactImport: React.FC<Props> = ({
  lists,
  currentListId,
  onSelectListId,
}) => {
  const { currentWorkspace } = useCurrentWorkspace()

  const [importStep, setImportStep] = useState<ImportStep>("upload")

  // These are the rows and headers that are imported from the CSV/XLS/XSLS.
  // Headers can be null if the document didn't have headers.
  const [importedRows, setImportedRows] = useState<string[][] | null>(null)
  const [importedRowHeaders, setImportedRowHeaders] = useState<string[] | null>(
    null,
  )

  // Array of fields in the index column that they were matched two. I.E.
  // if the first column is "First name", the first value in this array
  // will be "First name". If there is no match, the entry will be null.
  const [matchedFields, setMatchedFields] = useState<
    (null | FieldType)[] | null
  >(null)

  // Issues are fields where the values are invalid
  const [issues, setIssues] = useState<Issue[]>([])

  // We start with the currently selected list. However, this can change.
  const [importListId, setImportListId] = useState(currentListId)
  // Name of a new list if we're creating it
  const [newImportListName, setNewImportListName] = useState("")

  // Name of the list that we're currently importing into
  const importListName = useMemo(
    () => getImportListName(lists, importListId, newImportListName),
    [importListId, newImportListName, lists],
  )

  // To render the preview, we need new, updated, and unchanged contacts
  const [importPreview, setImportPreview] = useState<{
    new: ContactUploadResult[]
    updated: ContactUploadResult[]
    unchanged: ContactUploadResult[]
  }>({ new: [], updated: [], unchanged: [] })

  const [upload, { loading: isLoading }] = useMutation<
    Contact_BatchUpdateMutation,
    Contact_BatchUpdateMutationVariables
  >(CONTACT_BATCH_UPDATE)

  const setRows = (rows: string[][]) => {
    const headers = rows[0]
    const newMatchedFields = Array(headers.length)

    // Find the field we suggest for the header value
    headers.forEach((header, index) => {
      newMatchedFields[index] = getSuggestedField(String(header))
    })

    // If we found at least one field, we assume there's a header row
    if (newMatchedFields.some((header) => !!header)) {
      setImportedRowHeaders(headers)
      setImportedRows(rows.slice(1))
    } else {
      setImportedRowHeaders(null)
      setImportedRows(rows)
    }

    setMatchedFields(newMatchedFields)
  }

  const findIssues = () => {
    const newIssues: Issue[] = []

    const firstNameIndex = matchedFields?.indexOf("First name") ?? -1
    const lastNameIndex = matchedFields?.indexOf("Last name") ?? -1
    const emailIndex = matchedFields?.indexOf("Email") ?? -1

    importedRows?.forEach((row, rowIndex) => {
      const user = {
        firstName: row[firstNameIndex],
        lastName: row[lastNameIndex],
        email: row[emailIndex],
      }
      // We iterate over the matched headers instead of the row
      // because forEach will skip empty items in the row
      matchedFields?.forEach((field, columnIndex) => {
        if (field) {
          const value = row[columnIndex]
          const error = getFieldError(field, value)

          if (error) {
            newIssues.push({
              user,
              value,
              row: rowIndex,
              column: columnIndex,
            })
          }
        }
      })
    })

    setIssues(newIssues)
    return newIssues
  }

  // Sets the value for a fixed issue
  const setIssueValue = (issue: Issue, value: string) => {
    issue.value = value
    if (importedRows) {
      importedRows[issue.row][issue.column] = value
    }
  }

  // Run the import. The "save" parameter determines whether or not
  // we will persist these changes to the database.
  const runImport = async (save: boolean = true) => {
    const useExistingList = !!importListId

    const res = await upload({
      variables: {
        save,
        listId: importListId,
        contacts:
          importedRows?.map((row) => {
            const contactInput: any = {}
            matchedFields?.forEach((column, index) => {
              if (column) {
                const field = getFieldInfo(column)
                const value = row[index]

                // Ignore fields with an error
                if (!field.getError(value.trim())) {
                  contactInput[field.inputName] = field.toInputValue(
                    value.trim(),
                  )
                }
              }
            })

            return contactInput as ContactInput
          }) ?? [],
        // We need this conditional, because the newListName takes
        // precedence over the importListId on the server. Because
        // the newImportListName just represents the value in the text
        // field, we need to make sure we actually want to create a new list.
        newListName: useExistingList ? null : newImportListName,
      },
      refetchQueries: save ? [{ query: CONTACT_LISTS_QUERY }] : [],
    })
    if (res?.data?.contactBatchUpdate?.ok) {
      const newImportPreview: typeof importPreview = {
        new: [],
        updated: [],
        unchanged: [],
      }

      res?.data?.contactBatchUpdate?.results.forEach((result) => {
        if (result.new) {
          newImportPreview.new.push(result)
        } else if (resultUpdated(result)) {
          newImportPreview.updated.push(result)
        } else {
          newImportPreview.unchanged.push(result)
        }
      })

      setImportPreview(newImportPreview)
      setImportStep("review-and-import")
    }
    return res?.data?.contactBatchUpdate ?? null
  }

  // Only "First name" is required to be fixed
  const isAllRequiredIssuesFixed = () =>
    !matchedFields ||
    issues.every(
      (issue) =>
        matchedFields[issue.column] !== "First name" ||
        !getFieldError(matchedFields[issue.column]!, issue.value),
    )

  // Check for all errors
  const isAllIssuesFixed = () =>
    !matchedFields ||
    issues.every(
      (issue) => !getFieldError(matchedFields[issue.column]!, issue.value),
    )

  const goNext = useCallback(async () => {
    switch (importStep) {
      case "upload":
        setImportStep("match-columns")
        break
      case "match-columns":
        const error = getMatchedFieldError(matchedFields)
        if (error) {
          alert(error)
          return
        }
        const newIssuses = findIssues()

        // If we have no issues, we skip "formatting-check"
        if (newIssuses.length > 0) {
          setImportStep("formatting-check")
        } else {
          runImport(false)
        }
        break
      case "formatting-check":
        if (!isAllRequiredIssuesFixed()) {
          window.alert("Add a first name to all contacts to continue.")
        } else if (!isAllIssuesFixed()) {
          if (
            window.confirm(
              "Warning - your upload has outstanding issues to fix.\nDo you want to continue? (Unfixed issues will be ignored)",
            )
          ) {
            runImport(false)
          }
        } else {
          runImport(false)
        }
    }
  }, [importStep, matchedFields, setImportStep])

  const goPrevious = useCallback(() => {
    switch (importStep) {
      case "match-columns":
        setImportStep("upload")
        break
      case "formatting-check":
        setImportStep("match-columns")
        break
      case "review-and-import":
        // We go back to formatting-check even if the issues are fixed
        setImportStep(
          issues.length === 0 ? "match-columns" : "formatting-check",
        )
        break
      case "upload":
        break
    }
  }, [importStep, setImportStep, issues.length])

  return (
    <div tw="flex flex-1 flex-col h-full overflow-auto">
      <ViewContainer tw="py-9">
        <div tw="text-sm">
          <div tw="font-medium text-primary-500">{currentWorkspace?.name}</div>
        </div>
        <div tw="pt-2 text-3xl font-medium">Import Contacts</div>
        <Breadcrumb importStep={importStep} importListName={importListName} />
        <ContactUpload
          importStep={importStep}
          goNext={goNext}
          setRows={setRows}
          lists={lists}
          importListId={importListId}
          setImportListId={setImportListId}
          newImportListName={newImportListName}
          setNewImportListName={setNewImportListName}
        />
        <ContactMatchFields
          importStep={importStep}
          goNext={goNext}
          goPrevious={goPrevious}
          rows={importedRows}
          rowHeaders={importedRowHeaders}
          matchedFields={matchedFields}
          setMatchedFields={setMatchedFields}
          isLoading={isLoading}
        />
        <ContactFormattingCheck
          importStep={importStep}
          goNext={goNext}
          goPrevious={goPrevious}
          issues={issues}
          matchedFields={matchedFields}
          setIssueValue={setIssueValue}
          isLoading={isLoading}
        />
        <ContactRunImport
          importStep={importStep}
          goPrevious={goPrevious}
          importPreview={importPreview}
          runImport={runImport}
          isLoading={isLoading}
          onPressOpenList={onSelectListId}
          importListId={importListId}
        />
      </ViewContainer>
    </div>
  )
}

const ViewContainer = styled.div`
  ${tw`ml-auto mr-auto sm:mr-0 pr-5`};
  width: calc(100% * 11 / 12);
  @media (min-width: 640px) {
    width: calc(100% * 23 / 24);
  }
`

interface BreadcrumbProps {
  importStep: ImportStep
  importListName: string
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({
  importStep,
  importListName,
}) => {
  if (importStep === "upload") {
    return null
  }

  return (
    <BreadcrumbContainer>
      <BreadcrumbItem
        label="Match columns"
        number="1"
        selected={importStep === "match-columns"}
      />
      <BreadcrumbItem
        label="Formatting check"
        number="2"
        selected={importStep === "formatting-check"}
      />
      <BreadcrumbItem
        label="Review and import"
        number="3"
        selected={importStep === "review-and-import"}
      />
      <div tw="hidden sm:block flex-1" />
      <div tw="text-gray-400 border-l self-stretch p-4 hidden sm:block">
        Importing into{" "}
        <span tw="font-medium text-gray-500">{importListName}</span>
      </div>
    </BreadcrumbContainer>
  )
}

const BreadcrumbContainer = styled.div`
  ${tw`bg-white border border-gray-100 rounded-lg flex flex-col sm:flex-row flex-1 mt-8 py-4 sm:py-0`}
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05), 0px 4px 20px rgba(0, 0, 0, 0.04);
`

interface BreadcrumbItemProps {
  label: string
  number: string
  selected: boolean
}

const BreadcrumbItem: React.FC<BreadcrumbItemProps> = ({
  label,
  number,
  selected,
}) => {
  return (
    <div tw="flex flex-row items-center ml-4 mr-4 sm:ml-12 sm:mr-0 sm:first:ml-4 first:mt-0 mt-4 sm:mt-4 sm:first:mt-4 sm:mb-4">
      <div
        css={[
          tw`flex justify-center items-center h-7 w-7 rounded-full flex-shrink-0`,
          selected ? tw`bg-primary-500` : tw`bg-gray-200`,
        ]}
      >
        <div css={tw`text-white font-medium`}>{number}</div>
      </div>
      <div
        css={[tw`ml-3`, selected ? tw`text-primary-500` : tw`text-gray-400`]}
      >
        {label}
      </div>
    </div>
  )
}

const CONTACT_BATCH_UPDATE = gql`
  mutation Contact_BatchUpdate(
    $contacts: [ContactInput!]!
    $listId: ID
    $save: Boolean!
    $newListName: String
  ) {
    contactBatchUpdate(
      contacts: $contacts
      listId: $listId
      save: $save
      newListName: $newListName
    ) {
      ok
      error
      errorCode
      results {
        new
        newItems
        updateItems
        email
        name
        addListId
      }
    }
  }
`

export default ContactImport
