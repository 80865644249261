import {
  AutogiftRuleTenureClassification,
  useGlobalState,
} from "../../common/GlobalState"

import { omitSingleKey } from "@/common/utils/omitSingleKey"
import {
  AutogiftRuleAnchorDateSendOptionEnum,
  AutogiftRuleAnchorDateTypeEnum,
  AutogiftRuleProduct,
  AutogiftRuleStatus,
  GiftSwapTypeEnum,
  InternationalShippingTierEnum,
  StandardAutogiftRuleFragment,
} from "@/types/graphql-types"

interface UseAutogiftDataProps {
  onSelectAutogiftRule?: (autogiftRule: StandardAutogiftRuleFragment) => void
  onSelectNewAutogift?: () => void
}

// Hook to manage autogift rule data and set existing or new autogift rules to
// global state.
export const useAutogiftData = (props: UseAutogiftDataProps) => {
  const { onSelectAutogiftRule, onSelectNewAutogift } = props

  const [currentAutogiftRule, setCurrentAutogiftRule] = useGlobalState(
    "currentAutogiftRule",
  )

  // load existing autogift rule data to global state
  const loadAutogiftRuleData = (autogiftRule: StandardAutogiftRuleFragment) => {
    onSelectAutogiftRule && onSelectAutogiftRule(autogiftRule)

    if (autogiftRule !== null) {
      let tenureClassification: AutogiftRuleTenureClassification =
        AutogiftRuleTenureClassification.ALL
      const tenureMin = autogiftRule.tenureMin ?? null
      const tenureMax = autogiftRule.tenureMax ?? null

      if (tenureMin !== null && tenureMax !== null) {
        // Both min and max are present; determine which classification to set

        if (tenureMin === tenureMax) {
          tenureClassification = AutogiftRuleTenureClassification.YEAR_SINGLE
        } else {
          tenureClassification = AutogiftRuleTenureClassification.YEAR_RANGE
        }
      } else {
        if (
          (tenureMin !== null && tenureMax === null) ||
          (tenureMin === null && tenureMax !== null)
        ) {
          // One of min or max was present, but not both
          // This should not be possible, so set it to range and display an error
          tenureClassification = AutogiftRuleTenureClassification.YEAR_RANGE

          alert(
            "An error occurred with your tenure settings since either min or max tenure was specified, but not both. Please check your settings.",
          )
        }

        // Fallthrough: tenure min and max are null, so keep ALL
      }

      const loadSwapType = () => {
        if (autogiftRule.swapType) {
          return autogiftRule.swapType
        }

        // For legacy autogifts
        return autogiftRule.swapEnabled
          ? GiftSwapTypeEnum.swap_single
          : GiftSwapTypeEnum.swap_disabled
      }

      setCurrentAutogiftRule({
        id: autogiftRule.id,
        contactListId: autogiftRule.contactList.id,
        contactListName: autogiftRule.contactList.name,
        eventType: autogiftRule.eventType,
        status: autogiftRule.status,
        productList:
          autogiftRule.productList === null ||
          autogiftRule.productList === undefined
            ? []
            : autogiftRule.productList,
        card: autogiftRule.card
          ? {
              id: autogiftRule?.card?.id,
              image: autogiftRule?.card?.image?.url,
            }
          : null,
        fromName: autogiftRule.fromName || "",
        message: autogiftRule.message || "",
        expireAtOption:
          autogiftRule.expireAtOption === null
            ? currentAutogiftRule.expireAtOption
            : (autogiftRule.expireAtOption as any),
        autopayPaymentMethodName: autogiftRule.autopayPaymentMethodName,
        autopayPaymentMethodID: autogiftRule.autopayPaymentMethodId,
        swapType: loadSwapType(),
        userPublicId: autogiftRule.userPublicId,
        flexGiftPrice: autogiftRule.productList
          ? processFlexGiftPrice(autogiftRule.productList)
          : null,
        maximumProductPrice: null,
        processingFee: null,
        estimatedTaxLow: null,
        estimatedTaxHigh: null,
        estimatedTotalLow: null,
        estimatedTotalHigh: null,
        tenureClassification,
        tenureMin,
        tenureMax,
        contactsWithEventToday: autogiftRule.contactsWithEventToday,
        anchorDateType: autogiftRule.anchorDateType,
        anchorDateSendOption: autogiftRule.anchorDateSendOption,
        anchorDateNumberOfDaysDelta: autogiftRule.anchorDateNumberOfDaysDelta,
        internationalShippingTier: autogiftRule.internationalShippingTier,
        settings: omitSingleKey("__typename", autogiftRule.settings),
      })
    }
  }

  // Fetch the flex gift price from the product list, if any exist. Assumes only one flex gift exists on the list.
  const processFlexGiftPrice = (productList: AutogiftRuleProduct[]) => {
    let flexGiftPrice = null
    productList
      .filter((product) => product.priceEstimate.isFlexGift)
      .forEach((product) => {
        flexGiftPrice = product.priceEstimate.priceProduct
      })
    return flexGiftPrice
  }

  const loadNewAutogiftRuleData = (
    eventType: string,
    contactListName: string,
    contactListId: string,
    contactsWithEventToday?: string[],
  ) => {
    onSelectNewAutogift && onSelectNewAutogift()

    setCurrentAutogiftRule({
      id: null,
      eventType: eventType,
      contactListId: contactListId,
      contactListName: contactListName,
      status: AutogiftRuleStatus.PAUSED,
      productList: [],
      card: null,
      fromName: "",
      message: "",
      expireAtOption: "sixWeeks",
      autopayPaymentMethodID: null,
      autopayPaymentMethodName: null,
      swapType: GiftSwapTypeEnum.swap_single,
      userPublicId: null,
      maximumProductPrice: null,
      processingFee: null,
      estimatedTaxLow: null,
      estimatedTaxHigh: null,
      estimatedTotalLow: null,
      estimatedTotalHigh: null,
      tenureClassification: AutogiftRuleTenureClassification.ALL,
      tenureMin: null,
      tenureMax: null,
      flexGiftPrice: null,
      contactsWithEventToday: contactsWithEventToday || [],
      anchorDateType: AutogiftRuleAnchorDateTypeEnum.start_date,
      anchorDateSendOption:
        AutogiftRuleAnchorDateSendOptionEnum.send_on_anchor_date,
      anchorDateNumberOfDaysDelta: null,
      internationalShippingTier: InternationalShippingTierEnum.standard,
      settings: { giftCardsEnabled: false },
    })
  }

  return {
    loadAutogiftRuleData,
    loadNewAutogiftRuleData,
  }
}
