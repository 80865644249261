import React, { FC } from "react"
import { Link } from "react-router-dom"

import { generateRealmPath } from "../../common/realm"
import FAQAccordion, { PurpleLink } from "../components/FAQAccordion"

const FrequentlyAskedQuestions: FC = () => {
  const sections = [
    {
      // TODO: Update
      question: "How are Goody’s paid plans different from Starter?",
      answer: (
        <>
          <p>
            Goody’s free Starter plan includes unlimited gift sends to US- and
            Canada-based recipients. You can send 350+ curated brands and
            on-demand custom merch. Send or schedule 1,000+ gifts at once, and
            even upload CSVs to save time.
          </p>
          <p>
            On Goody’s Pro plan, you can send international gifts and swag to
            140+ more supported countries, send digital gift cards, integrate
            Calendly to book meetings via gifts, add custom branding and cards,
            and send gifts via your own Gmail account via our secure
            integration.
          </p>
          <p>
            With Goody’s Team plan, you can use gifting to supercharge your
            sales and employee engagement efforts. Set and allocate budgets
            across your organization. Connect apps like Salesforce and Calendly
            to power sales prospecting and client appreciation gifts. Or
            integrate your HR system to enable Autogifting for employees’ work
            anniversaries, birthdays, and onboarding. Customers on Team plans
            can also build
            <PurpleLink href="https://www.ongoody.com/blog/how-to-build-branded-gift-experiences-with-custom-stores">
              {" "}
              custom-branded digital stores{" "}
            </PurpleLink>
            of swag and gifts.
          </p>
        </>
      ),
    },
    {
      question: "How does Goody’s pricing work?",
      answer: (
        <>
          <p>
            Goody’s Pro plan is priced per user. Get access to send digital gift
            cards, add logo branding, schedule gifts, send gifts
            internationally, integrate Calendly, and more.
          </p>
          <p>
            Goody’s Team plan is priced per company. Pricing is based on the
            number of paid users on your plan and the number of contacts (such
            as employees) you’re sending{" "}
            <PurpleLink
              href={generateRealmPath("business", "/use-cases/autogifting")}
            >
              automated gifts
            </PurpleLink>{" "}
            to. To get a custom quote for your business,{" "}
            <PurpleLink href={generateRealmPath("business", "/team-demo")}>
              contact sales.
            </PurpleLink>
          </p>
        </>
      ),
    },
    {
      question: "Which platforms does Goody integrate with?",
      answer: (
        <>
          <p>
            Goody integrates with Google Workspace, Salesforce, Calendly, and{" "}
            <PurpleLink href="https://intercom.help/goody/en/articles/5998898-which-hr-platforms-does-goody-integrate-with">
              over 30 leading HRIS platforms
            </PurpleLink>
            , including Rippling, ADP, Justworks, Gusto, BambooHR, UKG, Workday,
            and more.
          </p>
          <p>
            You can also use our{" "}
            <PurpleLink href="https://zapier.com/apps/goody/integrations">
              Zapier API
            </PurpleLink>{" "}
            to integrate Goody with 5,000+ tools, such as Slack, HubSpot,
            Marketo, and DocuSign.
          </p>
          <p>
            If you’re looking to sell Goody gifts in your own platform, we can
            do that too!{" "}
            <Link
              tw="text-purple-500 font-medium"
              to={generateRealmPath("business", "/gift-api")}
            >
              Learn more about our Commerce API
            </Link>
            .
          </p>
        </>
      ),
    },
  ]

  return <FAQAccordion sections={sections} />
}

export default FrequentlyAskedQuestions
