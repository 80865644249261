import React, { useEffect, useState } from "react"

import { SwapOptions } from "../../common/components/SwapOptions"
import { useGlobalState } from "../../common/GlobalState"
import {
  SelectionGroup,
  SelectionIndicator,
  SelectionLabel,
} from "../../common/SelectionGroup"

import { BusinessSendProvider } from "@/common/hooks/send"
import InternationalShippingOptions from "@/common/InternationalShippingOptions"
import { AutogiftPaymentMethods } from "@/common/payments/PaymentMethods"
import {
  Alert,
  AlertColor,
} from "@/track/components/giftBatch/GiftBatchSettings"
import {
  AutogiftRule_ProductsQuery,
  InternationalShippingTierEnum,
} from "@/types/graphql-types"

interface AutogiftOptionsProps {
  products: AutogiftRule_ProductsQuery["products"]
}

const AutogiftOptions: React.FC<AutogiftOptionsProps> = ({ products }) => {
  const [currentAutogiftRule, setCurrentAutogiftRule] = useGlobalState(
    "currentAutogiftRule",
  )
  const [user] = useGlobalState("user")
  const [showFourWeeks, setShowFourWeeks] = useState(false)

  const hasSingleProduct = (products?.length && products?.length == 1) ?? false
  const hasMultipleProducts =
    (products?.length && products?.length > 1) ?? false
  const hasCustomStore = products?.some((product) => {
    return product?.customStore !== null
  })

  const hideSetting = hasCustomStore && hasSingleProduct

  // If we have a saved autogift rule with four weeks, then we want to enable that option, otherwise, six weeks
  useEffect(() => {
    if (currentAutogiftRule.expireAtOption === "fourWeeks") {
      setShowFourWeeks(true)
    }
  }, [currentAutogiftRule.expireAtOption === "fourWeeks"])

  return (
    <div>
      <div css={{ width: 530 }} tw="rounded-xl p-6 border border-gray-200">
        <div tw="text-xl text-gray-700 mb-2">Options</div>
        <div tw="text-sm text-gray-400 mb-4" css={{ lineHeight: "130%" }}>
          These options apply to new gifts, which are created one month out.
        </div>
        {hasCustomStore && hasMultipleProducts ? (
          <div tw="mb-6">
            <Alert
              primaryMessage="Your swap and international settings will not apply to the custom store."
              color={AlertColor.yellow}
            />
          </div>
        ) : (
          <div tw="mb-6" />
        )}
        <div tw="mb-6">
          <div tw="font-medium text-gray-600 mb-2" css={{ lineHeight: "19px" }}>
            Send at time
          </div>
          <div>Sends at 9am ET on the day of the event.</div>
        </div>
        {!hideSetting && (
          <div tw="mb-6">
            <div
              tw="font-medium text-gray-600 mb-2"
              css={{ lineHeight: "19px" }}
            >
              Swap
            </div>
            <SwapOptions
              swapType={currentAutogiftRule.swapType}
              setSwapType={(swapType) =>
                setCurrentAutogiftRule({
                  ...currentAutogiftRule,
                  swapType,
                })
              }
            />
          </div>
        )}
        <div tw="mb-6">
          <div tw="font-medium text-gray-600 mb-2" css={{ lineHeight: "19px" }}>
            Expiration
          </div>
          <div>
            <SelectionGroup
              onClick={() => {
                setCurrentAutogiftRule({
                  ...currentAutogiftRule,
                  expireAtOption: "none",
                })
              }}
            >
              <SelectionIndicator
                selected={currentAutogiftRule.expireAtOption === "none"}
              />
              <SelectionLabel
                selected={currentAutogiftRule.expireAtOption === "none"}
              >
                Doesn't expire
              </SelectionLabel>
            </SelectionGroup>
          </div>
          <div>
            {showFourWeeks ? (
              <SelectionGroup
                onClick={() => {
                  setCurrentAutogiftRule({
                    ...currentAutogiftRule,
                    expireAtOption: "fourWeeks",
                  })
                }}
              >
                <SelectionIndicator
                  selected={currentAutogiftRule.expireAtOption === "fourWeeks"}
                />
                <SelectionLabel
                  selected={currentAutogiftRule.expireAtOption === "fourWeeks"}
                >
                  4 weeks from send date
                </SelectionLabel>
              </SelectionGroup>
            ) : (
              <SelectionGroup
                onClick={() => {
                  setCurrentAutogiftRule({
                    ...currentAutogiftRule,
                    expireAtOption: "sixWeeks",
                  })
                }}
              >
                <SelectionIndicator
                  selected={currentAutogiftRule.expireAtOption === "sixWeeks"}
                />
                <SelectionLabel
                  selected={currentAutogiftRule.expireAtOption === "sixWeeks"}
                >
                  6 weeks from send date
                </SelectionLabel>
              </SelectionGroup>
            )}
          </div>
        </div>
        {!hideSetting && (
          <div tw="mb-6">
            <div tw="flex flex-row items-center justify-start pb-2 gap-4">
              <div tw="font-medium text-gray-600">International shipping</div>
            </div>

            <InternationalShippingOptions
              internationalShippingTier={
                currentAutogiftRule.internationalShippingTier
              }
              setInternationalShippingTier={(
                internationalShippingTier: InternationalShippingTierEnum,
              ) => {
                setCurrentAutogiftRule((curr) => ({
                  ...curr,
                  internationalShippingTier,
                }))
              }}
              giftCardsEnabled={
                currentAutogiftRule.settings.giftCardsEnabled || false
              }
              setGiftCardsEnabled={(giftCardsEnabled: boolean) => {
                setCurrentAutogiftRule((curr) => ({
                  ...curr,
                  settings: { ...curr.settings, giftCardsEnabled },
                }))
              }}
              swapType={currentAutogiftRule.swapType}
            />
          </div>
        )}
        <div tw="mb-6">
          <div tw="font-medium text-gray-600 mb-3" css={{ lineHeight: "19px" }}>
            Payment
          </div>
          {currentAutogiftRule.userPublicId &&
          currentAutogiftRule.userPublicId !== user?.publicId ? (
            <div tw="text-gray-600">
              Only the autogift creator can edit the payment method.
            </div>
          ) : (
            <BusinessSendProvider>
              <AutogiftPaymentMethods />
            </BusinessSendProvider>
          )}
        </div>
      </div>
    </div>
  )
}

export default AutogiftOptions
