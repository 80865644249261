import { useEffect, useState } from "react"
import Select, { GroupTypeBase, Styles } from "react-select"
import tw, { styled } from "twin.macro"

import {
  AutogiftConfigSection,
  AutogiftConfigSectionContent,
  AutogiftConfigSectionHeaderTitle,
} from "./common"
import { ReactComponent as CalendarIcon } from "../../assets/icons/autogift-onboarding-calendar.svg"
import { useGlobalState } from "../../common/GlobalState"
import {
  SelectionGroup,
  SelectionIndicator,
  SelectionLabel,
} from "../../common/SelectionGroup"
import CustomSelect from "../../contacts/components/ContactImport/CustomSelect"

import {
  AutogiftRuleAnchorDateSendOptionEnum,
  AutogiftRuleAnchorDateTypeEnum,
} from "@/types/graphql-types"

const AutogiftOnboarding = () => {
  const [currentAutogiftRule, setCurrentAutogiftRule] = useGlobalState(
    "currentAutogiftRule",
  )

  const [numberOfDaysDelta, setNumberOfDaysDelta] = useState<number | null>(
    null,
  )

  const getAnchorDateTypeLabel = (
    anchorDateType?: AutogiftRuleAnchorDateTypeEnum | null,
  ) => {
    if (anchorDateType === AutogiftRuleAnchorDateTypeEnum.start_date) {
      return "Based on start date"
    } else if (
      anchorDateType === AutogiftRuleAnchorDateTypeEnum.added_to_hris_date
    ) {
      return "Based on date added to HR system"
    } else {
      return "Cannot set onboarding trigger type"
    }
  }

  const selectAnchorDateSendOption = (
    anchorDateType: AutogiftRuleAnchorDateTypeEnum | null,
    anchorDateSendOption?: AutogiftRuleAnchorDateSendOptionEnum | null,
  ) => {
    if (
      (anchorDateType === AutogiftRuleAnchorDateTypeEnum.start_date &&
        anchorDateSendOption ===
          AutogiftRuleAnchorDateSendOptionEnum.send_before_anchor_date) ||
      (anchorDateType === AutogiftRuleAnchorDateTypeEnum.added_to_hris_date &&
        anchorDateSendOption ===
          AutogiftRuleAnchorDateSendOptionEnum.send_after_anchor_date)
    ) {
      return anchorDateSendOption
    }

    return AutogiftRuleAnchorDateSendOptionEnum.send_on_anchor_date
  }

  useEffect(() => {
    setCurrentAutogiftRule({
      ...currentAutogiftRule,
      anchorDateNumberOfDaysDelta: numberOfDaysDelta,
    })
  }, [numberOfDaysDelta])

  return (
    <>
      <AutogiftConfigSection>
        <AutogiftConfigSectionHeaderTitle>
          <CalendarIcon />
          When should this gift be sent?
        </AutogiftConfigSectionHeaderTitle>
        <AutogiftConfigSectionContent tw="p-6">
          <div tw="flex flex-row items-center">
            <div tw="mr-12">
              <OptionLabel>Send</OptionLabel>
            </div>
            <div tw="flex-1">
              <CustomSelect
                defaultValue={{
                  label: getAnchorDateTypeLabel(
                    currentAutogiftRule.anchorDateType,
                  ),
                  value: currentAutogiftRule.anchorDateType,
                }}
                options={[
                  {
                    label: getAnchorDateTypeLabel(
                      AutogiftRuleAnchorDateTypeEnum.start_date,
                    ),
                    value: AutogiftRuleAnchorDateTypeEnum.start_date,
                  },
                  {
                    label: getAnchorDateTypeLabel(
                      AutogiftRuleAnchorDateTypeEnum.added_to_hris_date,
                    ),
                    value: AutogiftRuleAnchorDateTypeEnum.added_to_hris_date,
                  },
                ]}
                onChange={(option: any) => {
                  setCurrentAutogiftRule({
                    ...currentAutogiftRule,
                    anchorDateType: option?.value ?? null,
                    anchorDateSendOption: selectAnchorDateSendOption(
                      option?.value ?? null,
                      currentAutogiftRule.anchorDateSendOption,
                    ),
                  })
                }}
              />
            </div>
          </div>
          <div tw="flex flex-row items-center mt-8">
            <div tw="mr-8">
              <OptionLabel>Timing</OptionLabel>
            </div>
            {currentAutogiftRule.anchorDateType ===
              AutogiftRuleAnchorDateTypeEnum.start_date && (
              <div tw="ml-4">
                <div>
                  <div>
                    <SelectionGroup
                      onClick={() => {
                        setCurrentAutogiftRule({
                          ...currentAutogiftRule,
                          anchorDateSendOption:
                            AutogiftRuleAnchorDateSendOptionEnum.send_on_anchor_date,
                        })
                      }}
                    >
                      <SelectionIndicator
                        selected={
                          currentAutogiftRule.anchorDateSendOption ===
                          AutogiftRuleAnchorDateSendOptionEnum.send_on_anchor_date
                        }
                      />
                      <SelectionLabel
                        selected={
                          currentAutogiftRule.anchorDateSendOption ===
                          AutogiftRuleAnchorDateSendOptionEnum.send_on_anchor_date
                        }
                      >
                        Send gift on start date
                      </SelectionLabel>
                    </SelectionGroup>
                    <SelectionGroup
                      onClick={() => {
                        setCurrentAutogiftRule({
                          ...currentAutogiftRule,
                          anchorDateSendOption:
                            AutogiftRuleAnchorDateSendOptionEnum.send_before_anchor_date,
                        })
                      }}
                    >
                      <SelectionIndicator
                        selected={
                          currentAutogiftRule.anchorDateSendOption ===
                          AutogiftRuleAnchorDateSendOptionEnum.send_before_anchor_date
                        }
                      />
                      <SelectionLabel
                        selected={
                          currentAutogiftRule.anchorDateSendOption ===
                          AutogiftRuleAnchorDateSendOptionEnum.send_before_anchor_date
                        }
                      >
                        <div tw="flex flex-row items-center">
                          <div>Send gift up to &nbsp;</div>
                          <div tw="mr-1">
                            <NumberSelect
                              selectedValue={
                                currentAutogiftRule.anchorDateNumberOfDaysDelta
                                  ? currentAutogiftRule.anchorDateNumberOfDaysDelta
                                  : 5
                              }
                              onChange={(option) => {
                                setNumberOfDaysDelta(option?.value!)
                              }}
                              isDisabled={
                                currentAutogiftRule.anchorDateSendOption !==
                                AutogiftRuleAnchorDateSendOptionEnum.send_before_anchor_date
                              }
                            />
                          </div>
                          <div>
                            day
                            {currentAutogiftRule.anchorDateNumberOfDaysDelta ===
                              null ||
                            (currentAutogiftRule.anchorDateNumberOfDaysDelta &&
                              currentAutogiftRule.anchorDateNumberOfDaysDelta >
                                1)
                              ? "s"
                              : ""}{" "}
                            before start date
                          </div>
                        </div>
                      </SelectionLabel>
                    </SelectionGroup>
                  </div>
                </div>
              </div>
            )}
            {currentAutogiftRule.anchorDateType ===
              AutogiftRuleAnchorDateTypeEnum.added_to_hris_date && (
              <div tw="ml-4">
                <div>
                  <div>
                    <SelectionGroup
                      onClick={() => {
                        setCurrentAutogiftRule({
                          ...currentAutogiftRule,
                          anchorDateSendOption:
                            AutogiftRuleAnchorDateSendOptionEnum.send_on_anchor_date,
                        })
                      }}
                    >
                      <SelectionIndicator
                        selected={
                          currentAutogiftRule.anchorDateSendOption ===
                          AutogiftRuleAnchorDateSendOptionEnum.send_on_anchor_date
                        }
                      />
                      <SelectionLabel
                        selected={
                          currentAutogiftRule.anchorDateSendOption ===
                          AutogiftRuleAnchorDateSendOptionEnum.send_on_anchor_date
                        }
                      >
                        Send gift on date added to HR system
                      </SelectionLabel>
                    </SelectionGroup>
                    <SelectionGroup
                      onClick={() => {
                        setCurrentAutogiftRule({
                          ...currentAutogiftRule,
                          anchorDateSendOption:
                            AutogiftRuleAnchorDateSendOptionEnum.send_after_anchor_date,
                        })
                      }}
                    >
                      <SelectionIndicator
                        selected={
                          currentAutogiftRule.anchorDateSendOption ===
                          AutogiftRuleAnchorDateSendOptionEnum.send_after_anchor_date
                        }
                      />
                      <SelectionLabel
                        selected={
                          currentAutogiftRule.anchorDateSendOption ===
                          AutogiftRuleAnchorDateSendOptionEnum.send_after_anchor_date
                        }
                      >
                        <div tw="flex flex-row items-center">
                          <div>Send gift &nbsp;</div>
                          <div tw="mr-1">
                            <NumberSelect
                              selectedValue={
                                currentAutogiftRule.anchorDateNumberOfDaysDelta
                                  ? currentAutogiftRule.anchorDateNumberOfDaysDelta
                                  : 5
                              }
                              onChange={(option) => {
                                setNumberOfDaysDelta(option?.value ?? null)
                              }}
                              isDisabled={
                                currentAutogiftRule.anchorDateSendOption !==
                                AutogiftRuleAnchorDateSendOptionEnum.send_after_anchor_date
                              }
                            />
                          </div>
                          <div>
                            day
                            {currentAutogiftRule.anchorDateNumberOfDaysDelta ===
                              null ||
                            (currentAutogiftRule.anchorDateNumberOfDaysDelta &&
                              currentAutogiftRule.anchorDateNumberOfDaysDelta >
                                1)
                              ? "s"
                              : ""}{" "}
                            after added to HR system
                          </div>
                        </div>
                      </SelectionLabel>
                    </SelectionGroup>
                  </div>
                </div>
              </div>
            )}
          </div>
        </AutogiftConfigSectionContent>
      </AutogiftConfigSection>
    </>
  )
}

interface NumberSelectProps {
  selectedValue: number
  onChange: (option: { label: string; value: number } | null) => void
  isDisabled: boolean
}

const NumberSelect = ({
  selectedValue,
  onChange,
  isDisabled,
}: NumberSelectProps) => {
  return (
    <Select
      defaultValue={{
        label: selectedValue.toString(),
        value: selectedValue,
      }}
      options={[
        {
          label: "1",
          value: 1,
        },
        {
          label: "2",
          value: 2,
        },
        {
          label: "3",
          value: 3,
        },
        {
          label: "4",
          value: 4,
        },
        {
          label: "5",
          value: 5,
        },
        {
          label: "6",
          value: 6,
        },
        {
          label: "7",
          value: 7,
        },
        {
          label: "8",
          value: 8,
        },
        {
          label: "9",
          value: 9,
        },
        {
          label: "10",
          value: 10,
        },
        {
          label: "11",
          value: 11,
        },
        {
          label: "12",
          value: 12,
        },
        {
          label: "13",
          value: 13,
        },
        {
          label: "14",
          value: 14,
        },
        {
          label: "15",
          value: 15,
        },
        {
          label: "16",
          value: 16,
        },
        {
          label: "17",
          value: 17,
        },
        {
          label: "18",
          value: 18,
        },
        {
          label: "19",
          value: 19,
        },
        {
          label: "20",
          value: 20,
        },
        {
          label: "21",
          value: 21,
        },
        {
          label: "22",
          value: 22,
        },
        {
          label: "23",
          value: 23,
        },
        {
          label: "24",
          value: 24,
        },
        {
          label: "25",
          value: 25,
        },
        {
          label: "26",
          value: 26,
        },
        {
          label: "27",
          value: 27,
        },
        {
          label: "28",
          value: 28,
        },
        {
          label: "29",
          value: 29,
        },
        {
          label: "30",
          value: 30,
        },
      ]}
      onChange={onChange}
      styles={customStyles}
      components={{
        DropdownIndicator: () => null,
        IndicatorsContainer: () => null,
      }}
      isDisabled={isDisabled}
    />
  )
}

const customStyles: Partial<
  Styles<
    { label: string; value: number },
    false,
    GroupTypeBase<{ label: string; value: number }>
  >
> = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "white" : "black",
    backgroundColor: state.isSelected ? "rgba(163, 114, 223,1)" : "white",
    "&:hover": {
      backgroundColor: "#efe9f6",
    },
  }),
  control: (provided, state) => ({
    ...provided,
    width: 40,
    color: "#d0b8ed",
    border: state.isDisabled
      ? "1px solid rgba(237, 242, 247, 1)"
      : "1px solid #d0b8ed",
    backgroundColor: "white",
    boxShadow: "none",
    textAlign: "center",
    "&:hover": {
      border: "1px solid #d0b8ed",
    },
    "&:focus": {
      border: "1px solid #d0b8ed",
    },
  }),
  input: (provided, state) => ({
    ...provided,
    color: "transparent",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    justifyContent: "center",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? "#9CA3AF" : "rgba(145, 89, 214, 1)",
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 6,
    boxShadow: "rgba(163, 114, 223, 0.15) 0 12px 40px",
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: 6,
    textAlign: "center",
  }),
}

const OptionLabel = styled.span`
  line-height: 17.22px;
  ${tw`text-gray-450 text-sm font-semibold`};
`

export default AutogiftOnboarding
