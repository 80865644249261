import { formatDistanceToNowStrict, isToday } from "date-fns"
import pluralize from "pluralize"
import tw, { styled } from "twin.macro"

import { ReactComponent as CakeIcon } from "./images/cake.svg"
import { ReactComponent as CalendarIcon } from "./images/calendarLarge.svg"
import { ReactComponent as ListIcon } from "./images/contactList.svg"
import { ReactComponent as EditIcon } from "./images/edit.svg"
import { ReactComponent as GiftIcon } from "./images/gift.svg"
import { ReactComponent as PhoneIcon } from "./images/phone.svg"
import Button from "../../common/Button"
import { formatPhone } from "../../common/format"
import { ReactComponent as CheckIcon } from "../../common/images/check.svg"
import { ReactComponent as MailIcon } from "../../common/images/mail.svg"
import { ReactComponent as PlusIcon } from "../../common/images/plus.svg"
import { isUpcomingSingleScheduledEvent } from "../../dashboard/lib"
import { eventTypeLabels, getNextDate, useRecipients } from "../lib"
import { EventType } from "../types/Contact"

import {
  ContactScheduledEventFragment,
  StandardContactFragment,
} from "@/types/graphql-types"

interface ContactViewProps {
  contact: StandardContactFragment
  onEdit: () => void
}

const ContactView = ({ contact, onEdit }: ContactViewProps) => {
  const hasEvent = contact.birthday || contact.workAnniversary
  const listsCount = contact.contactLists.length

  return (
    <div tw="relative h-full">
      <div tw="overflow-auto h-full">
        <ContactViewHeader
          tw=""
          className={hasEvent ? "withEvents" : undefined}
        >
          <h2
            tw="text-3xl font-medium mb-6"
            className="data-hj-suppress ph-no-capture fs-mask"
          >
            {[contact.firstName, contact.lastName].join(" ")}
          </h2>
          <div className="data-hj-suppress ph-no-capture fs-mask">
            {contact.title}
          </div>
          <div
            tw="text-gray-400"
            className="data-hj-suppress ph-no-capture fs-mask"
          >
            {contact.company}
          </div>

          {hasEvent && (
            <div tw="flex justify-between -mb-36">
              <EventCard
                type="birthday"
                event={contact.birthday}
                onAdd={onEdit}
              />
              <EventCard
                type={
                  isUpcomingSingleScheduledEvent(contact.startDate)
                    ? "startDate"
                    : "workAnniversary"
                }
                event={
                  isUpcomingSingleScheduledEvent(contact.startDate)
                    ? contact.startDate
                    : contact.workAnniversary
                }
                onAdd={onEdit}
              />
            </div>
          )}
        </ContactViewHeader>
        <EditRow>
          {contact.birthday || contact.workAnniversary ? (
            <hr />
          ) : (
            <Button onClick={onEdit}>
              <PlusIcon css={{ width: "0.9rem" }} />
              Birthday or work anniversary
            </Button>
          )}
          <Button onClick={onEdit}>
            <EditIcon />
            Edit
          </Button>
        </EditRow>
        <InfoBlock>
          {contact.email && (
            <>
              <MailIcon />{" "}
              <a
                href={`mailto:${contact.email}`}
                tw="hover:text-primary-600 focus-visible:text-primary-600 active:text-primary-700 transition-colors truncate"
                className="data-hj-suppress ph-no-capture fs-mask"
              >
                {contact.email}
              </a>
            </>
          )}
          {contact.phone && (
            <>
              <PhoneIcon />{" "}
              <span
                tw="truncate"
                className="data-hj-suppress ph-no-capture fs-mask"
              >
                {formatPhone(contact.phone)}
              </span>
            </>
          )}
          <ListIcon />
          <div>
            <div tw="text-gray-500">
              In {listsCount} {pluralize("list", listsCount)}
            </div>
            <ul>
              {contact.contactLists.map((list) => (
                <li
                  key={list.id}
                  className="data-hj-suppress ph-no-capture fs-mask"
                >
                  {list.name}
                </li>
              ))}
            </ul>
          </div>
        </InfoBlock>
      </div>
      <SendSection contact={contact} />
    </div>
  )
}

interface SendSectionProps {
  contact: StandardContactFragment
}
const SendSection = ({ contact }: SendSectionProps) => {
  const { addRecipient, hasRecipient } = useRecipients()
  const isRecipient = hasRecipient(contact)
  return (
    <section tw="absolute bottom-0 w-full p-5 lg:px-8 lg:pb-8 pt-0 bg-white">
      <SendButton onClick={() => addRecipient(contact)} disabled={isRecipient}>
        {isRecipient ? (
          <>
            <CheckIcon tw="stroke-current text-primary-300" /> In recipient list
          </>
        ) : (
          <>
            <GiftIcon tw="stroke-0" /> Send gift
          </>
        )}
      </SendButton>
    </section>
  )
}

const SendButton = styled(Button)`
  ${tw`w-full text-lg text-white font-medium justify-center p-3 relative z-10 overflow-hidden -mt-2`}

  &:enabled {
    background-image: linear-gradient(30deg, #bca7e0 0%, #de8fb1 100%);
  }

  &:disabled {
    ${tw`bg-primary-050 text-primary-400 font-normal opacity-100`}
  }

  &::before,
  &::after {
    ${tw`block absolute top-0 left-0 w-full h-full bg-gradient-to-r opacity-0 transition-opacity`}
    content: "";
    z-index: -1;
  }

  &::before {
    background-image: linear-gradient(30deg, #bc9dd8 0%, #de779b 100%);
  }

  &::after {
    background-image: linear-gradient(30deg, #bb94d8 0%, #de5e9a 100%);
  }

  &:hover:enabled,
  &:focus-visible:enabled {
    &::before {
      ${tw`opacity-100`}
    }
  }

  &:active {
    &::after {
      ${tw`opacity-100`}
    }
  }
`

const InfoBlock = styled.section`
  ${tw`grid gap-5 px-5 lg:px-8 mb-32`}
  grid-template-columns: 1.3rem 1fr;

  svg {
    ${tw`stroke-current text-gray-400`}
    margin-top: 0.1rem;

    .currentFill {
      ${tw`fill-current`}
      stroke: none;
    }
  }
`

const ContactViewHeader = styled.header`
  ${tw`px-5 py-9 lg:px-8 text-center bg-gradient-to-r from-gradient-alternate-purple-lightest to-gradient-alternate-pink-lightest`}

  &.withEvents {
    ${tw`mb-36`}
  }
`

const EditRow = styled.div`
  ${tw`flex gap-4 justify-between items-center px-5 lg:px-8 mb-5`}
  margin-top: -.9rem;

  hr {
    ${tw`border-t border-gray-200 flex-grow`}
  }

  > button {
    ${tw`gap-2 text-gray-500 text-sm px-2 h-7`}

    svg {
      ${tw`stroke-current`}
    }
  }
`

interface EventCardProps {
  type: EventType
  event?: ContactScheduledEventFragment | null
  onAdd: () => void
}
const EventCard = ({ type, event, onAdd }: EventCardProps) => {
  const nextDate = event
    ? getNextDate({
        day: event.day,
        month: event.month,
        year: event.year,
        type,
      })
    : null

  return (
    <div
      className={type === "birthday" ? "birthday" : "workAnniversary"}
      tw="relative flex flex-col items-center whitespace-nowrap mt-5 w-40"
    >
      <div tw="absolute">
        {type === "birthday" ? <CakeIcon /> : <CalendarIcon tw="mt-1" />}
      </div>
      <CalendarSheet date={nextDate} onAdd={onAdd} />
      <div tw="text-gray-400">
        {eventTypeLabels[type]}
        {nextDate && !isToday(nextDate) && " in"}
      </div>
      <NextEvent date={nextDate} />
    </div>
  )
}

const monthNames: readonly string[] = [
  "Jan",
  "Feb",
  "March",
  "April",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
]

interface CalendarSheetProps {
  date: Date | null
  onAdd: () => void
}
const CalendarSheet: React.FC<CalendarSheetProps> = ({ date, onAdd }) => {
  if (!date) {
    return (
      <StyledCalendarSheet>
        <button onClick={onAdd}>
          <span className="icon">+</span>
        </button>
      </StyledCalendarSheet>
    )
  }

  return (
    <StyledCalendarSheet>
      <div className="month data-hj-suppress ph-no-capture fs-exclude">
        {monthNames[date.getMonth()]}
      </div>
      <div className="day data-hj-suppress ph-no-capture fs-exclude">
        {date.getDate()}
      </div>
    </StyledCalendarSheet>
  )
}

const StyledCalendarSheet = styled.div`
  ${tw`bg-white flex flex-col rounded-lg h-28 mt-10 mb-6 relative z-10 overflow-hidden transition-colors items-center`}
  box-shadow: 0 2px 8px rgba(79, 31, 137, 0.08), 0 12px 40px rgba(79, 31, 137, 0.1);
  width: 5.5rem;
  height: 7rem;

  > .day,
  > .month {
    ${tw`inline-block w-min`}
  }

  > .month {
    ${tw`text-lg mt-4 mb-2`}
  }

  > .day {
    ${tw`text-3xl font-medium bg-clip-text text-transparent`}
  }

  > button {
    ${tw`w-full h-full`}

    > .icon {
      ${tw`text-3xl bg-clip-text text-transparent`}
    }

    &:hover {
      background: #fdfcff;
    }

    &:active {
      ${tw`bg-primary-000`}
    }
  }

  .birthday > & {
    > .month {
      ${tw`text-primary-300`}
    }

    > .day,
    > button > .icon {
      background-image: linear-gradient(90deg, #c6a0d3 0%, #e48ba9 100%);
    }
  }

  .workAnniversary > & {
    > .month {
      color: #85b0e9;
    }

    > .day,
    > button > .icon {
      background-image: linear-gradient(60deg, #9986f2 0%, #8bbde1 100%);
    }
  }
`

interface NextEventProps {
  date: Date | null
}
const NextEvent: React.FC<NextEventProps> = ({ date }) => {
  if (!date) {
    return <div tw="text-gray-400">Not set</div>
  }

  const distance = isToday(date) ? "Today" : formatDistanceToNowStrict(date)

  return (
    <StyledNextEvent className="data-hj-suppress ph-no-capture fs-exclude">
      {distance}
    </StyledNextEvent>
  )
}

const StyledNextEvent = styled.div`
  ${tw`text-2xl`}

  .birthday > & {
    ${tw`text-primary-500`}
  }

  .workAnniversary > & {
    color: #5096e9;
  }
`

export default ContactView
