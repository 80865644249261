import React, { useCallback } from "react"
import { ValueType } from "react-select"

import { FIELD_LIST, FieldType } from "./fields"
import Button from "../../common/Button"
import CustomSelect from "../../contacts/components/ContactImport/CustomSelect"

interface Props {
  rows: string[][] | null
  rowHeaders: string[] | null
  matchedFields: (FieldType | null)[] | null
  setMatchedFields: (headers: (FieldType | null)[]) => void
  onContinue: () => void
}

// Select options are "None" + all fields
// Using a map to have an ordered k/v store
const SELECT_LABELS = new Map([
  ["None", { value: null, label: "None" }],
  ...FIELD_LIST.map((field) => [
    field.name,
    {
      value: field.name,
      label: field.name,
    },
  ]),
] as any) as Map<string, { value: string; label: string }>

// Array to pass into select
const SELECT_LABEL_LIST = Array.from(SELECT_LABELS.values())

// Display a UI to match the columns of the CSV when there are unknown columns.
//
// If headers were detected, show the header name and ask user to match to the
// matching field.
//
// If headers were not detected, show the first row and ask the user to match to
// the matching field.
export default function CSVMatchColumns({
  rows,
  rowHeaders,
  matchedFields,
  setMatchedFields,
  onContinue,
}: Props) {
  const onOptionChange = useCallback(
    (
      index: number,
      option: ValueType<{ label: FieldType; value: FieldType }, false>,
    ) => {
      if (matchedFields) {
        // Copy fields and replace value
        const newMatchedFields = [...matchedFields]
        newMatchedFields[index] = option?.value ?? null
        setMatchedFields(newMatchedFields)
      }
    },
    [matchedFields, setMatchedFields],
  )

  const headersExist = !!rowHeaders

  return (
    <>
      <div tw="pb-4">
        Match the columns in your file with recipient contact fields.
      </div>
      {!headersExist && (
        <div tw="-mx-6 mb-4 px-6 py-4 bg-yellow-100 text-yellow-700">
          <div tw="font-medium text-lg pb-1">
            We didn&rsquo;t detect any headers in your file
          </div>
          <div>
            You can manually match the data in the example row shown on the left
            to the corresponding field on the right.
          </div>
        </div>
      )}
      <div tw="flex flex-row items-center font-medium text-gray-500 text-sm">
        <div tw="px-4 py-2 w-1/2">
          {headersExist ? "File column" : "Example row"}
        </div>
        <div tw="px-4 py-2 w-1/2">Match to</div>
      </div>
      <div tw="pb-4">
        {(rowHeaders ?? rows?.[0])?.map((_, i) => (
          <div
            key={i}
            tw="bg-gray-050 flex flex-row items-center rounded-lg mb-2"
          >
            <div
              tw="px-4 py-2 w-1/2"
              className="ph-no-capture fs-exclude data-hj-suppress"
            >
              {headersExist ? rowHeaders?.[i] : rows?.[0]?.[i]}
            </div>
            <div tw="px-4 pl-1 py-2 w-1/2">
              <CustomSelect
                options={SELECT_LABEL_LIST}
                value={SELECT_LABELS.get(matchedFields?.[i] ?? "None")}
                onChange={(option: any) => onOptionChange(i, option)}
                menuPortalTarget={document.body}
                menuPlacement="auto"
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                menuPosition="fixed"
              />
            </div>
          </div>
        ))}
      </div>
      <Button variant="primary" onClick={onContinue}>
        Import
      </Button>
    </>
  )
}
