import { useRef, useState } from "react"
import tw, { styled } from "twin.macro"

import DomesticShippingModal from "../components/modals/DomesticShippingModal"
import { DomesticShippingTag } from "../components/shipping/DomesticShippingTag"
import { useShippingCountriesSelector } from "../hooks/useShippingCountriesSelector"

import { CountryFlag } from "@/common/CountryFlag"
import TractorBeam from "@/common/TractorBeam"
import TransitionHideable from "@/common/TransitionHideable"

interface Props {
  domesticCountryCodes: string[]
}

const ProductDomesticShippingTag = ({ domesticCountryCodes }: Props) => {
  const { selectedShippingCountry, findCountryByCode } =
    useShippingCountriesSelector()
  const ref = useRef(null)

  const [open, setOpen] = useState(false)
  const [openDomesticModal, setOpenDomesticModal] = useState(false)

  const domesticCountry =
    selectedShippingCountry.code === "US"
      ? findCountryByCode(domesticCountryCodes.find((code) => code !== "US"))
      : selectedShippingCountry

  if (!domesticCountry) return null

  const domesticCountriesSize = domesticCountryCodes.length
  const multipleCountries = domesticCountriesSize > 1

  const labelStart =
    selectedShippingCountry.code === "US"
      ? "Also ships domestically to "
      : "Domestic shipping · "
  const labelEnd =
    selectedShippingCountry.code !== "US" && multipleCountries
      ? ` + ${domesticCountriesSize - 1} more`
      : ""

  if (domesticCountriesSize === 1 && domesticCountryCodes.includes("US")) {
    return null
  }

  const openOnHover = () => multipleCountries && setOpen(true)
  const closeOnHover = () => multipleCountries && setOpen(false)

  return (
    <>
      <DomesticShippingModal
        open={openDomesticModal}
        onClose={() => setOpenDomesticModal(false)}
      />

      <div ref={ref}>
        <TractorBeam anchor={ref} withinScrollable>
          <TransitionHideable
            hidden={!open}
            twBase={[
              tw`transition-all duration-200 ease-out origin-top`,
              tw`absolute w-max top-[calc(100% + 4px)] left-0`,
              tw`z-[1500]`,
            ]}
            twHidden={[tw`opacity-0 scale-90`]}
            twVisible={tw`opacity-100 scale-100`}
          >
            <DropdownContainer
              tw="flex flex-col gap-3 w-[350px]"
              onMouseEnter={openOnHover}
              onMouseLeave={closeOnHover}
            >
              <p tw="text-sm font-medium">Ships domestically to</p>

              <div tw="flex flex-col gap-2">
                {domesticCountryCodes.map((code) => (
                  <div tw="flex gap-3 items-center" key={code}>
                    <CountryFlag code={code} size="m" />
                    <span tw="text-base font-normal">
                      {findCountryByCode(code)?.name}
                    </span>
                  </div>
                ))}
              </div>

              <p tw="text-sm font-medium text-gray-500">
                All countries have the same shipping price
              </p>
            </DropdownContainer>
          </TransitionHideable>
        </TractorBeam>

        <div tw="w-max" onMouseEnter={openOnHover} onMouseLeave={closeOnHover}>
          <DomesticShippingTag
            country={domesticCountry}
            onClick={() => setOpenDomesticModal(true)}
            customLabel={labelStart + domesticCountry.name + labelEnd}
          />
        </div>
      </div>
    </>
  )
}

const DropdownContainer = styled.div`
  ${tw`p-5 rounded-xl border border-gray-150 bg-white`}

  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.02), 0px 8px 20px 0px rgba(0, 0, 0, 0.05);
`

export default ProductDomesticShippingTag
