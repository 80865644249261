import tw, { styled } from "twin.macro"

import { gradientBg } from "../assets/cdnAssets"

// Displays the gradient background behind the Send page.
export default function GradientBackground({
  visible,
  gradientStartPercent,
  gradientHeight,
  scrimHeight,
}: {
  visible: boolean
  gradientStartPercent?: number
  gradientHeight?: number
  scrimHeight?: number
}) {
  return (
    <>
      <BackgroundContainer visible={visible} gradientHeight={gradientHeight} />
      <ScrimContainer>
        <ScrimGradient
          gradientStartPercent={gradientStartPercent}
          scrimHeight={scrimHeight}
        />
        <ScrimSolid />
      </ScrimContainer>
    </>
  )
}

const BackgroundContainer = styled.div<{
  visible: boolean
  gradientHeight?: number
}>`
  ${tw`h-[300px] bg-no-repeat bg-cover bg-top absolute top-0 left-0 right-0 transition-opacity`};
  background-image: url(${gradientBg});

  ${(props) => (props.visible ? tw`opacity-100` : tw`opacity-0`)};
  ${(props) =>
    props.gradientHeight ? { height: `${props.gradientHeight}px` } : null};
`

const ScrimContainer = styled.div<{ gradientHeight?: number }>`
  ${tw`absolute top-0 left-0 right-0 z-[5] h-[300px] transition-transform duration-[400ms] ease-out transform translate-y-[-100px]`};
  ${(props) =>
    props.gradientHeight ? { height: `${props.gradientHeight}px` } : null};
`

const ScrimGradient = styled.div<{
  gradientStartPercent?: number
  scrimHeight?: number
}>`
  ${tw`h-[250px]`};
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) ${(props) => props.gradientStartPercent || 0}%,
    #ffffff 100%
  );

  ${(props) =>
    props.scrimHeight ? { height: `${props.scrimHeight}px` } : null};
`

const ScrimSolid = tw.div`h-[250px] bg-white`
