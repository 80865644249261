import { gql } from "@apollo/client"

import { CUSTOM_STORE_FULL_FRAGMENT } from "./CustomStoreFullFragment"

// noinspection GraphQLSchemaValidation - $shippingCountry is used in fragment
export const UPDATE_CUSTOM_STORE_MUTATION = gql`
  mutation Store_CustomStoreUpdate(
    $customStoreId: ID!
    $name: String!
    $subtitle: String!
    $desktopImage: CustomStoreImage!
    $mobileImage: CustomStoreImage!
    $activateStore: Boolean!
    $internationalShippingTier: InternationalShippingTierEnum!
    $brandValuesDisplay: BrandValuesDisplay!
    $shippingCountry: String
    $multipleSelectMode: MultipleSelectModeEnum
    $multipleSelectCount: Int
    $multipleSelectPrice: Int
    $settings: GiftsSettingsInput
  ) {
    customStoreUpdate(
      customStoreId: $customStoreId
      name: $name
      subtitle: $subtitle
      desktopImage: $desktopImage
      mobileImage: $mobileImage
      activateStore: $activateStore
      internationalShippingTier: $internationalShippingTier
      brandValuesDisplay: $brandValuesDisplay
      multipleSelectMode: $multipleSelectMode
      multipleSelectCount: $multipleSelectCount
      multipleSelectPrice: $multipleSelectPrice
      settings: $settings
    ) {
      ok
      errors
      customStore {
        ...CustomStoreFull
      }
    }
  }
  ${CUSTOM_STORE_FULL_FRAGMENT}
`
