import { gql, useQuery } from "@apollo/client"
import { useEffect } from "react"

import { createHookContext } from "./createHookContext"
import { useGlobalState } from "../GlobalState"

import { FeatureFlagsQuery } from "@/types/graphql-types"

// Add more feature flags here
type AvailableFeatureFlags =
  | "store_v2_product_mode"
  | "top_up_send"
  | "gift_preview"
  | "saml"

const useFeatureFlagsLocal = () => {
  const [user] = useGlobalState("user")

  const { data, refetch, loading } =
    useQuery<FeatureFlagsQuery>(GET_FEATURE_FLAGS)

  useEffect(() => {
    refetch()
  }, [user?.id])

  const hasFeatureFlag = (feature: AvailableFeatureFlags) => {
    if (data?.featureFlags) {
      return data?.featureFlags.indexOf(feature) >= 0
    }

    return false
  }

  return { hasFeatureFlag, loading }
}

export const { Provider: FeatureFlagsProvider, useHook: useFeatureFlags } =
  createHookContext("FeatureFlags", useFeatureFlagsLocal)

const GET_FEATURE_FLAGS = gql`
  query FeatureFlags {
    featureFlags
  }
`
