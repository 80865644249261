import { DEFAULT_COUNTRY } from "@/common/hooks/useShippingCountries"
import { InternationalShippingTierEnum } from "@/types/graphql-types"

type Options = {
  isShippingTierDisabled: boolean
  isGiftCard?: boolean
  hasProPlan: boolean
  countryCode: string
}

export const determineShippingTierForProduct = ({
  isShippingTierDisabled,
  hasProPlan,
  isGiftCard,
  countryCode,
}: Options) => {
  if (isShippingTierDisabled) {
    return InternationalShippingTierEnum.disabled
  }

  if (countryCode !== DEFAULT_COUNTRY.code && hasProPlan) {
    if (isGiftCard) {
      return InternationalShippingTierEnum.standard
    } else {
      return InternationalShippingTierEnum.full
    }
  }

  return InternationalShippingTierEnum.standard
}
