import React, { SetStateAction, useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { toast } from "react-hot-toast"
import { Link, useHistory, useLocation } from "react-router-dom"
import tw, { styled } from "twin.macro"

import Button from "../common/Button"
import { formatPhone, validateAndFormatPhoneE164 } from "../common/format"
import FormError from "../common/FormError"
import { useGlobalState } from "../common/GlobalState"
import { useQueryParams } from "../common/gtm"
import { useAuth, useAuthErrors, useRequireSSO } from "../common/hooks"
import { usePrevious } from "../common/hooks/previous"
import OmniAuthButton from "../common/OmniAuthButton"
import { generateRealmPath } from "../common/realm"
import TextInputField from "../common/TextInputField"
import VerifyModal from "../common/VerifyModal"

import { useFeatureFlags } from "@/common/hooks/featureFlags"
import RequireSSO from "@/signIn/RequireSSO"
import SSOButton from "@/signIn/SSOButton"
import SSOSignInForm from "@/signIn/SSOSignInForm"
import { PlusEnrollmentStatus } from "@/types/graphql-types"

interface Props {
  lastPathName: string | null
  lastHasEe: boolean
}

const LAST_PATH_STORAGE_NAME = "__goody__last_path_token"

const saveLastPathName = (lastPathName: string, lastHasEe: boolean) => {
  window.sessionStorage.setItem(
    LAST_PATH_STORAGE_NAME,
    JSON.stringify({ lastPathName, lastHasEe }),
  )
}

const restoreLastPathName = () => {
  const item = window.sessionStorage.getItem(LAST_PATH_STORAGE_NAME)
  return item && JSON.parse(item)
}

const clearLastPathName = () => {
  return window.sessionStorage.removeItem(LAST_PATH_STORAGE_NAME)
}

const SignIn: React.FC<Props> = ({ lastPathName, lastHasEe }) => {
  const history = useHistory()
  const { handleLogin, loginLoading } = useAuth()
  const [signedIn] = useGlobalState("signedIn")
  const [enrollmentStatus] = useGlobalState("enrollmentStatus")

  const { hasFeatureFlag } = useFeatureFlags()
  const hasSamlFeature = hasFeatureFlag("saml")

  const [identity, setIdentity] = useState("")
  const [password, setPassword] = useState("")

  const [identityType, setIdentityType] = useState("email")
  const [passwordHidden, setPasswordHidden] = useState(false)

  const [error, setError] = useState("")
  const [verifyOpen, setVerifyOpen] = useState(false)

  const [isSsoLogin, setIsSsoLogin] = useState(false)
  const [ssoEmail, setSsoEmail] = useState<string | null>(null)

  const { hash } = useLocation()

  useAuthErrors()

  const {
    requireSSOError,
    requireSSOSetting,
    requireSSOEmail,
    setRequireSSOError,
    setRequireSSOSetting,
    setRequireSSOEmail,
  } = useRequireSSO()
  const [hasRequireSSOError, setHasRequireSSOError] = useState(false)

  useEffect(() => {
    if (requireSSOError) {
      setHasRequireSSOError(true)
    }
  }, [requireSSOError])

  // If we're signed in already, go back if we can, otherwise go to the root.
  useEffect(() => {
    if (signedIn) {
      const [lastPathNameCheck, lastHasEeCheck] = (() => {
        if (lastPathName) {
          return [lastPathName, lastHasEe]
        } else {
          const restored = restoreLastPathName() ?? {}
          return [restored.lastPathName, restored.lastHasEe]
        }
      })()

      if (lastPathNameCheck === generateRealmPath("business", "/pricing")) {
        history.push(generateRealmPath("plus", "/organization/subscription"))
      } else if (
        lastPathNameCheck === generateRealmPath("business", "/signup") &&
        lastHasEeCheck
      ) {
        history.push(generateRealmPath("plus", "/organization/subscription"))
      } else if (
        lastPathNameCheck ===
        generateRealmPath("plus", "/organization/subscription")
      ) {
        history.push(generateRealmPath("plus", "/organization/subscription"))
      } else if (enrollmentStatus === PlusEnrollmentStatus.NONE) {
        history.push(generateRealmPath("business", "/signup"))
      } else if (hash === "#redirect-invite") {
        history.push(generateRealmPath("plus", "/workspace-invite"))
      } else {
        history.push(generateRealmPath("plus", "/"))
      }
      clearLastPathName()
    }
  }, [history, signedIn, enrollmentStatus, lastPathName, lastHasEe])

  // Events

  const onAuthError = (message?: string) => {
    if (!!message) {
      setError(message)
    }
  }

  const onRequireSSOError = (
    error: SetStateAction<string | null>,
    setting: SetStateAction<string | null>,
    email?: string | null,
  ) => {
    if (!!error && !!setting) {
      setHasRequireSSOError(true)
      setRequireSSOError(error)
      setRequireSSOSetting(setting)
      setRequireSSOEmail(email ?? null)
    }
  }

  const onIdentityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    const isPhoneNumber = /^\+?(\d+ ?)?\(?\d{2,}((?![a-z@]).)*$/i.test(value)
    if (isPhoneNumber) {
      setIdentity(formatPhone(value))
    } else {
      setIdentity(value)
    }

    setError("")
    setPasswordHidden(() => isPhoneNumber)
    setIdentityType(isPhoneNumber ? "phone" : "email")
  }

  const onIdentityBlur = (event: React.FocusEvent) => {
    if (identityType === "phone") {
      try {
        validateAndFormatPhoneE164(identity)
      } catch {
        setError("Invalid phone number provided.")
      }
    }
  }

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError("")
    setPassword(event.target.value)
  }

  const onVerifyComplete = () => {
    setVerifyOpen(false)
  }

  const onSsoEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setSsoEmail(value)
  }

  const onSignInSuccess = () => {
    toast.success("Welcome back!", {
      iconTheme: {
        primary: "#27ae60",
        secondary: "#fff",
      },
    })
  }

  // Auth

  const trySignIn = () => {
    if (error !== "") {
      return
    }

    if (identityType === "phone") {
      tryPhoneSignIn()
    } else {
      tryEmailSignIn()
    }
  }

  const tryEmailSignIn = () => {
    handleLogin({
      email: identity,
      password: password,
      onSuccess: onSignInSuccess,
      onError: onAuthError,
      onRequireSSOError: onRequireSSOError,
    })
  }

  const tryPhoneSignIn = () => {
    handleLogin({
      phone: identity,
      onSuccess: () => {
        setVerifyOpen(true)
      },
      onError: onAuthError,
    })
  }

  const displayRequireSSOForm =
    hasRequireSSOError && requireSSOSetting && requireSSOError && !isSsoLogin
  const displaySamlSignInForm =
    hasSamlFeature && isSsoLogin && !hasRequireSSOError
  const displayStandardSignInForm = !isSsoLogin && !hasRequireSSOError

  // Template

  return (
    <>
      <Helmet>
        <title>Sign In – Goody for Business</title>
      </Helmet>
      <BackgroundGradientContainer>
        <div tw="container mx-auto">
          <div tw="xl:w-2/3 mx-auto">
            <TitleTextGradient>
              Welcome back
              <SubTitle>Sign in to Goody</SubTitle>
            </TitleTextGradient>

            <MainContainer>
              {displayRequireSSOForm && (
                <RequireSSO
                  requireSSOSetting={requireSSOSetting}
                  requireSSOError={requireSSOError}
                  email={requireSSOEmail}
                />
              )}
              {displaySamlSignInForm && (
                <div>
                  <div tw="flex justify-center mt-6 mx-[2rem]">
                    <SSOSignInForm
                      email={ssoEmail}
                      onEmailChange={onSsoEmailChange}
                    />
                  </div>
                  <div tw="h-px bg-[#E5E7EB]" />
                  <div tw="p-8">
                    <SSOButton
                      text="Go Back"
                      onClick={() => {
                        setIsSsoLogin(false)
                        window.scrollTo(0, 0)
                      }}
                    />
                  </div>
                </div>
              )}
              {displayStandardSignInForm && (
                <>
                  <FormContainer
                    onSubmit={(e) => {
                      e.preventDefault()
                      trySignIn()
                    }}
                  >
                    <FormError message={error} />
                    <InputContainer>
                      <TextInputField
                        type="text"
                        placeholder="Email or phone number"
                        value={identity}
                        onChange={onIdentityChange}
                        onBlur={onIdentityBlur}
                        className="ph-no-capture fs-exclude data-hj-suppress"
                        autoFocus={true}
                      />
                    </InputContainer>
                    <InputContainer hidden={passwordHidden}>
                      <TextInputField
                        type="password"
                        placeholder="Password"
                        onChange={onPasswordChange}
                        className="ph-no-capture fs-exclude data-hj-suppress"
                      />
                    </InputContainer>
                    <div tw="flex justify-center mt-4">
                      <Button onClick={trySignIn} disabled={loginLoading}>
                        Sign in
                      </Button>
                    </div>
                    <div tw="flex justify-center mt-4 text-gray-500">
                      <Link
                        to={generateRealmPath("business", "/signup")}
                        tw="hover:text-primary-500 transition-colors"
                      >
                        Sign up for Goody
                      </Link>
                      <div tw="mx-4">&middot;</div>
                      <Link
                        to={generateRealmPath("business", "/forgot-password")}
                        tw="hover:text-primary-500 transition-colors"
                      >
                        Forgot password
                      </Link>
                    </div>
                  </FormContainer>
                  <div tw="p-8">
                    <div tw="space-y-4">
                      {["google", "microsoft"].map((provider) => (
                        <OmniAuthButton
                          onStartAuth={() => {
                            if (lastPathName) {
                              saveLastPathName(lastPathName, lastHasEe)
                            }
                          }}
                          key={provider}
                          provider={provider}
                          labelText="Sign in with"
                          origin={`/business/signin${hash}`}
                        />
                      ))}
                      {hasSamlFeature && (
                        <SSOButton
                          text="Sign in with SSO"
                          displayLogo={true}
                          onClick={() => {
                            setIsSsoLogin(true)
                            window.scrollTo(0, 0)
                          }}
                        />
                      )}
                    </div>
                  </div>
                </>
              )}
            </MainContainer>
          </div>
        </div>
      </BackgroundGradientContainer>
      <VerifyModal
        isOpen={verifyOpen}
        setClosed={onVerifyComplete}
        onSuccess={onSignInSuccess}
        phone={identity}
      />
    </>
  )
}

// Styles

export const BackgroundGradientContainer = styled.div`
  background: repeat-x
    linear-gradient(
      90deg,
      rgba(228, 161, 184, 0.15) 30.58%,
      rgba(179, 125, 213, 0.15) 100%
    );
  ${tw`px-5 pb-24`}
  background-size: 100% 468px;

  @media (max-width: 768px) {
    background-size: 100% 800px;
  }
`

const TitleTextGradient = styled.div`
  background: linear-gradient(90deg, #d77093, #b37dd5);
  -webkit-text-fill-color: transparent;
  ${tw`text-2xl font-light bg-clip-text text-center pt-24 pb-14`}

  line-height: 1.6;
`

const SubTitle = styled.div`
  ${tw`text-4xl`};
  background: inherit;
  line-height: 1.3;
`

export const MainContainer = styled.div`
  max-width: 530px;
  margin: 0 auto;
  ${tw`rounded-2xl bg-white divide-y divide-gray-100`}
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.04), 0 8px 50px rgba(0, 0, 0, 0.06);
  overflow: hidden;
`

export const FormContainer = styled.form`
  padding: 2rem;
`

export const SectionTitle = styled.div`
  ${tw`font-medium text-lg text-primary-600 mt-9 first:mt-0`}
`

interface InputContainerProps {
  hidden?: boolean
}

export const InputContainer = styled.div<InputContainerProps>`
  ${tw`flex flex-row mt-4 first:mt-0 transition`}
  height: calc(1.5em + 2rem + 2px);
  overflow: hidden;
  transition-property: height, margin, opacity;
  ${({ hidden }) => (hidden ? `height: 0; margin: 0; opacity: 0;` : ``)}
`

interface SignInWrapperProps {
  visible: boolean
}

const SignInWrapper: React.FC<SignInWrapperProps> = ({ visible }) => {
  const pathname = useLocation().pathname
  const validLastPath = () =>
    pathname !== generateRealmPath("business", "/signin")

  const lastPathName = usePrevious(pathname, validLastPath) ?? null
  const lastHasEe =
    usePrevious(useQueryParams().has("ee"), validLastPath) ?? false

  return visible ? (
    <SignIn lastPathName={lastPathName} lastHasEe={lastHasEe} />
  ) : null
}

export default SignInWrapper
