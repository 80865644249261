import { isNil } from "lodash-es"
import React, { useEffect } from "react"
import tw, { styled } from "twin.macro"

import { useGiftBatchSettings } from "./hooks"
import { allRecipientsAccepted, giftBatchSentOn } from "../utils"

import { Options, UpdateButton } from "./index"

import { BALANCE_PAYMENT_METHOD_ID } from "@/common/hooks/payment"
import {
  fromISOStringtoHawaiianTimeZoneDate,
  sixWeeksFromNowNoon,
} from "@/common/utilities"
import { omitSingleKey } from "@/common/utils/omitSingleKey"
import {
  Alert,
  AlertColor,
} from "@/track/components/giftBatch/GiftBatchSettings/Alert"
import {
  BatchSendMethod,
  Track_GiftBatchFragment,
  gift_meeting_setting,
} from "@/types/graphql-types"

interface Props {
  giftBatch?: Track_GiftBatchFragment | null
}
export const GiftBatchSettings = ({ giftBatch }: Props) => {
  const { setSettings } = useGiftBatchSettings()
  const disabled = giftBatch
    ? allRecipientsAccepted(giftBatch) && !giftBatch.isSmartLink
    : false

  useEffect(() => {
    if (giftBatch) {
      setSettings((currentSettings) => ({
        ...currentSettings,
        giftBatchId: giftBatch.id,
        batchName: giftBatch.batchName ?? "",
        expiresAt: giftBatch.isExpired
          ? sixWeeksFromNowNoon()
          : isNil(giftBatch.expiresAt)
          ? null
          : fromISOStringtoHawaiianTimeZoneDate(giftBatch.expiresAt),
        expireAtOption: giftBatch.expiresAt ? "custom" : "none",
        customExpirationDate:
          giftBatch.expiresAt && !giftBatch.isExpired
            ? fromISOStringtoHawaiianTimeZoneDate(giftBatch.expiresAt)
            : sixWeeksFromNowNoon(),
        hasCustomStore:
          giftBatch.cartProducts.some(
            (cartProduct) => !!cartProduct.product.customStore,
          ) ?? false,
        hasFlexGiftItem:
          giftBatch.cartProducts.length > 0 &&
          giftBatch.cartProducts.some(
            (cartProduct) => cartProduct.product.isFlexGift,
          ),
        hasForceSwapEnabledItem: giftBatch.cartProducts.some(
          (cartProduct) => cartProduct.product.forceSwapEnabled,
        ),
        isDirectSend: giftBatch.sendMethod === BatchSendMethod.direct_send,
        isExpired: giftBatch.isExpired,
        isUsingBalance:
          giftBatch.autopayPaymentMethodId === BALANCE_PAYMENT_METHOD_ID,
        swapType: giftBatch.swapType,
        internationalShippingTier: giftBatch.internationalShippingTier,
        userPublicId: giftBatch.userPublicId,
        userFullName: giftBatch.userFullName,
        giftMeetingSetting:
          giftBatch.meetingSettings?.giftSetting ||
          gift_meeting_setting.NO_MEETING,
        isSmartLink: giftBatch.isSmartLink ?? false,
        settings: omitSingleKey("__typename", giftBatch.settings),
      }))
    }
  }, [giftBatch])

  return (
    <div>
      {disabled ? (
        <div tw="flex items-center justify-center my-4">
          <Alert
            primaryMessage="All gifts have been accepted. This gift batch is no longer editable."
            color={AlertColor.yellow}
          />
        </div>
      ) : (
        <div tw="lg:mt-[72px]" />
      )}
      <div tw="flex flex-col items-center justify-center lg:w-[576px] mx-auto p-6 lg:p-0">
        {giftBatch ? (
          <div>
            <DisabledContainer disabled={disabled} unclickable={disabled}>
              <div tw="border-b border-gray-300 mb-5">
                <Options />
                <div tw="mt-16 mb-6 lg:mb-[92px]">
                  <UpdateButton />
                </div>
              </div>
            </DisabledContainer>
            <div tw="text-gray-450 leading-[22px]">
              <div tw="font-medium mb-3">Gift Information</div>
              <div tw="mb-2">
                Sent {giftBatchSentOn(giftBatch)} by {giftBatch.userFullName}
              </div>
              <div>Payment method: {giftBatch.autopayPaymentMethodName}</div>
            </div>
          </div>
        ) : (
          <div tw="text-xl text-gray-500">Loading...</div>
        )}
      </div>
    </div>
  )
}

const DisabledContainer = styled.div<{
  disabled?: boolean
  unclickable?: boolean
}>`
  ${({ disabled }) => (disabled ? tw`opacity-40` : null)};
  ${({ unclickable }) => (unclickable ? tw`pointer-events-none` : null)};
`
