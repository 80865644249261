import { gql, useQuery } from "@apollo/client"
import { useState } from "react"
import { Redirect } from "react-router-dom"

import InviteMembersDrawer from "./InviteMembersDrawer"
import MemberRow from "./MemberRow"
import Button from "../../common/Button"
import { useGlobalState } from "../../common/GlobalState"
import { ReactComponent as UserPlusIcon } from "../../common/images/user-plus.svg"
import { Table } from "../../common/members/Table"
import { generateRealmPath } from "../../common/realm"
import Container from "../../sites/App/Container"
import { ORGANIZATION_MEMBER_FRAGMENT } from "../budgeting/graphql"

import { GetOrganizationMembershipsQuery } from "@/types/graphql-types"

const Members = () => {
  const { data } = useQuery<GetOrganizationMembershipsQuery>(GET_WORKSPACE)
  const [currentUser] = useGlobalState("user")
  const [inviteMemberDrawerOpen, setInviteMemberDrawerOpen] = useState(false)

  const onInviteMemberClick = () => setInviteMemberDrawerOpen(true)

  const memberships = data?.workspace?.organization?.memberships

  if (!currentUser || !data) {
    return null
  }

  if (data.workspace == undefined) {
    return (
      <Redirect to={generateRealmPath("plus", "/organization/workspaces")} />
    )
  }

  return (
    <Container>
      <div tw="pb-9 px-6">
        <Button variant="primary" onClick={onInviteMemberClick} tw="my-10">
          <UserPlusIcon tw="h-4 w-4 text-white" />
          Invite Members
        </Button>
        <Table
          headers={["Name", "Title", "Workspaces", "Gifts", "Org. Role", ""]}
          gridTemplateColumns="3fr 2fr 3fr 2fr 2fr 1fr"
          withMinHeight
        >
          <>
            {memberships?.map((member) => (
              <MemberRow
                {...member}
                currentUser={currentUser}
                isTeamPlan={
                  !!data?.workspace?.organization?.subscription?.isTeamPlan
                }
              />
            ))}
          </>
        </Table>
      </div>
      <InviteMembersDrawer
        open={inviteMemberDrawerOpen}
        setOpen={setInviteMemberDrawerOpen}
      />
    </Container>
  )
}

export const GET_WORKSPACE = gql`
  query GetOrganizationMemberships {
    workspace {
      organization {
        id
        memberships {
          id
          memberGiftCount
          memberWorkspaceNames
          pendingGiftCount
          scheduledGiftBatchCount
          access
          role
          access
          member {
            ...OrganizationMember
          }
        }
        subscription {
          id
          isTeamPlan
        }
      }
    }
  }
  ${ORGANIZATION_MEMBER_FRAGMENT}
`

export default Members
