import { isNil } from "lodash-es"
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react"

import DialogModal, { DialogVariant } from "../DialogModal"

interface ContextProps {
  openAlert: (state: AlertState) => void
}

const Context = createContext<ContextProps>({
  openAlert: () => {},
})

interface Props {
  children: React.ReactNode
}

type AlertButton = {
  text: string
  onClick?: () => void
}

type AlertState = {
  variant?: DialogVariant
  title: ReactNode
  content?: ReactNode
  cancel?: AlertButton
  confirm?: AlertButton
}

export const AlertProvider = ({ children }: Props) => {
  const [open, setOpen] = useState(false)
  const stateRef = useRef<AlertState | null>(null)
  const state = stateRef.current

  const openAlert = useCallback(
    () => (state: AlertState) => {
      stateRef.current = state
      setOpen(true)
    },
    [],
  )

  const handleClose = () => {
    stateRef.current = null
    setOpen(false)
  }

  const handleCallback = (callback?: () => void) => {
    callback && callback()
    handleClose()
  }

  return (
    <Context.Provider value={{ openAlert }}>
      {children}

      {state && (
        <DialogModal open={open} onClose={handleClose}>
          <DialogModal.Header variant={state.variant || "info"} />
          <DialogModal.Title tw="px-16">{state.title}</DialogModal.Title>
          {state.content && (
            <DialogModal.Content>{state.content}</DialogModal.Content>
          )}

          <DialogModal.Actions>
            {state.cancel && (
              <DialogModal.Button
                onClick={() => handleCallback(state.cancel?.onClick)}
              >
                {state.cancel.text}
              </DialogModal.Button>
            )}
            {state.confirm && (
              <DialogModal.Button
                variant="primary-new"
                onClick={() => handleCallback(state.confirm?.onClick)}
              >
                {state.confirm.text}
              </DialogModal.Button>
            )}
          </DialogModal.Actions>
        </DialogModal>
      )}
    </Context.Provider>
  )
}

export default AlertProvider

export const useAlert = () => {
  const context = useContext(Context)

  if (isNil(context)) {
    throw new Error("useAlert must be used within the AlertProvider context")
  }

  return context
}
