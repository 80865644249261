import { gql, useQuery } from "@apollo/client"
import { Liquid } from "liquidjs"
import React, { useEffect, useMemo, useState } from "react"

import Option from "./Option"
import { CurrentGift, useGlobalState } from "../common/GlobalState"
import { useGiftData } from "../common/hooks/giftData"
import Input from "../common/Input"
import { filterOutBlankRecipients, wrapMatches } from "../common/utilities"

import { SelectionLabel } from "@/common/SelectionGroup"
import { EmailDefaultSubjectTemplatePreviewQuery } from "@/types/graphql-types"

const FROM_NAME_DISPLAY = "From Name"
const RECIPIENT_FIRST_NAME_DISPLAY = "Recipient First Name"

const SUBJECT_REGEX = new RegExp(
  `(${FROM_NAME_DISPLAY}|${RECIPIENT_FIRST_NAME_DISPLAY})`,
)

const liquidEngine = new Liquid()

type EmailSubjectOptionsType = "default" | "custom"

interface IEmailSubjectOption {
  giftBatch: CurrentGift
  removePadding?: boolean
}

const EmailSubjectOption = ({
  giftBatch,
  removePadding,
}: IEmailSubjectOption) => {
  const [recipients] = useGlobalState("recipients")
  const { setCustomEmailSubject } = useGiftData()

  const [defaultSubject, setDefaultSubject] = useState<string>("")
  const inputValue = giftBatch.customEmailSubject ?? ""

  const [currentOption, setCurrentOption] =
    useState<EmailSubjectOptionsType>("default")
  const [charactersCount, setCharactersCount] = useState<number>(0)

  const { data } = useQuery<EmailDefaultSubjectTemplatePreviewQuery>(
    EMAIL_DEFAULT_SUBJECT_TEMPLATE_PREVIEW,
  )

  const validRecipients = filterOutBlankRecipients(recipients)
  const recipientFirstName = validRecipients[0]?.firstName
  const emailDefaultSubjectTemplatePreview =
    data?.emailDefaultSubjectTemplatePreview

  // // Handle option change
  const onSelect = (value: EmailSubjectOptionsType) => {
    if (value === "default") {
      setCustomEmailSubject(null)
    }
    setCurrentOption(value)
  }

  // Update character count
  useEffect(() => {
    setCharactersCount(inputValue.length)
  }, [inputValue.length])

  // Set the defaultSubject
  useEffect(() => {
    if (emailDefaultSubjectTemplatePreview) {
      liquidEngine
        .parseAndRender(emailDefaultSubjectTemplatePreview, {
          senderFullName: giftBatch.fromName || FROM_NAME_DISPLAY,
          recipientFirstName:
            validRecipients.length === 1
              ? recipientFirstName
              : RECIPIENT_FIRST_NAME_DISPLAY,
        })
        .then((parsed) => setDefaultSubject(parsed))
    }
  }, [
    emailDefaultSubjectTemplatePreview,
    giftBatch.fromName,
    recipientFirstName,
    validRecipients.length,
  ])

  // Set the real value to the gift_batch
  useEffect(() => {
    if (giftBatch?.customEmailSubject) {
      setCurrentOption("custom")
    }
  }, [!!giftBatch, setCurrentOption])

  // Replaces default strings with different color divs
  const defaultSubjectComponent = useMemo(
    () => (
      <>
        {wrapMatches(defaultSubject, SUBJECT_REGEX, (str) => {
          const title =
            str === RECIPIENT_FIRST_NAME_DISPLAY
              ? "Replaced with your recipient’s first name"
              : "Replaced with your from name"
          return (
            <div
              key={str}
              tw="inline text-gray-400 underline"
              title={title}
              css="text-decoration-style: dotted; text-decoration-color: #E5E7EB"
            >
              {str}
            </div>
          )
        })}
      </>
    ),
    [defaultSubject],
  )

  return (
    <Option title="Email subject" removePadding={removePadding}>
      <>
        <Option.Radio
          onSelect={onSelect}
          currentValue={currentOption}
          value="default"
          explainer={defaultSubjectComponent}
        >
          Standard
        </Option.Radio>

        <Option.Radio
          onSelect={onSelect}
          currentValue={currentOption}
          value="custom"
        >
          <div tw="w-full">
            <SelectionLabel selected={currentOption === "custom"}>
              Custom
            </SelectionLabel>
            {currentOption === "custom" && (
              <span tw="float-right text-gray-500">{charactersCount}/100</span>
            )}
          </div>
        </Option.Radio>

        {currentOption === "custom" && (
          <div tw="ml-8">
            <Input
              tw="w-full h-9 py-0 px-3"
              onBlur={() => {
                inputValue || setCustomEmailSubject(null)
              }}
              onChange={(e) => setCustomEmailSubject(e.target.value)}
              value={inputValue}
              placeholder="Email subject"
            />
          </div>
        )}
      </>
    </Option>
  )
}

const EMAIL_DEFAULT_SUBJECT_TEMPLATE_PREVIEW = gql`
  query EmailDefaultSubjectTemplatePreview {
    emailDefaultSubjectTemplatePreview
  }
`

export default EmailSubjectOption
