import tw from "twin.macro"

import { useGlobalState } from "../../common/GlobalState"
import { useFeatureAccess } from "../../common/hooks/featureAccess"
import { ProTag } from "../../common/ProTag"

interface Props {
  variant: "index" | "detail"
}

export default function GiftCardProBanner({ variant }: Props) {
  const { hasFeature } = useFeatureAccess()

  const [, setIsProPlanModalOpen] = useGlobalState("isProPlanModalOpen")

  if (hasFeature("pro_plan")) {
    return null
  }

  return (
    <div
      css={[
        variant === "index" && tw`mx-4 mb-8`,
        variant === "detail" && tw`mb-8`,
      ]}
    >
      <button
        tw="p-4 bg-primary-new-050 rounded-lg flex flex-row items-center gap-3 hover:bg-primary-new-075 active:scale-95 text-left transition-all w-full"
        onClick={() => setIsProPlanModalOpen(true)}
      >
        <ProTag
          feature="gift_cards"
          disabled={true}
          tw="pointer-events-none bg-primary-new-600 text-white border-primary-new-600"
        />
        <div tw="flex-1 text-primary-new-700 font-medium">
          {variant === "index"
            ? "Send gift cards with Goody Pro"
            : "A Pro subscription is required to send gift cards"}
        </div>
      </button>
    </div>
  )
}
