import React from "react"
import { useHistory } from "react-router-dom"

import { ArrowBack } from "../assets/icons"

interface Props {
  label: string
  path?: string
}

const BackArrow = ({ label, path }: Props) => {
  const history = useHistory()

  return (
    <button
      tw="inline-flex flex-row text-primary-500 items-center stroke-current hover:text-primary-600 active:text-primary-700 transition-all"
      onClick={() => (path ? history.push(path) : history.goBack())}
    >
      <ArrowBack />
      <span tw="ml-2">{label}</span>
    </button>
  )
}

export default BackArrow
