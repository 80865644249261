import React from "react"
import tw from "twin.macro"

import { SwapTooltip } from "./SwapTooltip"
import {
  SelectionGroup,
  SelectionIndicator,
  SelectionLabel,
} from "../SelectionGroup"

import { GiftSwapTypeEnum } from "@/types/graphql-types"

interface Props {
  swapType: GiftSwapTypeEnum
  setSwapType: (swapType: GiftSwapTypeEnum) => void
  forceSwapEnabled?: boolean
  showTooltip?: boolean
  sendV3?: boolean
  hasFlexGiftItem?: boolean
}

export const SwapOptions = ({
  swapType,
  setSwapType,
  forceSwapEnabled,
  showTooltip,
  sendV3,
  hasFlexGiftItem,
}: Props) => {
  const selectText = hasFlexGiftItem ? "choosing " : "swapping for "

  return (
    <>
      <div>
        <div>
          <SelectionGroup
            onClick={() => setSwapType(GiftSwapTypeEnum.swap_single)}
          >
            <SelectionIndicator
              selected={swapType === GiftSwapTypeEnum.swap_single}
            />
            <SelectionLabel
              selected={swapType === GiftSwapTypeEnum.swap_single}
            >
              Prices hidden – enable {selectText}
              <div tw="font-medium inline">one</div> item
            </SelectionLabel>
          </SelectionGroup>
        </div>
        <div>
          <SelectionGroup
            onClick={() => setSwapType(GiftSwapTypeEnum.swap_multiple)}
            css={[sendV3 && tw`items-start`]}
          >
            <SelectionIndicator
              selected={swapType === GiftSwapTypeEnum.swap_multiple}
            />
            <SelectionLabel
              selected={swapType === GiftSwapTypeEnum.swap_multiple}
              css={[sendV3 && tw`pt-1`]}
            >
              Prices shown – enable {selectText}
              <div tw="font-medium inline">multiple</div> items up to total
              price
            </SelectionLabel>
          </SelectionGroup>
        </div>
        {!hasFlexGiftItem && (
          <div>
            <SelectionGroup
              disabled={forceSwapEnabled}
              unclickable={forceSwapEnabled}
              onClick={() => setSwapType(GiftSwapTypeEnum.swap_disabled)}
            >
              <SelectionIndicator
                selected={swapType === GiftSwapTypeEnum.swap_disabled}
              />

              <SelectionLabel
                selected={swapType === GiftSwapTypeEnum.swap_disabled}
              >
                Disable swap
              </SelectionLabel>
            </SelectionGroup>
          </div>
        )}
      </div>
      {showTooltip && (
        <div tw="pt-2 text-gray-500 text-sm font-text">
          Enabling gift swap is recommended for the best recipient experience.
          <SwapTooltip />
        </div>
      )}
    </>
  )
}
