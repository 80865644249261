import { gql } from "@apollo/client"

import { CUSTOM_STORE_FULL_FRAGMENT } from "./CustomStoreFullFragment"

// noinspection GraphQLSchemaValidation - $shippingCountry is used in fragment
export const GET_CUSTOM_STORE = gql`
  query Store_CustomStore($customStoreId: ID!, $shippingCountry: String) {
    me {
      customStore(id: $customStoreId) {
        ...CustomStoreFull
      }
    }
  }
  ${CUSTOM_STORE_FULL_FRAGMENT}
`
