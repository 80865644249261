import React from "react"

import { QuantityTagLarge } from "../../common/components/QuantityTagLarge"
import { ReactComponent as CalendarIcon } from "../../common/images/calendar.svg"

import { CartProductProductImage } from "@/track/components/CartProductProductImage"
import { CartProductShipmentFragment } from "@/types/graphql-types"

interface Props {
  cartQuantities: number[]
  recipientFullName?: string | null
  brandName: string
  productTitle?: string
  sendDateText: string
  isScheduledSend: boolean
  giftStatus: string
  cartProduct: CartProductShipmentFragment
}

export default function Gift({
  cartQuantities,
  recipientFullName,
  brandName,
  productTitle,
  sendDateText,
  isScheduledSend,
  giftStatus,
  cartProduct,
}: Props) {
  return (
    <>
      <div tw="relative">
        <CartProductProductImage
          cartProduct={cartProduct}
          tw="sm:w-48 sm:h-48"
        />
        {cartQuantities[0] > 1 && (
          <QuantityTagLarge>{cartQuantities[0]}</QuantityTagLarge>
        )}
      </div>

      <div tw="flex flex-col sm:flex-row p-5 sm:p-0 sm:justify-between flex-1">
        <div tw="flex-1 flex flex-col self-stretch sm:px-8 justify-center sm:pt-8 sm:pb-6 text-left">
          <div tw="flex-1 flex justify-between">
            <div
              tw="text-2xl"
              className="data-hj-suppress ph-no-capture fs-mask"
            >
              {recipientFullName}
            </div>
            <div tw="text-gray-500 self-start hidden sm:flex">{giftStatus}</div>
          </div>
          <div tw="flex flex-col gap-2">
            <div tw="text-gray-500 my-2">
              {productTitle}
              <br />
              {brandName}
              {cartQuantities.length > 1 && (
                <> + {cartQuantities.length > 1} more</>
              )}
            </div>
            {isScheduledSend ? (
              <div tw="grid grid-flow-col gap-2 text-gray-500 w-[fit-content] col-span-3">
                <CalendarIcon tw="w-4" />
                <div tw="text-sm">{sendDateText}</div>
              </div>
            ) : (
              <>
                <div tw="text-gray-500 text-sm mt-10 sm:mt-0">
                  {sendDateText}
                </div>
                <div tw="text-gray-500 self-start flex sm:hidden">
                  {giftStatus}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
