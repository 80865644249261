import { gql, useMutation, useQuery } from "@apollo/client"
import React, { useEffect, useState } from "react"
import { toast } from "react-hot-toast"
import tw, { styled } from "twin.macro"

import { LinkContainer, OutlinedButton } from "./components/common"
import GiftBatchErrorMessage from "./components/GiftBatchErrorMessage"
import GiftDispenserInfo from "./components/GiftDispenserInfo"
import GiftLink from "./components/giftLink/GiftLink"
import NavText from "./components/NavText"
import TrackingDetails from "./components/TrackingDetails"
import { ReactComponent as CopyIcon } from "./images/copy.svg"
import { GIFT_BATCH_RECIPIENTS_QUERY } from "./queries"
import RecipientCSVDownload from "./RecipientCSVDownload"
import DropdownMenu, {
  DropdownMenuItem,
  DropdownMenuPanel,
} from "../common/DropdownMenu"
import { formatPrice, isBlank } from "../common/format"
import { useCurrentWorkspace } from "../common/hooks"
import { ReactComponent as ChevronIcon } from "../common/images/chevron-down.svg"
import { ReactComponent as XIcon } from "../common/images/x.svg"
import Pagination from "../common/pagination/Pagination"
import { getSendViaStatus } from "../common/recipient"
import RoundedTable from "../common/RoundedTable"
import { Loader } from "../common/UI"
import { ROOT_DATA_QUERY } from "../graphql"

import DeliveryStatus from "@/track/components/DeliveryStatus"
import { GiftStatusWithExpired, PENDING_GIFT_STATUSES } from "@/track/shared"
import {
  BatchSendMethod,
  GiftSeriesFilter,
  GiftViewableStatus,
} from "@/types/graphql-types"
import {
  TrackGiftBatchRecipientRequestItemFragment,
  Track_GiftBatchRecipientsQuery,
  Track_GiftBatchRecipientsQueryVariables,
  Track_GiftCancelMutation,
  Track_GiftCancelMutationVariables,
} from "@/types/graphql-types"

type TrackGiftRecipientsItems = TrackGiftBatchRecipientRequestItemFragment[]

const PER_PAGE = 50

interface Props {
  giftBatchId: string
}

const filters: ReadonlyArray<GiftSeriesFilter> = Object.freeze([
  GiftSeriesFilter.all,
  GiftSeriesFilter.pending_self_send,
  GiftSeriesFilter.not_yet_accepted,
  GiftSeriesFilter.delivered,
])

const smartLinkFilters: ReadonlyArray<GiftSeriesFilter> = Object.freeze([
  GiftSeriesFilter.all,
  GiftSeriesFilter.delivered,
])

type FilterLabelMapping = {
  [filter in GiftSeriesFilter]: string
}
const filterLabels: FilterLabelMapping = Object.freeze({
  [GiftSeriesFilter.all]: "All recipients",
  [GiftSeriesFilter.pending_self_send]: "Pending self-sends",
  [GiftSeriesFilter.not_yet_accepted]: "Not yet accepted",
  [GiftSeriesFilter.delivered]: "Delivered",
  // Not used, here to make TypeScript not yell
  [GiftSeriesFilter.opened]: "Opened",
  [GiftSeriesFilter.recently_accepted]: "Recently accepted",
  [GiftSeriesFilter.notified]: "Notified",
  [GiftSeriesFilter.shipped]: "Shipped",
})

const Recipients: React.FC<Props> = ({ giftBatchId }) => {
  const { currentWorkspace } = useCurrentWorkspace()
  const [sending, setSending] = useState(false)

  const [page, setPage] = useState(0)
  const [filter, setFilter] = useState<GiftSeriesFilter>(GiftSeriesFilter.all)

  const { data, loading, refetch } = useQuery<
    Track_GiftBatchRecipientsQuery,
    Track_GiftBatchRecipientsQueryVariables
  >(GIFT_BATCH_RECIPIENTS_QUERY, {
    variables: {
      id: giftBatchId,
      page: page + 1,
      filter,
    },
    context: {
      workspaceId: currentWorkspace?.id,
    },
    // The gift batch is reloaded as long as there are unsent gifts
    // so the user can watch gifts being sent.
    fetchPolicy: sending ? "network-only" : "cache-and-network",
    pollInterval: sending ? 500 : 0,
  })

  const refetchRecipients = () => {
    if (refetch) {
      refetch({
        page: page + 1,
        filter,
      })
    }
  }

  const giftBatch = data?.workspace?.giftBatch

  const recipients = data?.workspace?.giftBatch?.recipientSeries?.items || []
  const totalCount =
    data?.workspace?.giftBatch?.recipientSeries?.totalCount || 0

  const landingPageWithoutNotification =
    giftBatch?.landingPageEnabled && !giftBatch.landingPageSendNotifs
  const directSend = giftBatch?.sendMethod === BatchSendMethod.direct_send

  const someGiftPendingNotification = recipients.some((recipient) => {
    const isSelfSend = getSendViaStatus(recipient) === "self-send"

    if (isSelfSend) return false

    const status = giftStatus(
      recipient,
      landingPageWithoutNotification,
      directSend,
    )

    return isPendingStatus(status)
  })

  useEffect(() => {
    if (giftBatch?.id) {
      // Set sending=true in case we have no error messages and we have gifts that
      // were not notified.
      setSending(
        giftBatch.failureMessages.length === 0 &&
          (giftBatch.pendingSends > 0 || someGiftPendingNotification),
      )
    }
  }, [
    giftBatch?.failureMessages?.length,
    giftBatch?.pendingSends,
    someGiftPendingNotification,
    giftBatch?.id,
  ])

  const [cancelGift] = useMutation<
    Track_GiftCancelMutation,
    Track_GiftCancelMutationVariables
  >(GIFT_CANCEL)

  const handleCancelGift = async (id: string) => {
    const confirmed = window.confirm(
      "Are you sure you want to cancel this gift?",
    )

    if (!confirmed) {
      return
    }

    const { data } = await cancelGift({
      variables: { id },
      refetchQueries: [{ query: ROOT_DATA_QUERY }],
    })

    if (!data) {
      return
    }

    const {
      giftCancel: { ok, refundedCreditAmount, error },
    } = data

    if (ok) {
      let successMessage = "Gift cancelled."
      if (refundedCreditAmount != null && refundedCreditAmount > 0) {
        successMessage += ` ${formatPrice(
          refundedCreditAmount,
        )} in credit returned to your account.`
      }

      toast.success(successMessage)
    } else if (error) {
      alert(error)
    } else {
      alert("An unexpected error occurred.")
    }

    refetchRecipients()
  }

  const handleCopyGiftId = (id: string) => {
    navigator.clipboard.writeText(id)
    toast.success("Copied gift ID.")
  }

  const giftDispenser = data?.workspace?.giftBatch?.giftDispenser

  return (
    <Container>
      {giftBatch && giftBatch.failureMessages.length > 0 && (
        <div tw="mt-[88px] px-5 pt-0 -mb-12 ">
          <GiftBatchErrorMessage
            variant="2"
            onAuthorizationGranted={refetchRecipients}
            giftBatch={giftBatch}
          />
        </div>
      )}

      {!directSend ? (
        <div tw="px-6 max-w-[1064px] mx-auto">
          <GiftLink giftBatch={giftBatch} />
        </div>
      ) : null}

      {giftDispenser && (
        <GiftDispenserInfo
          giftDispenser={giftDispenser}
          giftBatchId={giftBatchId}
        />
      )}
      <RecipientsContainer
        className={
          giftBatch?.landingPageEnabled
            ? "withLandingPage"
            : "withoutLandingPage"
        }
      >
        <FilterRow>
          <div tw="flex gap-2 flex-wrap">
            {(giftBatch?.isSmartLink ? smartLinkFilters : filters).map(
              (filterOption) => (
                <FilterButton
                  value={filter}
                  filter={filterOption}
                  onChange={(filter: GiftSeriesFilter) => {
                    setFilter(filter)
                    setPage(0)
                    refetchRecipients()
                  }}
                  key={filterOption}
                >
                  {filterLabels[filterOption]}
                </FilterButton>
              ),
            )}
          </div>
          <SendingIndicator visible={sending} text="Creating" />
        </FilterRow>
        <RecipientsTable
          directSend={directSend}
          isSmartLink={giftBatch?.isSmartLink ?? false}
        >
          <thead>
            <tr>
              <th>Name</th>
              <th>Status</th>
              {directSend && <th>Tracking</th>}
              <th>Individual gift link</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading && !data ? <LoadingRow /> : null}
            {!loading && recipients.length === 0 ? <EmptyRow /> : null}
            {recipients.map((recipient) => (
              <tr key={recipient.id}>
                <td tw="flex items-center">
                  <Recipient>
                    <h2 className="data-hj-suppress ph-no-capture fs-exclude">
                      {recipient.firstName} {recipient.lastName}
                    </h2>
                    {[recipient.email, recipient.phone]
                      .filter((e) => !isBlank(e)) // discard blank
                      .map((e) => (
                        <div
                          key={e}
                          className="data-hj-suppress ph-no-capture fs-exclude"
                        >
                          {e}
                        </div>
                      ))}
                  </Recipient>
                </td>
                <td tw="flex items-center">
                  <DeliveryStatus
                    giftId={recipient.gift?.id}
                    status={giftStatus(
                      recipient,
                      landingPageWithoutNotification,
                      directSend,
                    )}
                    giftBatchId={giftBatchId}
                  />
                </td>
                {directSend && (
                  <td tw="flex items-center">
                    <TrackingDetails gift={recipient.gift} />
                  </td>
                )}
                <td tw="flex items-center">
                  {recipient.gift?.sharedLink ? (
                    <LinkContainer
                      href={recipient.gift.sharedLink}
                      previewHref={recipient.gift.senderLink!}
                      color={
                        giftStatus(recipient) === "self-send" ? "blue" : "gray"
                      }
                    />
                  ) : null}
                </td>
                <td tw="flex items-center">
                  <ActionsMenu
                    gift={recipient.gift}
                    onCancel={handleCancelGift}
                    onCopy={handleCopyGiftId}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </RecipientsTable>
        <div tw="sm:pt-6">
          <div tw="flex flex-col sm:flex-row items-center">
            <div tw="flex-1">
              <RecipientCSVDownload giftBatchId={giftBatchId} filter={filter} />
            </div>
            <div tw="flex-1">
              {totalCount > PER_PAGE && (
                <Pagination
                  tw="pb-0"
                  pageIndex={page}
                  perPage={PER_PAGE}
                  totalCount={totalCount}
                  setPageIndex={(value: number) => {
                    setPage(value)
                    refetchRecipients()
                  }}
                />
              )}
            </div>
            <div tw="flex-1" />
          </div>
        </div>
      </RecipientsContainer>
    </Container>
  )
}

interface FilterButtonProps {
  value: GiftSeriesFilter
  onChange: (option: GiftSeriesFilter) => void
  filter: GiftSeriesFilter
  children: string
}
const FilterButton: React.FC<FilterButtonProps> = ({
  value,
  onChange,
  filter,
  children,
}) => (
  <StyledFilterButton
    className={value === filter ? "active" : undefined}
    onClick={() => onChange(filter)}
  >
    <NavText>{children}</NavText>
  </StyledFilterButton>
)

const StyledFilterButton = styled(OutlinedButton)`
  ${tw`relative z-10 h-auto py-1 overflow-hidden text-base rounded-full`}

  &::before {
    ${tw`absolute top-0 left-0 block w-full h-full opacity-0 bg-gradient-to-r transition-opacity from-gradient-alternate-pink-light to-gradient-alternate-purple-light`}
    content: "";
    z-index: -1;
  }

  &.active {
    ${tw`font-medium text-primary-600`}

    &::before {
      ${tw`opacity-100`}
    }
  }
`

const StyledOneCellRow = styled.tr`
  td {
    ${tw`flex items-center justify-center col-span-4`}
  }
`

const OneCellRow: React.FC = ({ children }) => (
  <StyledOneCellRow>
    <td colSpan={4}>{children}</td>
  </StyledOneCellRow>
)

const LoadingRow: React.FC = () => (
  <OneCellRow>
    <Loader />
  </OneCellRow>
)

const EmptyRow: React.FC = () => (
  <OneCellRow>No recipients match the selected filter.</OneCellRow>
)

const Recipient = styled.div`
  ${tw`mb-6 lg:mb-0`}

  h2 {
    ${tw`mb-1 text-xl font-medium`}
  }

  div {
    ${tw`text-gray-400`}
  }
`

interface SendingIndicatorProps {
  visible: boolean
  text: string
}
const SendingIndicator: React.FC<SendingIndicatorProps> = ({
  visible,
  text,
}) => (
  <StyledSendingIndicator className={visible ? "" : "hidden"}>
    {text}… <Loader />
  </StyledSendingIndicator>
)
const StyledSendingIndicator = styled.div`
  ${tw`flex items-center self-end order-first text-lg gap-2 text-primary-500 transition-opacity md:order-none md:self-auto`}

  &.hidden {
    ${tw`opacity-0`}
  }
`

const FilterRow = styled.div`
  ${tw`flex flex-col justify-between mb-8 md:flex-row gap-6`}
`
interface ActionsMenuProps {
  gift: TrackGiftRecipientsItems[0]["gift"]
  onCancel: (giftId: string) => void
  onCopy: (giftId: string) => void
}
const ActionsMenu: React.FC<ActionsMenuProps> = ({
  gift,
  onCancel,
  onCopy,
}) => {
  const actionsButtons: JSX.Element[] = []

  if (gift && ["CREATED", "OPENED"].includes(gift.status)) {
    actionsButtons.push(
      <button key="cancel" onClick={() => onCancel(gift.id)}>
        <XIcon />
        Cancel gift
      </button>,
    )
  }

  if (navigator.clipboard && gift) {
    actionsButtons.push(
      <button key="copy" onClick={() => onCopy(gift.id)}>
        <CopyIcon />
        Copy gift ID
      </button>,
    )
  }

  return (
    <ActionDropdownMenu
      trigger={
        <ActionsMenuTrigger>
          Actions
          <ChevronIcon />
        </ActionsMenuTrigger>
      }
      disabled={actionsButtons.length === 0}
    >
      <ActionMenuPanel>
        {actionsButtons.map((button) => (
          <ActionMenuItem key={button.key}>{button}</ActionMenuItem>
        ))}
      </ActionMenuPanel>
    </ActionDropdownMenu>
  )
}

const ActionDropdownMenu = styled(DropdownMenu)`
  ${tw`lg:w-auto`}
  width: calc(50% - .25rem);

  .trigger {
    ${tw`w-full`}
  }
`

const ActionsMenuTrigger = styled.div`
  ${tw`flex items-center justify-center h-10 px-3 text-sm text-gray-500 bg-gray-100 rounded-lg gap-3`}

  &:hover {
    ${tw`bg-primary-050 text-primary-600`}
  }

  .expanded & {
    ${tw`bg-primary-100 text-primary-600`}
  }

  .disabled & {
    ${tw`text-gray-300`}
  }

  svg {
    ${tw`transform scale-90 stroke-current stroke-1.5`}
  }
`

const ActionMenuPanel = styled(DropdownMenuPanel)`
  ${tw`right-0 top-11 shadow-primary-2xl`}
`

const ActionMenuItem = styled(DropdownMenuItem)`
  button {
    ${tw`flex items-center justify-start gap-4`}
  }

  svg {
    ${tw`w-5 transform scale-90`}
  }
`

const RecipientsTable = styled(RoundedTable)<{
  directSend?: boolean
  isSmartLink?: boolean
}>`
  tr {
    ${tw`transition-colors block lg:grid mb-14 lg:mb-0 rounded-lg lg:rounded-none shadow-1.5xl lg:shadow-none p-5 lg:p-0`}
    grid-template-columns: ${(props) =>
      props.isSmartLink
        ? "4fr 18rem minmax(0, 6fr) 8rem"
        : props.directSend
        ? "3fr 12rem 4fr minmax(0, 6fr) 9rem"
        : "4fr 13rem minmax(0, 6fr) 8rem"};
  }

  thead {
    ${tw`hidden lg:block`}
  }

  tbody tr:hover {
    ${tw`lg:bg-gray-50`}
  }

  tbody td {
    ${tw`py-0 lg:py-6`}
  }

  td:not(:last-child) {
    ${tw`lg:border-r`}
  }

  td,
  th {
    ${tw`px-0 lg:px-3 xl:px-6`}

    // Status, Actions
    &:nth-child(2),
    &:nth-child(4) {
      ${tw`lg:justify-center lg:text-center`}
    }
  }

  // Gift link, Actions
  td:nth-child(3),
  td:nth-child(4) {
    ${tw`inline lg:flex`}
  }

  th {
    font-weight: 500;
  }
`

const isPendingStatus = (status: GiftStatusWithExpired): boolean => {
  return !!PENDING_GIFT_STATUSES.find((s) => s === status)
}

export const giftStatus = (
  recipient: TrackGiftRecipientsItems[0],
  landingPageWithoutNotification: boolean | null = false,
  directSend: boolean | null = false,
): GiftStatusWithExpired => {
  const gift = recipient.gift

  if (!gift) {
    return landingPageWithoutNotification || directSend ? "pending" : "sending"
  }

  if (
    gift.isExpired &&
    (gift.status === GiftViewableStatus.CREATED ||
      gift.status === GiftViewableStatus.NOTIFIED ||
      gift.status === GiftViewableStatus.OPENED)
  ) {
    return "expired"
  }

  switch (gift.status) {
    case "NOTIFIED":
    case "CREATED":
      if (directSend) return "created"

      const isSelfSend = getSendViaStatus(recipient) === "self-send"

      if (isSelfSend) {
        return "self-send"
      } else if (gift.notifiedAt) {
        return "sent"
      } else if (landingPageWithoutNotification) {
        return "created"
      } else {
        return "pending"
      }
    case "OPENED":
      if (directSend) return "created"
      return "opened"
    case "ACCEPTED":
    case "SHIPPED":
      return "shipped"
    case "PENDING_PAYMENT":
    case "PAID":
    case "ORDERED":
      if (directSend) return "created"
      return "accepted"
    case "FAILED":
      return "failed"
    case "DELIVERED":
      return "delivered"
    case "PENDING_USER_APPROVAL":
      return "pending_user_approval"
    default:
      return "failed"
  }
}

const RecipientsContainer = styled.div`
  &.withLandingPage {
    ${tw`p-6 pt-0 lg:pt-6`}
  }

  &.withoutLandingPage {
    ${tw`p-6 mt-20`}
  }
`

const Container = tw.div`
  w-screen max-w-[1440px] mx-auto mt-4 lg:mt-12
`

const GIFT_CANCEL = gql`
  mutation Track_GiftCancel($id: ID!) {
    giftCancel(id: $id) {
      ok
      error
      refundedCreditAmount
    }
  }
`

export default Recipients
