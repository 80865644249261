import Drawer from "@mui/material/Drawer"
import React, { Dispatch, SetStateAction, useEffect, useState } from "react"

import DrawerCloseButton from "@/common/DrawerCloseButton"
import Store from "@/store/Store"
import { onSelectProductIDType } from "@/store/types"

interface AutogiftSelectGiftsDrawerProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  onSelectProductID: onSelectProductIDType
}

const AutogiftSelectGiftsDrawer: React.FC<AutogiftSelectGiftsDrawerProps> = ({
  open,
  setOpen,
  onSelectProductID,
}) => {
  const [openDrawer, setOpenDrawer] = useState(false)

  const openAutogiftDrawer = () => {
    setOpenDrawer(true)
  }

  // close drawer and hide child components
  const closeAutogiftDrawer = () => {
    setOpenDrawer(false)

    // To reset the open prop to false when closing the drawer
    // This must be called here also since it doesn't get called on the onClose callback when using this method
    // to close the drawer
    setOpen(false)
  }

  // when the open prop changes, open / close the drawer based on the prop
  // this will allow for opening/closing the drawer from outside the component
  useEffect(() => {
    if (open) {
      openAutogiftDrawer()
    } else {
      closeAutogiftDrawer()
    }
  }, [open])

  return (
    <Drawer
      anchor="bottom"
      open={openDrawer}
      onClose={() => {
        // To reset the open prop to false when closing the drawer
        setOpen(false)
      }}
    >
      <div tw="flex flex-col items-stretch h-[100vh]">
        <div tw="bg-gray-100">
          <div tw="flex flex-row items-center justify-center px-6 leading-6 text-xl h-16">
            <div>Select a gift for autogift</div>
            <div tw="flex items-center ml-auto">
              <DrawerCloseButton onClick={closeAutogiftDrawer} />
            </div>
          </div>
        </div>
        <div tw="overflow-y-scroll flex-1" className="autogift-store">
          <Store
            visible={true}
            isEmbedded={true}
            onSelectProductID={onSelectProductID}
            useVirtualUrl={true}
            caller="autogift"
          />
        </div>
      </div>
    </Drawer>
  )
}

export default AutogiftSelectGiftsDrawer
