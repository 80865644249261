import { track } from "../../../common/analytics"
import RoundedButton from "../../../common/RoundedButton"
import staticAssetUrl from "../../../common/staticAssetUrl"
import Section, {
  SectionContainer,
  SectionContent,
  SectionHeader,
} from "../Section"

export default function Automate() {
  return (
    <SectionContainer>
      <Section tw="bg-[#f9f7ff]">
        <SectionHeader tw="text-[#7a53c3] max-w-[450px] mx-auto">
          Automate gifts that deliver delight.
        </SectionHeader>
        <SectionContent>
          <p>
            The <strong tw="font-semibold">Automation API</strong> lets you
            automatically send gifts to users or customers using triggers from
            your business systems.
          </p>
          <p>
            You can build direct integrations with the REST API, or connect with
            thousands of apps on Zapier.
          </p>
        </SectionContent>
        <div tw="flex flex-col md:flex-row items-stretch justify-center gap-4 pt-8 md:px-6">
          <RoundedButton
            variant="primary"
            element="a"
            href="https://zapier.com/apps/goody/integrations"
            target="_blank"
            rel="noopener"
            onClick={() =>
              track("Business Marketing - Automation API - Click Zapier")
            }
          >
            Connect with Zapier
          </RoundedButton>
          <RoundedButton
            variant="secondary"
            element="a"
            href="https://developer.ongoody.com"
            tw="font-medium"
            target="_blank"
            rel="noopener"
            onClick={() =>
              track("Business Marketing - Automation API - Click Docs")
            }
          >
            Read API Docs
          </RoundedButton>
        </div>
        <div tw="pt-10 -mx-4">
          <img
            src={staticAssetUrl("static/web/landing/api/automation.png")}
            tw="w-[568px] mx-auto"
          />
        </div>
      </Section>
    </SectionContainer>
  )
}
