import { gql, useMutation } from "@apollo/client"
import { isEmpty } from "lodash-es"
import React, { useEffect, useState } from "react"
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  DropResult,
  Droppable,
  NotDraggingStyle,
  // @ts-ignore
} from "react-beautiful-dnd"

import { useCustomStore } from "./hooks"
import StoreOption from "./StoreOption"

import {
  CustomStoreFullFragment,
  CustomStoreOptionListItemFragment,
  Store_CustomStores_ReorderCustomStoreOptionsMutation,
  Store_CustomStores_ReorderCustomStoreOptionsMutationVariables,
} from "@/types/graphql-types"

interface GiftListProps {
  customStore: CustomStoreFullFragment | null
  refetchCustomStore: Function
  isInsideGiftsDrawer?: boolean
}

const GiftList: React.FC<GiftListProps> = ({
  customStore,
  refetchCustomStore,
  isInsideGiftsDrawer,
}) => {
  const { formFields } = useCustomStore()

  const [itemList, setItemList] = useState(
    customStore?.optionList ?? formFields.optionList,
  )

  useEffect(() => {
    setItemList(customStore?.optionList ?? formFields.optionList)
  }, [customStore?.optionList, formFields.optionList])

  const [reorderCustomStoreOptions] = useMutation<
    Store_CustomStores_ReorderCustomStoreOptionsMutation,
    Store_CustomStores_ReorderCustomStoreOptionsMutationVariables
  >(REORDER_CUSTOM_STORE_OPTIONS)

  const reorder = (
    list: CustomStoreOptionListItemFragment[],
    startIndex: number,
    endIndex: number,
  ) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    handleReorderCustomStoreOptions(result)

    return result
  }

  const handleReorderCustomStoreOptions = async (
    list: CustomStoreOptionListItemFragment[],
  ) => {
    if (!customStore?.id) {
      return
    }

    const { data } = await reorderCustomStoreOptions({
      variables: {
        customStoreID: customStore.id,
        customStoreOptionIDs: list.map((option) => option.id),
      },
    })

    if (data?.customStoreReorderOptions?.ok) {
      !!refetchCustomStore && refetchCustomStore()
    }
  }

  const grid = 0.75

  const getItemStyle = (
    isDragging: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
  ) => ({
    userSelect: "none",
    margin: `${grid}rem 0 0 0`,

    // Styles we need to apply on draggables
    ...draggableStyle,
  })

  const getListStyle = (_isDraggingOver: boolean) => ({})

  const onDragEnd = (result: DropResult) => {
    // Dropped outside the list
    if (!result.destination || !itemList) {
      return
    }

    const items = reorder(
      itemList,
      result.source.index,
      result.destination.index,
    )

    setItemList(items)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {!isEmpty(itemList) && (
        <Droppable droppableId="droppable">
          {
            // @ts-ignore
            (provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {itemList.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id}
                    index={index}
                    isDragDisabled={isInsideGiftsDrawer}
                  >
                    {
                      // @ts-ignore
                      (provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                          )}
                        >
                          <StoreOption
                            customStoreID={customStore?.id}
                            key={item.id}
                            customStoreOption={item}
                            refetchCustomStore={refetchCustomStore}
                          />
                        </div>
                      )
                    }
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )
          }
        </Droppable>
      )}
    </DragDropContext>
  )
}

const REORDER_CUSTOM_STORE_OPTIONS = gql`
  mutation Store_CustomStores_ReorderCustomStoreOptions(
    $customStoreID: ID!
    $customStoreOptionIDs: [ID!]
  ) {
    customStoreReorderOptions(
      customStoreId: $customStoreID
      customStoreOptionIds: $customStoreOptionIDs
    ) {
      ok
      errors
    }
  }
`

export default GiftList
