import React from "react"
import tw, { css, styled } from "twin.macro"

import { ReactComponent as CanceledCalendar } from "../../assets/icons/canceled-calendar.svg"
import { ReactComponent as CheckedCalendar } from "../../assets/icons/checked-calendar.svg"
import { ReactComponent as GiftIcon } from "../../assets/icons/gift.svg"

import { gift_meeting_setting } from "@/types/graphql-types"

const CalendarGiftSettings = ({
  setGiftSetting,
  giftSetting,
}: {
  setGiftSetting: (
    value: gift_meeting_setting,
  ) => void | React.SetStateAction<string>
  giftSetting: string | null
}) => {
  return (
    <>
      <GiftSettingButton
        selected={giftSetting === gift_meeting_setting.NO_MEETING}
        onClick={() => setGiftSetting(gift_meeting_setting.NO_MEETING)}
        tw="rounded-t-lg mb-[-1px]"
      >
        <span tw="font-medium">
          <CanceledCalendar tw="stroke-current inline" />
          No Meeting Calendar
        </span>
      </GiftSettingButton>
      <GiftSettingButton
        onClick={() => setGiftSetting(gift_meeting_setting.GIFT_FIRST)}
        selected={giftSetting === gift_meeting_setting.GIFT_FIRST}
      >
        <span>
          <GiftIcon tw="stroke-current inline" />
          <span tw="font-medium">Gift First</span> — Show meeting calendar after
          gift is accepted.
        </span>
      </GiftSettingButton>
      <GiftSettingButton
        onClick={() => setGiftSetting(gift_meeting_setting.MEETING_REQUIRED)}
        selected={giftSetting === gift_meeting_setting.MEETING_REQUIRED}
        tw="rounded-b-lg"
      >
        <span>
          <CheckedCalendar tw="stroke-current inline mt-[-1px]" />
          <span tw="font-medium">Require Meeting</span> — Meeting must be
          scheduled to claim gift
        </span>
      </GiftSettingButton>
    </>
  )
}

const selectedCss = css`
  background: #f9f7ff;
  & > span {
    background: linear-gradient(to right, #621df1 19%, #8c6aff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  svg {
    color: #8c6aff;
  }
  border-color: #b3a1ff;
  z-index: 10;
`

const GiftSettingButton = styled.button<{ selected?: boolean }>`
  ${tw`text-base leading-[22.4px] p-4 relative z-0 border-solid border border-[#eceef1] w-full hover:bg-gray-50 active:bg-gray-100 transition-colors`}
  svg {
    ${tw`mr-4`}
    color: #838A97;
  }
  text-align: start;
  ${({ selected }) => (selected ? selectedCss : ``)}
`

export default CalendarGiftSettings
