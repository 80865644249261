import Drawer from "@mui/material/Drawer"
import React from "react"

import AutogiftConfig from "./AutogiftConfig"

interface Props {
  open: boolean
  onClose: () => void
  contactListID: string
  contactListName: string | undefined
}

const AutogiftConfigDrawer: React.FC<Props> = ({
  open,
  onClose,
  contactListID,
  contactListName,
}) => {
  return (
    <>
      <Drawer anchor="right" open={open} onClose={onClose}>
        {open && <AutogiftConfig onClose={onClose} />}
      </Drawer>
    </>
  )
}

export default AutogiftConfigDrawer
