import "twin.macro"
import { Flow } from "@frigade/js"
import React, { useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import tw, { styled } from "twin.macro"

import { track } from "@/common/analytics"
import { useGiftCart } from "@/common/hooks/giftData"
import Content from "@/onboarding/ChecklistUI/Content"
import Header from "@/onboarding/ChecklistUI/Header"
import Progress from "@/onboarding/ChecklistUI/Progress"
import useAutoComplete from "@/onboarding/ChecklistUI/useAutoComplete"

interface Props {
  flow: Flow
}

const USER_MINIMIZED_LOCALSTORAGE_KEY =
  "__goody_onboarding_checklist_user_minimized"
const USER_MINIMIZED_LOCALSTORAGE_VALUE = "true"

// When a checklist is user-minimized:
// 1) On page load, the checklist will be minimized by default
// 2) We do not automatically maximize it when the user is on a non-Browse page
//    (see below for more info)
let userMinimized =
  localStorage.getItem(USER_MINIMIZED_LOCALSTORAGE_KEY) ===
  USER_MINIMIZED_LOCALSTORAGE_VALUE

export default function ChecklistUI({ flow }: Props) {
  const location = useLocation()
  const pathname = location.pathname
  const { cartQuantity } = useGiftCart()

  const [dismissConfirmationVisible, setDismissConfirmationVisible] =
    useState(false)

  // Remember whether the checklist is user-minimized in localStorage
  // and also minimize it if we are not on the send page
  const [minimized, setMinimized] = useState(userMinimized)

  const autoMinimizedOnBrowse = useRef(false)

  // When the user is on the Browse page when the cart is empty, the onboarding
  // checklist blocks part of the page. As a result, we automatically minimize
  // the checklist on the Browse page. Once they add something to their cart,
  // or they go to a non-Browse page, we automatically maximize it again, unless
  // the user has user-minimized it, in which case it stays minimized.
  //
  // In other words, if a user specifically minimized the checklist before they
  // reached the Browse page, it will not be automatically maximized again when
  // the user leaves the Browse page or adds something to their cart.
  useEffect(() => {
    const isBrowsePage = pathname.startsWith("/browse")
    const cartIsEmpty = cartQuantity === 0

    // On Browse, we minimize the checklist if the cart is empty
    if (isBrowsePage && cartIsEmpty) {
      // Only happens once when a user goes to Browse for the first time after
      // being on a non-Browse page, otherwise they won't be able to open the
      // checklist
      if (!autoMinimizedOnBrowse.current) {
        setMinimized(true)
        autoMinimizedOnBrowse.current = true
      }
    } else if (minimized && !userMinimized) {
      // Otherwise, if the checklist is minimized, we maximize it, unless the
      // user has minimized it
      setMinimized(false)
    }

    // When the user leaves the Browse page, we clear the auto-minimized state
    if (!isBrowsePage && autoMinimizedOnBrowse.current) {
      autoMinimizedOnBrowse.current = false
    }
  }, [pathname, minimized, cartQuantity])

  function userMinimize() {
    setMinimized(true)
    userMinimized = true
    localStorage.setItem(
      USER_MINIMIZED_LOCALSTORAGE_KEY,
      USER_MINIMIZED_LOCALSTORAGE_VALUE,
    )

    track("Business - Onboarding Checklist - Minimize")
  }

  function userMaximize() {
    setMinimized(false)
    userMinimized = false
    localStorage.removeItem(USER_MINIMIZED_LOCALSTORAGE_KEY)

    track("Business - Onboarding Checklist - Maximize")
  }

  function toggleUserMinimized() {
    if (minimized) {
      userMaximize()
    } else {
      userMinimize()
    }
  }

  const currentStep = flow?.getNumberOfCompletedSteps() || 0
  const totalSteps = flow?.getNumberOfAvailableSteps() || 1

  useAutoComplete(flow)

  // When minimized, close the dismiss confirmation so if a user clicks the
  // "hide" button, toggles to be shown correctly when the checklist is
  // maximized
  useEffect(() => {
    if (minimized && dismissConfirmationVisible) {
      setDismissConfirmationVisible(false)
    }
  }, [minimized, dismissConfirmationVisible])

  useEffect(() => {
    track("Business - Onboarding Checklist - Show")
  }, [])

  return (
    <ChecklistContainer
      isClickable={minimized}
      onClick={() => {
        if (minimized) {
          userMaximize()
        }
      }}
    >
      <Header
        minimized={minimized}
        toggleMinimized={toggleUserMinimized}
        toggleDismissConfirmation={() =>
          setDismissConfirmationVisible(!dismissConfirmationVisible)
        }
      />
      <Content
        flow={flow}
        minimized={minimized}
        dismissConfirmationVisible={dismissConfirmationVisible}
        setDismissConfirmationVisible={setDismissConfirmationVisible}
      />
      <Progress
        currentStep={currentStep}
        totalSteps={totalSteps}
        minimized={minimized}
      />
    </ChecklistContainer>
  )
}

const ChecklistContainer = styled.div<{ isClickable: boolean }>`
  ${tw`hidden md:flex flex-col rounded-[10px] border bg-white fixed z-20 bottom-5 right-5 border border-gray-200 transition-all`};
  width: 270px;
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.02),
    0 1px 4px 0 rgba(0, 0, 0, 0.03);

  ${({ isClickable }) =>
    isClickable && tw`cursor-pointer hover:bg-gray-050 active:bg-gray-100`};
`
