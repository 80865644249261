import React from "react"
import tw, { styled } from "twin.macro"

import GradientButton from "./GradientButton"
import { ReactComponent as ArrowRight } from "../assets/icons/arrow-right.svg"
import { ReactComponent as GoodyLarge } from "../assets/images/goody-large.svg"

const IframeRedirect = () => {
  return (
    <div tw="w-full h-full absolute flex items-center justify-center bg-white">
      <IframeRedirectContainer>
        <GoodyLarge />
        <div tw="mt-9 text-primary-500 font-semibold">Welcome to Goody</div>
        <div tw="mt-3 text-2xl text-center" css="max-width: 300px;">
          To send a gift, please first sign in on Goody’s website.
        </div>
        <GradientButton
          tw="mt-9"
          onClick={() => {
            // @ts-ignore
            if (document.requestStorageAccess) {
              document // @ts-ignore
                .requestStorageAccess()
                .then(
                  () => window.location.reload(),
                  () =>
                    // Always open if we fail
                    // requestStorageAccess doesn't tell us WHY we failed
                    window.open(
                      "https://www.ongoody.com/business/signin",
                      "_blank",
                    ),
                )
            }
          }}
        >
          Sign In
          <ArrowRight tw="ml-8" />
        </GradientButton>
      </IframeRedirectContainer>
    </div>
  )
}

export default IframeRedirect

const IframeRedirectContainer = styled.div`
  /* Rectangle 1 */
  ${tw`bg-white flex items-center flex-col w-full h-full rounded-xl justify-center`}

  max-width: 502px;
  max-height: 408px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
`
