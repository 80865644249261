import { useQuery } from "@apollo/client"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import Modal from "react-modal"
import tw from "twin.macro"

import {
  ExternalGiftOptionDisplayProps,
  GiftOptionDisplay,
} from "./GiftOptionDisplay"
import { GIFT_OPTION_BY_SLUG_QUERY } from "./graphql/GiftOptionBySlugQuery"
import { isFullData } from "./utils/isFullData"
import { useShippingCountriesSelector } from "../hooks/useShippingCountriesSelector"
import { generateMetaDescription, useBrowseUrls } from "../StoreHelpers"

import AgeVerificationModal from "@/common/AgeVerificationModal"
import { clickstreamEvent } from "@/common/clickstream"
import { modalStyle } from "@/common/modal"
import { WithContext, withContext } from "@/common/requestContext"
import StoreSEO from "@/common/StoreSEO"
import SwipeableBottomSheet from "@/common/SwipeableBottomSheet"
import ModalCloseButton from "@/store/components/ModalCloseButton"
import ModalCloseHint, {
  hideModalCloseHint,
} from "@/store/components/ModalCloseHint"
import { ModalContainer, Underlay } from "@/store/components/ModalContainer"
import {
  ImageFragment,
  Store_GiftOptionPreviewFragment,
  Store_GiftOption_GiftOptionSlugQuery,
  Store_GiftOption_GiftOptionSlugQueryVariables,
} from "@/types/graphql-types"

interface Props extends ExternalGiftOptionDisplayProps {
  giftOptionPreview?: Store_GiftOptionPreviewFragment | null
  imagesCached: Set<string>
  // Whether we should avoid overflowing the container.
  noOverflow: boolean
  resetGiftOptionID: () => void
  isGiftCardInCustomStore?: (
    productID: string,
    giftCardAmount: number | null,
  ) => boolean
  storeVisible: boolean
  isEmbeddedCustomStore?: boolean
  onClose: () => void
  isProductOpen: boolean
}

// Displays a gift option sheet.
// Contains a list of products (or just one product).
// Loads modals for product variants and expanding product images.
const GiftOptionModal = ({
  giftOptionPreview,
  giftOptionSlug,
  imagesCached,
  noOverflow,
  resetGiftOptionID,
  customStoreGiftOptionButton,
  storeVisible,
  onClose,
  isEmbeddedCustomStore,
  isProductOpen,
  ...externalGiftOptionDisplayProps
}: Props) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const underlayRef = useRef<HTMLDivElement>(null)

  // used only for CSS transition
  const { generateBrowseUrl } = useBrowseUrls()

  const { selectedShippingCountry } = useShippingCountriesSelector()

  const giftOptionDisplayContainerRef = useScrollTop(giftOptionSlug)
  const [showSheet, setShowSheet] = useState(true)

  // Expand image modal
  const [expandImageOpen, setExpandImageOpen] = useState(false)
  const [expandImage, setExpandImage] = useState<ImageFragment | null>(null)

  // Load full data; will use cache if prefetched
  const { data, loading } = useQuery<
    Store_GiftOption_GiftOptionSlugQuery,
    WithContext<Store_GiftOption_GiftOptionSlugQueryVariables>
  >(GIFT_OPTION_BY_SLUG_QUERY, {
    variables: withContext(
      {
        slug: giftOptionSlug,
        shippingCountry: selectedShippingCountry.code,
      },
      {
        ...(isEmbeddedCustomStore && { storeType: "custom_store" }),
      },
    ),
  })

  const products = useMemo(
    () => data?.giftOption?.products || [],
    [data?.giftOption?.products],
  )

  useEffect(() => {
    document.querySelector("body")?.classList.add("no-scroll-mobile")

    return () => {
      document.querySelector("body")?.classList.remove("no-scroll-mobile")
    }
  }, [])

  useEffect(() => {
    // Preload product images
    products.forEach((product) => {
      product.productImages.forEach((productImage) => {
        const imageUrl = productImage.imageLarge.url

        if (!imagesCached.has(imageUrl)) {
          if (process.env.NODE_ENV === "development") {
            console.log(
              `Prefetching product image: ${productImage.imageLarge.url}`,
            )
          }
          const image = new Image()
          image.src = imageUrl
          imagesCached.add(imageUrl)
        }
      })
    })
  }, [products, imagesCached])

  // If the full data is available, use it, otherwise load the preview.
  const giftOption = data?.giftOption || giftOptionPreview

  const defaultScrollContainer = useRef<HTMLDivElement>(null)
  const bottomSheetScrollContainer = useRef<HTMLDivElement>(null)

  const handleClose = useCallback(() => {
    contentRef.current?.classList?.add("scale-slide-out")
    underlayRef.current?.classList?.add("closing")
    setTimeout(() => {
      onClose()
    }, 200)
  }, [onClose])

  const giftOptionDisplayProps = {
    ...externalGiftOptionDisplayProps,
    customStoreGiftOptionButton,
    giftOption,
    giftOptionSlug,
    loading,
  }

  const canonicalPath = generateBrowseUrl({
    categorySlug: null,
    giftOptionSlug: isFullData(giftOption) ? giftOption.slug : null,
    pathRealm: "consumer",
  })

  const ogImage = giftOption?.primaryImage?.imageLarge?.url || null

  // Do not update the store SEO title if we are not on a gift option page
  // This is to prevent the title from changing when auto-opening a gift option on a category page
  const locationPathIncludesGiftOption =
    window.location.pathname.includes("gift-option")

  useEffect(() => {
    // Don't fire this when gift option is auto-opened on category page
    if (locationPathIncludesGiftOption && giftOption) {
      clickstreamEvent("store.gift_option", giftOption.id)
    }
  }, [giftOption?.id])

  // Handle user pressing the Escape key. Close sheet if open.
  const handleEscapeKey = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        // If PDP is open, let PDP handle the escape instead of us.
        if (isProductOpen) {
          return false
        }

        e.stopPropagation()
        handleClose()
        return false
      }
    },
    [handleClose, isProductOpen],
  )

  useEffect(() => {
    // Set useCapture to be true to prevent the Material Drawer's escape key
    // handler from running (which is used in Autogift configuration) if the PDP
    // sheet is currently open.
    document.addEventListener("keydown", handleEscapeKey, true)

    return () => {
      document.removeEventListener("keydown", handleEscapeKey, true)
    }
  }, [handleEscapeKey])

  if (!giftOption) {
    return null
  }

  return (
    <>
      <AgeVerificationModal verifyAge={giftOption.isAlcohol} />
      {storeVisible && locationPathIncludesGiftOption && (
        <StoreSEO
          canonicalPath={canonicalPath}
          metaDescription={generateMetaDescription(
            data?.giftOption?.name,
            data?.giftOption?.seo?.metaDescription || giftOption.description,
          )}
          ogImage={ogImage}
          titlePrefix={data?.giftOption?.seo?.metaTitle || giftOption.name}
        />
      )}
      <Underlay
        tw="fixed inset-0"
        onClick={(e) => {
          // Don't trigger for inside elements, only underlay.
          if (e.target === e.currentTarget) {
            handleClose()
            hideModalCloseHint()
          }
        }}
        ref={underlayRef}
      >
        <ModalContainer ref={contentRef}>
          <div tw="overflow-y-scroll h-full">
            <ModalCloseHint />
            <ModalCloseButton onClick={handleClose} />
            <GiftOptionDisplayContainer ref={giftOptionDisplayContainerRef}>
              <GiftOptionDisplay
                scrollContainerRef={defaultScrollContainer}
                assignScrollContainerRef
                {...giftOptionDisplayProps}
                giftOption={giftOption}
                key={giftOptionSlug}
              />
            </GiftOptionDisplayContainer>
          </div>
        </ModalContainer>
        <SwipeableBottomSheet
          swipeableViewsProps={{
            containerStyle: {
              borderTopLeftRadius: 16,
              borderTopRightRadius: 16,
              margin: "0 auto",
              maxWidth: 500,
              overflow: "hidden",
              transition: "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s",
            },
          }}
          open={showSheet}
          fullScreen={true}
          marginTop={135}
          onChange={(isOpen: boolean) => {
            setShowSheet(isOpen)
            setTimeout(() => {
              resetGiftOptionID()
            }, 350)
          }}
          tw="lg:hidden"
          bodyRef={bottomSheetScrollContainer}
        >
          <GiftOptionDisplay
            scrollContainerRef={bottomSheetScrollContainer}
            key={giftOptionSlug}
            {...giftOptionDisplayProps}
            giftOption={giftOption}
          />
        </SwipeableBottomSheet>
      </Underlay>
      <Modal
        isOpen={expandImageOpen}
        closeTimeoutMS={500}
        onRequestClose={() => {
          setExpandImageOpen(false)
        }}
        onAfterClose={() => {
          setExpandImage(null)
        }}
        shouldCloseOnOverlayClick={true}
        style={modalStyle}
      >
        {expandImage?.url && (
          <div
            className="modal-content modal-content-wide"
            key={expandImage.url}
          >
            <img
              src={expandImage.url}
              style={{
                width: "100%",
                display: "block",
              }}
              alt="Expanded product"
            />
          </div>
        )}
      </Modal>
    </>
  )
}

const useScrollTop = (giftOptionId: string) => {
  const [lastGiftOptionId, setLastGiftOptionId] = useState<string | null>(null)
  const giftOptionDisplayContainerRef = useRef(null)
  const giftOptionDisplayContainerEl =
    giftOptionDisplayContainerRef.current as HTMLDivElement | null
  const giftOptionEl = giftOptionDisplayContainerEl?.children[0].children[0]

  if (giftOptionEl && giftOptionId !== lastGiftOptionId) {
    giftOptionEl.scrollTop = 0
    setLastGiftOptionId(giftOptionId)
  }

  return giftOptionDisplayContainerRef
}

const GiftOptionDisplayContainer = tw.div`
  flex lg:rounded-2xl flex-col bg-white w-full transform transition-all ease-out
`

export default GiftOptionModal
