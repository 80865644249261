import { Flow } from "@frigade/js"
import { useEffect, useMemo } from "react"

import { track } from "@/common/analytics"
import { useGlobalState } from "@/common/GlobalState"
import { useCurrentGift } from "@/common/hooks/currentGift"
import { useGiftCart } from "@/common/hooks/giftData"
import { getNonEmptyRecipientRows } from "@/common/recipient"
import { isBlank } from "@/common/utilities"
import { BatchSendMethod } from "@/types/graphql-types"

const STEP_1_ID = "join-goody"
const STEP_2_ID = "select-gift"
const STEP_3_ID = "personalize-gift"

let disableAutoComplete = false

// Declaratively update the onboarding checklist based on currentGift
export default function useAutoComplete(flow: Flow) {
  const [currentGift] = useCurrentGift()
  const [recipients] = useGlobalState("recipients")
  const { cartQuantity } = useGiftCart()

  const step1Completed = flow.steps.get(STEP_1_ID)?.$state.completed || false
  const step2Completed = flow.steps.get(STEP_2_ID)?.$state.completed || false
  const step3Completed = flow.steps.get(STEP_3_ID)?.$state.completed || false

  function trackStepComplete(step: number) {
    track("Business - Onboarding Checklist - Complete Step", {
      step,
    })
  }

  // Step 1 is for free
  useEffect(() => {
    if (!step1Completed) {
      flow.steps.get(STEP_1_ID)?.complete()
    }
  }, [step1Completed, flow.steps])

  // Step 2 is completed when an item is in cart
  useEffect(() => {
    if (disableAutoComplete) {
      return
    }

    if (cartQuantity > 0 && !step2Completed) {
      flow.steps.get(STEP_2_ID)?.complete()
      trackStepComplete(2)
    } else if (cartQuantity === 0 && step2Completed) {
      flow.steps.get(STEP_2_ID)?.reset()
    }
  }, [disableAutoComplete, step2Completed, cartQuantity, flow.steps])

  const potentialRecipients = useMemo(() => {
    return getNonEmptyRecipientRows(recipients)
  }, [recipients])

  // Step 3 is completed when a from name, message, card, and recipient are set
  // (see exceptions below)
  useEffect(() => {
    if (disableAutoComplete) {
      return
    }

    const infoCompleted =
      !isBlank(currentGift.fromName) &&
      !isBlank(currentGift.message) &&
      currentGift.card

    const recipientCompleted = potentialRecipients > 0

    // Smart links don't require recipients
    const isSmartLink = currentGift.isSmartLink

    // Direct send no notifications doesn't require a message
    const isDirectSendNoNotifications =
      currentGift.sendMethod === BatchSendMethod.direct_send &&
      !currentGift.landingPageSendNotifs

    const filled =
      (recipientCompleted || isSmartLink) &&
      (infoCompleted || isDirectSendNoNotifications)

    if (filled && !step3Completed) {
      flow.steps.get(STEP_3_ID)?.complete()
      trackStepComplete(3)
    } else if (!filled && step3Completed) {
      flow.steps.get(STEP_3_ID)?.reset()
    }
  }, [
    disableAutoComplete,
    currentGift,
    step3Completed,
    flow.steps,
    potentialRecipients,
  ])
}

// After sending a gift batch, we reset currentGift, which causes the state of
// the steps to be reset. We could check flow.isCompleted, but there are some
// race conditions that could happen depending on whether useAutoComplete() is
// rendered after currentGift is reset.
//
// Instead, we use a variable in this module to disable auto-completion. This
// function is called from useSend's sendPlusGift to disable autocomplete after
// a batch is sent.
export function disableOnboardingFlowAutoComplete() {
  disableAutoComplete = true
}
