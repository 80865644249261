import { gql, useApolloClient, useQuery } from "@apollo/client"
import { useEffect, useMemo, useState } from "react"
import tw, { styled } from "twin.macro"

import AutogiftSelectButton from "./AutogiftSelectButton"
import { useAutogiftData } from "./hooks"
import { ReactComponent as AutogiftIcon } from "../../assets/icons/autogift-light-purple.svg"
import calendarImage from "../../assets/images/autogift/autogift-index-calendar.png"
import airplaneImage from "../../assets/images/autogift/autogift-index-paper-airplane.png"
import sliderImage from "../../assets/images/autogift/autogift-index-slider.png"
import DrawerCloseButton from "../../common/DrawerCloseButton"
import { useGlobalState } from "../../common/GlobalState"
import { useFeatureAccess } from "../../common/hooks/featureAccess"

import { GIFT_SETTINGS_FRAGMENT } from "@/graphql/GiftSettingsFragment"
import {
  AutogiftRuleAnchorDateTypeEnum,
  AutogiftRuleStatus,
  EventKind,
} from "@/types/graphql-types"
import {
  Contacts_AutogiftsQuery,
  Contacts_AutogiftsQueryVariables,
  StandardAutogiftRuleFragment,
} from "@/types/graphql-types"

interface AutogiftIndexProps {
  contactListId: string
  contactListName: string | undefined
  contactListHRISSynced: boolean
  onSelectAutogiftRule: (rule: StandardAutogiftRuleFragment) => void
  onSelectNewAutogift: () => void
  closeAutogiftDrawer: () => void
}

const AutogiftIndex = ({
  contactListId,
  contactListName,
  contactListHRISSynced,
  closeAutogiftDrawer,
  onSelectAutogiftRule,
  onSelectNewAutogift,
}: AutogiftIndexProps) => {
  const { hasFeature } = useFeatureAccess()
  const hasOnboardingAutogift =
    hasFeature("onboarding_autogift") &&
    hasFeature("plus_hr_integration") &&
    contactListHRISSynced

  const [currentAutogiftRule, setCurrentAutogiftRule] = useGlobalState(
    "currentAutogiftRule",
  )
  const [birthdayAutogiftRule, setBirthdayAutogiftRule] =
    useState<StandardAutogiftRuleFragment | null>(null)
  const [workAnniversaryAutogiftRule, setWorkAnniversaryAutogiftRule] =
    useState<any>(null)
  const [onboardingAutogiftRule, setOnboardingAutogiftRule] =
    useState<StandardAutogiftRuleFragment | null>(null)

  // To manage loading the contacts with events today data
  const [loadingNewAutogiftRule, setLoadingNewAutogiftRule] =
    useState<boolean>(false)
  const [newAutogiftRuleEventType, setNewAutogiftRuleEventType] =
    useState<EventKind | null>(null)

  const loadContactsWithEventToday = useApolloClient()

  const { data, refetch } = useQuery<
    Contacts_AutogiftsQuery,
    Contacts_AutogiftsQueryVariables
  >(AUTOGIFTS_QUERY, {
    variables: {
      contactListId: contactListId,
    },
  })

  const autogiftRules = data?.workspace?.contactList?.autogiftRules

  const getAutogiftRule = (
    autogiftRules: StandardAutogiftRuleFragment[],
    eventType: string,
  ): StandardAutogiftRuleFragment | null => {
    if (!autogiftRules || autogiftRules.length < 1) {
      return null
    }

    // Sort autogift rules by status (active first) and then tenure (smallest
    // first), so that if there is a single active one anywhere in the list, it
    // will be selected.
    const statusAndTenureSortedAutogiftRules = [...autogiftRules].sort(
      (a, b) => {
        if (!a.tenureMin || !b.tenureMin) {
          return 0
        }

        if (a.status !== b.status) {
          if (
            a.status === AutogiftRuleStatus.ACTIVE &&
            b.status === AutogiftRuleStatus.PAUSED
          ) {
            return -1
          } else {
            return 1
          }
        }

        return a.tenureMin - b.tenureMin
      },
    )

    return (
      statusAndTenureSortedAutogiftRules.find(
        (rule) => rule.eventType === eventType,
      ) || null
    )
  }

  const { loadAutogiftRuleData, loadNewAutogiftRuleData } = useAutogiftData({
    onSelectAutogiftRule,
    onSelectNewAutogift,
  })

  // Load data for either a new or existing autogift rule
  const handleAutogiftRuleSelect = async (
    autogiftRule: StandardAutogiftRuleFragment | null,
    eventType: EventKind,
  ) => {
    if (autogiftRule !== null) {
      loadAutogiftRuleData(autogiftRule)
    } else {
      setLoadingNewAutogiftRule(true)
      setNewAutogiftRuleEventType(eventType)

      loadNewAutogiftRuleData(
        eventType.toLocaleLowerCase(),
        contactListName || "",
        contactListId,
      )
    }
  }

  // Runs query that returns an array of contacts that have an event today and saves that to global state
  const runContactsWithEventTodayQuery = async (
    contactListId: string,
    eventType: EventKind,
  ) => {
    const { data: contactsWithEventTodayData } =
      await loadContactsWithEventToday.query({
        query: CONTACTS_WITH_EVENT_TODAY_QUERY,
        variables: {
          contactListId: contactListId,
          eventType: eventType,
        },
      })

    const contactsWithEventToday =
      contactsWithEventTodayData?.workspace?.contactList?.contactsWithEventToday

    setCurrentAutogiftRule({
      ...currentAutogiftRule,
      contactsWithEventToday: contactsWithEventToday,
    })
  }

  const determineContactEventType = (autogiftRuleEventType: EventKind) => {
    if (autogiftRuleEventType === EventKind.ONBOARDING) {
      if (
        currentAutogiftRule.anchorDateType ===
        AutogiftRuleAnchorDateTypeEnum.start_date
      ) {
        return EventKind.START_DATE
      } else if (
        currentAutogiftRule.anchorDateType ===
        AutogiftRuleAnchorDateTypeEnum.added_to_hris_date
      ) {
        return EventKind.ADDED_TO_HRIS_DATE
      }
    }

    return autogiftRuleEventType
  }

  useEffect(() => {
    if (loadingNewAutogiftRule && newAutogiftRuleEventType) {
      const contactEventType = determineContactEventType(
        newAutogiftRuleEventType,
      )
      runContactsWithEventTodayQuery(contactListId, contactEventType)
      setLoadingNewAutogiftRule(false)
    }
  }, [loadingNewAutogiftRule])

  useEffect(() => {
    refetch({
      contactListId: contactListId,
    })
  }, [contactListId])

  useEffect(() => {
    // if the autogift rules have been loaded, assign the appropriate autogift rules if they exist
    if (autogiftRules) {
      const existingBirthdayAutogiftRule = getAutogiftRule(
        autogiftRules,
        "birthday",
      )

      if (existingBirthdayAutogiftRule) {
        setBirthdayAutogiftRule(existingBirthdayAutogiftRule)
      }

      const existingWorkAnniversaryAutogiftRule = getAutogiftRule(
        autogiftRules,
        "work_anniversary",
      )

      if (existingWorkAnniversaryAutogiftRule) {
        setWorkAnniversaryAutogiftRule(existingWorkAnniversaryAutogiftRule)
      }

      const existingOnboardingAutogiftRule = getAutogiftRule(
        autogiftRules,
        "onboarding",
      )

      if (existingOnboardingAutogiftRule) {
        setOnboardingAutogiftRule(existingOnboardingAutogiftRule)
      }
    }
  }, [autogiftRules])

  // Get the count of all work anniversary rules, and also only active ones.
  const [workAnniversaryRuleCountAll, workAnniversaryRuleCountActive] =
    useMemo(() => {
      // Only consider work anniversary rules.
      const filteredAutogiftRules =
        autogiftRules?.filter(
          (rule) => rule.eventType === "work_anniversary",
        ) || []

      // Translate into a True/False array where the boolean = active status.
      const workAnniversaryRules = filteredAutogiftRules?.map(
        (rule) => rule.status === AutogiftRuleStatus.ACTIVE,
      )

      return [
        // First val: total work anniversary rules
        workAnniversaryRules.length,

        // Second val: active work anniversary rules
        workAnniversaryRules.filter(Boolean).length,
      ]
    }, [autogiftRules])

  return (
    <div css={[tw`flex-1 flex flex-col`, { width: 848 }]}>
      <ViewContainer tw="py-9">
        <div>
          <div tw="flex flex-row w-full">
            <div>
              <div tw="text-primary-500 font-medium">{contactListName}</div>
              <div tw="flex flex-row">
                <div tw="flex justify-center items-center text-2xl font-medium">
                  Autogift
                </div>
                <div tw="flex justify-center items-center ml-3">
                  <AutogiftIcon css={{ opacity: 5 }} />
                </div>
              </div>
            </div>
            <div tw="ml-auto">
              <DrawerCloseButton onClick={closeAutogiftDrawer} />
            </div>
          </div>
        </div>
        <div tw="flex flex-row mt-12">
          <div
            css={[
              tw`rounded-xl mr-6 p-6 leading-6`,
              { backgroundColor: "#F9FAFB", flexBasis: "100%" },
            ]}
          >
            <div>
              <img src={calendarImage} />
            </div>
            <div tw="text-lg">
              <span tw="font-medium">Automatically send a gift </span>
              on someone’s birthday or work anniversary
            </div>
          </div>
          <div
            css={[
              tw`rounded-xl mr-6 p-6 leading-6`,
              { backgroundColor: "#F9FAFB", flexBasis: "100%" },
            ]}
          >
            <div>
              <img src={sliderImage} />
            </div>
            <div tw="text-lg">
              <span tw="font-medium">You can edit each gift</span> to change the
              product, message, or card, up until it’s sent
            </div>
          </div>
          <div
            css={[
              tw`rounded-xl p-6 leading-6`,
              { backgroundColor: "#F9FAFB", flexBasis: "100%" },
            ]}
          >
            <div>
              <img src={airplaneImage} />
            </div>
            <div tw="text-lg">
              <span tw="font-medium">We’ll remind you </span> one week before
              and 24 hours before the gift is sent
            </div>
          </div>
        </div>
        <div tw="mt-12" />
        <AutogiftSelectButton
          onClick={() => {
            handleAutogiftRuleSelect(birthdayAutogiftRule, EventKind.BIRTHDAY)
          }}
          autogiftRule={birthdayAutogiftRule}
          defaultType="birthday"
        />
        <div tw="mt-8" />
        <AutogiftSelectButton
          onClick={() => {
            handleAutogiftRuleSelect(
              workAnniversaryAutogiftRule,
              EventKind.WORK_ANNIVERSARY,
            )
          }}
          autogiftRule={workAnniversaryAutogiftRule}
          defaultType="work_anniversary"
          overrideActiveAdditionalText={`${workAnniversaryRuleCountActive} active tenure level${
            workAnniversaryRuleCountActive !== 1 ? "s" : ""
          } of ${workAnniversaryRuleCountAll} total`}
        />
        {hasOnboardingAutogift && (
          <div>
            <div tw="mt-8" />
            <AutogiftSelectButton
              onClick={() => {
                handleAutogiftRuleSelect(
                  onboardingAutogiftRule,
                  EventKind.ONBOARDING,
                )
              }}
              autogiftRule={onboardingAutogiftRule}
              defaultType="onboarding"
              overrideActiveAdditionalText=""
            />
          </div>
        )}
      </ViewContainer>
    </div>
  )
}

const ViewContainer = styled.div`
  ${tw`w-11/12 mx-auto`};
`

export const STANDARD_AUTOGIFT_RULE_FRAGMENT = gql`
  fragment StandardAutogiftRule on AutogiftRule {
    id
    contactList {
      id
      name
    }
    eventType
    status
    productList {
      productId
      priceEstimate {
        priceProduct
        priceShipping
        priceProcessingFee
        priceEstTaxLow
        priceEstTaxHigh
        priceEstTotalLow
        priceEstTotalHigh
        isFlexGift
      }
    }
    card {
      id
      image {
        url
      }
    }
    message
    fromName
    expireAtOption
    autopayPaymentMethodId
    autopayPaymentMethodName
    swapEnabled
    firstGiftSummary
    userPublicId
    tenureMin
    tenureMax
    contactsWithEventToday
    anchorDateSendOption
    anchorDateType
    anchorDateNumberOfDaysDelta
    internationalShippingTier
    swapType
    settings {
      ...GiftSettings
    }
  }
  ${GIFT_SETTINGS_FRAGMENT}
`

export const AUTOGIFTS_QUERY = gql`
  query Contacts_Autogifts($contactListId: ID!) {
    workspace {
      contactList(id: $contactListId) {
        autogiftRules {
          ...StandardAutogiftRule
        }
      }
    }
  }
  ${STANDARD_AUTOGIFT_RULE_FRAGMENT}
`

export const CONTACTS_WITH_EVENT_TODAY_QUERY = gql`
  query Autogift_ContactsWithEventToday(
    $contactListId: ID!
    $eventType: EventKind!
    $tenureMin: Int
    $tenureMax: Int
  ) {
    workspace {
      contactList(id: $contactListId) {
        contactsWithEventToday(
          eventType: $eventType
          tenureMin: $tenureMin
          tenureMax: $tenureMax
        )
      }
    }
  }
`

export default AutogiftIndex
