import { gql, useApolloClient } from "@apollo/client"
import { format } from "date-fns"
import { useHistory } from "react-router-dom"

import { ReactComponent as ReportsIcon } from "../../assets/icons/reports.svg"
import { useFeatureAccess } from "../../common/hooks/featureAccess"
import { ProPlanProtected } from "../../common/ProPlanModal"
import { ProTag } from "../../common/ProTag"
import { generateRealmPath } from "../../common/realm"
import TrackActionButton from "../../common/TrackActionButton"

import {
  Track_OpenInReportsButtonFirstGiftDateQuery,
  Track_OpenInReportsButtonFirstGiftDateQueryVariables,
} from "@/types/graphql-types"

const OpenInReportsButton = ({ giftBatchId }: { giftBatchId: string }) => {
  const client = useApolloClient()

  const { hasFeature } = useFeatureAccess()
  const history = useHistory()

  const handleClick = async () => {
    const res = await client.query<
      Track_OpenInReportsButtonFirstGiftDateQuery,
      Track_OpenInReportsButtonFirstGiftDateQueryVariables
    >({
      query: OPEN_IN_REPORTS_BUTTON_FIRST_GIFT_DATE_QUERY,
      variables: { id: giftBatchId },
    })

    const params = new URLSearchParams()
    params.set("filter_gift_batch", giftBatchId)

    if (res.data.workspace?.giftBatch?.firstGiftDate) {
      params.set("date_range", "custom")
      params.set("date_start", res.data.workspace.giftBatch.firstGiftDate)
      params.set("date_end", format(new Date(), "yyyy-MM-dd"))
    }

    const hash = params.toString()

    history.push(generateRealmPath("plus", "/reports#" + hash))
  }

  return (
    <>
      <ProPlanProtected feature="report_filters">
        <TrackActionButton
          onClick={handleClick}
          hasProTag={!hasFeature("pro_plan")}
        >
          <ReportsIcon tw="self-center mr-3 stroke-current" />
          Open in Reports
          <ProTag tw="ml-3 pointer-events-none" feature="report_filters" />
        </TrackActionButton>
      </ProPlanProtected>
    </>
  )
}

const OPEN_IN_REPORTS_BUTTON_FIRST_GIFT_DATE_QUERY = gql`
  query Track_OpenInReportsButtonFirstGiftDate($id: ID!) {
    workspace {
      giftBatch(id: $id) {
        id
        firstGiftDate
      }
    }
  }
`

export default OpenInReportsButton
