import React, { useEffect } from "react"
import useCollapse from "react-collapsed"
import { UseFormReturn } from "react-hook-form"
import tw from "twin.macro"

import CardFrom from "./CardFrom"
import CardMessage from "./CardMessage"
import CardSelect from "./CardSelect"
import DirectSendNotification from "./components/DirectSendNotification"
import { CurrentGiftSendForm } from "./sendTypes"
import { CurrentGift } from "../common/GlobalState"

import { useBusinessSend } from "@/common/hooks/send"
import SendErrorAlertBubble from "@/send/SendErrorAlertBubble"
import { BatchSendMethod } from "@/types/graphql-types"

interface CardAndMessageProps {
  currentGift: CurrentGift
  currentGiftForm: UseFormReturn<CurrentGiftSendForm>
  sendV3?: boolean
}

const CardAndMessage = ({
  currentGift,
  currentGiftForm,
  sendV3,
}: CardAndMessageProps) => {
  const isDirectSend = currentGift.sendMethod === BatchSendMethod.direct_send
  const { clearError } = useBusinessSend()

  // Expanded if not (direct send and notifications are disabled)
  // In other words, we only collapse if direct send and notifications are disabled
  const expanded = !(isDirectSend && !currentGift.landingPageSendNotifs)

  const { getCollapseProps, setExpanded } = useCollapse({
    isExpanded: expanded,
  })

  useEffect(() => {
    setExpanded(expanded)
  }, [expanded])

  return (
    <>
      <div
        tw="border border-primary-200 rounded-lg"
        css={[sendV3 && tw`border-gray-200`]}
      >
        {isDirectSend && <DirectSendNotification />}
        <div {...getCollapseProps()}>
          <div
            tw="flex flex-col sm:flex-row"
            css={[
              isDirectSend && tw`border-t border-primary-200`,
              isDirectSend && sendV3 && tw`border-gray-200`,
            ]}
          >
            <div tw="flex-1">
              <SendErrorAlertBubble
                text="Please enter who the gift is from"
                subtext="A from name is required."
                error="fromName"
                position="below"
                open={(errors) => errors.fromName && !errors.product}
              >
                <CardFrom
                  currentGiftForm={currentGiftForm}
                  onChange={() => clearError("fromName")}
                />
              </SendErrorAlertBubble>
              <SendErrorAlertBubble
                text="Please enter a message"
                subtext="A message for your gift is required."
                error="message"
                open={(errors) =>
                  errors.message && !errors.fromName && !errors.product
                }
              >
                <CardMessage
                  hidePersonalizationTags={currentGift.isSmartLink}
                  currentGiftForm={currentGiftForm}
                  sendV3={sendV3}
                  onChange={() => clearError("message")}
                />
              </SendErrorAlertBubble>
            </div>
            <CardSelect
              currentGift={currentGift}
              onChange={() => clearError("card")}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default CardAndMessage
