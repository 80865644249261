import { startCase } from "lodash-es"
import React, { useState } from "react"

import AutogiftConfigSwitch from "./AutogiftConfigSwitch"
import AutogiftSettings from "./AutogiftSettings"
import AutogiftUpcoming from "./AutogiftUpcoming"
import { ReactComponent as BirthdayIcon } from "../../assets/icons/autogift-birthday-default.svg"
import { ReactComponent as OnboardingIconDefault } from "../../assets/icons/autogift-onboarding-default.svg"
import { ReactComponent as WorkAnniversaryIcon } from "../../assets/icons/autogift-work-anniversary-default.svg"
import DrawerCloseButton from "../../common/DrawerCloseButton"
import { useGlobalState } from "../../common/GlobalState"

interface AutogiftConfigProps {
  onClose: () => void
}

const AutogiftConfig: React.FC<AutogiftConfigProps> = ({ onClose }) => {
  const [currentAutogiftRule] = useGlobalState("currentAutogiftRule")
  const [selectedSwitchOption, setSelectedSwitchOption] = useState("Settings")

  const onSelectSwitch = (selection: any) => {
    if (selection === "Settings") {
      setSelectedSwitchOption("Settings")
    } else {
      setSelectedSwitchOption("Upcoming")
    }
  }

  return (
    <div>
      <div css={{ width: 958 }}>
        <div
          tw="pt-4 px-8 pb-16 rounded-md"
          css={{
            backgroundColor: "#F9FAFB",
          }}
        >
          <div tw="absolute pt-8 pr-8 right-0 top-0">
            <DrawerCloseButton onClick={onClose} tw="ml-auto -mr-1" />
          </div>
          <div tw="flex flex-row justify-center items-center mt-8">
            <div tw="flex items-center absolute left-0 pl-8">
              <div
                tw="p-5 bg-white rounded-lg"
                css={{ boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.05)" }}
              >
                {currentAutogiftRule.eventType === "work_anniversary" ? (
                  <WorkAnniversaryIcon />
                ) : currentAutogiftRule.eventType === "birthday" ? (
                  <BirthdayIcon />
                ) : (
                  <OnboardingIconDefault />
                )}
              </div>
            </div>
            <div tw="text-center">
              <div tw="text-primary-500 font-medium mb-1">
                {currentAutogiftRule.contactListName}
              </div>
              <div
                tw="text-3xl font-medium leading-9 capitalize"
                css={{ letterSpacing: "-0.01em" }}
              >
                {startCase(currentAutogiftRule.eventType)} Autogift
              </div>
            </div>
          </div>
        </div>
      </div>
      <div tw="absolute w-full">
        <AutogiftConfigSwitch
          initialSelection="Settings"
          onSelectSwitch={onSelectSwitch}
        />
      </div>
      {selectedSwitchOption === "Upcoming" ? (
        <AutogiftUpcoming />
      ) : (
        <AutogiftSettings
          closeAutogiftRuleConfigScreen={onClose}
          showTenure={currentAutogiftRule.eventType === "work_anniversary"}
          showOnboarding={currentAutogiftRule.eventType === "onboarding"}
        />
      )}
    </div>
  )
}

export default AutogiftConfig
