import React, { FC } from "react"
import Modal from "react-modal"
import { Link } from "react-router-dom"

import { formatPhone } from "../../common/format"
import { useAuth } from "../../common/hooks"
import { modalStyle } from "../../common/modal"
import { generateRealmPath } from "../../common/realm"

interface Props {
  isOpen: boolean
  setClosed: () => void
  onSuccess: (needsPhoneVerify: boolean) => void
  authPhone: string
  setAuthPhone: (phone: string) => void
}
const LoginModal: FC<Props> = ({
  isOpen,
  setClosed,
  authPhone,
  setAuthPhone,
  onSuccess,
}) => {
  const { handleLogin, loginLoading } = useAuth()

  const tryLogin = () => {
    handleLogin({
      phone: authPhone,
      onSuccess: () => {
        setClosed()
        onSuccess(true)
      },
      onError: (message) => {
        alert(message || "Sorry, an error occurred.")
      },
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      closeTimeoutMS={500}
      onRequestClose={setClosed}
      onAfterClose={() => {}}
      shouldCloseOnOverlayClick={true}
      style={modalStyle}
    >
      <div className="modal-content">
        <div tw="p-6">
          <div tw="pb-2 text-center text-xl font-medium text-primary-500">
            Sign in
          </div>
          <div tw="pb-6 text-gray-400 text-center">
            Enter your phone number to sign in.
          </div>
          <input
            type="tel"
            value={authPhone}
            onChange={(e) => {
              setAuthPhone(formatPhone(e.target.value))
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                tryLogin()
              }
            }}
            placeholder="(000) 000-0000"
            tw="block w-full text-center text-3xl border border-gray-200 py-4 focus:ring-4 focus:outline-none"
            autoFocus={true}
            className="data-hj-suppress ph-no-capture fs-exclude"
            disabled={loginLoading}
          />
          <div tw="pt-4">
            <button
              tw="block w-full bg-primary-500 text-white text-lg py-3 rounded-lg"
              onClick={tryLogin}
            >
              Sign in
            </button>
          </div>
        </div>
        <div tw="h-px w-full bg-gray-200" />
        <div tw="p-6 flex flex-col items-center">
          <div tw="text-gray-400 text-sm">Business customers</div>
          <Link
            tw="block w-full bg-primary-050 hover:bg-primary-100 active:bg-primary-200 text-primary-600 text-lg py-3 rounded-lg mt-4 text-center"
            to={generateRealmPath("business", "/signin")}
            onClick={() => setClosed()}
          >
            Sign in to Goody for Business
          </Link>
        </div>
      </div>
    </Modal>
  )
}

export default LoginModal
