import React from "react"

import { useGlobalState } from "../../common/GlobalState"
import PurpleGradientLink from "../../common/PurpleGradientLink"
import RainbowHeading from "../../common/RainbowHeading"
import { generateRealmPath } from "../../common/realm"
import WhiteBackgroundLink from "../../common/WhiteBackgroundLink"

const CallToAction = () => {
  const [signedIn] = useGlobalState("signedIn")

  return (
    <div tw="mt-24">
      <RainbowHeading
        as="div"
        tw="text-center font-medium text-xl pb-2 lg:pb-5"
      >
        See it in action.
      </RainbowHeading>
      <h2 tw="text-center font-bold text-3xl lg:text-5xl">
        Take Goody for a spin.
      </h2>
      <div
        tw="pt-6 px-4 mb-8 text-gray-600 text-2xl text-center mx-auto leading-normal"
        style={{ maxWidth: "700px" }}
      >
        When you sign up for Goody for Business, you'll get a{" "}
        <span tw="font-semibold">$20 gift credit</span> you can use to send your
        first gift.
      </div>
      <div tw="flex flex-col items-center sm:flex-row justify-center mt-4">
        {!signedIn ? (
          <PurpleGradientLink
            tw="w-48 mb-2 sm:mb-0 sm:mr-6 text-center"
            to={generateRealmPath("business", "/signup")}
          >
            Sign up — it’s free
          </PurpleGradientLink>
        ) : (
          <PurpleGradientLink
            tw="w-64 mb-2 sm:mb-0 sm:mr-6 text-center"
            to={generateRealmPath("plus", "/send")}
          >
            Go to Goody for Business
          </PurpleGradientLink>
        )}
        <WhiteBackgroundLink
          tw="w-48 text-black text-center"
          to={generateRealmPath("business", "/book-a-call")}
        >
          Book a Demo
        </WhiteBackgroundLink>
      </div>
    </div>
  )
}

export default CallToAction
