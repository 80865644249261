import React from "react"
import tw from "twin.macro"

import RealmToggle from "../../sites/shared/RealmToggle"

import { useWindowSize } from "@/common/hooks/useWindowSize"
import HeaderLogo from "@/sites/Public/components/HeaderLogo"

interface props {
  currentRealm: string | null
}

const SignUpToggleContainer = ({ currentRealm }: props) => {
  const { width } = useWindowSize()

  return (
    <div
      tw="absolute w-full pointer-events-none z-[60] max-w-[100vw] py-3"
      css={[currentRealm !== "plus" && tw`fixed`]}
      id="realmToggleContainer"
    >
      <div tw="w-screen 2xl:max-w-[1440px] flex flex-row items-center justify-start h-12 mx-auto hidden lg:flex px-5">
        {width > 1080 && <HeaderLogo />}
        <RealmToggle />
      </div>
    </div>
  )
}

export default SignUpToggleContainer
