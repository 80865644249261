import pluralize from "pluralize"
import Modal from "react-modal"

import CloseButton from "@/common/CloseButton"
import { modalStyle } from "@/common/modal"
import {
  Details_ProductFragment,
  VariantGroupFragment,
} from "@/types/graphql-types"

interface VariantsModalProps {
  isOpen: boolean
  onAfterClose?: () => void
  close: () => void

  variantsLabel?: string | null
  variants?: Details_ProductFragment["variants"]
  variantGroups?: VariantGroupFragment[] | null
}

interface VariantsPreviewProps {
  variants?: Details_ProductFragment["variants"]
}

const VariantsPreview = ({ variants }: VariantsPreviewProps) => (
  <div tw="grid grid-cols-2 gap-4 p-4">
    {(variants ?? []).map((variant) => (
      <div
        key={variant.name}
        tw="rounded-xl bg-white overflow-hidden flex flex-col border border-gray-300"
      >
        {variant.imageMedium?.url && (
          <div>
            <img
              src={variant.imageMedium.url}
              alt=""
              tw="w-full"
              css={{
                aspectRatio: `${variant.imageMedium.width} / ${variant.imageMedium.height}`,
              }}
            />
          </div>
        )}
        <div tw="p-4 flex-1">
          <div tw="font-medium pb-2">{variant.name}</div>
          {variant.subtitle && (
            <div tw="text-sm text-gray-600 leading-5">{variant.subtitle}</div>
          )}
        </div>
      </div>
    ))}
  </div>
)

const VariantGroupsPreview = ({
  variantGroups,
}: {
  variantGroups: VariantGroupFragment[]
}) => {
  return (
    <div tw="flex flex-col p-4 gap-4">
      {variantGroups.map(({ name, variantGroupOptions }) => (
        <div tw="w-full text-center items-center justify-center mb-12">
          <div tw="w-full font-medium mb-6">{name}</div>
          <div tw="flex flex-row flex-wrap items-center gap-4 justify-center">
            {variantGroupOptions?.map((variantGroupOption) => (
              <div
                key={variantGroupOption.name}
                tw="rounded-xl bg-white overflow-hidden flex flex-col border border-gray-300 w-p-31 justify-between self-stretch"
              >
                {variantGroupOption.image?.url && (
                  <div>
                    <img
                      src={variantGroupOption.image.url}
                      alt=""
                      tw="w-full"
                      css={{
                        aspectRatio: `${variantGroupOption.image.width} / ${variantGroupOption.image.height}`,
                      }}
                    />
                  </div>
                )}
                {!variantGroupOption.image && variantGroupOption.color && (
                  <div
                    tw="w-full h-28 sm:h-32 md:h-40 rounded-xl border-b"
                    style={{ backgroundColor: variantGroupOption.color }}
                  />
                )}
                <div tw="p-4 flex-initial">
                  <div tw="font-medium">{variantGroupOption.name}</div>
                  {variantGroupOption.subtitle && (
                    <div tw="text-sm text-gray-600 leading-5 pt-2">
                      {variantGroupOption.subtitle}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

const VariantsModal = ({
  isOpen,
  onAfterClose,
  close,
  variantsLabel,
  variants,
  variantGroups,
}: VariantsModalProps) => {
  const label = variantsLabel ? pluralize(variantsLabel) : "Options"

  return (
    <Modal
      isOpen={isOpen}
      closeTimeoutMS={500}
      onRequestClose={close}
      onAfterClose={onAfterClose}
      shouldCloseOnOverlayClick={true}
      style={modalStyle}
    >
      <div className="modal-content modal-content-wide">
        <div
          css={{
            height: "75vh",
            overflow: "auto",
          }}
        >
          <div tw="absolute top-0 right-0 p-6">
            <CloseButton onClick={close} />
          </div>
          <div tw="px-3 py-4 pt-8">
            <div tw="text-center text-1.5xl font-medium text-primary-500">
              {label}
            </div>
            <div tw="text-center text-lg text-gray-600 pt-1">
              Your recipient can select from among these {label.toLowerCase()}.
            </div>
          </div>
          {variantGroups && variantGroups.length > 0 ? (
            <VariantGroupsPreview variantGroups={variantGroups} />
          ) : (
            <VariantsPreview variants={variants} />
          )}
        </div>
      </div>
    </Modal>
  )
}

export default VariantsModal
