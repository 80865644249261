import React from "react"
import tw, { styled } from "twin.macro"

import VariantButtonInfo, { InfoPopup } from "./VariantButtonInfo"
import VariantButtonQuantity from "./VariantButtonQuantity"
import { ReactComponent as NewIcon } from "../../assets/icons/new.svg"
import { ProductImage } from "../ProductImage"
import { VariantsOrVariantGroupOptions } from "../types"

interface Props {
  variant: VariantsOrVariantGroupOptions | null
  count: number
  onClick: (e: React.MouseEvent) => void
  softDisabled?: boolean
  hardDisabled?: boolean
  selectable?: boolean
  isMultiple: boolean
  onIncrement?: () => void
  onDecrement?: () => void
  canIncrement?: boolean
  canDecrement?: boolean
  horizontalPopupPosition?: "left" | "right"
  imagesScalable?: boolean
}

// Variant button displays a variant or variant group option.
// Clickable to set the variant or select the variant group option.
// Incrementable/decrementable when there are multiple selectable variants.
export default function VariantButton({
  variant,
  count,
  onClick,
  softDisabled,
  hardDisabled,
  isMultiple,
  onIncrement,
  onDecrement,
  canIncrement,
  canDecrement,
  horizontalPopupPosition,
  imagesScalable,
  selectable = true,
}: Props) {
  const name = variant ? variant.name : "Let recipient choose"
  const subtitle = variant?.subtitle
    ? variant.subtitle
    : !variant
    ? "Your recipient can choose the option they prefer."
    : null

  const image =
    variant &&
    ("imageMedium" in variant
      ? variant.imageMedium
      : "image" in variant
      ? variant.image
      : null)

  // Don't show the popup if there is no subtitle or image
  const hasExtraInfo = subtitle || image

  const isSelected = count > 0 && selectable

  const isMultipleNonRecipChoice = isMultiple && !!variant

  return (
    <VariantButtonContainer
      tw="mb-2 mr-2"
      css={[isMultiple && tw`w-full relative`]}
    >
      <button
        tw="inline-flex flex-row items-center gap-3 p-1.5 pr-3 text-gray-450 rounded-lg border border-gray-200 transition-all"
        css={[
          isMultiple && tw`w-full`,
          isSelected &&
            tw`bg-primary-new-050 border-primary-new-100 text-primary-new-550 hover:(bg-primary-new-050 border-primary-new-100 text-primary-new-550)`,
          softDisabled && tw`bg-gray-100 border-gray-100`,
          hardDisabled &&
            tw`opacity-50 bg-white line-through pointer-events-none border-transparent hover:(border-transparent)`,
          selectable
            ? tw`hover:(bg-primary-new-000 border-primary-new-050 text-primary-new-500) active:(scale-[0.97] bg-primary-new-050)`
            : tw`bg-white cursor-default`,
          // Mirror left padding with right to equalize when no image
          !image && variant && tw`pl-3`,
          isMultipleNonRecipChoice && tw`pr-[112px]`,
        ]}
        onClick={onClick}
      >
        {image ? (
          <ProductImage
            tw="rounded-md h-[32px] w-[32px] transition-all"
            image={image}
            alt={variant?.name}
            css={[isSelected && tw`shadow-min-sm`]}
            scalable={imagesScalable}
          />
        ) : !variant ? (
          <div
            tw="rounded-md h-[32px] w-[32px] flex items-center justify-center text-primary-new-550 bg-white transition-all"
            css={[isSelected ? tw`shadow-min-sm` : tw`bg-primary-new-050`]}
          >
            <NewIcon />
          </div>
        ) : null}
        <div tw="min-h-[32px] flex flex-row items-center justify-center">
          <div
            tw="text-left"
            css={[
              // Additional padding for short names
              name.length <= 2 ? tw`px-2` : null,
            ]}
          >
            <div css={[isSelected && tw`font-medium`]}>{name}</div>
            <div tw="font-medium h-0 overflow-hidden">{name}</div>
          </div>
        </div>
      </button>
      {hasExtraInfo && (
        <VariantButtonInfo
          floatHorizontal={isMultiple}
          image={image ?? null}
          variant={variant}
          name={variant?.name}
          subtitle={subtitle}
          horizontalPopupPosition={horizontalPopupPosition}
          imagesScalable={imagesScalable}
        />
      )}
      {isMultipleNonRecipChoice && (
        <VariantButtonQuantity
          count={count}
          canDecrement={!!(canDecrement && count > 0)}
          onDecrement={onDecrement}
          canIncrement={canIncrement}
          onIncrement={onIncrement}
        />
      )}
    </VariantButtonContainer>
  )
}

const VariantButtonContainer = styled.div`
  button:hover + ${InfoPopup} {
    ${tw`opacity-100 scale-100 delay-500`}
  }
`
