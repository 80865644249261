import { gql } from "@apollo/client"

import { SHIPPING_COUNTRY_GROUP_FRAGMENT } from "@/graphql/ShippingCountryGroupFragment"

export const GIFT_OPTION_INTERFACE_DATA_FRAGMENT = gql`
  fragment Store_GiftOptionInterfaceData on GiftOptionInterface {
    id
    isAlcohol
    name
    slug
    subtitle
    description
    isFlexGift
    singleProductMode
    seo {
      metaTitle
      metaDescription
    }
    primaryImage {
      id
      imageLarge {
        url
        width
        height
      }
    }
    brand {
      id
      brandValues
      shippingPrice
      shippingCountriesFinal
      name
      slug
      restrictedStatesByCountry
      freeShippingMinimum
      hideMadeBySubtitle
      segments
    }
    sections {
      id
      name
      productList
    }
    isSingleProduct
    giftOptionImages {
      id
      imageXlarge {
        url
        width
        height
      }
      imageLarge {
        url
        width
        height
      }
      image {
        url
        width
        height
      }
    }
    priceMin
    priceMax
    recommendedOccasionIds
    logoImage {
      url
      height
      width
    }
    colors {
      primary
      background
    }
    shippingCountryGroup {
      ...ShippingCountryGroup
    }
  }
  ${SHIPPING_COUNTRY_GROUP_FRAGMENT}
`
